import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import opts from './vuetify_opts';
import VuetifyDialog from 'vuetify-dialog';

import '@mdi/font/css/materialdesignicons.css';
import 'vuetify-dialog/dist/vuetify-dialog.css';

Vue.use(Vuetify);
const vuetifyInstance = new Vuetify(opts);

Vue.use(VuetifyDialog, {
  context: {
    vuetifyInstance
  }
});

export default vuetifyInstance;
