












































import { FanfestEventClass } from '@/interfaces';
import { UserModule } from '@/store/user';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Events } from '../eventbus/events';
import { ChatMessageData } from './chat';
import ChatMessage from './ChatMessage.vue';

@Component({ components: { ChatMessage } })
export default class Chat extends Vue {
  @Prop({})
  readonly event!: FanfestEventClass;

  @Prop({})
  readonly isMobileBrowser!: boolean;

  @Prop({})
  readonly producerFeatures!: boolean;

  @Prop({ default: () => [] })
  readonly messages!: ChatMessageData[];

  readonly maxMessageLength = 280;

  input = '';
  wasLargeModal = false;
  isLargeModal = false;
  isLandscape = false;
  onOrientationChangeBound!: () => void;

  scrollTimeout!: ReturnType<typeof setTimeout>;

  $refs!: {
    chatMessageElement: HTMLDivElement;
  };

  mounted() {
    this.onOrientationChangeBound = this.onOrientationChange.bind(this);

    this.onOrientationChange();
    window.addEventListener('orientationchange', this.onOrientationChangeBound);
    window.addEventListener('resize', this.onOrientationChangeBound);

    /* setTimeout(() => {
      this.sendWelcomeMessage();
    }, 3000); */
  }

  beforeUnmount() {
    if ('onOrientationChangeBound' in this) {
      window.removeEventListener(
        'orientationchange',
        this.onOrientationChangeBound
      );
      window.removeEventListener('onresize', this.onOrientationChangeBound);
    }

    if ('scrollTimeout' in this) {
      clearTimeout(this.scrollTimeout);
    }
  }

  @Watch('messages')
  onMessagesChanged() {
    this.$nextTick(() => {
      this.$refs.chatMessageElement.scrollTop =
        this.$refs.chatMessageElement.scrollHeight;
    });
  }

  get isFloater(): boolean {
    return this.$vuetify.breakpoint.smAndDown && !this.isLandscape;
  }

  get isLogged(): boolean {
    return UserModule.isLogged;
  }

  onOrientationChange() {
    this.isLandscape = window.matchMedia(
      'all and (min-aspect-ratio: 13/9)'
    ).matches;
  }

  focus() {
    if (this.isMobileBrowser) {
      this.wasLargeModal = this.isLargeModal;
      this.isLargeModal = true;

      const shouldScroll =
        this.$refs.chatMessageElement.scrollHeight - 100 <=
        this.$refs.chatMessageElement.scrollTop +
          this.$refs.chatMessageElement.offsetHeight;

      if (shouldScroll) {
        this.scrollTimeout = setTimeout(() => {
          this.$refs.chatMessageElement.scrollTo({
            top: this.$refs.chatMessageElement.scrollHeight,
            behavior: 'smooth'
          });
        }, 200);
      }
    }
  }

  blur() {
    if (this.isMobileBrowser) {
      this.isLargeModal = this.wasLargeModal;
    }
  }

  async sendMessage(): Promise<void> {
    const message = this.input.trim().slice(0, this.maxMessageLength);
    if (!this.isLogged && !UserModule.isGuestPlus) {
      this.$emit(Events.OpenLogin, () => {
        this.$emit(Events.ChatSendMessage, message);
      });
    } else {
      this.$emit(Events.ChatSendMessage, message);
    }
    this.input = '';
  }

  async sendWelcomeMessage(): Promise<void> {
    if (!this.isLogged && !UserModule.isGuestPlus) return;
    // const message = this.$t('chat.welcomeMessage');
    const message = '👋';
    this.$emit(Events.ChatSendMessage, message);
  }

  close(): void {
    this.$emit(Events.CloseDialog);
  }
}
