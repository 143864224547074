




















































import {
  FanfestEventClass,
  VideoClass,
  VideoGraphQLTranslator
} from '@/interfaces';
import socket from '@/services/socket';
import { ClientToServerMessageTypes } from '@common/types/SocketProtocol';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { LiveEventDataClass } from '@common/types/LiveEvent';
import { VideoOrder } from '@/generated/graphql';
import { graphQLErrorAlert } from '@/util/GraphQL';
import VideoItem from '@/components/crud/Video/VideoItem.vue';

@Component({ components: { VideoItem } })
export default class ContentTab extends Vue {
  @Prop({})
  readonly event!: FanfestEventClass;

  @Prop({})
  readonly liveEventData!: LiveEventDataClass;

  embedURL = '';

  videos: VideoClass[] = [];

  async mounted () {
    this.fetchVideos();
  }

  get hasContent (): boolean {
    return !!this.liveEventData.embedURL;
  }

  hideContent (): void {
    socket.emit(ClientToServerMessageTypes.EmbedURL, this.event.objectId, '');
  }

  loadEmbed (): void {
    socket.emit(
      ClientToServerMessageTypes.EmbedURL,
      this.event.objectId,
      this.embedURL
    );
    this.embedURL = '';
  }

  loadVideo (video: VideoClass): void {
    socket.emit(
      ClientToServerMessageTypes.EmbedURL,
      this.event.objectId,
      video.manifest
    );
    this.embedURL = '';
  }

  async fetchVideos (): Promise<void> {
    try {
      const result = await this.$apollo.query({
        query: require('../../crud/Video/getActiveVideosByChannel.graphql'),
        variables: {
          order: VideoOrder.CreatedAtDesc,
          skip: 0,
          first: 100,
          channelName: this.event.channelPointer.name
        }
      });
      this.$set(
        this,
        'videos',
        result.data.videos.edges.map((i: any) => VideoGraphQLTranslator(i.node))
      );
    } catch (error) {
      graphQLErrorAlert(error);
    }
  }
}
