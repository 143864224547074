import {
  LiveEventDataInterface,
  LiveEventStreamInterface,
  MuteOrigin
} from '@common/types/LiveEvent';
import { SimulcastStatusEnum } from '@common/types/Simulfest';

export const liveEventData: LiveEventDataInterface = {
  started: true,
  transmitted: false,
  simulcasting: true,
  simulcastStatus: SimulcastStatusEnum.running,
  embedURL: '',
  eventURL: 'https://live.fanfest.io',
  streams: [],
  blockedUserIds: [],
  topic: {
    title: '',
    subtitle: '',
    show: false
  },
  presentation: null
};

export const getTestStreamSrc = () =>
  Math.random() > 0.5 ? '/test/video169.mp4' : '/test/portraitvideo.mp4';

export function generateTestStreams(total: number): LiveEventStreamInterface[] {
  const streams: LiveEventStreamInterface[] = [];
  for (let i = 0; i < total; i++) {
    streams.push({
      user: {
        name: 'User ' + i,
        objectId: 'xyz' + i
      },
      stream: {
        isPresenting: false,
        isInvited: false,
        isStreaming: false,
        isVIP: false,
        muteMicrophone: MuteOrigin.NONE,
        muteCamera: MuteOrigin.NONE,
        isOnStage: true,
        sessionChanged: new Date().toISOString()
      }
    });
  }
  return streams;
}
