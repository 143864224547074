export default [
  { url: '', name: 'None' },
  {
    url: 'https://www.instagram.com/p/CWGPJBuFo_C/?utm_source=ig_web_copy_link',
    name: 'Instagram post'
  },
  {
    url: 'https://www.instagram.com/tv/CZAiZ42hY5x/?utm_source=ig_web_copy_link',
    name: 'Instagram TV'
  },
  {
    url: 'https://twitter.com/parseplatform/status/1361034568799772675',
    name: 'Twitter text post'
  },
  {
    url: 'https://twitter.com/ManCity/status/1484541487555969032',
    name: 'Twitter image post'
  },
  {
    url: 'https://twitter.com/ManCity/status/1484533742731501573?cxt=HHwWioC9idvkj5opAAAA',
    name: 'Twitter video post'
  },
  { url: 'https://www.twitch.tv/chess', name: 'Twitch channel' },
  { url: 'https://www.twitch.tv/videos/1271542399', name: 'Twitch video' },
  {
    url: 'https://www.youtube.com/watch?v=CtcjrPCwojQ',
    name: 'Youtube video'
  },
  {
    url: 'https://youtu.be/Jv-WaCeuTyQ',
    name: 'Youtube video short'
  },
  {
    url: 'https://d1ubhz68i2p1v7.cloudfront.net/9b7faf2c-f4a7-4d3a-b832-c2aaea86a621/hls/PSGofficiel_l1cj6nrxsjm.m3u8',
    name: 'VOD'
  }
];
