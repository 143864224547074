













































































import { Component, Prop, Vue } from 'vue-property-decorator';
import DateHuman from '@/components/base/DateHuman.vue';
import { FanfestEventClass, FanfestEventMode } from '../../../interfaces';
import { LanguageEnum } from '@common/types/LanguageEnum';
import { Events } from '@/components/eventbus/events';
import EventBus from '@/components/eventbus/EventBus.vue';
import { appendCurrentQueryString } from '@/util/url';
import { DateTime } from 'luxon';
import { getShareableEventLink } from '@/util/share';
import { windowOpen } from '@/util/navigator';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const ics = require('ics');

@Component({ components: { DateHuman } })
export default class FanfestEventCard extends Vue {
  @Prop()
  readonly event!: FanfestEventClass;

  @Prop({ default: true })
  readonly showActions!: boolean;

  get chipString(): string {
    switch (this.event.mode) {
      case FanfestEventMode.Live:
        return this.$tc('fanfestEvent.chipLive');
      case FanfestEventMode.Future:
        return this.$tc('fanfestEvent.chipFuture');
      case FanfestEventMode.Replay:
      default:
        return this.$tc('fanfestEvent.chipReplay');
    }
  }

  get chipColor(): string {
    switch (this.event.mode) {
      case FanfestEventMode.Live:
        return 'error';
      case FanfestEventMode.Future:
        return 'primary';
      case FanfestEventMode.Replay:
      default:
        return 'black';
    }
  }

  get prettyLanguage(): string {
    if (this.event.language) {
      return LanguageEnum[this.event.language as keyof typeof LanguageEnum];
    }
    return '';
  }

  get eventDate(): string {
    return this.event.startDatetime?.toLocaleString(DateTime.DATE_MED);
  }

  get eventTime(): string {
    return this.event.startDatetime?.toLocaleString(DateTime.TIME_SIMPLE);
  }

  get isLive(): boolean {
    return this.event.mode === FanfestEventMode.Live;
  }

  onJoinClick(): void {
    this.$router.push(appendCurrentQueryString(this.event.url));
  }

  onSaveClick(): void {
    this.ics();
  }

  async ics(): Promise<void> {
    ics.createEvent(
      {
        start: [
          this.event.startDatetime.year,
          this.event.startDatetime.month,
          this.event.startDatetime.day,
          this.event.startDatetime.hour,
          this.event.startDatetime.minute
        ],
        title: this.event.title,
        description: this.event.description || '',
        url: await getShareableEventLink(this.event),
        status: 'CONFIRMED',
        busyStatus: 'BUSY',
        duration: {
          minutes: 30
        }
      },
      (error: Error | undefined, value: string) => {
        if (error) {
          EventBus.$emit(Events.AlertError, error);
          return;
        }
        windowOpen(
          'data:text/calendar;charset=utf8,' + encodeURIComponent(value)
        );
      }
    );
  }
}
