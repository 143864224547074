






import { Component, Vue } from 'vue-property-decorator';
import NewPasswordForm from '@/components/auth/NewPasswordForm.vue';

@Component({
  components: { NewPasswordForm }
})
export default class ParseFrameURL extends Vue {
  loading = false;
  component = '';
  bind = {};

  async mounted () {
    const url = new URL(location.href);
    const baselink = url.searchParams.get('link');
    if (baselink?.includes('request_password_reset')) {
      const token = url.searchParams.get('token');
      const username = url.searchParams.get('username');
      this.component = 'NewPasswordForm';
      this.$set(this, 'bind', {
        token,
        email: username
      });
    }
  }
}
