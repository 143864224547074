import {
  LiveEventDataInterface,
  LiveEventStreamInterface
} from '@common/types/LiveEvent';
import { PhenixStreamMap } from './phenix';

export function filterLiveStreamsLinkedWithPhenix(
  phenixStreamMap: PhenixStreamMap[],
  liveEventData: LiveEventDataInterface,
  filterStream: (stream: LiveEventStreamInterface) => boolean,
  testKey: string
) {
  const streams: Array<{
    key: string;
    phenix: PhenixStreamMap | null;
    live: LiveEventStreamInterface;
  }> = [];

  for (const liveStream of liveEventData.streams) {
    if (liveStream.user.objectId && filterStream(liveStream)) {
      const phenixStream = phenixStreamMap.find(
        (phenixStream) => phenixStream.objectId === liveStream.user.objectId
      );

      if (phenixStream) {
        streams.push({
          key: liveStream.user.objectId,
          phenix: phenixStream,
          live: liveStream
        });
      } else if (liveStream.user.objectId.startsWith('/')) {
        streams.push({
          key: liveStream.user.objectId,
          phenix: null,
          live: liveStream
        });
      } else {
        console.log('No phenixStream found for liveStream: ', liveStream);
      }
    }
  }

  console.log(`Current streams - ${testKey}:`, {
    visibleStreams: streams.map((s) => s.phenix),
    allLiveStreams: liveEventData.streams,
    allPhenixStreamMap: phenixStreamMap
  });

  return streams;
}

export function cleanupStream(stream: MediaStream | null): null {
  if (stream) {
    stream.getTracks().forEach((track) => {
      track.stop();
      stream.removeTrack(track);
    });
  }

  return null;
}
