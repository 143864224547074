











import { Component, Vue, Prop } from 'vue-property-decorator';
import { ChatMessageData } from './chat';
import ChatToast from './ChatToast.vue';
import { FanfestEventClass } from '@/interfaces';

const MAXIMUM_SHOW_TIME_SECONDS = 5;

@Component({ components: { ChatToast } })
export default class ChatFloatingNotifications extends Vue {
  @Prop({})
  readonly event!: FanfestEventClass;

  @Prop({ default: () => [] })
  readonly messages!: ChatMessageData[];

  refreshInterval!: ReturnType<typeof setInterval>;

  messagesToShow: ChatMessageData[] = [];

  mounted () {
    this.refreshInterval = setInterval(
      this.messageListRefresh.bind(this),
      1000
    );
  }

  beforeDestroy () {
    clearInterval(this.refreshInterval);
  }

  get maximumMessages () {
    return this.$vuetify.breakpoint.smAndDown ? 1 : 3;
  }

  messageListRefresh () {
    // at most maximuMessages
    const lastFew = this.messages.slice(
      this.messages.length - this.maximumMessages,
      this.messages.length
    );
    // that are no older than MAXIMUM_SHOW_TIME_SECONDS
    const filtered = lastFew.filter(
      (message) =>
        Math.abs(message.datetime.diffNow('seconds').seconds) <
        MAXIMUM_SHOW_TIME_SECONDS
    );
    this.$set(this, 'messagesToShow', filtered);
  }
}
