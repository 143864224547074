import { Roles } from '@common/types/Roles';
import FanfestConfigEdit from './FanfestConfigEdit.vue';
import FanfestConfigList from './FanfestConfigList.vue';

export default [
  {
    path: 'fanfestConfig',
    name: 'fanfestConfigList',
    component: FanfestConfigList,
    meta: { roles: [Roles.Admin] }
  },
  {
    path: 'fanfestConfig/:id?/edit',
    name: 'fanfestConfigEdit',
    component: FanfestConfigEdit,
    meta: { roles: [Roles.Admin, Roles.ChannelAdmin] }
  }
];
