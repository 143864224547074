











































































































































import {
  FanfestEventClass,
  FanfestEventGraphQLTranslator
} from '../../../interfaces';
import { empty } from './data';
import { Component, Vue, Ref, Watch } from 'vue-property-decorator';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import { Id } from '@/interfaces/base';
import EventBus from '@/components/eventbus/EventBus.vue';
import { Events } from '@/components/eventbus/events';
import { graphQLErrorAlert } from '@/util/GraphQL';
import AutocompletePointer from '@/components/crud/Base/AutocompletePointer.vue';
import { UserModule } from '@/store/user';
import { Roles } from '@common/types/Roles';
import DatetimePicker from '@/components/base/DatetimePicker.vue';
import { DateTime } from 'luxon';
import { getShareableEventLink } from '@/util/share';
import { getLocation, writeToClipboard } from '@/util/navigator';

@Component({
  components: {
    ValidationObserver,
    ValidationProvider,
    AutocompletePointer,
    DatetimePicker
  }
})
export default class FanfestEventEdit extends Vue {
  @Ref() readonly observer!: InstanceType<typeof ValidationProvider>;
  fanfestEvent: FanfestEventClass = new FanfestEventClass({
    ...empty,
    startDatetime: DateTime.local().plus({ minutes: 60 }),
    isActive: true
  });

  readonly fanfestConfigQuery = require('../FanfestConfig/autocompleteFanfestConfigs.graphql');

  loading = false;
  eventBackground: File | null = null;
  eventBackgroundPreview: string | null = null;

  @Watch('eventBackground')
  onEventBackgroundPreviewChanged(newval: File | null) {
    if (!newval) {
      this.eventBackgroundPreview =
        this.fanfestEvent?.eventBackground?.url || '';
    } else {
      this.eventBackgroundPreview = URL.createObjectURL(newval);
    }
  }

  get channelEditable(): boolean {
    return UserModule.user.role === Roles.Admin && !this.fanfestEvent.objectId;
  }

  get isAdmin(): boolean {
    return UserModule.user.role === Roles.Admin;
  }

  async mounted(): Promise<void> {
    if (this.$route.params.id) {
      await this.load(this.$route.params.id);
    } else if (this.$route.query.clone) {
      await this.clone(this.$route.query.clone as string);
    } else if (UserModule.user.worksChannelPointer) {
      this.fanfestEvent = new FanfestEventClass({
        ...this.fanfestEvent,
        channelPointer: UserModule.user.worksChannelPointer
      });
    }
    if (!this.isAdmin) {
      this.fanfestEvent.channelPointer.objectId =
        UserModule.user.worksChannelPointer?.objectId || '';
    }

    if (this.fanfestEvent.eventBackground?.url) {
      this.eventBackgroundPreview = this.fanfestEvent.eventBackground?.url;
    } else if (UserModule.user.worksChannelPointer?.eventBackground?.url) {
      this.eventBackgroundPreview =
        UserModule.user.worksChannelPointer?.eventBackground?.url;
    }
  }

  async clone(id: Id): Promise<void> {
    try {
      this.loading = true;
      const event = await this.fetch(id);

      // clone relevant fields
      this.$set(this.fanfestEvent, 'channelPointer', event.channelPointer);
      this.$set(this.fanfestEvent, 'cost', event.cost);
      this.$set(this.fanfestEvent, 'title', event.title);
      this.$set(this.fanfestEvent, 'description', event.description);
      this.$set(this.fanfestEvent, 'language', event.language);

      if (event.eventBackground?.url) {
        await fetch(event.eventBackground.url)
          .then((res) => res.blob())
          .then((blob) => {
            this.eventBackground = new File(
              [blob],
              event.eventBackground?.name || 'image.jpg'
            );
          });
      }
    } catch (error) {
      graphQLErrorAlert(error);
    } finally {
      this.loading = false;
    }
  }

  async fetch(id: Id): Promise<FanfestEventClass> {
    const result = await this.$apollo.query({
      // Query
      query: require('./getFanfestEvent.graphql'),
      // Parameters
      variables: {
        id: id
      }
    });

    return FanfestEventGraphQLTranslator(result.data.fanfestEvent);
  }

  async load(id: Id): Promise<void> {
    try {
      const event = await this.fetch(id);
      this.$set(this, 'fanfestEvent', event);
      if (
        this.fanfestEvent.eventBackground &&
        this.fanfestEvent.eventBackground.url
      ) {
        this.eventBackgroundPreview = this.fanfestEvent.eventBackground.url;
      }
    } catch (error) {
      graphQLErrorAlert(error);
    } finally {
      this.loading = false;
    }
  }

  async save(): Promise<void> {
    this.observer.validate();
    const location = await getLocation();
    const fanfestEventData: { [k: string]: any } = {
      channelPointer: {
        link: this.fanfestEvent.channelPointer?.objectId
      },
      title: this.fanfestEvent.title,
      description: this.fanfestEvent.description,
      language: this.fanfestEvent.language || undefined,
      isActive: this.fanfestEvent.isActive,
      shouldSendNotifications:
        this.fanfestEvent.shouldSendNotifications ?? false,
      startDatetime: this.fanfestEvent.startDatetime,
      hostUrl: location.origin + location.pathname
    };

    if (this.eventBackground) {
      fanfestEventData.eventBackground = {
        upload: this.eventBackground
      };
    }

    try {
      this.loading = true;
      if (this.fanfestEvent.id) {
        await this.$apollo.mutate({
          // Query
          mutation: require('./updateFanfestEvent.graphql'),
          // Parameters
          variables: {
            id: this.fanfestEvent.id,
            fanfestEvent: fanfestEventData
          }
        });

        this.$router.push({ path: '/dashboard/fanfestEvent' });
      } else {
        const response = await this.$apollo.mutate({
          mutation: require('./createFanfestEvent.graphql'),
          // Parameters
          variables: {
            fanfestEvent: fanfestEventData
          }
        });

        const createdEvent = FanfestEventGraphQLTranslator({
          ...response.data.createFanfestEvent.fanfestEvent,
          channelPointer: this.fanfestEvent.channelPointer
        });

        try {
          await writeToClipboard(await getShareableEventLink(createdEvent));

          EventBus.$emit(Events.AlertSuccess, this.$tc('share.clipboard'));
        } catch (err) {
          EventBus.$emit(Events.AlertSuccess, this.$t('form.saved'));
        }

        this.$router.push({ path: '/dashboard/fanfestEvent' });
      }
    } catch (error) {
      graphQLErrorAlert(error);
    } finally {
      this.loading = false;
    }
  }
}
