






import { Component, Vue } from 'vue-property-decorator';
import UserAccept from '@/components/crud/User/UserAccept.vue';

@Component({ components: { UserAccept } })
export default class UserAcceptPage extends Vue {}
