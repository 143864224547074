




import { Component, Vue, Prop } from 'vue-property-decorator';
import { DateISOString } from '@/interfaces/base';
import { DateTime } from 'luxon';

@Component({
  components: {}
})
export default class DateISO extends Vue {
  @Prop({})
  readonly date!: DateISOString;

  get formatted (): string {
    return DateTime.fromISO(this.date).toLocaleString(DateTime.DATETIME_FULL);
  }
}
