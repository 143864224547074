





















































import { Vue, Component } from 'vue-property-decorator';
import NavigationListChannelAdmin from '@/components/dashboard/NavigationList.channelAdmin.vue';
import LargeIconButton from '@/components/base/LargeIconButton.vue';
import { UserModule } from '@/store/user';
import RecentEventList from '@/components/crud/FanfestEvent/RecentEventList.vue';

@Component({
  components: { LargeIconButton, NavigationListChannelAdmin, RecentEventList }
})
export default class DashboardChannelAdmin extends Vue {
  get channelEditURL(): string {
    return (
      '/dashboard/fanfestConfig/' +
      UserModule.user.worksChannelPointer?.id +
      '/edit'
    );
  }
}
