var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('h1',{staticClass:"text-h1 font-weight-light mb-12"},[_vm._v(" "+_vm._s(_vm.fanfestEvent.objectId ? _vm.$t('form.edit') : _vm.$t('form.create'))+" "+_vm._s(_vm.$t('fanfestEvent.objectName'))+" ")]),_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.save.apply(null, arguments)}}},[(_vm.isAdmin)?_c('validation-provider',{attrs:{"name":_vm.$t('user.role'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('autocomplete-pointer',{staticClass:"required",attrs:{"query":_vm.fanfestConfigQuery,"result-key":"fanfestConfigs","item-text":"name","item-value":"objectId","label":_vm.$t('fanfestEvent.channel'),"disabled":!_vm.channelEditable,"error-messages":errors},model:{value:(_vm.fanfestEvent.channelPointer.objectId),callback:function ($$v) {_vm.$set(_vm.fanfestEvent.channelPointer, "objectId", $$v)},expression:"fanfestEvent.channelPointer.objectId"}})]}}],null,true)}):_vm._e(),_c('validation-provider',{attrs:{"name":_vm.$t('fanfestEvent.title'),"rules":"required|max:250"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('f-text-field',{staticClass:"required",attrs:{"counter":250,"error-messages":errors,"label":_vm.$t('fanfestEvent.title'),"required":""},model:{value:(_vm.fanfestEvent.title),callback:function ($$v) {_vm.$set(_vm.fanfestEvent, "title", $$v)},expression:"fanfestEvent.title"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":_vm.$t('fanfestEvent.description'),"rules":"required|max:500"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('f-textarea',{staticClass:"required",attrs:{"counter":500,"error-messages":errors,"label":_vm.$t('fanfestEvent.description')},model:{value:(_vm.fanfestEvent.description),callback:function ($$v) {_vm.$set(_vm.fanfestEvent, "description", $$v)},expression:"fanfestEvent.description"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"startDatetime"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('datetime-picker',{staticClass:"required",attrs:{"text-field-props":{ outlined: true },"label":_vm.$t('fanfestEvent.startTime'),"use-iso":true,"required":""},model:{value:(_vm.fanfestEvent.startDatetime),callback:function ($$v) {_vm.$set(_vm.fanfestEvent, "startDatetime", $$v)},expression:"fanfestEvent.startDatetime"}}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(errors.length),expression:"errors.length"}],staticClass:"error--text"},_vm._l((errors),function(error,i){return _c('p',{key:i},[_vm._v(_vm._s(error))])}),0)]}}],null,true)}),_c('validation-provider',{attrs:{"name":_vm.$t('fanfestEvent.isActive')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-checkbox',{attrs:{"label":_vm.$t('fanfestEvent.isActive'),"error-messages":errors},model:{value:(_vm.fanfestEvent.isActive),callback:function ($$v) {_vm.$set(_vm.fanfestEvent, "isActive", $$v)},expression:"fanfestEvent.isActive"}})]}}],null,true)}),(
        _vm.isAdmin || _vm.fanfestEvent.channelPointer.enableShowCreationNotifications
      )?_c('validation-provider',{attrs:{"name":_vm.$t('fanfestEvent.shouldSendNotifications')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var errors = ref.errors;
return [_c('v-checkbox',{attrs:{"label":_vm.$t('fanfestEvent.shouldSendNotifications'),"error-messages":errors},model:{value:(_vm.fanfestEvent.shouldSendNotifications),callback:function ($$v) {_vm.$set(_vm.fanfestEvent, "shouldSendNotifications", $$v)},expression:"fanfestEvent.shouldSendNotifications"}})]}}],null,true)}):_vm._e(),_c('validation-provider',{attrs:{"name":"language"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var errors = ref.errors;
return [_c('language-select',{attrs:{"label":_vm.$t('fanfestEvent.language'),"error-messages":errors},model:{value:(_vm.fanfestEvent.language),callback:function ($$v) {_vm.$set(_vm.fanfestEvent, "language", $$v)},expression:"fanfestEvent.language"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":_vm.$t('fanfestEvent.eventBackground'),"rules":"image|eventBackgroundImage"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var errors = ref.errors;
return [_c('v-row',[_c('v-col',{attrs:{"cols":"9"}},[_c('v-file-input',{attrs:{"outlined":"","truncate-length":"15","error-messages":errors,"label":_vm.$t('fanfestEvent.eventBackground'),"prepend-icon":"camera","accept":"image/png, image/jpeg, image/webp, image/gif"},model:{value:(_vm.eventBackground),callback:function ($$v) {_vm.eventBackground=$$v},expression:"eventBackground"}})],1),_c('v-col',{attrs:{"cols":"3"}},[(_vm.eventBackgroundPreview)?_c('v-img',{attrs:{"src":_vm.eventBackgroundPreview}}):_vm._e()],1)],1)]}}],null,true)}),_c('div',{staticClass:"text-right"},[_c('v-btn',{staticClass:"mr-2 text-uppercase",attrs:{"outlined":""},on:{"click":function($event){return _vm.$router.back()}}},[_vm._v(" "+_vm._s(_vm.$t('form.cancel'))+" ")]),_c('v-btn',{staticClass:"text-uppercase",attrs:{"type":"submit","color":"primary","loading":_vm.loading,"disabled":_vm.loading || invalid}},[_vm._v(_vm._s(_vm.$t('form.save')))])],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }