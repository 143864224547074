<template>
  <v-dialog
    v-model="display"
    :width="dialogWidth"
    :persistent="persistent"
    @click:outside="outsiteHandler"
    @keydown.esc="outsiteHandler"
  >
    <template #activator="{ on }">
      <v-text-field
        v-bind="textFieldProps"
        :disabled="disabled"
        :loading="loading"
        :label="label"
        :value="formattedDatetime"
        readonly
        :error-messages="errorMessages"
        v-on="on"
      >
        <template #progress>
          <slot name="progress">
            <v-progress-linear
              color="primary"
              indeterminate
              absolute
              height="2"
            ></v-progress-linear>
          </slot>
        </template>
      </v-text-field>
    </template>

    <v-card>
      <v-card-text class="px-0 py-0">
        <v-tabs v-if="!withoutTime" v-model="activeTab" fixed-tabs>
          <v-tab key="calendar">
            <slot name="dateIcon">
              <v-icon>calendar_month</v-icon>
            </slot>
          </v-tab>
          <v-tab key="timer" :disabled="dateSelected">
            <slot name="timeIcon">
              <v-icon>schedule</v-icon>
            </slot>
          </v-tab>
          <v-tab-item key="calendar">
            <v-date-picker
              v-model="date"
              v-bind="datePickerProps"
              full-width
              @input="showTimePicker"
            ></v-date-picker>
          </v-tab-item>
          <v-tab-item key="timer">
            <div class="timeField">
              <v-text-field
                v-model="time"
                v-bind="textFieldProps"
                type="time"
                :step="60 * 1000"
                :label="label"
                :error-messages="errorMessages"
              ></v-text-field>
            </div>
          </v-tab-item>
        </v-tabs>
        <v-date-picker
          v-if="withoutTime"
          v-model="date"
          v-bind="datePickerProps"
          full-width
          @input="showTimePicker"
        ></v-date-picker>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <slot name="actions" :parent="this">
          <v-btn
            v-if="clearText.length > 0"
            color="grey lighten-1"
            text
            @click.native="clearHandler"
            >{{ clearText }}</v-btn
          >
          <v-btn color="green darken-1" text @click="okHandler">{{
            okText
          }}</v-btn>
          <v-btn
            v-if="cancelText.length > 0"
            color="grey lighten-1"
            text
            @click.native="cancelHandler"
            >{{ cancelText }}</v-btn
          >
        </slot>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
/**
 * This file comes from
 * https://github.com/darrenfang/vuetify-datetime-picker/blob/11c0b1e5ccd15252e8644f912c510bb7087935b5/src/components/DatetimePicker.vue
 */
import { format, parse, parseISO } from 'date-fns';
import { DateTime } from 'luxon';

const DEFAULT_DATE = '';
const DEFAULT_TIME = '00:00';
const DEFAULT_DATE_FORMAT = 'yyyy-MM-dd';
const DEFAULT_TIME_FORMAT = 'HH:mm';
const DEFAULT_DIALOG_WIDTH = 340;
const DEFAULT_CLEAR_TEXT = 'CLEAR';
const DEFAULT_OK_TEXT = 'OK';
const DEFAULT_CANCEL_TEXT = 'CANCEL';

export default {
  name: 'DatetimePicker',
  model: {
    prop: 'datetime',
    event: 'input'
  },
  props: {
    datetime: {
      type: [Date, String, Object],
      default: null
    },
    disabled: {
      type: Boolean
    },
    loading: {
      type: Boolean
    },
    persistent: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: ''
    },
    dialogWidth: {
      type: Number,
      default: DEFAULT_DIALOG_WIDTH
    },
    dateFormat: {
      type: String,
      default: DEFAULT_DATE_FORMAT
    },
    timeFormat: {
      type: String,
      default: 'HH:mm'
    },
    clearText: {
      type: String,
      default: DEFAULT_CLEAR_TEXT
    },
    okText: {
      type: String,
      default: DEFAULT_OK_TEXT
    },
    cancelText: {
      type: String,
      default: DEFAULT_CANCEL_TEXT
    },
    textFieldProps: {
      type: Object,
      default: () => ({})
    },
    datePickerProps: {
      type: Object,
      default: () => ({})
    },
    timePickerProps: {
      type: Object,
      default: () => ({})
    },
    useIso: {
      type: Boolean,
      default: false
    },
    withoutTime: {
      type: Boolean,
      default: false
    },
    errorMessages: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      display: false,
      activeTab: 0,
      date: DEFAULT_DATE,
      time: DEFAULT_TIME
    };
  },
  computed: {
    dateTimeFormat() {
      return this.withoutTime
        ? this.dateFormat
        : this.dateFormat + ' ' + this.timeFormat;
    },
    defaultDateTimeFormat() {
      return DEFAULT_DATE_FORMAT + ' ' + DEFAULT_TIME_FORMAT;
    },
    formattedDatetime() {
      return this.selectedDatetime
        ? format(this.selectedDatetime, this.dateTimeFormat)
        : '';
    },
    selectedDatetime() {
      if (this.date && this.time) {
        let datetimeString = this.date + ' ' + this.time;
        if (this.time.length === 4) {
          datetimeString += ':00';
        }
        return parse(datetimeString, this.defaultDateTimeFormat, new Date());
      } else {
        return null;
      }
    },
    dateSelected() {
      return !this.date;
    }
  },
  watch: {
    datetime: function () {
      this.init();
    }
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      if (!this.datetime) {
        this.date = DEFAULT_DATE;
        this.time = DEFAULT_TIME;
        return;
      }

      let initDateTime;
      if (this.datetime instanceof Date) {
        initDateTime = this.datetime;
      } else if (this.datetime instanceof DateTime) {
        initDateTime = this.datetime.toJSDate();
      } else if (
        typeof this.datetime === 'string' ||
        this.datetime instanceof String
      ) {
        // see https://stackoverflow.com/a/9436948
        initDateTime = this.useIso
          ? parseISO(this.datetime)
          : parse(this.datetime, this.dateTimeFormat, new Date());
      }

      if (this.withoutTime) {
        initDateTime.setHours(0, 0, 0, 0);
      }

      this.date = format(initDateTime, DEFAULT_DATE_FORMAT);
      this.time = format(initDateTime, DEFAULT_TIME_FORMAT);
    },
    okHandler() {
      this.resetPicker();
      return this.useIso
        ? this.$emit('input', this.selectedDatetime.toISOString())
        : this.$emit('input', this.selectedDatetime);
    },
    clearHandler() {
      this.resetPicker();
      this.date = DEFAULT_DATE;
      this.time = DEFAULT_TIME;
      this.$emit('input', null);
    },
    cancelHandler() {
      this.resetPicker();
      this.init();
    },
    outsiteHandler() {
      if (!this.persistent) {
        this.cancelHandler();
      }
    },
    resetPicker() {
      this.display = false;
      this.activeTab = 0;
      if (this.$refs.timer) {
        this.$refs.timer.selectingHour = true;
      }
    },
    showTimePicker() {
      this.activeTab = 1;
    }
  }
};
</script>

<style lang="scss" scoped>
.timeField {
  margin: 1rem 0.5rem 0;
}
</style>
