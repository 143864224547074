






import { Component, Vue, Prop } from 'vue-property-decorator';
import VideoShow from '@/components/crud/Video/VideoShow.vue';
import ContentAspectRatio from '../ContentAspectRatio.vue';

@Component({ components: { ContentAspectRatio, VideoShow } })
export default class VideoEmbed extends Vue {
  @Prop({})
  readonly url!: string;
}
