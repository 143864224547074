
















































import { Vue, Component } from 'vue-property-decorator';
import LargeIconButton from '@/components/base/LargeIconButton.vue';

@Component({ components: { LargeIconButton } })
export default class DashboardAdmin extends Vue {}
