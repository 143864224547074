
































import { Component, Vue } from 'vue-property-decorator';
import NavigationListAdmin from '@/components/dashboard/NavigationList.admin.vue';
import NavigationListChannelAdmin from '@/components/dashboard/NavigationList.channelAdmin.vue';
import NavigationListProducer from '@/components/dashboard/NavigationList.producer.vue';
import DashboardHeaderSlotAdmin from '@/components/dashboard/DashboardHeaderSlot.admin.vue';
import DashboardHeaderSlotChannel from '@/components/dashboard/DashboardHeaderSlot.channel.vue';
import { UserModule } from '@/store/user';
import { Roles } from '@common/types/Roles';
import Logo from '@/components/util/Logo.vue';
import UserDropdown from '@/components/base/UserDropdown.vue';

@Component({
  components: {
    Logo,
    NavigationListAdmin,
    NavigationListChannelAdmin,
    NavigationListProducer,
    DashboardHeaderSlotAdmin,
    DashboardHeaderSlotChannel,
    UserDropdown
  }
})
export default class Dashboard extends Vue {
  drawer = true;

  created() {
    this.drawer = !this.showNavIcon;
  }

  get showNavIcon(): boolean {
    return this.$vuetify.breakpoint.mdAndDown;
  }

  get userName(): string {
    return UserModule.user.name;
  }

  get userEmail(): string {
    return UserModule.user.email;
  }

  get dashboardComponentHeaderSlot(): string | null {
    switch (UserModule.user.role) {
      case Roles.Admin:
        return 'DashboardHeaderSlotAdmin';
      case Roles.ChannelAdmin:
      case Roles.Producer:
      case Roles.Moderator:
        return 'DashboardHeaderSlotChannel';
      default:
        return null;
    }
  }

  get channelURL(): string {
    return UserModule.myChannelURI || '';
  }

  get dashboardComponentNavigation(): string | null {
    switch (UserModule.user.role) {
      case Roles.Admin:
        return 'NavigationListAdmin';
      case Roles.ChannelAdmin:
        return 'NavigationListChannelAdmin';
      case Roles.Producer:
        return 'NavigationListProducer';
      default:
        return null;
    }
  }
}
