




































































import {
  FanfestConfigClass,
  FanfestConfigGraphQLTranslator
} from '../../../interfaces';
import { Component, Vue, Watch } from 'vue-property-decorator';
import { FanfestConfigOrder } from '@/generated/graphql';
import DateISO from '@/components/base/DateISO.vue';
import BooleanChip from '@/components/base/BooleanChip.vue';
import EventBus from '@/components/eventbus/EventBus.vue';
import { Events } from '@/components/eventbus/events';
import { graphQLErrorAlert } from '@/util/GraphQL';
import { debounce } from 'typescript-debounce-decorator';

@Component({
  components: { DateISO, BooleanChip }
})
export default class FanfestConfigList extends Vue {
  private loading = false;

  private initialItem = 0;

  private perPage = 20;

  private totalItems = -1;

  headers = [
    {
      text: this.$t('fanfestConfig.name'),
      align: 'start',
      sortable: false,
      value: 'name'
    },
    { text: this.$t('fanfestConfig.isActive'), value: 'isActive' },
    { text: this.$t('fanfestConfig.sponsorName'), value: 'sponsorName' },
    { text: this.$t('form.createdAt'), value: 'createdAt' },
    { text: this.$t('form.actions'), value: 'actions', sortable: false }
  ];

  fanfestConfigs = [];

  order: FanfestConfigOrder = FanfestConfigOrder.CreatedAtDesc;

  search = '';

  async created (): Promise<void> {
    this.fetch();
  }

  @Watch('$route')
  onRouteChanged () {
    this.fetch();
  }

  @Watch('search')
  @debounce(250)
  onSearchChanged () {
    this.fetch();
  }

  async updateItemsPerPage (perPage: number): Promise<void> {
    this.perPage = perPage;
    await this.fetch();
  }

  async updatePage (page: number): Promise<void> {
    this.initialItem = (page - 1) * this.perPage;
    await this.fetch();
  }

  async fetch (): Promise<void> {
    this.loading = true;
    try {
      this.loading = true;
      const result = await this.$apollo.query({
        query: require('./getFanfestConfigs.graphql'),
        variables: {
          order: [this.order],
          skip: this.initialItem,
          first: this.perPage,
          name: this.search || ''
        }
      });
      this.$set(
        this,
        'fanfestConfigs',
        result.data.fanfestConfigs.edges.map((i: any) =>
          FanfestConfigGraphQLTranslator(i.node)
        )
      );
      this.totalItems = result.data.fanfestConfigs.count;
    } catch (error) {
      graphQLErrorAlert(error);
    } finally {
      this.loading = false;
    }
  }

  async deleteItem (item: FanfestConfigClass): Promise<void> {
    if (!confirm(this.$t('form.reallyDelete') as string)) {
      return;
    }
    try {
      await this.$apollo.mutate({
        mutation: require('./deleteFanfestConfig.graphql'),
        variables: {
          id: item.id
        }
      });
      EventBus.$emit(Events.AlertSuccess, this.$t('form.deleted'));
      await this.fetch();
    } catch (error: any) {
      graphQLErrorAlert(error);
    }
  }
}
