import Vue from 'vue';
import { VTextField } from 'vuetify/lib';

Vue.component('FTextField', {
  extends: VTextField,
  props: {
    outlined: {
      type: Boolean,
      default: true
    }
  }
});
