







import { Vue, Component } from 'vue-property-decorator';
import { UserModule } from '@/store/user';

@Component({ components: {} })
export default class DashboardHeaderSlotChannel extends Vue {
  get channelURL (): string {
    return UserModule.myChannelURI || '';
  }
}
