import { FileInfo, Maybe, Offer, Scalars } from '@/generated/graphql';
import { FanfestConfigClass } from '.';
import { empty as FanfestConfigEmpty } from '../components/crud/FanfestConfig/data';

/**
 * Patch the frontend types that we don't have on GraphQL and remove
 * stuff we don't need
 */
export type OfferInterface = Omit<Offer, 'ACL' | 'channelPointer'> & {
  channelPointer: FanfestConfigClass;
};

export class OfferClass implements OfferInterface {
  id = '';
  createdAt: any;
  objectId = '';
  updatedAt: any;
  __typename?: 'Offer' | undefined;
  channelPointer: FanfestConfigClass;
  image?: Maybe<FileInfo> | undefined;
  isActive = true;
  subtitle?: Maybe<string> | undefined;
  title: Scalars['String'] = '';
  url?: Maybe<string> | undefined;

  constructor (data: OfferInterface | Offer | OfferClass) {
    Object.assign(this, data);
    this.channelPointer = new FanfestConfigClass(
      data.channelPointer ? data.channelPointer : FanfestConfigEmpty
    );
  }
}

/**
 * Converts data that comes from GraphQL to the expected values/types.
 *
 * @param configData GraphQL data
 * @returns A properly built interface
 */
export function OfferGraphQLTranslator (eventData: Offer): OfferClass {
  const event = new OfferClass({ ...eventData });
  return event;
}
