import { UserClass } from '@/interfaces';
import Amplitude from 'amplitude-js';
import LogRocket from 'logrocket';
import Hotjar from '@hotjar/browser';
import './sprig';

function shouldTrack(): boolean {
  return process.env.NODE_ENV !== 'development';
}

// no tracking
if (shouldTrack()) {
  LogRocket.init('cphkqf/fanfestr2');
  Amplitude.getInstance().init('0fb0f89bc119a7c980f7669cdd7fe776');
  Hotjar.init(3537920, 6);
}

declare global {
  interface Window {
    gtag: any;
    Sprig: any;
  }
}

export enum TrackEventEnum {
  Login = 'Login',
  RegistrationFinished = 'Registration Finished',
  FanIdentified = 'Fan Identified',
  EventJoined = 'Event Joined',
  EventLeft = 'Event Left',
  RaisedHand = 'Raised Hand',
  StartPresentation = 'Start Presentation',
  StopPresentation = 'Stop Presentation',
  FanBarJoined = 'Fan Bar Joined',
  StageJoined = 'Stage Joined',
  StageLeft = 'Stage Left',
  SetUserVIP = 'Set User VIP',
  GenerateShareLink = 'Generate Share Link',
  TopicWatched = 'Topic Watched',
  OfferWatched = 'Offer Watched',
  OfferClicked = 'Offer Clicked',
  ChatMessage = 'Chat Message',
  EventStarted = 'Event Started',
  EventEnded = 'Event Ended',
  ShowViewed = 'Live Show Viewed',
  ReplayViewed = 'Replay Viewed',
}

export enum EventCategory {
  Engagement = 'engagement',
  Growth = 'growth'
}

export class Analytics {
  static setUser(user: UserClass) {
    if (!shouldTrack()) {
      return;
    }

    LogRocket.identify(user.objectId, {
      name: user.name,
      email: user.email
    });

    window.gtag('set', { user_id: 'USER_ID' });

    window.Sprig('setUserId', user.objectId);
    window.Sprig('setEmail', user.email);
    window.Sprig('setAttribute', 'name', user.name);

    Amplitude.getInstance().setUserId(user.objectId);
    Amplitude.getInstance().setUserProperties({
      Name: user.name,
      Email: user.email,
      Role: user.role
    });
  }

  static setUserProperties(data: Record<string, string>): void {
    Amplitude.getInstance().setUserProperties(data);
  }

  static _eventNameToGoogle(event: TrackEventEnum): string {
    return event.replaceAll(' ', '_').toLocaleLowerCase();
  }

  static track(
    event: TrackEventEnum,
    category: EventCategory = EventCategory.Engagement,
    baseData: Record<string, string> = {},
    nonInteraction: boolean | null = null
  ) {
    if (!shouldTrack()) {
      return;
    }
    const data: { [k: string]: any } = {
      ...baseData,
      event_category: category
    };
    if (nonInteraction) {
      data.non_interaction = nonInteraction;
    }
    // window.gtag('event', Analytics._eventNameToGoogle(event), data);
    Amplitude.getInstance().logEvent(event, data);
  }
}
