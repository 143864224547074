import axios from 'axios';
import type { Realtime } from 'ably';

declare global {
  interface Window {
    __ablySdk: Realtime | null;
  }
}

interface ChatVisibilityStatus {
  isDisabled: boolean;
}

window.__ablySdk = null;

const pub = 'status';
function getChannel(ably: Realtime, showId: string) {
  return ably.channels.get(`fanfest:${showId}:f2-chat-status`, {
    params: {
      rewind: '1'
    }
  });
}

export async function listenToIsChatEnabled(
  showId: string,
  onChange: (isEnabled: boolean) => void
) {
  const ably = await getOrInitializeAblySdk();
  const channel = getChannel(ably, showId);

  channel.subscribe(pub, async (msg) => {
    // eslint-disable-next-line no-console
    console.log(`Received chat status update: ${JSON.stringify(msg.data)}`);
    const data = msg.data as ChatVisibilityStatus;
    onChange(!data.isDisabled);
  });

  return () => {
    channel.unsubscribe(pub);
  };
}

export async function sendIsChatEnabledUpdate(
  showId: string,
  isEnabled: boolean
) {
  const ably = await getOrInitializeAblySdk();
  const channel = getChannel(ably, showId);

  const data: ChatVisibilityStatus = {
    isDisabled: !isEnabled
  };
  channel.publish(pub, data);
}

async function getOrInitializeAblySdk() {
  // Only initialize the Ably SDK once
  if (window.__ablySdk !== null) return window.__ablySdk;

  const Ably = await import('ably');

  // Initialize Ably SDK with token from server (ours)
  window.__ablySdk = new Ably.Realtime({
    transportParams: { remainPresentFor: 1000 },
    authCallback: async (_, callback) => {
      try {
        const response = await axios.post(
          'https://live.fanfest.io/.netlify/functions/ably',
          {},
          {
            headers: {
              'Content-Type': 'application/json'
            }
          }
        );
        const tokenRequest = response.data;
        callback(null, tokenRequest);
      } catch (error) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        callback(error, null);
      }
    }
  });
  window.__ablySdk.connection
    .once('connected')
    .then(() => console.log('Connected to Ably'));

  return window.__ablySdk;
}
