























































































































import { Component, Prop, Vue } from 'vue-property-decorator';
import {
  ClientToServerMessageTypes,
  EventUser
} from '@common/types/SocketProtocol';
import socket from '@/services/socket';
import ActionsMenu from '../../chat/ActionsMenu.vue';
import { FanfestEventClass } from '@/interfaces';
import { Analytics, EventCategory, TrackEventEnum } from '@/services/tracking';
import { Roles } from '@common/types/Roles';
import { MuteOrigin } from '@common/types/LiveEvent';

@Component({ components: { ActionsMenu } })
export default class UserItem extends Vue {
  @Prop({})
  readonly event!: FanfestEventClass;

  @Prop({})
  readonly user!: EventUser;

  MuteOrigin = Object.freeze(MuteOrigin);

  get isGuestPlus(): boolean {
    return this.user.user.role !== Roles.GuestPlus;
  }

  get isStreaming(): boolean {
    return this.user.stream.isStreaming;
  }

  get isOnFanBar(): boolean {
    return this.isStreaming && !this.user.stream.isOnStage;
  }

  get userItemNameClass(): string {
    if (this.user.stream.isVIP) {
      return 'user-item__name--vip';
    }
    if (
      this.user.user.role === Roles.Producer ||
      this.user.user.role === Roles.ChannelAdmin
    ) {
      return 'user-item__name--producer';
    }
    return 'user-item__name';
  }

  inviteUserToStage(): void {
    socket.emit(
      ClientToServerMessageTypes.InviteUserToStage,
      this.event.objectId,
      this.user.user.objectId
    );
  }

  sendToFanBar(): void {
    socket.emit(
      ClientToServerMessageTypes.SetUserStage,
      this.event.objectId,
      this.user.user.objectId,
      false
    );
    Analytics.track(
      TrackEventEnum.FanBarJoined,
      EventCategory.Engagement,
      this.event.trackData()
    );
  }

  sendUserToStage(): void {
    socket.emit(
      ClientToServerMessageTypes.SetUserStage,
      this.event.objectId,
      this.user.user.objectId,
      true
    );
    Analytics.track(
      TrackEventEnum.StageJoined,
      EventCategory.Engagement,
      this.event.trackData()
    );
  }

  closeVideo(): void {
    socket.emit(
      ClientToServerMessageTypes.StopUserCamera,
      this.event.objectId,
      this.user.user.objectId
    );
    Analytics.track(
      TrackEventEnum.StageLeft,
      EventCategory.Engagement,
      this.event.trackData()
    );
  }

  toggleVIP(): void {
    socket.emit(
      ClientToServerMessageTypes.SetUserVIP,
      this.event.objectId,
      this.user.user.objectId,
      !this.user.stream.isVIP
    );
    if (!this.user.stream.isVIP) {
      Analytics.track(
        TrackEventEnum.SetUserVIP,
        EventCategory.Engagement,
        this.event.trackData()
      );
    }
  }

  toggleMuteCamera(): void {
    socket.emit(
      ClientToServerMessageTypes.SetUserMuteCamera,
      this.event.objectId,
      this.user.user.objectId,
      this.user.stream.muteCamera === MuteOrigin.NONE
    );
  }

  toggleMuteMicrophone(): void {
    socket.emit(
      ClientToServerMessageTypes.SetUserMuteMicrophone,
      this.event.objectId,
      this.user.user.objectId,
      this.user.stream.muteMicrophone === MuteOrigin.NONE
    );
  }
}
