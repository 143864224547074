import { VideoInterface, FanfestConfigClass } from '@/interfaces';
import { empty as FanfestConfigEmpty } from '../FanfestConfig/data';

const empty: VideoInterface = {
  id: '',
  objectId: '',

  name: '',
  manifest: '',
  isActive: false,
  isProcessed: false,
  video: {
    name: '',
    url: ''
  },
  channelPointer: new FanfestConfigClass(FanfestConfigEmpty),

  updatedAt: '',
  createdAt: ''
};

const test: VideoInterface = {
  id: '000000',
  objectId: '000000',
  name: 'Test video',
  manifest: '',
  isActive: false,
  isProcessed: false,
  video: {
    name: '',
    url: ''
  },
  channelPointer: new FanfestConfigClass(FanfestConfigEmpty),
  updatedAt: '2021-12-06T14:04:35.893Z',
  createdAt: '2021-12-06T14:04:35.893Z'
};

export { empty, test };
