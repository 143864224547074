

















import { Component, Vue, Prop, Watch, Ref } from 'vue-property-decorator';

@Component({ components: {} })
export default class Twitch extends Vue {
  @Ref('container') readonly containerElement!: InstanceType<
    typeof HTMLDivElement
  >;

  @Prop({})
  readonly url!: string;

  src = '';

  width = 100;
  height = 100;

  // bind event handlers to the `handleResize` method (defined below)
  mounted () {
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
    this.loadTwitch(this.url);
  }

  beforeDestroy () {
    window.removeEventListener('resize', this.handleResize);
  }

  handleResize (): void {
    const width = Math.min(
      this.containerElement.clientWidth,
      (this.containerElement.clientHeight * 16) / 9
    );
    this.width = width;
    this.height = (width * 9) / 16;
  }

  @Watch('url')
  onURLChange (newval: string | null) {
    this.loadTwitch(newval);
  }

  async loadTwitch (url: string | null): Promise<void> {
    if (!url) {
      return;
    }

    const parsed = new URL(url);

    if (parsed.pathname.indexOf('/videos') === 0) {
      const videoId = parsed.pathname.substring(8);
      this.src = `https://player.twitch.tv/?video=${videoId}&parent=${window.location.hostname}&muted=true`;
    } else {
      const channel = parsed.pathname.substring(1);
      if (!channel) {
        return;
      }
      this.src = `https://player.twitch.tv/?channel=${channel}&parent=${window.location.hostname}&muted=true`;
    }
  }
}
