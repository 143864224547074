import { FanfestConfig, FileInfo, Maybe } from '@/generated/graphql';
import { BaseRoute } from '@/router/routeNames';
import { RawLocation } from 'vue-router';
import { AddressClass } from './Address';
import { Color, DateISOString, Id, URLString } from './base';
import { empty as emptyAddress } from '../components/crud/Address/data';

/**
 * Patch the frontend types that we don't have on GraphQL and remove
 * stuff we don't need
 */
export type FanfestConfigInterface = Omit<
  FanfestConfig,
  'ACL' | 'image' | 'bannedRelation' | 'addressPointer'
> & {
  image: Maybe<FileInfo> | undefined;
};

export class FanfestConfigClass implements FanfestConfigInterface {
  __typename?: 'FanfestConfig' | undefined;
  objectId = '';
  id: Id = '';
  createdAt: DateISOString = '';
  updatedAt: DateISOString = '';

  name!: string;
  title!: string;
  isActive!: boolean;
  channelHeroColor!: Color;
  loginBG?: string | undefined;
  mainBG?: string | undefined;
  bannedRelation: any;
  description?: Maybe<string> | undefined;
  image: Maybe<FileInfo> | undefined;
  eventBackground?: Maybe<FileInfo> | undefined;
  channelBackground?: Maybe<FileInfo> | undefined;
  sponsorName?: Maybe<string> | undefined;
  sponsorImage?: Maybe<FileInfo> | undefined;
  addressPointer?: Maybe<AddressClass> | undefined;

  enableGatedEvents = false;
  enableShowCreationNotifications = false;

  constructor(
    data: FanfestConfig | FanfestConfigInterface | FanfestConfigClass
  ) {
    Object.assign(this, data);

    this.enableGatedEvents = data.enableGatedEvents ?? false;
    this.enableShowCreationNotifications =
      data.enableShowCreationNotifications ?? false;

    if ('addressPointer' in data && data.addressPointer) {
      this.addressPointer = new AddressClass(data.addressPointer);
    } else {
      this.addressPointer = new AddressClass(emptyAddress);
    }
  }

  get url(): URLString {
    return '/' + this.name;
  }

  get rawLocation(): RawLocation {
    return {
      name: BaseRoute.Channel,
      params: {
        name: this.name
      }
    };
  }

  trackData(): Record<string, string> {
    return {
      Channel: this.name
    };
  }
}

/**
 * Converts data that comes from GraphQL to the expected values/types.
 *
 * @param configData GraphQL data
 * @returns A properly built interface
 */
export function FanfestConfigGraphQLTranslator(
  configData: FanfestConfig
): FanfestConfigClass {
  const config = new FanfestConfigClass({ ...configData });
  return config;
}
