






































































import { Roles, RolesChannel } from '@common/types/Roles';
import { UserClass } from '../../../interfaces';
import { Component, Vue, Ref, Prop } from 'vue-property-decorator';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import EventBus from '@/components/eventbus/EventBus.vue';
import { Events } from '@/components/eventbus/events';
import { graphQLErrorAlert } from '@/util/GraphQL';
import { UserModule } from '@/store/user';
import AutocompletePointer from '@/components/crud/Base/AutocompletePointer.vue';
import { empty } from './data';

@Component({
  components: { ValidationObserver, ValidationProvider, AutocompletePointer }
})
export default class UserInvite extends Vue {
  user: UserClass = new UserClass({ ...empty, role: Roles.Fan });

  loading = false;

  Roles = Object.freeze([
    Roles.Admin,
    Roles.ChannelAdmin,
    Roles.Fan,
    Roles.Moderator,
    Roles.Producer
  ]);

  @Prop({ default: '' })
  readonly objectId!: string;

  // eslint-disable-next-line graphql/template-strings
  readonly fanfestConfigQuery = require('../FanfestConfig/autocompleteFanfestConfigs.graphql');

  @Ref() readonly observer!: InstanceType<typeof ValidationProvider>;

  get currentUserChannelId() {
    return UserModule.user.worksChannelPointer?.objectId;
  }

  get canSelectChannel(): boolean {
    return this.isAdmin && this.hasChannelPointer;
  }

  get isAdmin(): boolean {
    return UserModule.user.role === Roles.Admin;
  }

  get isChannelAdmin(): boolean {
    return UserModule.user.role === Roles.ChannelAdmin;
  }

  get hasChannelPointer(): boolean {
    return (
      this.user.role === Roles.Producer ||
      this.user.role === Roles.ChannelAdmin ||
      this.user.role === Roles.Moderator
    );
  }

  get roles(): ReadonlyArray<string> {
    // Momentarily filter out Fan from the list of roles since we need a channel to invite users to but Fans do not necessarily belong to a channel.
    const rolesWithoutChannel = (r: Roles) =>
      ![Roles.Fan, Roles.Guest, Roles.Admin].includes(r);
    return this.isAdmin
      ? Object.freeze(Object.values(Roles).filter(rolesWithoutChannel))
      : Object.freeze(RolesChannel.filter(rolesWithoutChannel));
  }

  async save(): Promise<void> {
    this.observer.validate();

    const channelId =
      (this.canSelectChannel
        ? this.user.worksChannelPointer?.objectId
        : this.currentUserChannelId) || null;

    if (!channelId) {
      EventBus.$emit(Events.AlertError, 'Need a channel to invite to');
      return;
    }
    if (!this.user.username) {
      EventBus.$emit(Events.AlertError, 'Need an email to invite to');
      return;
    }

    const userData = {
      email: this.user.username.toLocaleLowerCase(),
      role: this.user.role,
      channelId: channelId || null
    };

    try {
      this.loading = true;
      await this.$apollo.mutate({
        // Query
        mutation: require('./userInvite.graphql'),
        // Parameters
        variables: userData
      });

      EventBus.$emit(Events.AlertSuccess, this.$t('form.saved'));
      this.$router.push('/dashboard');
    } catch (error) {
      graphQLErrorAlert(error);
    } finally {
      this.loading = false;
    }
  }
}
