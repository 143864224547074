var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('h1',{staticClass:"text-h1 font-weight-light mb-12"},[_vm._v(" "+_vm._s(_vm.offer.objectId ? _vm.$t('form.edit') : _vm.$t('form.create'))+" "+_vm._s(_vm.$t('offer.objectName'))+" ")]),_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.save.apply(null, arguments)}}},[_c('autocomplete-pointer',{staticClass:"required",attrs:{"query":_vm.fanfestConfigQuery,"result-key":"fanfestConfigs","item-text":"name","item-value":"objectId","label":_vm.$t('offer.channel'),"disabled":!_vm.channelEditable},model:{value:(_vm.offer.channelPointer.objectId),callback:function ($$v) {_vm.$set(_vm.offer.channelPointer, "objectId", $$v)},expression:"offer.channelPointer.objectId"}}),_c('validation-provider',{attrs:{"name":_vm.$t('offer.title'),"rules":"required|max:30"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('f-text-field',{staticClass:"required",attrs:{"counter":30,"error-messages":errors,"label":_vm.$t('offer.title'),"required":""},model:{value:(_vm.offer.title),callback:function ($$v) {_vm.$set(_vm.offer, "title", $$v)},expression:"offer.title"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":_vm.$t('offer.subtitle'),"rules":"max:34"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('f-text-field',{attrs:{"counter":34,"error-messages":errors,"label":_vm.$t('offer.subtitle')},model:{value:(_vm.offer.subtitle),callback:function ($$v) {_vm.$set(_vm.offer, "subtitle", $$v)},expression:"offer.subtitle"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":_vm.$t('offer.url'),"rules":"url|max:500"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('f-text-field',{attrs:{"value":_vm.offer.url,"label":_vm.$t('offer.url'),"counter":200,"error-messages":errors},on:{"input":_vm.onUrlChange}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":_vm.$t('offer.isActive')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-checkbox',{attrs:{"label":_vm.$t('offer.isActive'),"error-messages":errors},model:{value:(_vm.offer.isActive),callback:function ($$v) {_vm.$set(_vm.offer, "isActive", $$v)},expression:"offer.isActive"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":_vm.$t('offer.image'),"rules":"image"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-row',[_c('v-col',{attrs:{"cols":"9"}},[_c('v-file-input',{attrs:{"outlined":"","truncate-length":"15","error-messages":errors,"label":_vm.$t('offer.image'),"prepend-icon":"camera","accept":"image/png, image/jpeg, image/webp, image/gif"},model:{value:(_vm.image),callback:function ($$v) {_vm.image=$$v},expression:"image"}})],1),_c('v-col',{attrs:{"cols":"3"}},[(_vm.imagePreview)?_c('v-img',{attrs:{"src":_vm.imagePreview}}):_vm._e()],1)],1)]}}],null,true)}),_c('h2',{staticClass:"text-h2 font-weight-light"},[_vm._v("Preview")]),_c('offer-show',{attrs:{"offer":_vm.offer}}),_c('div',{staticClass:"text-right"},[_c('v-btn',{staticClass:"mr-2 text-uppercase",attrs:{"outlined":""},on:{"click":function($event){return _vm.$router.back()}}},[_vm._v(" "+_vm._s(_vm.$t('form.cancel'))+" ")]),_c('v-btn',{staticClass:"text-uppercase",attrs:{"type":"submit","color":"primary","loading":_vm.loading,"disabled":_vm.loading || invalid}},[_vm._v(_vm._s(_vm.$t('form.save')))])],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }