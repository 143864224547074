import { io, Socket } from 'socket.io-client';
import {
  ClientToServerEvents,
  ServerToClientEvents
} from '@common/types/SocketIOInterfaces';
import EventBus from '@/components/eventbus/EventBus.vue';
import { Events } from '@/components/eventbus/events';

// please note that the types are reversed
const socket: Socket<ServerToClientEvents, ClientToServerEvents> = io(
  process.env.VUE_APP_SOCKET_URL || window.location.origin,
  {
    transports: ['websocket'],
    withCredentials: true
  }
);

socket.on('connect', () => {
  console.warn('connected', socket.id);
  (EventBus as any).$emit(Events.SocketConnected, true);
});
socket.on('disconnect', () => {
  console.warn('disconnect', socket.id);
});

export default socket;
