import { getApolloClient } from '@/vue-apollo';
import gql from 'graphql-tag';

const CLIENT_ID = 'b17e3141-bc83-43bb-8c31-2d27bc57c26b';

export interface RestreamPlatform {
  id: number;
  name: string;
  url: string;
  image: {
    png: string;
    svg: string;
  };
}

export interface RestreamChannel {
  id: number;
  streamingPlatformId: number;
  embedUrl: string;
  url: string;
  identifier: string;
  displayName: string;
  enabled: boolean;
}

export interface RestreamMeta {
  title: string;
}

export class Restream {
  accessToken = '';
  accessTokenExpiresAt = '';

  async refreshToken(): Promise<void> {
    if (this.accessTokenExpiresAt > new Date().toISOString()) {
      return;
    }
    const result = await getApolloClient().query({
      query: gql`
        query restreamToken {
          restreamToken {
            status
            message
            accessToken
            accessTokenExpiresAt
          }
        }
      `
    });
    if (result.data.restreamToken.status) {
      this.accessToken = result.data.restreamToken.accessToken;
      this.accessTokenExpiresAt =
        result.data.restreamToken.accessTokenExpiresAt;
    } else {
      throw new Error(result.data.restreamToken.message);
    }
  }

  setToken(accessToken: string, accessTokenExpiresAt: string): Restream {
    this.accessToken = accessToken;
    this.accessTokenExpiresAt = accessTokenExpiresAt;
    return this;
  }

  async _fetch(
    url: string,
    body: string | undefined = undefined,
    method = 'GET'
  ): Promise<any> {
    await this.refreshToken();
    const bearer = 'Bearer ' + this.accessToken;

    return fetch(url, {
      method: method,
      body: body,
      headers: new Headers({
        Authorization: bearer,
        'Content-Type': 'application/json'
      })
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.error) {
          return Promise.reject(responseJson.error.message);
        }
        return responseJson;
      });
  }

  getAuth(): string {
    const opaqueToken = 'asdfasdf'; // TODO
    const url = new URL('https://api.restream.io/login');
    url.searchParams.append('response_type', 'code');
    url.searchParams.append('client_id', CLIENT_ID);
    url.searchParams.append(
      'redirect_uri',
      process.env.VUE_APP_RESTREAM_REDIRECT_URI || ''
    );
    url.searchParams.append('state', opaqueToken);
    return url.toString();
  }

  async getPlatforms(): Promise<RestreamPlatform[]> {
    return fetch('https://api.restream.io/v2/platform/all', {
      method: 'GET'
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.error) {
          return Promise.reject(responseJson.error.message);
        }
        return responseJson;
      })
      .catch((error) => console.error(error));
  }

  async getStreamKey(): Promise<string> {
    const data = await this._fetch('https://api.restream.io/v2/user/streamKey');
    return data.streamKey;
  }

  async getChannels(): Promise<RestreamChannel[]> {
    return this._fetch('https://api.restream.io/v2/user/channel/all');
  }

  async getChannelMeta(restreamChannelId: number): Promise<RestreamMeta> {
    return this._fetch(
      'https://api.restream.io/v2/user/channel-meta/' + restreamChannelId
    );
  }

  async updateChannel(
    restreamChannelId: string,
    active: boolean
  ): Promise<void> {
    return this._fetch(
      'https://api.restream.io/v2/user/channel/' + restreamChannelId,
      JSON.stringify({ active }),
      'PATCH'
    );
  }

  async updateChannelMeta(
    restreamChannelId: string,
    title: string
  ): Promise<void> {
    return this._fetch(
      'https://api.restream.io/v2/user/channel-meta/' + restreamChannelId,
      JSON.stringify({ title }),
      'PATCH'
    );
  }
}
