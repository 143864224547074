import { OfferInterface, FanfestConfigClass } from '@/interfaces';
import { empty as FanfestConfigEmpty } from '../FanfestConfig/data';

const empty: OfferInterface = {
  objectId: '',
  id: '',

  // main title
  title: '',
  // subtitle
  subtitle: '',

  isActive: true,

  // the target url to open
  url: '',

  channelPointer: new FanfestConfigClass(FanfestConfigEmpty),

  updatedAt: '',
  createdAt: ''
};

const test: OfferInterface = {
  objectId: '000000',
  id: '000000',

  // main title
  title: 'Offer title',
  // subtitle
  subtitle: 'Offer subtitle',

  isActive: true,

  // the target url to open
  url: 'https://fanfest.io',

  channelPointer: new FanfestConfigClass(FanfestConfigEmpty),

  updatedAt: '2021-12-06T14:04:35.893Z',
  createdAt: '2021-12-06T14:04:35.893Z'
};

export { empty, test };
