var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('content-aspect-ratio',{staticClass:"memberstream__container",class:{
    memberstream__container_vip: _vm.liveStream.stream.isVIP
  },style:(_vm.liveStream.stream.isVIP
      ? {
          'border-color': _vm.teamBorderColor
        }
      : undefined),attrs:{"aspect-ratio":_vm.aspectRatio}},[_c('div',{staticClass:"memberstream__content_stream_background"}),_c('video',{ref:"videoElement",class:{
      memberstream__content_stream: true,
      'memberstream__content_stream-self':
        _vm.isSelfStream && _vm.isMirrored,
      'memberstream__content_stream--stream_loaded': _vm.streamLoaded,
      memberstream__content_landscape: _vm.videoAspectRatio >= 1.0,
      memberstream__content_portrait: _vm.videoAspectRatio < 1.0
    },style:({
      visibility:
        _vm.liveStream.stream.muteCamera === _vm.MuteOrigin.NONE
          ? 'visible'
          : 'hidden'
    }),attrs:{"playsinline":"","autoplay":"","oncontextmenu":"return false;"},domProps:{"muted":_vm.isMutedMicrophone}}),(_vm.event.channelPointer.image)?_c('img',{directives:[{name:"show",rawName:"v-show",value:(_vm.liveStream.stream.muteCamera !== _vm.MuteOrigin.NONE),expression:"liveStream.stream.muteCamera !== MuteOrigin.NONE"}],class:{
      memberstream__content_stream: true,
      'memberstream__content_stream--stream_loaded': _vm.streamLoaded
    },attrs:{"src":_vm.event.channelPointer.image.url}}):_vm._e(),_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(_vm.forceMuted && _vm.streamLoaded && _vm.wantsAudio),expression:"forceMuted && streamLoaded && wantsAudio"}],staticClass:"memberstream__unmute",on:{"click":_vm.manualUnmute}},[_vm._v(" "+_vm._s(_vm.$t('video.clickEnableAudio'))+" ")]),(_vm.showLabel)?_c('div',{staticClass:"memberstream__name"},[_vm._v(_vm._s(_vm.lowerName))]):_vm._e(),(_vm.showControls)?_c('video-button-controls',{attrs:{"event":_vm.event,"live-stream":_vm.liveStream,"is-self":_vm.isSelfStream},on:{"mirrorStageVideo":function($event){return _vm.mirrorStageVideo()}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }