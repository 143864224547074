












































































































import { cleanupStream } from '@/util/streams';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import EventBus from '../eventbus/EventBus.vue';
import { Events } from '../eventbus/events';
import StreamInputSettingsDialog from './StreamInputSettingsDialog.vue';

@Component({ components: { StreamInputSettingsDialog } })
export default class JoinStreamDialog extends Vue {
  @Prop()
  readonly value!: boolean;

  @Prop()
  readonly canProduce!: boolean;

  @Prop()
  readonly hasActiveInvite!: boolean;

  $refs!: {
    videoInputElement: HTMLVideoElement;
  };

  settingsDialog = false;

  audioActive = true;
  videoActive = true;

  /**
   * Current state of the input device manager. Can be one of:
   * - idle
   * - prompting
   * Works as a state machine with the signature: Idle > Prompting > Idle.
   */
  state: 'idle' | 'prompting' = 'idle';

  /**
   * Selected video input device for prompt dialog.
   */
  testVideoInputStream: MediaStream | null = null;
  testVideoInputDevice: MediaDeviceInfo | null = null;

  /**
   * Selected audio input device for prompt dialog.
   */
  testAudioInputStream: MediaStream | null = null;
  testAudioInputDevice: MediaDeviceInfo | null = null;

  availableInputDevices: MediaDeviceInfo[] = [];

  isPlaying = false;

  get isIdle() {
    return this.state === 'idle';
  }

  get isPrompting() {
    return this.state === 'prompting';
  }

  get isOpen() {
    return this.value;
  }

  set isOpen(value: boolean) {
    this.$emit('input', value);
  }

  get availableVideoInputDevices(): MediaDeviceInfo[] {
    return this.availableInputDevices.filter(
      (device) => device.kind === 'videoinput'
    );
  }

  get availableAudioInputDevices(): MediaDeviceInfo[] {
    return this.availableInputDevices.filter(
      (device) => device.kind === 'audioinput'
    );
  }

  @Watch('value')
  onValueChanges() {
    if (this.value) {
      this.prompt();
    } else {
      this.stop();
    }
  }

  mounted() {
    if (this.isOpen) {
      this.prompt();
    }
  }

  async toggleAudioStatus() {
    const nextState = !this.audioActive;
    this.audioActive = nextState;

    if (!nextState) {
      this.testAudioInputStream = cleanupStream(this.testAudioInputStream);
    } else if (this.testAudioInputDevice) {
      this.testAudioInputStream = await this.fetchTestStreamFromDeviceId(
        'audio',
        this.testAudioInputDevice
      );
    }
  }

  async toggleVideoStatus() {
    const nextState = !this.videoActive;
    this.videoActive = nextState;

    if (!nextState) {
      this.testVideoInputStream = cleanupStream(this.testVideoInputStream);
    } else if (this.testVideoInputDevice) {
      this.testVideoInputStream = await this.fetchTestStreamFromDeviceId(
        'video',
        this.testVideoInputDevice
      );
    }
  }

  onVideoCanPlay() {
    this.$refs.videoInputElement.play();
    this.isPlaying = true;
  }

  async updateVideoElementSourceObject(stream: MediaStream) {
    await this.$nextTick();

    if (!this.$refs.videoInputElement || !this.isOpen) return;

    this.$refs.videoInputElement.srcObject = null;
    await this.$nextTick();

    this.$refs.videoInputElement.srcObject = stream;
  }

  cleanupTestDevices() {
    this.testVideoInputStream = cleanupStream(this.testVideoInputStream);
    this.testAudioInputStream = cleanupStream(this.testAudioInputStream);
  }

  async fetchAvailableDevices(): Promise<MediaDeviceInfo[]> {
    const devices = await navigator.mediaDevices.enumerateDevices();
    console.log(devices);

    this.$set(this, 'availableInputDevices', devices);

    return devices;
  }

  async fetchTestStreamFromDeviceId(
    kind: 'video' | 'audio',
    device: MediaDeviceInfo
  ) {
    return navigator.mediaDevices.getUserMedia({
      [kind]: {
        deviceId: device.deviceId
      }
    });
  }

  getDevice(deviceOrId: MediaDeviceInfo | string) {
    return typeof deviceOrId === 'string'
      ? this.availableInputDevices.find(
          (device) => device.deviceId === deviceOrId
        )
      : deviceOrId;
  }

  async setTestVideoInputDevice(deviceOrId: MediaDeviceInfo | string) {
    const device = this.getDevice(deviceOrId);
    if (!device) throw new Error('Device not found' + deviceOrId);

    const stream = await this.fetchTestStreamFromDeviceId('video', device);
    this.testVideoInputDevice = device;
    this.testVideoInputStream = stream;
    this.updateVideoElementSourceObject(stream);
  }

  async setTestAudioInputDevice(deviceOrId: MediaDeviceInfo | string) {
    const device = this.getDevice(deviceOrId);
    if (!device) throw new Error('Device not found' + deviceOrId);

    const stream = await this.fetchTestStreamFromDeviceId('audio', device);
    this.testAudioInputDevice = device;
    this.testAudioInputStream = stream;
  }

  async prompt() {
    this.cleanupTestDevices();

    // Set initial devices
    try {
      await this.fetchAvailableDevices();

      this.state = 'prompting';

      await this.setTestVideoInputDevice(this.availableVideoInputDevices[0]);
      await this.setTestAudioInputDevice(this.availableAudioInputDevices[0]);

      // On Safari, you get "empty" devices to prompt user for permission,
      // if permission is given, the devices will be updated.
      await this.fetchAvailableDevices();

      this.$nextTick().then(this.onVideoCanPlay);
    } catch (error) {
      if (error && error instanceof Error && 'name' in error) {
        const narrowError = error as { name: string };

        if (narrowError.name === 'NotAllowedError') {
          EventBus.$emit(
            Events.AlertError,
            this.$t('stream.devicePermissionDenied')
          );
        }
      }

      console.error(error);
    }
  }

  async select() {
    if (!this.testAudioInputDevice || !this.testVideoInputDevice) {
      return;
    }

    await this.stop();

    return {
      audioInputDeviceId: this.testAudioInputDevice.deviceId,
      videoInputDeviceId: this.testVideoInputDevice.deviceId
    };
  }

  async stop() {
    this.cleanupTestDevices();
    this.state = 'idle';
  }

  onClose(): void {
    this.$emit('input', false);
    this.$emit('decline');
  }

  async onJoin(): Promise<void> {
    const deviceIds = await this.select();
    this.$emit('join', deviceIds);

    await this.$nextTick();
    this.$emit('input', false);
  }
}
