var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('h1',{staticClass:"text-h1 font-weight-light mb-12"},[_vm._v(" "+_vm._s(_vm.$t('user.inviteUser'))+" ")]),_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.save.apply(null, arguments)}}},[(!_vm.user.id)?_c('validation-provider',{attrs:{"name":_vm.$t('user.email'),"rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('f-text-field',{staticClass:"required",attrs:{"error-messages":errors,"label":_vm.$t('user.email'),"required":"","autocomplete":"off"},model:{value:(_vm.user.username),callback:function ($$v) {_vm.$set(_vm.user, "username", $$v)},expression:"user.username"}})]}}],null,true)}):_vm._e(),(_vm.isAdmin || _vm.isChannelAdmin)?_c('validation-provider',{attrs:{"name":_vm.$t('user.role'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('f-select',{staticClass:"required",attrs:{"items":_vm.roles,"required":"","name":"role","label":_vm.$t('user.role'),"disabled":!!_vm.user.objectId,"error-messages":errors},model:{value:(_vm.user.role),callback:function ($$v) {_vm.$set(_vm.user, "role", $$v)},expression:"user.role"}})]}}],null,true)}):_vm._e(),(_vm.canSelectChannel && _vm.user.worksChannelPointer)?_c('validation-provider',{attrs:{"name":_vm.$t('user.channel'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('autocomplete-pointer',{staticClass:"required",attrs:{"query":_vm.fanfestConfigQuery,"result-key":"fanfestConfigs","item-text":"name","item-value":"objectId","label":_vm.$t('user.channel'),"error-messages":errors,"disabled":!!_vm.user.objectId},model:{value:(_vm.user.worksChannelPointer.objectId),callback:function ($$v) {_vm.$set(_vm.user.worksChannelPointer, "objectId", $$v)},expression:"user.worksChannelPointer.objectId"}})]}}],null,true)}):_vm._e(),_c('v-btn',{attrs:{"type":"submit","color":"primary","loading":_vm.loading,"disabled":_vm.loading || invalid}},[_vm._v(_vm._s(_vm.$t('user.inviteUser')))])],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }