















import { Component, Vue, Prop, Ref } from 'vue-property-decorator';

@Component({ components: {} })
export default class Instagram extends Vue {
  @Ref('container') readonly containerElement!: InstanceType<
    typeof HTMLDivElement
  >;

  @Prop({})
  readonly url!: string;

  width = 100;
  height = 100;
  observer!: ResizeObserver;
  timeout!: ReturnType<typeof setTimeout>

  mounted () {
    this.handleResize();
    this.observer = new ResizeObserver(this.handleResize);
    this.observer.observe(this.containerElement);
    window.addEventListener('resize', this.handleResize);
  }

  beforeDestroy () {
    if ('timeout' in this) {
      clearTimeout(this.timeout);
    }
    this.observer.disconnect();
  }

  handleResize (): void {
    if ('timeout' in this) {
      // TODO: this is currently a workaround to make this resolve the new width/height but it produces a lot of blinking.
      this.width = 0;
      this.height = 0;
      clearTimeout(this.timeout);
    }
    this.timeout = setTimeout(() => {
      if (!this.containerElement) return;

      const min = Math.min(
        this.containerElement.offsetWidth - 25,
        this.containerElement.offsetHeight - 25
      );

      this.width = min;
      this.height = min;
    }, 100);
  }

  get embedURL (): string {
    const parsed = new URL(this.url);
    if (parsed.pathname.endsWith('/embed')) {
      return this.url;
    }
    parsed.pathname += 'embed';
    return parsed.toString();
  }
}
