import { UserClass } from '@/interfaces';
import { PhenixChatMessage } from '@/util/phenix';
import { DateTime } from 'luxon';

/**
 * Our message types.
 */
export enum ChatMessageType {
  Message = 'Message',
  RaiseHand = 'RaiseHand'
}

/**
 * Our message data interface.
 */
export interface ChatMessageData {
  id: string;
  type: ChatMessageType;
  text: string;
  datetime: DateTime;
  user: {
    objectId: string;
    name: string;
    isVIP: boolean;
  };
}

/**
 * This is what we're encoding in PhenixChatMessage.message
 */
interface PhenixInternalMessageData {
  type: ChatMessageType;
  text: string;
  user: {
    objectId: string;
    name: string;
  };
}

export function phenixEncodeMessage (
  text: string,
  type: ChatMessageType,
  user: UserClass
): string {
  const data: PhenixInternalMessageData = {
    text: text,
    type: type,
    user: {
      objectId: user.objectId,
      name: user.name
    }
  };
  return JSON.stringify(data);
}

const emptyPhenixMessageData: PhenixInternalMessageData = {
  text: '',
  type: ChatMessageType.Message,
  user: {
    objectId: '',
    name: ''
  }
};

export function phenixDecodeMessage (pdata: PhenixChatMessage): ChatMessageData {
  const x: PhenixInternalMessageData = {
    ...emptyPhenixMessageData,
    ...JSON.parse(pdata.message)
  };
  const data: ChatMessageData = {
    id: pdata.messageId,
    type: x.type,
    text: x.text,
    user: {
      ...x.user,
      isVIP: false // TODO: get from list
    },
    datetime: DateTime.fromSeconds(pdata.timestamp / 1000.0)
  };
  return data;
}
