
















import { FanfestEventClass } from '@/interfaces';
import { Component, Vue, Prop } from 'vue-property-decorator';
import { LiveEventDataClass } from '@common/types/LiveEvent';
import MemberStream from './MemberStream.vue';
import { PhenixStreamMap } from '@/util/phenix';
import { filterLiveStreamsLinkedWithPhenix } from '@/util/streams';

@Component({ components: { MemberStream } })
export default class FanBarArea extends Vue {
  @Prop({})
  readonly event!: FanfestEventClass;

  @Prop({})
  readonly liveEventData!: LiveEventDataClass;

  @Prop({})
  readonly phenixStreamMap!: PhenixStreamMap[];

  get fanStreams() {
    const streams = filterLiveStreamsLinkedWithPhenix(
      this.phenixStreamMap,
      this.liveEventData,
      (liveStream) =>
        !liveStream.stream.isOnStage && liveStream.stream.isStreaming,
      'FanBar'
    );

    this.$emit('visibleStreams', streams.length);
    return streams;
  }
}
