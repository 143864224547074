var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('h1',{staticClass:"text-h1 font-weight-light mb-12"},[_vm._v(" "+_vm._s(_vm.user.objectId ? _vm.$t('form.edit') : _vm.$t('form.create'))+" "+_vm._s(_vm.$t('user.objectName'))+" ")]),_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.save.apply(null, arguments)}}},[_c('validation-provider',{attrs:{"name":_vm.$t('user.name'),"rules":"required|max:200"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('f-text-field',{staticClass:"required",attrs:{"counter":200,"error-messages":errors,"label":_vm.$t('user.name'),"required":""},nativeOn:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }$event.preventDefault();}},model:{value:(_vm.user.name),callback:function ($$v) {_vm.$set(_vm.user, "name", $$v)},expression:"user.name"}})]}}],null,true)}),(!_vm.user.id)?_c('validation-provider',{attrs:{"name":_vm.$t('user.email'),"rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('f-text-field',{staticClass:"required",attrs:{"error-messages":errors,"label":_vm.$t('user.email'),"required":"","autocomplete":"off"},nativeOn:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }$event.preventDefault();}},model:{value:(_vm.user.username),callback:function ($$v) {_vm.$set(_vm.user, "username", $$v)},expression:"user.username"}})]}}],null,true)}):_vm._e(),(_vm.editablePassword)?_c('validation-provider',{attrs:{"name":_vm.$t('user.password'),"rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('f-text-field',{class:{ required: !_vm.user.objectId },attrs:{"id":"password","required":!_vm.user.id,"name":"password","label":_vm.$t('user.password'),"append-icon":_vm.showPassword ? 'visibility' : 'visibility-off',"type":_vm.showPassword ? 'text' : 'password',"error-messages":errors,"autocomplete":"off"},on:{"click:append":function($event){_vm.showPassword = !_vm.showPassword}},nativeOn:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }$event.preventDefault();}},model:{value:(_vm.user.password),callback:function ($$v) {_vm.$set(_vm.user, "password", $$v)},expression:"user.password"}})]}}],null,true)}):_vm._e(),(_vm.isAdmin || _vm.isChannelAdmin)?_c('validation-provider',{attrs:{"name":_vm.$t('user.role'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('f-select',{staticClass:"required",attrs:{"items":_vm.roles,"required":"","name":"role","label":_vm.$t('user.role'),"disabled":!!_vm.user.objectId,"error-messages":errors},nativeOn:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }$event.preventDefault();}},model:{value:(_vm.user.role),callback:function ($$v) {_vm.$set(_vm.user, "role", $$v)},expression:"user.role"}})]}}],null,true)}):_vm._e(),(_vm.isAdmin && _vm.hasChannelPointer && _vm.user.worksChannelPointer)?_c('validation-provider',{attrs:{"name":_vm.$t('user.channel'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('autocomplete-pointer',{staticClass:"required",attrs:{"query":_vm.fanfestConfigQuery,"result-key":"fanfestConfigs","item-text":"name","item-value":"objectId","label":_vm.$t('user.channel'),"error-messages":errors,"disabled":!!_vm.user.objectId},nativeOn:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }$event.preventDefault();}},model:{value:(_vm.user.worksChannelPointer.objectId),callback:function ($$v) {_vm.$set(_vm.user.worksChannelPointer, "objectId", $$v)},expression:"user.worksChannelPointer.objectId"}})]}}],null,true)}):_vm._e(),_c('validation-provider',{attrs:{"name":_vm.$t('user.timezone')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"outlined":"","items":_vm.timezones,"search-input":_vm.searchTimezones,"name":"timezone","label":_vm.$t('user.timezone'),"error-messages":errors},on:{"update:searchInput":function($event){_vm.searchTimezones=$event},"update:search-input":function($event){_vm.searchTimezones=$event}},nativeOn:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }$event.preventDefault();}},model:{value:(_vm.user.timezone),callback:function ($$v) {_vm.$set(_vm.user, "timezone", $$v)},expression:"user.timezone"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":_vm.$t('user.language')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"outlined":"","items":_vm.languages,"search-input":_vm.searchLanguages,"name":"role","label":_vm.$t('user.language'),"error-messages":errors},on:{"update:searchInput":function($event){_vm.searchLanguages=$event},"update:search-input":function($event){_vm.searchLanguages=$event}},nativeOn:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }$event.preventDefault();}},model:{value:(_vm.user.language),callback:function ($$v) {_vm.$set(_vm.user, "language", $$v)},expression:"user.language"}})]}}],null,true)}),_c('notification-form-fields',{attrs:{"user":_vm.user},on:{"changed":_vm.notificationChanged}}),_c('div',{staticClass:"text-right"},[_c('v-btn',{staticClass:"mr-2 text-uppercase",attrs:{"outlined":""},on:{"click":function($event){return _vm.$router.back()}}},[_vm._v(" "+_vm._s(_vm.$t('form.cancel'))+" ")]),_c('v-btn',{staticClass:"text-uppercase",attrs:{"type":"submit","color":"primary","loading":_vm.loading,"disabled":_vm.loading || invalid}},[_vm._v(_vm._s(_vm.$t('form.save')))])],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }