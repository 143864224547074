














import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import { DocumentNode } from 'graphql';
import { graphQLErrorAlert } from '@/util/GraphQL';
import { debounce } from 'typescript-debounce-decorator';

@Component({ components: { ValidationObserver, ValidationProvider } })
export default class AutocompletePointer extends Vue {
  // the graphql query to fetch
  @Prop({})
  readonly query!: DocumentNode;

  // the variable to search (autocomplete), like name
  @Prop({})
  readonly searchVariable!: string;

  // the result key where results are.
  @Prop({})
  readonly resultKey!: string;

  // the prop value
  @Prop({
    default: ''
  })
  readonly value!: string;

  // the selected value
  select = '';

  // current list of items
  items = [];

  search = '';

  loading = false;

  @Watch('search')
  async onSearchChanged (val: string): Promise<void> {
    this.load(val);
  }

  @Watch('value', { immediate: true })
  async onValueChanged (val: string): Promise<void> {
    this.select = val;
  }

  async mounted (): Promise<void> {
    await this.load();
  }

  @debounce(250)
  async load (value = ''): Promise<void> {
    try {
      this.loading = true;
      const results = await this.$apollo.query({
        query: this.query,
        variables: {
          search: value ? '(?i)' + value : ''
        }
      });
      if (!(this.resultKey in results.data)) {
        return;
      }
      this.items = results.data[this.resultKey].edges.map((i: any) => i.node);
    } catch (error) {
      graphQLErrorAlert(error);
    } finally {
      this.loading = false;
    }
  }

  @Watch('select')
  onSelectChanged (val: string) {
    this.$emit('input', val);
  }
}
