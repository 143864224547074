























































import { OfferClass } from '../../../interfaces';
import { Component, Vue, Prop } from 'vue-property-decorator';
import { Analytics, EventCategory, TrackEventEnum } from '@/services/tracking';

@Component({
  components: {}
})
export default class OfferShow extends Vue {
  @Prop({})
  readonly offer!: OfferClass;

  @Prop({})
  readonly defaultImage!: OfferClass['image'];

  onClick(): void {
    Analytics.track(TrackEventEnum.OfferClicked, EventCategory.Engagement, {
      offerId: this.offer.id,
      label: this.offer.title
    });
  }

  get offerImage() {
    return this.offer.image?.url ?? this.defaultImage?.url;
  }
}
