














import { Component, Vue, Prop, Watch, Ref } from 'vue-property-decorator';
import ContentAspectRatio from '../ContentAspectRatio.vue';

@Component({ components: { ContentAspectRatio } })
export default class Youtube extends Vue {
  @Ref('container') readonly containerElement!: InstanceType<
    typeof HTMLDivElement
  >;

  @Prop({})
  readonly url!: string;

  width = 100;
  height = 100;
  src = '';

  async mounted() {
    this.loadYoutube(this.url);
    // loadYoutubeAPI
  }

  @Watch('url')
  onURLChange(newval: string | null) {
    this.loadYoutube(newval);
  }

  async loadYoutube(url: string | null): Promise<void> {
    if (!url) {
      return;
    }
    const regExp =
      /^.*(youtu\.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
    const match = url.match(regExp);
    const id = match && match[2].length === 11 ? match[2] : false;
    if (!id) {
      return;
    }
    this.src = `https://www.youtube.com/embed/${id}?enablejsapi=1&autoplay=1&mute=1&origin=https://fanfest.io`;
  }

  async loadYoutubeApi() {
    const jsId = 'youtubejs';
    if (document.getElementById('#' + jsId)) {
      return;
    }
    const tag = document.createElement('script');
    tag.id = jsId;
    tag.src = 'https://www.youtube.com/iframe_api';
    const headElement = document.getElementsByTagName('head');
    headElement[0].appendChild(tag);
  }
}
