
import { Component } from 'vue-property-decorator';
import { MetaInfo } from 'vue-meta';
import Event from './Event.vue';

declare global {
  interface Window {
    __IS_SIMULFEST__: boolean;
  }
}

@Component({})
export default class EventSimulfestPage extends Event {
  get containerClasses(): (string | boolean)[] {
    return ['event__container event__container--simulfest'];
  }

  metaInfo(): MetaInfo {
    const title =
      this.$tc('producerControls.useThisWindowToSimulcast') +
      ': FanFest StageArea ' +
      this.event.title;
    return {
      title: title
    };
  }

  async created() {
    this.notInteracted = false;
  }

  async mounted(): Promise<void> {
    window.__IS_SIMULFEST__ = true;
    this.chatDrawer = false;
    this.bottomBar = false;
  }

  get canProduce(): boolean {
    return false;
  }

  initSprig(): void {
    // override parent, pass
  }
}
