
















































import { Component, Vue, Ref } from 'vue-property-decorator';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import { Events } from '@/components/eventbus/events';
import EventBus from '@/components/eventbus/EventBus.vue';
import { graphQLErrorAlert } from '@/util/GraphQL';
import { UserModule } from '@/store/user';
import { Roles } from '@common/types/Roles';
import AutocompletePointer from '@/components/crud/Base/AutocompletePointer.vue';

@Component({
  components: { AutocompletePointer, ValidationObserver, ValidationProvider }
})
export default class VideoEdit extends Vue {
  @Ref() readonly observer!: InstanceType<typeof ValidationProvider>;

  readonly fanfestConfigQuery = require('../FanfestConfig/autocompleteFanfestConfigs.graphql');

  channelPointerId = '';
  loading = false;
  url = '';

  async mounted(): Promise<void> {
    if (UserModule.user.role !== Roles.Admin) {
      this.channelPointerId =
        UserModule.user.worksChannelPointer?.objectId || '';
    }
  }

  get channelEditable(): boolean {
    return UserModule.user.role === Roles.Admin;
  }

  async save(): Promise<void> {
    this.observer.validate();
    const videoData: { [k: string]: any } = {
      url: this.url,
      channel: this.channelPointerId
    };

    try {
      this.loading = true;
      const response = await this.$apollo.mutate({
        mutation: require('./createVideoFromYoutube.graphql'),
        // Parameters
        variables: videoData
      });

      const status = response.data.createVideoFromYoutube as string;

      if (status === 'OK') {
        EventBus.$emit(
          Events.AlertSuccess,
          this.$t('video.savedAndProcessing')
        );
        this.$router.push({ path: '/dashboard/video' });
      } else if (response.data.createVideoFromYoutube === 'GEOFENCING') {
        EventBus.$emit(Events.AlertError, this.$t('video.errorGeofencing'));
      }
    } catch (error) {
      graphQLErrorAlert(error);
    } finally {
      this.loading = false;
    }
  }
}
