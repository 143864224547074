import { User } from '@/generated/graphql';
import { Events } from '../eventbus/events';
import { UserModule } from '@/store/user';
import { Analytics, EventCategory, TrackEventEnum } from '@/services/tracking';
import { UserGraphQLTranslator } from '@/interfaces';
import EventBus from '../eventbus/EventBus.vue';

export function loginSuccess (sessionToken: string, user: User) {
  UserModule.setSessionToken(sessionToken);
  UserModule.setUser(UserGraphQLTranslator(user));
  (EventBus as any).$emit(Events.LoggedIn);
  Analytics.setUser(UserModule.user);
  Analytics.track(TrackEventEnum.Login, EventCategory.Engagement);
  Analytics.track(TrackEventEnum.FanIdentified, EventCategory.Engagement);
}
