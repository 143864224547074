





















import { UserClass } from '@/interfaces';
import { UserModule } from '@/store/user';
import { graphQLErrorAlert } from '@/util/GraphQL';
import { Component, Vue } from 'vue-property-decorator';
import NotificationFormFields from './NotificationFormFields.vue';

@Component({ components: { NotificationFormFields } })
export default class NotificationSettings extends Vue {
  get user (): UserClass {
    return UserModule.user;
  }

  async save (): Promise<void> {
    if (this.user.id) {
      try {
        await this.$apollo.mutate({
          // Query
          mutation: require('../crud/User/updateUser.graphql'),
          // Parameters
          variables: {
            id: this.user.objectId,
            user: {
              phone: this.user.phone || '',
              smsNotifications: !!this.user.smsNotifications,
              whatsappNotifications: !!this.user.whatsappNotifications,
              emailNotifications: !!this.user.emailNotifications
            }
          }
        });
      } catch (error) {
        graphQLErrorAlert(error);
      }
    }
  }

  changed (field: string, value: string): void {
    this.$set(this.user, field, value);
    this.save();
  }
}
