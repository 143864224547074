import Vue from 'vue';
import Router from 'vue-router';
import { AdminRoute, BaseRoute } from './routeNames';

import Error from '@/views/Error.vue';
import Channel from '@/views/Channel.vue';
import Event from '@/views/Event.vue';
import EventSimulfest from '@/views/EventSimulfest.vue';
import HomePage from '@/views/HomePage.vue';
import Profile from '@/views/Profile.vue';
import Login from '@/views/Login.vue';
import ResetPasswordRequest from '@/views/ResetPasswordRequest.vue';
import ResetPassword from '@/views/ResetPassword.vue';
import ParseFrameURL from '@/views/ParseFrameURL.vue';
import Privacy from '@/views/Privacy.vue';
import AcceptCookies from '@/views/AcceptCookies.vue';
import TermsOfUse from '@/views/TermsOfUse.vue';
import CookiesPolicy from '@/views/CookiesPolicy.vue';
import RestreamCallback from '@/views/RestreamCallback.vue';
import UserAcceptPage from '@/views/UserAccept.vue';
import FanfestConfigRoutes from '@/components/crud/FanfestConfig/routes';
import FanfestEventRoutes from '@/components/crud/FanfestEvent/routes';
import OfferRoutes from '@/components/crud/Offer/routes';
import VideoRoutes from '@/components/crud/Video/routes';
import UserRoutes from '@/components/crud/User/routes';
import { UserModule } from '@/store/user';
import { Roles } from '@common/types/Roles';

const Dashboard = () => import('@/views/Dashboard.vue');
const DashboardMain = () => import('@/views/DashboardMain.vue');

Vue.use(Router);

const routes = [
  {
    path: '/dashboard',
    component: Dashboard,
    meta: {
      roles: [Roles.Admin, Roles.ChannelAdmin, Roles.Producer]
    },
    redirect: '/dashboard/main',
    children: [
      {
        path: 'main',
        name: AdminRoute.Dashboard,
        component: DashboardMain,
        meta: {
          roles: [Roles.Admin, Roles.ChannelAdmin, Roles.Producer]
        }
      },
      ...FanfestConfigRoutes,
      ...FanfestEventRoutes,
      ...OfferRoutes,
      ...VideoRoutes,
      ...UserRoutes
    ]
  },
  {
    path: '/login',
    name: BaseRoute.Login,
    component: Login
  },
  {
    path: '/resetPasswordRequest',
    name: BaseRoute.ResetPasswordRequest,
    component: ResetPasswordRequest
  },
  {
    path: '/resetPassword',
    name: BaseRoute.ResetPassword,
    component: ResetPassword
  },
  {
    path: '/parseFrameURL',
    name: BaseRoute.ParseFrameURL,
    component: ParseFrameURL
  },
  {
    path: '/privacy',
    name: BaseRoute.Privacy,
    component: Privacy
  },
  {
    path: '/restream/callback',
    name: 'RestreamCallback',
    component: RestreamCallback
  },
  {
    path: '/profile',
    name: BaseRoute.Profile,
    component: Profile,
    meta: {
      roles: [
        Roles.Admin,
        Roles.ChannelAdmin,
        Roles.Producer,
        Roles.Moderator,
        Roles.Fan
      ]
    }
  },
  {
    path: '/accept-cookies',
    name: BaseRoute.AcceptCookies,
    component: AcceptCookies
  },
  {
    path: '/cookies-policy',
    name: BaseRoute.CookiesPolicy,
    component: CookiesPolicy
  },
  {
    path: '/terms-of-use',
    name: BaseRoute.TermsOfUse,
    component: TermsOfUse
  },
  {
    path: '/user-accept',
    name: 'userAccept',
    component: UserAcceptPage
  },
  {
    path: '/:channel/:id([0-9A-Za-z]+)\\-:slug(.*)/simulfest',
    name: BaseRoute.EventSimulfest,
    component: EventSimulfest,
    meta: {}
  },
  {
    path: '/:channel/:id([0-9A-Za-z]+)\\-:slug(.*)',
    name: BaseRoute.Event,
    component: Event
  },
  {
    path: '/:channel/:id([0-9A-Za-z]+)',
    name: BaseRoute.EventShort,
    component: Event
  },
  {
    path: '/:channel',
    name: BaseRoute.Channel,
    component: Channel
  },
  {
    path: '/',
    name: BaseRoute.HomePage,
    component: HomePage
  },
  {
    path: '*',
    component: Error,
    props: { message: '' }
  }
];

const router = new Router({
  mode: 'history',
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else if (to.hash) {
      return { selector: to.hash };
    } else {
      return { x: 0, y: 0 };
    }
  }
});

// checks meta.unauthorized
router.beforeEach((to, from, next) => {
  const isLogged = UserModule.isLogged;

  if (to.meta?.roles && !isLogged) {
    next({ path: '/login', params: { redirect: to.path } });
  } else if (to.meta?.roles && !to.meta.roles.includes(UserModule.user.role)) {
    alert('You cannot access this.');
    console.error('cannot access that', to);
  } else {
    next();
  }
});

router.onError((error) => {
  if (/loading chunk \d* failed./i.test(error.message) && navigator.onLine) {
    alert('We have a new version online. Please reload the page.');
  }
});

export default router;
