
import { Component } from 'vue-property-decorator';
import EventBus from '@/components/eventbus/EventBus.vue';
import { Events } from '@/components/eventbus/events';
import { graphQLErrorAlert } from '@/util/GraphQL';
import UserEdit from './UserEdit.vue';
import { Roles } from '@common/types/Roles';
import { FanfestConfigClass } from '@/interfaces';
import { loginSuccess } from '../../auth/login';
import { empty as FanfestConfigEmpty } from '../FanfestConfig/data';
import { LogInInput } from '@/generated/graphql';

@Component({})
export default class UserAccept extends UserEdit {
  async mounted(): Promise<void> {
    this.user.email = this.user.username = (
      this.$route.query.email as string
    ).toLocaleLowerCase();
    this.user.role = this.$route.query.role as Roles;
    this.user.worksChannelPointer = new FanfestConfigClass({
      ...FanfestConfigEmpty,
      objectId: this.$route.query.channelId as string
    });
  }

  _saveUserData(): Record<string, any> {
    const userData = {
      name: this.user.name,
      language: this.user.language || '',
      timezone: this.user.timezone || ''
    } as any;

    if (!this.user.id) {
      userData.username = this.user.username;
      userData.email = this.user.username;
    }
    if (this.user.password) {
      userData.password = this.user.password;
    }

    userData.role = this.user.role;

    if (this.hasChannelPointer) {
      userData.worksChannelPointer = {
        link: this.user.worksChannelPointer!.objectId
      };
    }
    return userData;
  }

  async save(): Promise<void> {
    this.observer.validate();
    const userData = this._saveUserData();
    try {
      this.loading = true;
      await this.$apollo.mutate({
        // Query
        mutation: require('./userAccept.graphql'),
        // Parameters
        variables: {
          input: userData,
          date: this.$route.query.date,
          invitationCode: this.$route.query.invitationCode
        }
      });

      EventBus.$emit(Events.AlertSuccess, this.$t('form.saved'));

      const input: LogInInput = {
        username: userData.email.toLocaleLowerCase(),
        password: userData.password
      };
      const result = await this.$apollo.mutate({
        // Query
        mutation: require('../../auth/logIn.graphql'),
        // Parameters
        variables: {
          input
        }
      });

      loginSuccess(
        result.data.logIn.viewer.sessionToken,
        result.data.logIn.viewer.user
      );
      EventBus.$emit(Events.LoggedIn);
      this.$emit(Events.LoggedIn);
      this.$router.replace('/dashboard');
    } catch (error) {
      graphQLErrorAlert(error);
    } finally {
      this.loading = false;
    }
  }
}
