import {
  TranslationMessages,
  TranslationMessagesInner
} from '../translationMessages';
import gr from './gr.json';

// @ts-expect-error - we provide fallbacks to english
const messages: TranslationMessagesInner = gr;

export default messages as TranslationMessages;
