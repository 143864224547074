

















































import { ResetPasswordInput } from '@/generated/graphql';
import { Component, Vue, Prop } from 'vue-property-decorator';
import { Events } from '../eventbus/events';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import { graphQLErrorAlert } from '@/util/GraphQL';
import EventBus from '../eventbus/EventBus.vue';

@Component({ components: { ValidationObserver, ValidationProvider } })
export default class ResetPasswordForm extends Vue {
  @Prop({
    default: true
  })
  readonly showClose!: boolean;

  email = '';
  loading = false;
  valid = true;

  async resetPassword(): Promise<void> {
    // this.$refs.form.validate();
    this.loading = true;

    try {
      const input: ResetPasswordInput = {
        email: this.email.toLocaleLowerCase()
      };
      const result = await this.$apollo.mutate({
        // Query
        mutation: require('./resetPassword.graphql'),
        // Parameters
        variables: {
          input
        }
      });
      if (result.data.resetPassword.ok) {
        EventBus.$emit(Events.AlertSuccess, this.$t('login.resetPasswordSent'));
        this.$emit(Events.CloseDialog);
      } else {
        EventBus.$emit(Events.AlertError, this.$t('login.errorReset'));
      }
    } catch (error: any) {
      graphQLErrorAlert(error);
    } finally {
      this.loading = false;
    }
  }

  close(): void {
    this.$emit(Events.CloseDialog);
  }
}
