var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('h1',{staticClass:"text-h1 font-weight-light mb-12"},[_vm._v(" "+_vm._s(_vm.video.objectId ? _vm.$t('form.edit') : _vm.$t('form.create'))+" "+_vm._s(_vm.$t('video.objectName'))+" ")]),_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.save.apply(null, arguments)}}},[_c('autocomplete-pointer',{staticClass:"required",attrs:{"query":_vm.fanfestConfigQuery,"result-key":"fanfestConfigs","item-text":"name","item-value":"objectId","label":_vm.$t('video.channel'),"disabled":!_vm.channelEditable},model:{value:(_vm.video.channelPointer.objectId),callback:function ($$v) {_vm.$set(_vm.video.channelPointer, "objectId", $$v)},expression:"video.channelPointer.objectId"}}),_c('validation-provider',{attrs:{"name":_vm.$t('video.name'),"rules":"required|max:200"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('f-text-field',{staticClass:"required",attrs:{"counter":200,"error-messages":errors,"label":_vm.$t('video.name'),"required":""},model:{value:(_vm.video.name),callback:function ($$v) {_vm.$set(_vm.video, "name", $$v)},expression:"video.name"}})]}}],null,true)}),(!_vm.video.id)?_c('validation-provider',{attrs:{"name":_vm.$t('video.video'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-file-input',{staticClass:"required",attrs:{"outlined":"","truncate-length":"15","error-messages":errors,"label":_vm.$t('video.video'),"prepend-icon":"camera","accept":"video/mp4,video/x-m4v,video/*"},model:{value:(_vm.videoFile),callback:function ($$v) {_vm.videoFile=$$v},expression:"videoFile"}})]}}],null,true)}):_vm._e(),_c('validation-provider',{attrs:{"name":_vm.$t('video.isActive')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-checkbox',{attrs:{"label":_vm.$t('video.isActive'),"error-messages":errors},model:{value:(_vm.video.isActive),callback:function ($$v) {_vm.$set(_vm.video, "isActive", $$v)},expression:"video.isActive"}})]}}],null,true)}),_c('div',{staticClass:"text-right"},[_c('v-btn',{staticClass:"mr-2 text-uppercase",attrs:{"outlined":""},on:{"click":function($event){return _vm.$router.back()}}},[_vm._v(" "+_vm._s(_vm.$t('form.cancel'))+" ")]),_c('v-btn',{staticClass:"text-uppercase",attrs:{"type":"submit","color":"primary","loading":_vm.loading,"disabled":_vm.loading || invalid || (!_vm.video.id && !_vm.videoFile)}},[_vm._v(_vm._s(_vm.$t('form.save')))])],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }