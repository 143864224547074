import Vue from 'vue';
import { VTextarea } from 'vuetify/lib';

Vue.component('FTextarea', {
  extends: VTextarea,
  props: {
    outlined: {
      type: Boolean,
      default: true
    }
  }
});
