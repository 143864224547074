

























import { Component, Vue } from 'vue-property-decorator';
import { RawLocation } from 'vue-router';
import EventBus from './components/eventbus/EventBus.vue';
import { Events } from './components/eventbus/events';
import { LogOutInput } from './generated/graphql';
import { UserGraphQLTranslator } from './interfaces';
import { UserModule } from './store/user';
import { graphQLErrorAlert } from './util/GraphQL';
import { Analytics, EventCategory, TrackEventEnum } from '@/services/tracking';
import { MetaInfo } from 'vue-meta';
import { socketLogin } from './services/socketUtils';
import { Roles } from '@common/types/Roles';

type SnackbarPosition = 'top' | 'bottom';

@Component({})
export default class App extends Vue {
  metaInfo(): MetaInfo {
    const title = 'FanFest';
    const description = 'Join this show on FanFest!';

    return {
      title: 'FanFest',
      titleTemplate: '%s',
      meta: [
        {
          vmid: 'description',
          name: 'description',
          content: description
        },
        {
          vmid: 'twitter:title',
          property: 'twitter:title',
          content: title
        },
        {
          vmid: 'twitter:description',
          property: 'twitter:description',
          content: description
        },
        {
          vmid: 'og:title',
          property: 'og:title',
          content: title
        },
        {
          vmid: 'og:description',
          property: 'og:description',
          content: description
        },
        {
          vmid: 'og:image',
          property: 'og:image',
          content: 'https://live.fanfest.io/logo/logo_square_fanfest_1024.png'
        }
      ]
    };
  }

  snackbarShow = false;
  snackbarMessage = '';
  snackbarPosition: SnackbarPosition = 'bottom';
  snackbarColor = 'red';
  snackbarTimeout = 5000;
  firstConnection = true;

  async created(): Promise<void> {
    await this.refreshUser();
  }

  async mounted(): Promise<void> {
    this.attachAlertsListeners();

    EventBus.$on(Events.SocketConnected, () => {
      this.refreshSocket(this.firstConnection);
      this.firstConnection = false;
    });
    EventBus.$on(Events.LoggedIn, () => {
      this.refreshSocket(true);
    });
    EventBus.$on(Events.PostLoggedOut, () => {
      this.refreshSocket(true);
    });
    EventBus.$on(Events.LoggedOut, (rawLocation: RawLocation | undefined) => {
      this.logout(rawLocation);
    });

    // this sets a dynamic `--vh` variable that follows the actual window inner height.
    // handles things well on safari: https://css-tricks.com/the-trick-to-viewport-units-on-mobile/
    if (window.visualViewport) {
      window.visualViewport.addEventListener('resize', this.onResizeViewport);
      this.onResizeViewport();
    } else {
      // fallback
      window.addEventListener('resize', this.onResize);
      this.onResize();
    }
  }

  attachAlertsListeners() {
    if (window.__IS_SIMULFEST__) return;

    EventBus.$on(
      Events.AlertSuccess,
      (message: string, position: SnackbarPosition = 'bottom') => {
        this.snackbarShow = true;
        this.snackbarMessage = message;
        this.snackbarColor = 'success';
        this.snackbarPosition = position;
        this.snackbarTimeout = 5000;
      }
    );
    EventBus.$on(
      Events.AlertInfo,
      (message: string, position: SnackbarPosition = 'bottom') => {
        this.snackbarShow = true;
        this.snackbarMessage = message;
        this.snackbarColor = 'default';
        this.snackbarPosition = position;
        this.snackbarTimeout = 5000;
      }
    );
    EventBus.$on(
      Events.AlertInfoPersistent,
      (message: string, position: SnackbarPosition = 'bottom') => {
        this.snackbarShow = true;
        this.snackbarMessage = message;
        // This is a special case for persistent info alerts for user's
        // to be able to see clear visual feedback for an action.
        this.snackbarColor = '#0960F5';
        this.snackbarPosition = position;
        this.snackbarTimeout = -1;
      }
    );
    EventBus.$on(
      Events.AlertError,
      (message: string, position: SnackbarPosition = 'bottom') => {
        this.snackbarShow = true;
        this.snackbarMessage = message;
        this.snackbarColor = 'error';
        this.snackbarPosition = position;
        this.snackbarTimeout = 5000;
      }
    );
    EventBus.$on(
      Events.AlertErrorPersistent,
      (message: string, position: SnackbarPosition = 'bottom') => {
        this.snackbarShow = true;
        this.snackbarMessage = message;
        this.snackbarColor = 'error';
        this.snackbarPosition = position;
        this.snackbarTimeout = -1;
      }
    );
    EventBus.$on(Events.HidePermanentAlerts, () => {
      const isInfoPersistentAlertActive =
        this.snackbarColor === 'default' && this.snackbarTimeout === -1;

      // We only want to hide when it's an info alert and it's persistent
      if (!isInfoPersistentAlertActive) {
        return;
      }

      this.snackbarShow = false;
      this.snackbarMessage = '';
      this.snackbarColor = 'red';
      this.snackbarPosition = 'bottom';
      this.snackbarTimeout = 5000;
    });
  }

  onResize() {
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  }

  onResizeViewport() {
    if (!window.visualViewport) return;

    const vh = window.visualViewport.height * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  }

  async refreshUser(): Promise<void> {
    if (UserModule.user.role === Roles.GuestPlus) {
      Analytics.setUser(UserModule.user);
    }
    if (!UserModule.isLogged) {
      return;
    }

    try {
      const result = await this.$apollo.query({
        // Query
        query: require('./components/auth/viewer.graphql')
      });
      UserModule.setUser(UserGraphQLTranslator(result.data.viewer.user));
      Analytics.setUser(UserModule.user);
      Analytics.track(TrackEventEnum.FanIdentified, EventCategory.Engagement);
    } catch (error) {
      graphQLErrorAlert(error);
    }
  }

  /**
   * @param forceClear
   */
  async refreshSocket(forceClear: boolean): Promise<void> {
    try {
      await socketLogin(forceClear);
      EventBus.$emit(Events.SocketLogin, true);
    } catch (e) {
      EventBus.$emit(Events.SocketLogin, false);
    }
  }

  async logout(rawLocation: RawLocation | undefined): Promise<void> {
    try {
      const input: LogOutInput = {};

      await this.$apollo.mutate({
        // Query
        mutation: require('./components/auth/logOut.graphql'),
        // Parameters
        variables: {
          input
        }
      });
    } catch (error: any) {
      // not much to do here
    } finally {
      UserModule.logOut();
      Analytics.setUser(UserModule.user);
      if (rawLocation) {
        this.$router.push(rawLocation);
      }
      EventBus.$emit(Events.PostLoggedOut);
    }
  }
}
