export async function cropImage (imgFile: File, maxWidth = 760, maxHeight = 760) {
  const fileName = 'show-image.' + imgFile.name.split('.').pop();

  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');

  if (!ctx) throw new Error('Could not create canvas context');

  const img = await createImageBitmap(imgFile);

  const ratio = Math.max(maxWidth / img.width, maxHeight / img.height);

  const width = (img.width * ratio) | 0;
  const height = (img.height * ratio) | 0;

  // resize the canvas to the new dimensions
  canvas.width = Math.min(width, height);
  canvas.height = Math.min(width, height);

  const wDiff = width - canvas.width;
  const hDiff = height - canvas.height;

  const x = wDiff > 0 ? -(wDiff / 2) : 0;
  const y = hDiff > 0 ? -(hDiff / 2) : 0;

  // scale & draw the image onto the canvas
  ctx.drawImage(img, x, y, width, height);

  const blob: Blob | null = await new Promise((resolve) => canvas.toBlob(resolve));

  if (!blob) throw new Error('Could not create blob');
  return new File([blob], fileName, imgFile);
}

export async function squareCropImage (imgFile: File, size = 760) {
  return cropImage(imgFile, size, size);
}
