import { Roles } from '@common/types/Roles';

export const GUEST_PLUS_PREFIX = 'fanfestguestplus+';
export const GUEST_PLUS_SUFFIX = 'plus@fanfest.io';

export function getGuestPlusIdentity(username: string) {
  return !username
    ? ''
    : `${GUEST_PLUS_PREFIX}${username}${GUEST_PLUS_SUFFIX}`.toLocaleLowerCase();
}

export function validateGuestPlus(id: string) {
  return id.startsWith(GUEST_PLUS_PREFIX) && id.endsWith(GUEST_PLUS_SUFFIX);
}

export function createGuestPlus(username: string) {
  return {
    id: '', // Most important bit, otherwise will be marked as logged in.
    objectId: getGuestPlusIdentity(username),
    name: username,
    username: getGuestPlusIdentity(username),
    email: getGuestPlusIdentity(username),
    role: Roles.GuestPlus,
    language: 'en',
    timezone: '',
    phone: '',
    smsNotifications: false,
    whatsappNotifications: false,
    emailNotifications: false,

    updatedAt: '',
    createdAt: ''
  };
}
