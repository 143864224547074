/* eslint-disable @typescript-eslint/ban-types */
import { ChatMessageType, phenixEncodeMessage } from '@/components/chat/chat';
import { Analytics, EventCategory, TrackEventEnum } from '@/services/tracking';
import { UserModule } from '@/store/user';

/**
 * Typescript typing for phenix
 */

export interface PCastExpress {
  sessionIdSubscription: any;
  _logger: any;
}

/**
 * This is what Phenix actually sends as a structure
 */
export interface PhenixChatMessage {
  from: {
    lastUpdate: number;
    role: string;
    screenName: string;
    sessionId: string;
  };
  message: string;
  messageId: string;
  timestamp: number;
}

export interface PhenixSubscription {
  dispose(): void;
}

export interface PhenixObservable<T> {
  subscribe(
    callback: Function,
    options?: Record<string, any>
  ): PhenixSubscription;
  getValue(): T;
  setValue(value: T): void;
}

export interface PhenixRealTimeStream {
  disableAudio: () => void;
  disableVideo: () => void;
  enableVideo: () => void;
  enableAudio: () => void;
  getStream: () => MediaStream;
  setStreamErrorCallback: (callback: () => void) => void;
  setStreamEndedCallback: (
    callback: (
      mediaStream: PhenixRealTimeStream,
      reason: string,
      reasonDescription: string
    ) => void
  ) => void;
  stop: (reason: string) => void;
}

export interface PhenixSubscribeToMemberStreamResponse {
  mediaStream: PhenixRealTimeStream;
  originStreamId: string;
  status: string;
}

export interface PhenixChatService {
  start(batchSize: number): void;
  stop(): void;
  getObservableChatMessages(): PhenixObservable<Array<PhenixChatMessage>>;
  getObservableChatEnabled(): PhenixObservable<boolean>;
  getObservableLastChatMessage(): PhenixObservable<string>;
  sendMessageToRoom(message: string, callback: Function): void;
  getMessages(
    batchSize: number,
    afterMessageId: string,
    beforeMessageId: string,
    callback: Function
  ): PhenixChatMessage[];
}

export interface PhenixRoomService {
  getRoomChatService: () => PhenixChatService;
  getChatService(): PhenixChatService;
  leaveRoom: (callback: Function, isForceLeaveRoom: boolean) => void;
  stop: () => void;
}

export interface PhenixPublisher {
  getStreamId: () => string;
  getStream: () => MediaStream;
  hasEnded: () => boolean;
  setPublisherEndedCallback: (callback: Function) => void;
  stop: (reason: string) => void;
  monitor: (options: Record<string, any>, monitorCallback: Function) => void;
}

export interface PhenixPublishToRoomResponse {
  lastUpdate: string | null;
  message?: string;
  publisher: PhenixPublisher;
  roomService: PhenixRoomService;
  status: string;
}

export interface PhenixStream {
  getUri(): string;
  getType(): string;
  getObservableAudioState(): PhenixObservable<string>;
  getObservableVideoState(): PhenixObservable<string>;
  getInfo(): Record<string, any>;
}

export interface PhenixMember {
  getSessionId(): string;
  getObservableRole(): PhenixObservable<string>;
  getObservableState(): PhenixObservable<string>;
  getObservableScreenName(): PhenixObservable<string>;
  getObservableStreams(): PhenixObservable<PhenixStream[]>;
  getObservableLastUpdate(): PhenixObservable<string>;
  getLastUpdate(): number;
  setStreams(streams: PhenixStream[]): void;
  toJson(): object;
  commitChanges(): void;
  reload(): void;
}

export interface PhenixRoomExpress {
  getPCastExpress(): PCastExpress;

  createRoom(
    options: Record<string, any>,
    callback: (error: string, response: Record<string, any>) => void
  ): void;

  joinRoom(
    options: Record<string, any>,
    joinRoomCallback: (error: string, response: Record<string, any>) => void,
    membersChangedCallback: (members: PhenixMember[]) => void
  ): void;

  subscribeToMemberStream(
    memberStream: PhenixStream,
    options: Record<string, any>,
    callback: (
      error: string,
      response: PhenixSubscribeToMemberStreamResponse
    ) => void
  ): void;

  publishToRoom(
    options: Record<string, any>,
    callback: (error: string, response: PhenixPublishToRoomResponse) => void
  ): void;

  publishScreenToRoom(
    options: Record<string, any>,
    callback: (error: string, response: PhenixPublishToRoomResponse) => void
  ): void;

  dispose(): void;
}

export interface PhenixRoom {
  getRoomId(): string;
  getObservableAlias(): PhenixObservable<string>;
  getObservableName(): PhenixObservable<string>;
  getObservableDescription(): PhenixObservable<string>;
  getObservableType(): PhenixObservable<string>;
  getObservableMembers(): PhenixObservable<string>;
  getObservableBridgeId(): PhenixObservable<string>;
  getObservablePin(): PhenixObservable<string>;
  toJson(): object;
  commitChanges(): void;
  reload(): void;
}

/**
 * Map for open streams
 */
export interface PhenixStreamMap {
  // the phenix session id
  sessionId: string;
  // the user name
  name: string;
  // the user id
  objectId: string;
}

/**
 * Actual Phenix objects.
 * These are kept in a separate object to avoid reactivity issues.
 */
const phenixData = {
  members: [] as PhenixMember[],
  roomExpress: null as PhenixRoomExpress | null,
  roomService: null as PhenixRoomService | null,
  publisher: null as PhenixPublisher | null,
  presentationRoomExpress: null as PhenixRoomExpress | null,
  presentation: null as PhenixPublisher | null,
  selfSrcObject: null as MediaStream | null,
  selfPresentationObject: null as MediaStream | null
};

export async function phenixSendChatMessage(
  message: string,
  type: ChatMessageType,
  trackData: any
): Promise<void> {
  return new Promise((resolve, reject) => {
    if (!phenixData.roomService) {
      reject(new Error('no room'));
      return;
    }
    if (!message.length) {
      resolve();
      return;
    }
    phenixData.roomService
      .getChatService()
      .sendMessageToRoom(
        phenixEncodeMessage(message, type, UserModule.user),
        (error: any, result: any) => {
          if (error || result.status !== 'ok') {
            reject(error);
          } else {
            resolve();
            Analytics.track(
              TrackEventEnum.ChatMessage,
              EventCategory.Engagement,
              trackData
            );
          }
        }
      );
  });
}

export { phenixData };
