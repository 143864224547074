import Vue from 'vue';
import { VSelect } from 'vuetify/lib';
import { LanguageEnum } from '@common/types/LanguageEnum';

// Helper
const StringIsNotNumber = (value: string) => isNaN(Number(value)) === true;

interface LanguageSelectData {
  id: string;
  name: string;
}

// Turn enum into array
function ToArray<T extends { [key: number]: string }> (
  enumData: T
): LanguageSelectData[] {
  return Object.keys(enumData)
    .filter(StringIsNotNumber)
    .map((key) => {
      return { id: key, name: enumData[key as any] };
    });
}

Vue.component('LanguageSelect', {
  extends: VSelect,
  props: {
    outlined: {
      type: Boolean,
      default: true
    },
    items: {
      type: Array,
      default: () => ToArray(LanguageEnum)
    },
    itemText: {
      type: String,
      default: 'name'
    },
    itemValue: {
      type: String,
      default: 'id'
    }
  }
});
