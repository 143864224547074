/* eslint-disable */
(function (l, e, a, p) {
  window.Sprig = function () {
    S._queue.push(arguments);
  };
  var S = window.Sprig;
  S.appId = a;
  S._queue = [];
  window.UserLeap = S;
  a = l.createElement('script');
  a.async = 1;
  a.src = e + '?id=' + S.appId;
  p = l.getElementsByTagName('script')[0];
  p.parentNode.insertBefore(a, p);
})(
  document,
  'https://cdn.sprig.com/shim.js',
  process.env.NODE_ENV === 'development' ? 'KpCm2PbZr' : '7MvkKzzhe3'
);
