




import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({ components: {} })
export default class Logo extends Vue {
  @Prop({ default: true })
  readonly color!: boolean;

  get src (): string {
    return this.color ? '/logo/logo_fanfest.svg' : '/logo/logo_white.svg';
  }
}
