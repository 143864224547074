







































































import { Component, Vue, Prop } from 'vue-property-decorator';
import { Events } from '../eventbus/events';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import { graphQLErrorAlert } from '@/util/GraphQL';
import EventBus from '../eventbus/EventBus.vue';
import { loginSuccess } from './login';
import { LogInInput } from '@/generated/graphql';
import { getLoginRedirectUrl } from '@/util/redirect';
import { UserModule } from '@/store/user';

@Component({ components: { ValidationObserver, ValidationProvider } })
export default class NewPasswordForm extends Vue {
  @Prop({
    default: true
  })
  readonly showClose!: boolean;

  @Prop()
  readonly email!: string;

  @Prop()
  readonly token!: string;

  password = '';
  passwordConfirmation = '';
  showPassword = false;
  loading = false;
  valid = true;

  async resetPassword(): Promise<void> {
    if (this.password !== this.passwordConfirmation) {
      EventBus.$emit(
        Events.AlertError,
        this.$t('login.passwordConfirmationInvalid')
      );
      return;
    }

    this.loading = true;

    try {
      const result = await this.$apollo.mutate({
        // Query
        mutation: require('./requestPasswordReset.graphql'),
        // Parameters
        variables: {
          email: this.email.toLocaleLowerCase(),
          token: '',
          password: this.password
        }
      });
      if (result.data.requestPasswordReset.ok) {
        EventBus.$emit(
          Events.AlertSuccess,
          this.$t('login.resetPasswordSuccess')
        );

        const input: LogInInput = {
          username: this.email.toLocaleLowerCase(),
          password: this.password
        };
        const result = await this.$apollo.mutate({
          // Query
          mutation: require('./logIn.graphql'),
          // Parameters
          variables: {
            input
          }
        });

        loginSuccess(
          result.data.logIn.viewer.sessionToken,
          result.data.logIn.viewer.user
        );
        this.$emit(Events.CloseDialog);

        this.$router.push(
          getLoginRedirectUrl(UserModule.user.role, UserModule.myChannelURI)
        );
      } else {
        EventBus.$emit(Events.AlertError, this.$t('login.passwordResetError'));
      }
    } catch (error: any) {
      graphQLErrorAlert(error);
    } finally {
      this.loading = false;
    }
  }

  close(): void {
    this.$emit(Events.CloseDialog);
  }
}
