import {
  TranslationMessages,
  TranslationMessagesInner
} from '../translationMessages';
import pt from './pt.json';

// @ts-expect-error - we provide fallbacks to english
const messages: TranslationMessagesInner = pt;

export default messages as TranslationMessages;
