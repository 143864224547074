import Vue from 'vue';
import VueApollo from 'vue-apollo';
import { ApolloClient } from 'apollo-client';
import { createUploadLink } from 'apollo-upload-client';
import { setContext } from 'apollo-link-context';
import { UserModule } from './store/user';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { NormalizedCacheObject } from '@apollo/client/cache';

// Install the vue plugin
Vue.use(VueApollo);

// Http endpoint
const httpEndpoint = process.env.VUE_APP_GRAPHQL_HTTP;

const httpLink = createUploadLink({
  uri: httpEndpoint
});

const authLink = setContext((_, { headers }) => {
  // return the headers to the context so httpLink can read them
  const h: { [k: string]: any } = {
    headers: {
      ...headers,
      'X-Parse-Application-Id': process.env.VUE_APP_PARSE_APP_ID
    }
  };
  if (UserModule.sessionToken) {
    h.headers['X-Parse-Session-Token'] = UserModule.sessionToken;
  }
  return h;
});

let apolloClient: ApolloClient<NormalizedCacheObject>;

export function getApolloClient (options = {}) {
  if (!apolloClient) {
    apolloClient = new ApolloClient({
      link: authLink.concat(httpLink as any),
      cache: new InMemoryCache(),
      defaultOptions: {
        watchQuery: {
          fetchPolicy: 'no-cache',
          errorPolicy: 'ignore'
        },
        query: {
          fetchPolicy: 'no-cache',
          errorPolicy: 'all'
        }
      },
      ...options
    });
  }
  return apolloClient;
}

// Call this in the Vue app file
export function createProvider (options = {}) {
  const _apolloClient = getApolloClient(options);

  // Create vue apollo provider
  const apolloProvider = new VueApollo({
    defaultClient: _apolloClient,
    defaultOptions: {
      $query: {
        fetchPolicy: 'no-cache'
      }
    },
    errorHandler (error) {
      // eslint-disable-next-line no-console
      console.log(
        '%cError',
        'background: red; color: white; padding: 2px 4px; border-radius: 3px; font-weight: bold;',
        error.message
      );
    }
  });

  return apolloProvider;
}

// // Manually call this when user log in
// export async function onLogin (
//   apolloClient: ApolloClient,
//   token: string
// ): Promise<void> {
//   if (typeof localStorage !== 'undefined' && token) {
//     localStorage.setItem(AUTH_TOKEN, token);
//   }
//   if (apolloClient.wsClient) restartWebsockets(apolloClient.wsClient);
//   try {
//     await apolloClient.resetStore();
//   } catch (e) {
//     // eslint-disable-next-line no-console
//     console.log('%cError on cache reset (login)', 'color: orange;', e.message);
//   }
// }

// // Manually call this when user log out
// export async function onLogout (apolloClient): Promise<void> {
//   if (typeof localStorage !== 'undefined') {
//     localStorage.removeItem(AUTH_TOKEN);
//   }
//   if (apolloClient.wsClient) restartWebsockets(apolloClient.wsClient);
//   try {
//     await apolloClient.resetStore();
//   } catch (e) {
//     // eslint-disable-next-line no-console
//     console.log('%cError on cache reset (logout)', 'color: orange;', e.message);
//   }
// }
