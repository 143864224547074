import { UserClass } from '@/interfaces';
import { createGuestPlus } from '@/util/guestPlus';
import { exists } from '@/util/objects';
import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';

Vue.use(Vuex);

const store = new Vuex.Store({
  modules: {},
  plugins: [
    createPersistedState({
      storage: window.localStorage,
      getState(key, storage) {
        const value = storage.getItem(key);
        try {
          const data: any =
            typeof value === 'string'
              ? JSON.parse(value)
              : typeof value === 'object'
              ? value
              : undefined;

          if (data && 'user' in data && 'user' in data.user) {
            // rehydrate with class
            data.user.user = new UserClass(data.user.user);
          }

          const url = new URLSearchParams(window.location.href);
          const guestPlusUsername = url.get('guest_username');
          const isLogged = data?.user?.user?.id;

          if (!isLogged && exists(guestPlusUsername)) {
            const guestPlusUser = new UserClass(
              createGuestPlus(guestPlusUsername as string)
            );

            return {
              user: {
                user: guestPlusUser
              }
            };
          }

          return data;
        } catch (err) {
          console.error(err);
        }
        return undefined;
      }
    })
  ]
});

export default store;
