







import { Component, Vue, Prop, Ref, Watch } from 'vue-property-decorator';

@Component({ components: {} })
export default class Twitter extends Vue {
  @Ref('container') readonly containerElement!: InstanceType<
    typeof HTMLDivElement
  >;

  @Ref('tweetDiv') readonly tweetDiv!: InstanceType<typeof HTMLDivElement>;

  @Prop({})
  readonly url!: string | null;

  loading = true;

  async mounted () {
    this.loadTweet(this.url);
  }

  @Watch('url')
  onURLChange (newval: string | null) {
    this.loadTweet(newval);
  }

  async loadTweet (tweetUrl: string | null): Promise<void> {
    this.tweetDiv.innerHTML = '';
    if (!tweetUrl) {
      return;
    }
    this.loading = true;
    const regex = /\/([0-9]+)/g;
    const data = regex.exec(tweetUrl);
    if (data && data.length) {
      const tweetId = data[1];
      // https://developer.twitter.com/en/docs/twitter-for-websites/embedded-tweets/guides/embedded-tweet-parameter-reference
      (window as any).twttr.widgets
        .createTweet(tweetId, this.$refs.tweetDiv, {
          theme: 'dark',
          dnt: true,
          width: Math.max(this.containerElement.offsetWidth, 550)
        })
        .then(() => {
          this.loading = false;
        });
    }
  }
}
