var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"elevation-12"},[_c('v-card-title',[_vm._v(_vm._s(_vm.title)+" "),_c('v-spacer'),_c('v-btn',{attrs:{"icon":""}},[_c('v-icon',{on:{"click":_vm.close}},[_vm._v("close")])],1)],1),_c('v-card-subtitle',[_vm._v(_vm._s(_vm.isEmbedded ? _vm.$t('login.embeddedSignUpMessage') : _vm.$t('login.signUpMessage')))]),_c('v-card-text',[_c('v-tabs',{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab',[_vm._v(_vm._s(_vm.isEmbedded ? _vm.$t('login.embeddedSignUp') : _vm.$t('login.signUp')))]),_c('v-tab',[_vm._v(_vm._s(_vm.$t('login.login')))])],1),_c('v-tabs-items',{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab-item',[_c('validation-observer',{ref:"observerSignup",staticClass:"login-form__container",attrs:{"tag":"form"},on:{"submit":function($event){$event.preventDefault();return _vm.signup.apply(null, arguments)}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('h2',{staticClass:"text-h5 py-4"},[_vm._v(" "+_vm._s(_vm.isEmbedded ? _vm.$t('login.embeddedSignUp') : _vm.$t('login.signUp'))+" ")]),_c('validation-provider',{attrs:{"name":_vm.$t('login.name'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"required":"","outlined":"","prepend-icon":"face","name":"name","label":_vm.$t('login.name'),"error-messages":errors},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":_vm.$t('login.email'),"rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('f-text-field',{attrs:{"required":"","prepend-icon":"person","name":"email","label":_vm.$t('login.email'),"type":"email","error-messages":errors},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":_vm.$t('login.password'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('f-text-field',{attrs:{"id":"password","required":"","prepend-icon":"lock","name":"password","autocomplete":"new-password","label":_vm.$t('login.password'),"append-icon":_vm.showPassword ? 'visibility' : 'visibility-off',"type":_vm.showPassword ? 'text' : 'password',"error-messages":errors},on:{"click:append":function($event){_vm.showPassword = !_vm.showPassword}},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}})]}}],null,true)}),(_vm.signupErrors)?_c('p',{staticClass:"error--text"},[_vm._v(" "+_vm._s(_vm.signupErrors)+" ")]):_vm._e(),_c('p',[_c('i18n',{attrs:{"path":_vm.isEmbedded
                  ? 'login.embeddedAgreePrivacyPolicy'
                  : 'login.agreePrivacyPolicy',"tag":"span"},scopedSlots:_vm._u([{key:"privacyPolicy",fn:function(){return [_c('a',{attrs:{"href":"/privacy","target":"_blank"}},[_vm._v(" "+_vm._s(_vm.$t('login.privacyPolicy')))])]},proxy:true}],null,true)}),_vm._v(", "),_c('a',{attrs:{"href":"/terms-of-use","target":"_blank"}},[_vm._v(_vm._s(_vm.$t('login.termsOfUse')))]),_vm._v(", "),_c('a',{attrs:{"href":"/cookies-policy","target":"_blank"}},[_vm._v(_vm._s(_vm.$t('login.cookiesPolicy')))]),_vm._v(" . ")],1),_c('v-btn',{attrs:{"color":"primary","block":"","loading":_vm.loading,"disabled":_vm.loading || invalid,"type":"submit"}},[_vm._v(" "+_vm._s(_vm.isEmbedded ? _vm.$t('login.embeddedSignUp') : _vm.$t('login.signUp'))+" ")])]}}])})],1),_c('v-tab-item',[_c('validation-observer',{ref:"observerLogin",staticClass:"login-form__container",attrs:{"tag":"form"},on:{"submit":function($event){$event.preventDefault();return _vm.login.apply(null, arguments)}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var invalid = ref.invalid;
return [_c('h2',{staticClass:"text-h5 py-4"},[_vm._v(_vm._s(_vm.$t('login.login')))]),_c('validation-provider',{attrs:{"name":"Name","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('f-text-field',{attrs:{"required":"","prepend-icon":"person","name":"login","label":_vm.$t('login.email'),"type":"email","error-messages":errors},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"password","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('f-text-field',{attrs:{"id":"password","required":"","prepend-icon":"lock","name":"password","autocomplete":"current-password","label":_vm.$t('login.password'),"append-icon":_vm.showPassword ? 'visibility' : 'visibility-off',"type":_vm.showPassword ? 'text' : 'password',"error-messages":errors},on:{"click:append":function($event){_vm.showPassword = !_vm.showPassword}},nativeOn:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.login.apply(null, arguments)}},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}})]}}],null,true)}),_c('p',{staticClass:"mb-6 text-right"},[_c('a',{attrs:{"href":"/privacy","target":"_blank"}},[_vm._v(_vm._s(_vm.$t('login.privacyPolicy')))]),_vm._v("   "),_c('router-link',{attrs:{"to":"/resetPasswordRequest"}},[_vm._v(" "+_vm._s(_vm.$t('login.forgotPassword'))+" ")])],1),(_vm.loginErrors)?_c('p',{staticClass:"error--text"},[_vm._v(" "+_vm._s(_vm.loginErrors)+" ")]):_vm._e(),_c('v-btn',{attrs:{"color":"primary","block":"","loading":_vm.loading,"disabled":_vm.loading || invalid,"type":"submit"},on:{"submit":function($event){$event.preventDefault();return _vm.login.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.$t('login.login'))+" ")])]}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }