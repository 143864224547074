import { Video } from '@/generated/graphql';
import { FanfestConfigClass } from '.';

/**
 * Patch the frontend types that we don't have on GraphQL and remove
 * stuff we don't need
 */
export type VideoInterface = Omit<Video, 'ACL' | 'channelPointer'> & {
  channelPointer: FanfestConfigClass;
};

export class VideoClass implements VideoInterface {
  id = '';
  createdAt: any;
  objectId = '';
  updatedAt: any;
  __typename?: 'Video' | undefined;

  name = '';
  manifest = '';
  isActive = false;
  isProcessed = false;
  video = {
    name: '',
    url: ''
  };

  channelPointer: FanfestConfigClass;

  constructor (data: VideoInterface | Video | VideoClass) {
    Object.assign(this, data);
    this.channelPointer = new FanfestConfigClass(data.channelPointer);
  }
}

/**
 * Converts data that comes from GraphQL to the expected values/types.
 *
 * @param configData GraphQL data
 * @returns A properly built interface
 */
export function VideoGraphQLTranslator (eventData: Video): VideoClass {
  const event = new VideoClass({ ...eventData });
  return event;
}
