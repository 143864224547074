






import { FanfestEventClass } from '@/interfaces';
import { Component, Vue, Prop } from 'vue-property-decorator';
import { LiveEventDataClass } from '@common/types/LiveEvent';

@Component({ components: {} })
export default class ContentAspectRatio extends Vue {
  @Prop({})
  readonly event!: FanfestEventClass;

  @Prop({})
  readonly liveEventData!: LiveEventDataClass;

  @Prop({})
  readonly aspectRatio!: string;
}
