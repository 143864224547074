




















































































































































import { Roles, RolesChannel } from '@common/types/Roles';
import { UserClass } from '../../../interfaces';
import { Component, Vue, Ref, Prop } from 'vue-property-decorator';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import EventBus from '@/components/eventbus/EventBus.vue';
import { Events } from '@/components/eventbus/events';
import { graphQLErrorAlert } from '@/util/GraphQL';
import { SignUpInput } from '@/generated/graphql';
import { UserModule } from '@/store/user';
import AutocompletePointer from '@/components/crud/Base/AutocompletePointer.vue';
import NotificationFormFields from '@/components/notification/NotificationFormFields.vue';
import { empty } from './data';
import { Id } from '@/interfaces/base';
import { rawTimeZones } from '@vvo/tzdb';
import ISO6391 from 'iso-639-1';

@Component({
  components: {
    ValidationObserver,
    ValidationProvider,
    AutocompletePointer,
    NotificationFormFields
  }
})
export default class UserEdit extends Vue {
  user: UserClass = new UserClass({ ...empty, role: Roles.Fan });

  showPassword = false;
  searchTimezones = '';
  searchLanguages = '';
  loading = false;

  Roles = Object.freeze(Roles);

  @Prop({ default: '' })
  readonly objectId!: string;

  // eslint-disable-next-line graphql/template-strings
  readonly fanfestConfigQuery = require('../FanfestConfig/autocompleteFanfestConfigs.graphql');

  @Ref() readonly observer!: InstanceType<typeof ValidationProvider>;

  get timezones(): any {
    return rawTimeZones
      .map((i) => ({
        text: `${i.alternativeName} (${i.mainCities.reduce(
          (c, old) => old + ', ' + c
        )})`,
        value: i.name
      }))
      .sort((a, b) => (a.text > b.text ? 1 : b.text > a.text ? -1 : 0));
  }

  get languages(): any {
    return ISO6391.getLanguages(ISO6391.getAllCodes())
      .map((i) => ({
        text: `${i.name} / ${i.nativeName}`,
        value: i.code
      }))
      .sort((a, b) => (a.text > b.text ? 1 : b.text > a.text ? -1 : 0));
  }

  get editablePassword(): boolean {
    return !this.user.id || UserModule.user.objectId === this.user.objectId;
  }

  get isAdmin(): boolean {
    return UserModule.user.role === Roles.Admin;
  }

  get isChannelAdmin(): boolean {
    return UserModule.user.role === Roles.ChannelAdmin;
  }

  get hasChannelPointer(): boolean {
    return (
      this.user.role === Roles.Producer ||
      this.user.role === Roles.ChannelAdmin ||
      this.user.role === Roles.Moderator
    );
  }

  get roles(): ReadonlyArray<string> {
    return this.isChannelAdmin
      ? Object.freeze(RolesChannel)
      : Object.freeze(
          Object.values([
            Roles.Admin,
            Roles.ChannelAdmin,
            Roles.Fan,
            Roles.Moderator,
            Roles.Producer
          ])
        );
  }

  async mounted(): Promise<void> {
    if (this.$route?.params.id) {
      this.load(this.$route.params.id);
    } else if (this.objectId) {
      this.load(this.objectId);
    }
  }

  async load(id: Id): Promise<void> {
    try {
      this.loading = true;
      const result = await this.$apollo.query({
        // Query
        query: require('./getUser.graphql'),
        // Parameters
        variables: {
          id: id
        }
      });
      this.$set(this, 'user', result.data.user);
    } catch (error) {
      graphQLErrorAlert(error);
    } finally {
      this.loading = false;
    }
  }

  _saveUserData(): Record<string, any> {
    const userData = {
      name: this.user.name,
      language: this.user.language || '',
      timezone: this.user.timezone || '',
      phone: this.user.phone,
      smsNotifications: this.user.smsNotifications,
      whatsappNotifications: this.user.whatsappNotifications,
      emailNotifications: this.user.emailNotifications
    } as any;

    if (!this.user.id) {
      userData.username = this.user.username;
      userData.email = this.user.username;
    }
    if (this.user.password) {
      userData.password = this.user.password;
    }

    if (this.isAdmin || this.isChannelAdmin) {
      userData.role = this.user.role;
    }

    if (this.hasChannelPointer) {
      if (this.isAdmin) {
        userData.worksChannelPointer = {
          link: this.user.worksChannelPointer?.objectId
        };
      } else if (this.isChannelAdmin) {
        userData.worksChannelPointer = {
          link: UserModule.user.worksChannelPointer?.objectId
        };
      }
    }
    return userData;
  }

  async save(): Promise<void> {
    this.observer.validate();
    const userData = this._saveUserData();

    try {
      this.loading = true;
      if (this.user.id) {
        await this.$apollo.mutate({
          // Query
          mutation: require('./updateUser.graphql'),
          // Parameters
          variables: {
            id: this.user.objectId,
            user: userData
          }
        });
      } else {
        const input: SignUpInput = {
          fields: userData as any
        };
        await this.$apollo.mutate({
          // Query
          mutation: require('../../auth/signUp.graphql'),
          // Parameters
          variables: {
            input
          }
        });
      }

      EventBus.$emit(Events.AlertSuccess, this.$t('form.saved'));
      if (this.user.password) {
        window.location.assign('/login');
      } else if (
        [Roles.Admin, Roles.ChannelAdmin, Roles.Producer].includes(
          UserModule.user.role
        )
      ) {
        this.$router.back();
      }
    } catch (error) {
      graphQLErrorAlert(error);
    } finally {
      this.loading = false;
    }
  }

  notificationChanged(field: string, value: string): void {
    this.$set(this.user, field, value);
  }
}
