





















































































import { Component, Prop, Vue } from 'vue-property-decorator';
import UserItem from './UserItem.vue';
import {
  LiveEventAdminDataInterface,
  LiveEventDataClass
} from '@common/types/LiveEvent';
import { FanfestEventClass } from '@/interfaces';
import {
  ClientToServerMessageTypes,
  EventUser
} from '@common/types/SocketProtocol';
import socket from '@/services/socket';
import { Roles } from '@common/types/Roles';

@Component({ components: { UserItem } })
export default class UsersTab extends Vue {
  @Prop({})
  readonly event!: FanfestEventClass;

  @Prop({})
  readonly liveEventData!: LiveEventDataClass;

  @Prop({})
  readonly liveEventAdminData!: LiveEventAdminDataInterface;

  searchName = '';
  tab = 0;

  users: EventUser[] = [];

  get allUsers(): EventUser[] {
    const searchLower = this.searchName.toLowerCase();
    return this.liveEventAdminData.users.filter((user) =>
      user.user.name.toLowerCase().includes(searchLower)
    );
  }

  get stageUsers(): EventUser[] {
    const searchLower = this.searchName.toLowerCase();
    return this.liveEventAdminData.users.filter(
      (user) =>
        user.user.objectId &&
        user.stream.isOnStage &&
        user.stream.isStreaming &&
        user.user.name.toLowerCase().includes(searchLower)
    );
  }

  get raisedHandUsers(): EventUser[] {
    const searchLower = this.searchName.toLowerCase();
    return this.liveEventAdminData.users.filter(
      (user) =>
        user.stream.isStreaming &&
        !user.stream.isOnStage &&
        user.user.name.toLowerCase().includes(searchLower)
    );
  }

  get otherUsers(): EventUser[] {
    const searchLower = this.searchName.toLowerCase();
    return this.liveEventAdminData.users.filter(
      (user) =>
        !user.stream.isStreaming &&
        user.user.role !== Roles.Guest &&
        user.user.name.toLowerCase().includes(searchLower)
    );
  }

  removeAll() {
    if (!confirm(this.$tc('producerControls.confirmRemoveAll'))) {
      return;
    }
    socket.emit(
      ClientToServerMessageTypes.StopUserCamera,
      this.event.objectId,
      null
    );
  }

  muteMicrophoneAll() {
    if (!confirm(this.$tc('producerControls.confirmmuteMicrophoneAll'))) {
      return;
    }
    socket.emit(
      ClientToServerMessageTypes.SetUserMuteMicrophone,
      this.event.objectId,
      null,
      true
    );
  }

  muteCameraAll() {
    if (!confirm(this.$tc('producerControls.confirmmuteCameraAll'))) {
      return;
    }
    socket.emit(
      ClientToServerMessageTypes.SetUserMuteCamera,
      this.event.objectId,
      null,
      true
    );
  }
}
