































import { UserClass } from '@/interfaces';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { VPhoneInput } from 'v-phone-input';
import 'flag-icons/css/flag-icons.min.css';

@Component({ components: { VPhoneInput } })
export default class NotificationFormField extends Vue {
  @Prop({})
  readonly user!: UserClass;

  phone = '';

  created () {
    this.phone = this.user.phone;
  }

  get hasPhone (): boolean {
    return !!this.user.phone;
  }

  @Watch('user.phone')
  propPhoneChanged (value: string): void {
    this.phone = value;
  }

  @Watch('phone')
  phoneChanged (value: string): void {
    this.$emit('changed', 'phone', value);
  }

  changed (field: string, value: string): void {
    this.$emit('changed', field, value);
  }
}
