















































































import { Component, Prop, Vue } from 'vue-property-decorator';
import RestreamChannelForm from './RestreamChannelForm.vue';
import { Restream, RestreamChannel, RestreamPlatform } from './restream';
import { FanfestEventClass } from '@/interfaces';
import { getShareableEventLink } from '@/util/share';
import { shortenLink } from '@/util/url';

@Component({ components: { RestreamChannelForm } })
export default class RestreamWidget extends Vue {
  @Prop({})
  readonly event!: FanfestEventClass;

  @Prop({})
  readonly configuredInCurrentSession!: boolean;

  loading = false;
  restream = new Restream();
  platforms: RestreamPlatform[] = [];
  restreamChannels: RestreamChannel[] = [];
  popup: Window | null = null;
  showPopupButton = false;
  forceRestreamToReloadKey = 0;

  async created() {
    try {
      await this.init();
    } catch (e) {
      window.addEventListener('message', this.restreamTokenEvent, false);
      this.openPopup();
    }
  }

  get enabledRestreamChannels() {
    return this.restreamChannels.filter((c) => c.enabled);
  }

  openPopup(): void {
    this.popup = window.open(
      this.restream.getAuth(),
      'name',
      'height=600,width=450'
    );
    if (!this.popup) {
      this.showPopupButton = true;
    } else {
      this.showPopupButton = false;
    }
  }

  async beforeDestroy() {
    window.removeEventListener('message', this.restreamTokenEvent);
  }

  async restreamTokenEvent(event: MessageEvent) {
    const data = event.data;
    try {
      if ('accessToken' in data && 'accessTokenExpiresAt' in data) {
        this.restream.setToken(data.accessToken, data.accessTokenExpiresAt);
        this.init();
      }
    } catch (e) {
      // ignore
    }
  }

  async init() {
    this.loading = true;
    this.restream.getPlatforms().then((platforms: RestreamPlatform[]) => {
      this.$set(this, 'platforms', platforms);
    });

    const streamKey = await this.restream.getStreamKey();
    this.$emit('streamKey', streamKey);

    await this.getChannels();
    this.loading = false;

    if (this.configuredInCurrentSession) {
      this.setDefaultMetaTitles();
    }
  }

  async getChannels(): Promise<void> {
    this.$set(this, 'restreamChannels', await this.restream.getChannels());
  }

  async updateChannelStatus(event: {
    id: string;
    active: boolean;
  }): Promise<void> {
    await this.restream.updateChannel(event.id, event.active);
    await this.getChannels();
  }

  async updateChannelMeta(event: { id: string; title: string }): Promise<void> {
    await this.restream.updateChannelMeta(event.id, event.title);
  }

  async setDefaultMetaTitles(): Promise<void> {
    // Setting default meta titles for show in Restream
    const promises: Promise<void>[] = [];

    const shareableLink = await getShareableEventLink(this.event);
    const shortLink = await shortenLink(shareableLink);

    const title = `${this.event.title} - ${shortLink} - @FanFestLive`;

    for (const restreamChannel of this.restreamChannels) {
      promises.push(
        this.restream.updateChannelMeta(restreamChannel.id + '', title)
      );
    }

    await Promise.allSettled(promises);
    ++this.forceRestreamToReloadKey;
  }

  getPlatformIcon(platformId: number) {
    const platform = this.platforms.find(
      (p: RestreamPlatform) => p.id === platformId
    );
    if (platform) {
      return platform.image.png;
    } else {
      return '';
    }
  }
}
