




import { UserModule } from '@/store/user';
import { Roles } from '@common/types/Roles';
import { MetaInfo } from 'vue-meta';
import { Component, Vue } from 'vue-property-decorator';
import DashboardAdmin from './Dashboard.admin.vue';
import DashboardProducer from './Dashboard.producer.vue';
import DashboardChannelAdmin from './Dashboard.channelAdmin.vue';

@Component({
  components: {
    DashboardAdmin,
    DashboardChannelAdmin,
    DashboardProducer
  }
})
export default class DashboardMain extends Vue {
  metaInfo(): MetaInfo {
    const title = 'Dashboard';
    return {
      title: title
    };
  }

  get dashboardComponent(): string | null {
    switch (UserModule.user.role) {
      case Roles.Admin:
        return 'DashboardAdmin';
      case Roles.ChannelAdmin:
        return 'DashboardChannelAdmin';
      case Roles.Producer:
        return 'DashboardProducer';
      default:
        return null;
    }
  }
}
