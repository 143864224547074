import { render, staticRenderFns } from "./PresentationStream.vue?vue&type=template&id=3de4acca&scoped=true&"
import script from "./PresentationStream.vue?vue&type=script&lang=ts&"
export * from "./PresentationStream.vue?vue&type=script&lang=ts&"
import style0 from "./PresentationStream.vue?vue&type=style&index=0&id=3de4acca&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3de4acca",
  null
  
)

export default component.exports