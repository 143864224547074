




import { Component, Vue, Prop } from 'vue-property-decorator';
import { DateTime } from 'luxon';

@Component({
  components: {}
})
export default class TimeOnly extends Vue {
  @Prop({ default: null })
  readonly date!: DateTime;

  get formatted (): string {
    return this.date?.toLocaleString(DateTime.TIME_SIMPLE);
  }
}
