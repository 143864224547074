
























































































import { Component, Vue, Prop } from 'vue-property-decorator';
import { FanfestEventClass, OfferClass } from '@/interfaces';
import StageArea from './StageArea.vue';
import FanBar from './FanBar.vue';
import Instagram from './embed/Instagram.vue';
import Twitch from './embed/Twitch.vue';
import Twitter from './embed/Twitter.vue';
import Youtube from './embed/Youtube.vue';
import VideoEmbed from './embed/VideoEmbed.vue';
import { LiveEventDataClass } from '@common/types/LiveEvent';
import { UserModule } from '@/store/user';
import { PhenixStreamMap } from '@/util/phenix';
import { ChatMessageData } from '../chat/chat';
import { DateTime } from 'luxon';
import PresentationStream from './embed/PresentationStream.vue';
import VipStream from './embed/VipStream.vue';
import initPolls from '@/services/sportbuff';

@Component({
  components: {
    Instagram,
    Twitch,
    Twitter,
    Youtube,
    VideoEmbed,
    StageArea,
    FanBar,
    PresentationStream,
    VipStream
  }
})
export default class ContentArea extends Vue {
  @Prop({})
  readonly event!: FanfestEventClass;

  @Prop({})
  readonly liveEventData!: LiveEventDataClass;

  @Prop({})
  readonly isMobileBrowser!: boolean;

  @Prop({})
  readonly producerFeatures!: boolean;

  @Prop({})
  readonly phenixStreamMap!: PhenixStreamMap[];

  @Prop({ default: () => [] })
  readonly messages!: ChatMessageData[];

  @Prop({})
  readonly offers!: OfferClass[];

  mounted() {
    initPolls(this.event.channelPointer.name, UserModule.user?.name, UserModule.user?.objectId);
  }

  get canProduce(): boolean {
    return this.event.canProduce(UserModule.user);
  }

  get millisecondsToStartDatetime(): number {
    return this.event.startDatetime.diff(DateTime.now()).milliseconds;
  }

  get showStreamLabels() {
    return (
      !(!!this.presentationLiveStream && !!this.presentationStream) &&
      this.embedComponent === null
    );
  }

  get embedComponent(): string | null {
    if (!this.liveEventData.embedURL) {
      return null;
    } else if (this.liveEventData.embedURL.includes('twitter.com')) {
      return 'Twitter';
    } else if (
      this.liveEventData.embedURL.includes('youtube.com') ||
      this.liveEventData.embedURL.includes('youtu.be')
    ) {
      return 'Youtube';
    } else if (this.liveEventData.embedURL.includes('instagram.com')) {
      return 'Instagram';
    } else if (this.liveEventData.embedURL.includes('twitch.tv')) {
      return 'Twitch';
    } else if (this.liveEventData.embedURL.endsWith('.m3u8')) {
      return 'VideoEmbed';
    }
    return '';
  }

  get backgroundUrl(): string {
    if (this.event.eventBackground?.url) {
      return 'url("' + this.event.eventBackground?.url + '")';
    } else if (this.event.channelPointer.eventBackground?.url) {
      return 'url("' + this.event.channelPointer.eventBackground?.url + '")';
    }
    return '';
  }

  get presentationLiveStream() {
    return this.liveEventData.presentation;
  }

  get vipStream() {
    const liveStream = this.liveEventData.streams.find(
      (stream) => stream.stream.isVIP && stream.stream.isOnStage
    );

    if (!liveStream) return null;

    for (let i = this.phenixStreamMap.length - 1; i >= 0; i--) {
      const phenixStream = this.phenixStreamMap[i];

      if (phenixStream.objectId === liveStream.user.objectId) {
        return { phenix: phenixStream, live: liveStream };
      }
    }

    return null;
  }

  get presentationStream() {
    const { phenixStreamMap } = this;
    if (phenixStreamMap.length === 0) return null;

    for (let i = phenixStreamMap.length - 1; i >= 0; i--) {
      if (phenixStreamMap[i].objectId === 'Presentation') {
        return phenixStreamMap[i];
      }
    }

    return null;
  }
}
