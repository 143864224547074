





























































import { UserClass, UserGraphQLTranslator } from '../../../interfaces';
import { Component, Vue, Watch } from 'vue-property-decorator';
import { UserOrder } from '@/generated/graphql';
import DateISO from '@/components/base/DateISO.vue';
import EventBus from '@/components/eventbus/EventBus.vue';
import { Events } from '@/components/eventbus/events';
import { graphQLErrorAlert } from '@/util/GraphQL';
import { debounce } from 'typescript-debounce-decorator';
import { UserModule } from '@/store/user';
import { Roles } from '@common/types/Roles';
@Component({
  components: { DateISO }
})
export default class UserList extends Vue {
  loading = false;

  initialItem = 0;

  perPage = 20;

  totalItems = -1;

  headers = [
    {
      text: this.$t('user.name'),
      align: 'start',
      sortable: false,
      value: 'name'
    },
    { text: this.$t('user.email'), value: 'username' },
    { text: this.$t('user.role'), value: 'role' },
    { text: this.$t('user.channel'), value: 'worksChannelPointer' },
    { text: this.$t('form.createdAt'), value: 'createdAt' },
    { text: this.$t('form.actions'), value: 'actions', sortable: false }
  ];

  users: UserClass[] = [];

  order: UserOrder = UserOrder.CreatedAtAsc;

  search = '';

  @Watch('$route')
  onRouteChanged() {
    this.fetch();
  }

  @Watch('search')
  @debounce(250)
  onSearchChanged() {
    this.fetch();
  }

  get pageTitle(): string {
    let translation;

    if (UserModule.user.role === Roles.ChannelAdmin) {
      translation = this.$t('producer.objectNamePlural');
    } /* Admin */ else {
      translation = this.$t('user.objectNamePlural');
    }

    return translation.toString();
  }

  async mounted(): Promise<void> {
    await this.fetch();
  }

  async updateItemsPerPage(perPage: number): Promise<void> {
    this.perPage = perPage;
    await this.fetch();
  }

  async updatePage(page: number): Promise<void> {
    this.initialItem = (page - 1) * this.perPage;
    await this.fetch();
  }

  async fetch(): Promise<void> {
    this.loading = true;
    try {
      const result = await this.$apollo.query({
        query: require('./getUsers.graphql'),
        variables: {
          order: [this.order],
          skip: this.initialItem,
          first: this.perPage,
          name: this.search || ''
        }
      });
      this.$set(
        this,
        'users',
        result.data.users.edges.map((i: any) => UserGraphQLTranslator(i.node))
      );
      this.totalItems = result.data.users.count;
    } catch (error) {
      graphQLErrorAlert(error);
    } finally {
      this.loading = false;
    }
  }

  async deleteItem(item: UserClass): Promise<void> {
    if (!confirm(this.$t('form.reallyDelete') as string)) {
      return;
    }
    try {
      await this.$apollo.mutate({
        mutation: require('./deleteUser.graphql'),
        variables: {
          id: item.objectId
        }
      });
      EventBus.$emit(Events.AlertSuccess, this.$t('form.deleted'));
      await this.fetch();
    } catch (error: any) {
      graphQLErrorAlert(error);
    }
  }
}
