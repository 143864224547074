import { LocaleMessages } from 'vue-i18n';
import ar from './ar';
import en from './en';
import es from './es';
import fr from './fr';
import gr from './gr';
import pt from './pt';
import ja from './ja';

const messages: LocaleMessages = {
  ar,
  en,
  es,
  fr,
  gr,
  pt,
  ja
};

export default messages;
