export enum BaseRoute {
  HomePage = 'HomePage',
  Channel = 'Channel',
  Event = 'Event',
  EventShort = 'EventShort',
  Privacy = 'Privacy',
  AcceptCookies = 'AcceptCookies',
  CookiesPolicy = 'CookiesPolicy',
  TermsOfUse = 'TermsOfUse',
  Profile = 'Profile',
  EventSimulfest = 'EventSimulfest',

  Login = 'Login',
  ResetPasswordRequest = 'ResetPasswordRequest',
  ResetPassword = 'ResetPassword',
  ParseFrameURL = 'ParseFrameURL'
}

export enum AdminRoute {
  Dashboard = 'Dashboard'
}
