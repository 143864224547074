






import { MetaInfo } from 'vue-meta';
import { Component, Vue } from 'vue-property-decorator';
import LoginForm from '@/components/auth/LoginForm.vue';
import { UserModule } from '@/store/user';
import { getLoginRedirectUrl } from '@/util/redirect';

@Component({
  components: { LoginForm }
})
export default class Login extends Vue {
  metaInfo(): MetaInfo {
    const title = 'Login';
    return {
      title: title
    };
  }

  LoggedIn(): void {
    this.$router.push(
      getLoginRedirectUrl(UserModule.user.role, UserModule.myChannelURI)
    );
  }
}
