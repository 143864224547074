






















import { Component, Prop, Vue } from 'vue-property-decorator';
import { ClientToServerMessageTypes } from '@common/types/SocketProtocol';
import socket from '@/services/socket';

@Component({})
export default class ActionsMenu extends Vue {
  @Prop({})
  readonly eventId!: string;

  @Prop({})
  readonly userId!: string;

  onBlockChannel (): void {
    if (!confirm(this.$tc('producerControls.confirmBlockUser'))) {
      return;
    }
    this.$emit('blockChannel', false);
    socket.emit(
      ClientToServerMessageTypes.UserBlockChannel,
      this.eventId,
      this.userId
    );
  }

  onRemoveEvent (): void {
    if (!confirm(this.$tc('producerControls.confirmRemoveUser'))) {
      return;
    }
    this.$emit('removeEvent', false);
    socket.emit(
      ClientToServerMessageTypes.UserRemoveEvent,
      this.eventId,
      this.userId
    );
  }
}
