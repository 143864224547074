








































































































































import { FanfestEventClass, FanfestEventGraphQLTranslator } from '@/interfaces';
import { Component, Vue, Watch } from 'vue-property-decorator';
import { FanfestEventOrder } from '@/generated/graphql';
import DateHuman from '@/components/base/DateHuman.vue';
import BooleanChip from '@/components/base/BooleanChip.vue';
import EventBus from '@/components/eventbus/EventBus.vue';
import { Events } from '@/components/eventbus/events';
import { graphQLErrorAlert } from '@/util/GraphQL';
import { UserModule } from '@/store/user';
import { Roles } from '@common/types/Roles';
import { debounce } from 'typescript-debounce-decorator';
import TwitterShareAnchor from './TwitterShareAnchor.vue';
import { getShareableEventLink } from '@/util/share';
import { writeToClipboard } from '@/util/navigator';

@Component({
  components: { DateHuman, BooleanChip, TwitterShareAnchor }
})
export default class FanfestEventList extends Vue {
  loading = false;

  initialItem = 0;

  perPage = 20;

  totalItems = -1;

  headers = [
    {
      text: this.$t('fanfestEvent.title'),
      align: 'start',
      sortable: false,
      value: 'title'
    },
    // { text: this.$t('fanfestEvent.status'), value: 'mode' },
    ...(UserModule.user.role === Roles.Admin
      ? [
          {
            text: this.$t('offer.channel'),
            value: 'channelPointer',
            sortable: false
          }
        ]
      : []),
    { text: this.$t('fanfestEvent.status'), value: 'status', sortable: false },
    { text: this.$t('fanfestEvent.startTime'), value: 'startDatetime' },
    { text: this.$t('form.actions'), value: 'actions', sortable: false }
  ];

  fanfestEvents: FanfestEventClass[] = [];

  order: FanfestEventOrder | undefined = undefined;

  languageNames: any = null;

  search = '';

  @Watch('$route')
  onRouteChanged() {
    this.fetch();
  }

  @Watch('search')
  @debounce(250)
  onSearchChanged() {
    this.fetch();
  }

  getItemUrl(item: FanfestEventClass) {
    return encodeURIComponent(item.urlWithDomain);
  }

  async created() {
    this.languageNames = new (Intl as any).DisplayNames(
      [this.$i18n.locale, 'en'],
      {
        type: 'language'
      }
    );
  }

  async mounted(): Promise<void> {
    await this.fetch();
  }

  languageName(language: string | null | undefined): string {
    if (!language) {
      return '';
    }
    return this.languageNames.of(language);
  }

  async updateItemsPerPage(perPage: number): Promise<void> {
    this.perPage = perPage;
    await this.fetch();
  }

  async updatePage(page: number): Promise<void> {
    this.initialItem = (page - 1) * this.perPage;
    await this.fetch();
  }

  updateSortByAsc(order: boolean | string | undefined) {
    if (order === false && this.order?.includes('ASC')) {
      return this.updateSortBy(undefined, undefined);
    }

    if (typeof order !== 'string') {
      return;
    }

    return this.updateSortBy(order, 'asc');
  }

  updateSortByDesc(order: any) {
    if (order === true && this.order !== undefined) {
      return this.updateSortBy(undefined, 'desc');
    }

    if (typeof order !== 'string') {
      return;
    }

    return this.updateSortBy(order, 'desc');
  }

  async updateSortBy(
    order: string | undefined,
    direction: 'asc' | 'desc' | undefined
  ): Promise<void> {
    if (direction === undefined) {
      this.order = undefined;
      return await this.fetch();
    }

    const localOrder = 'startDatetime';

    const orderWithDirection =
      order === undefined && this.order
        ? this.order.split(/^([A-Za-z]+)_((asc)|(desc))$/)[1]
        : `${localOrder}_${direction === 'asc' ? 'ASC' : 'DESC'}`;

    this.order = orderWithDirection as FanfestEventOrder;
    await this.fetch();
  }

  async fetch(): Promise<void> {
    this.loading = true;
    const order = this.order ?? FanfestEventOrder.CreatedAtDesc;

    try {
      let queryData;
      if (UserModule.user.role === Roles.Admin) {
        queryData = {
          query: require('./getFanfestEvents.graphql'),
          variables: {
            order: [order],
            skip: this.initialItem,
            first: this.perPage,
            name: this.search || ''
          }
        };
      } else {
        queryData = {
          query: require('./getFanfestEventsByChannel.graphql'),
          variables: {
            order: [order],
            skip: this.initialItem,
            first: this.perPage,
            name: this.search || '',
            channelName: UserModule.user.worksChannelPointer?.name
          }
        };
      }
      const result = await this.$apollo.query(queryData);

      this.$set(
        this,
        'fanfestEvents',
        result.data.fanfestEvents.edges.map((i: any) =>
          FanfestEventGraphQLTranslator(i.node)
        )
      );
      this.totalItems = result.data.fanfestEvents.count;
    } catch (error) {
      graphQLErrorAlert(error);
    } finally {
      this.loading = false;
    }
  }

  async deleteItem(item: FanfestEventClass): Promise<void> {
    if (!confirm(this.$t('form.reallyDelete') as string)) {
      return;
    }
    try {
      await this.$apollo.mutate({
        mutation: require('./deleteFanfestEvent.graphql'),
        variables: {
          id: item.id
        }
      });
      EventBus.$emit(Events.AlertSuccess, this.$t('form.deleted'));
      await this.fetch();
    } catch (error: any) {
      graphQLErrorAlert(error);
    }
  }

  async shareItem(item: FanfestEventClass): Promise<void> {
    if (navigator.clipboard) {
      try {
        await writeToClipboard(await getShareableEventLink(item));
        EventBus.$emit(Events.AlertSuccess, this.$tc('share.clipboard'));
      } catch (error) {
        EventBus.$emit(Events.AlertError, this.$tc('share.shareError'));
      }
    } else {
      EventBus.$emit(Events.AlertError, this.$tc('share.shareError'));
    }
  }
}
