
























import { VideoClass } from '../../../interfaces';
import { Component, Vue, Ref } from 'vue-property-decorator';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import { graphQLErrorAlert } from '@/util/GraphQL';
import { Id } from '@/interfaces/base';
import { empty } from './data';
import { UserModule } from '@/store/user';
import { Roles } from '@common/types/Roles';

import AutocompletePointer from '@/components/crud/Base/AutocompletePointer.vue';
import Hls from 'hls.js';

@Component({
  components: { AutocompletePointer, ValidationObserver, ValidationProvider }
})
export default class VideoEdit extends Vue {
  @Ref('video') readonly videoElement!: InstanceType<typeof HTMLVideoElement>;

  @Ref() readonly observer!: InstanceType<typeof ValidationProvider>;

  readonly fanfestConfigQuery = require('../FanfestConfig/autocompleteFanfestConfigs.graphql');

  video: VideoClass = new VideoClass({ ...empty, isActive: true });

  videoFile: File | null = null;

  loading = false;

  hls: Hls | null = null;
  hlsSupported = true;

  async mounted(): Promise<void> {
    if (this.$route?.params.id) {
      this.load(this.$route.params.id);
    }
    if (UserModule.user.role !== Roles.Admin) {
      this.video.channelPointer.objectId =
        UserModule.user.worksChannelPointer?.objectId || '';
    }
  }

  beforeDestroy() {
    if (this.hls) {
      this.hls.destroy();
    }
    this.hls = null;
  }

  async load(id: Id): Promise<void> {
    try {
      this.loading = true;
      const result = await this.$apollo.query({
        // Query
        query: require('./getVideo.graphql'),
        // Parameters
        variables: {
          id: id
        }
      });
      this.$set(this, 'video', result.data.video);

      // Wait for video element to be ready
      await this.$nextTick();
      this.loadVideo();
    } catch (e) {
      graphQLErrorAlert(e);
    } finally {
      this.loading = false;
    }
  }

  loadVideo() {
    if (!Hls.isSupported()) {
      if (this.videoElement.canPlayType('application/vnd.apple.mpegurl')) {
        this.videoElement.src = this.video.manifest;
        this.videoElement.addEventListener('loadedmetadata', () => {
          this.videoElement.play();
        });
      } else {
        this.hlsSupported = true;
      }
      return;
    }

    if (!this.hls) {
      this.hls = new Hls({
        debug: true
      });
    }

    this.hls.attachMedia(this.videoElement);
    this.hls.on(Hls.Events.MEDIA_ATTACHED, () => {
      if (!this.hls) {
        return;
      }
      this.hls.loadSource(this.video.manifest);
      // this.hls.on(Hls.Events.MANIFEST_PARSED, (event, data) => {
      //   console.log(
      //     'manifest loaded, found ' + data.levels.length + ' quality level'
      //   );
      // });
    });
    this.hls.on(Hls.Events.ERROR, (event, data) => {
      if (!this.hls) {
        return;
      }
      console.error('Video HLS error', event, data);
      if (data.fatal) {
        switch (data.type) {
          case Hls.ErrorTypes.NETWORK_ERROR:
            // try to recover network error
            console.warn('fatal network error encountered, try to recover');
            this.hls.startLoad();
            break;
          case Hls.ErrorTypes.MEDIA_ERROR:
            console.warn('fatal media error encountered, try to recover');
            this.hls.recoverMediaError();
            break;
          default:
            // cannot recover
            this.hls.destroy();
            break;
        }
      }
    });
  }
}
