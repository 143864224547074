import { AddressInterface } from '@/interfaces';

const empty: AddressInterface = {
  objectId: '',
  id: '',
  updatedAt: '',
  createdAt: '',
  address: '',
  address2: '',
  city: '',
  state: '',
  zip: '',
  country: '',
  sendFromEmail: ''
};

const test: AddressInterface = {
  objectId: '000000',
  id: '000000',
  updatedAt: '2021-12-06T14:04:35.893Z',
  createdAt: '2021-12-06T14:04:35.893Z',
  address: '123 Main Street',
  address2: 'Back Alley',
  city: 'Springfield',
  state: 'NY',
  zip: '012345',
  country: 'United States',
  sendFromEmail: 'email@test.com'
};

export { empty, test };
