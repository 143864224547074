
















import { Component, Vue, Prop } from 'vue-property-decorator';
import { ChatMessageData, ChatMessageType } from './chat';
import ActionsMenu from './ActionsMenu.vue';
import ChatMessageText from './messages/ChatMessageText.vue';
import TimeOnly from '@/components/base/TimeOnly.vue';
import { UserModule } from '@/store/user';
import { FanfestEventClass } from '@/interfaces';

@Component({ components: { ChatMessageText, TimeOnly, ActionsMenu } })
export default class ChatToast extends Vue {
  @Prop({
    required: true
  })
  readonly message!: ChatMessageData;

  @Prop({})
  readonly event!: FanfestEventClass;

  get canProduce (): boolean {
    return this.event.canProduce(UserModule.user);
  }

  get color (): string {
    if (this.message.user.isVIP) {
      return 'rgba(94, 56, 189, 0.1)';
    }
    return '';
  }

  get messageComponent (): string {
    switch (this.message.type) {
    case ChatMessageType.Message:
      return 'ChatMessageText';
    default:
      return '';
    }
  }

  get lowerName (): string {
    return this.message.user.name.toLocaleLowerCase();
  }
}
