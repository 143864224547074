import { express, net } from 'phenix-web-sdk';
import EventBus from '@/components/eventbus/EventBus.vue';
import {
  FanfestEventClass,
  FanfestEventGraphQLTranslator,
  OfferClass,
  OfferGraphQLTranslator
} from '@/interfaces';
import Vue from 'vue';
import Component from 'vue-class-component';
import { empty as emptyEventData } from '@/components/crud/FanfestEvent/data';
import { Id } from '@/interfaces/base';
import { graphQLErrorAlert } from '@/util/GraphQL';
import { OfferOrder } from '@/generated/graphql';
import {
  PhenixChatMessage,
  phenixData,
  PhenixMember,
  PhenixPublishToRoomResponse,
  phenixSendChatMessage,
  PhenixStreamMap,
  PhenixRoomExpress
} from '../util/phenix';
import { UserModule } from '@/store/user';
import { Events } from '@/components/eventbus/events';
import {
  ChatMessageData,
  ChatMessageType,
  phenixDecodeMessage
} from '@/components/chat/chat';
import {
  LiveEventAdminDataInterface,
  LiveEventDataClass,
  LiveEventDataInterface
} from '@common/types/LiveEvent';
import socket from '@/services/socket';
import { Socket } from 'vue-socket.io-extended';
import {
  ClientToServerMessageTypes,
  ServerToClientMessageTypes
} from '@common/types/SocketProtocol';
import { Analytics, EventCategory, TrackEventEnum } from '@/services/tracking';
import { notEmpty } from '@/util/array';
import { SimulcastStatusEnum } from '@common/types/Simulfest';
import { rejects } from 'assert';

enum PhenixCustomEvent {
  userStoppedCamera = 'userStoppedCamera',
  socketStoppedCamera = 'socketStoppedCamera',
  startCleanupStoppedCamera = 'startCleanupStoppedCamera'
}

// HelloWorld class will be a Vue compon
@Component
export default class BaseEvent extends Vue {
  event: FanfestEventClass = new FanfestEventClass({ ...emptyEventData });

  offers: OfferClass[] = [];

  liveEventData: LiveEventDataClass = new LiveEventDataClass({
    started: false,
    simulcasting: false,
    simulcastStatus: SimulcastStatusEnum.initial,
    transmitted: false,
    eventURL: '',
    embedURL: '',
    streams: [],
    blockedUserIds: [],
    topic: {
      title: '',
      subtitle: '',
      show: false
    },
    presentation: null
  });

  liveEventAdminData: LiveEventAdminDataInterface = { users: [] };

  chatMessages: ChatMessageData[] = [];

  /**
   * The member sessions on phenix. This is a reactive array for vue, but
   * the actual data is on phenixData.members.
   */
  phenixStreamMap: PhenixStreamMap[] = [];

  isCameraOpen = false;

  // have we already told everyone about our camera?
  isCameraPropagated = false;

  // for presentation
  presentationStream: MediaStream | null = null;

  isPresenting = false;

  get canProduce(): boolean {
    return this.event.canProduce(UserModule.user);
  }

  @Socket(ServerToClientMessageTypes.LiveEventData)
  onLiveDataEvent(objectId: string, data: LiveEventDataInterface): void {
    if (this.event.objectId !== objectId) {
      return;
    }
    console.log('onLiveDataEvent', data);
    this.liveEventData.update(data);
    if (this.liveEventData.transmitted) {
      this.event.transmitted = true;
    }
  }

  @Socket(ServerToClientMessageTypes.LiveEventAdmin)
  onLiveAdminEvent(objectId: string, data: LiveEventAdminDataInterface): void {
    if (this.event.objectId !== objectId) {
      return;
    }
    this.$set(this, 'liveEventAdminData', data);
  }

  @Socket(ServerToClientMessageTypes.StopUserCamera)
  onStopUserCamera(objectId: string): void {
    if (this.event.objectId !== objectId) {
      return;
    }
    this.cameraStop('socket');
  }

  @Socket(ServerToClientMessageTypes.UserBlockChannel)
  onUserBlockChannel(objectId: string, userObjectId: string): void {
    if (this.event.objectId !== objectId) {
      return;
    }
    if (UserModule.user.objectId === userObjectId) {
      // we got kicked out.
      EventBus.$emit(Events.AlertError, this.$t('chat.userBlockChannelSelf'));
    } else {
      // someone else got kicked out
      this.liveEventData.blockedUserIds.push(userObjectId);
    }
  }

  @Socket(ServerToClientMessageTypes.UserRemoveEvent)
  onUserRemoveEvent(objectId: string, userObjectId: string): void {
    if (this.event.objectId !== objectId) {
      return;
    }
    if (UserModule.user.objectId === userObjectId) {
      // we got kicked out.
      EventBus.$emit(Events.AlertError, this.$t('chat.userRemoveEventSelf'));
      this.$router.push(this.event.channelPointer.url);
    } else {
      // someone else got kicked out
      this.$set(
        this,
        'chatMessages',
        this.chatMessages.filter(
          (i: ChatMessageData) => i.user.objectId !== userObjectId
        )
      );
    }
  }

  async _joinRoomSocket(): Promise<string> {
    if (!this.event.objectId) {
      return 'No event';
    }
    return new Promise<string>((resolve, reject) => {
      socket.emit(
        ClientToServerMessageTypes.JoinRoom,
        this.event.objectId,
        async (valid: boolean, reason: string): Promise<void> => {
          if (!valid) {
            EventBus.$emit(Events.AlertErrorPersistent, reason);
            phenixData.roomExpress?.dispose();
            phenixData.roomExpress = null;
            reject(reason);
          } else {
            Analytics.track(
              TrackEventEnum.EventJoined,
              EventCategory.Engagement,
              this.event.trackData()
            );
            if (UserModule.user.objectId) {
              try {
                await this.$apollo.mutate({
                  mutation: require('../components/crud/Registration/createRegistration.graphql'),
                  // Parameters
                  variables: {
                    registration: {
                      eventPointer: {
                        link: this.event.objectId
                      }
                    }
                  }
                });
              } catch (_error) {
                // pass, it's a duplicate
              }
            }
            resolve('');
          }
        }
      );
    });
  }

  async fetchEvent(id: Id): Promise<void> {
    try {
      const result = await this.$apollo.query({
        // Query
        query: require('../components/crud/FanfestEvent/getFanfestEvent.graphql'),
        // Parameters
        variables: {
          id: id
        }
      });
      this.$set(
        this,
        'event',
        FanfestEventGraphQLTranslator(result.data.fanfestEvent)
      );

      // TODO this is wrong. this will set user properties for what is event data.
      // but it was requested to be done this way.
      // https://www.notion.so/playvici/Amplitude-user-tracking-implementation-incomplete-352fe64b046e4dd491f2bb0bd4aa3be8
      Analytics.setUserProperties(this.event.trackData());
    } catch (e) {}
  }

  async fetchOffers(channel: string): Promise<void> {
    try {
      const result = await this.$apollo.query({
        query: require('../components/crud/Offer/getActiveOffersByChannel.graphql'),
        variables: {
          order: OfferOrder.CreatedAtAsc,
          skip: 0,
          first: 20,
          channelName: channel
        }
      });
      this.$set(
        this,
        'offers',
        result.data.offers.edges.map((i: any) => OfferGraphQLTranslator(i.node))
      );
    } catch (error) {
      graphQLErrorAlert(error);
    }
  }

  createPhenixRoom() {
    const adminApiProxyClient = new net.AdminApiProxyClient();
    adminApiProxyClient.setBackendUri(
      'https://demo-integration.phenixrts.com/pcast'
    );
    adminApiProxyClient.setAuthenticationData({
      userId: 'phenix-test-1',
      password: 'phenix-test-2'
    });

    const channelExpressOptions = {
      treatBackgroundAsOffline: false,
      adminApiProxyClient: adminApiProxyClient,
      onError: (error: string) => {
        throw new Error(error);
      }
    };

    const room = new express.RoomExpress(channelExpressOptions);

    // For Adam: This is ONLY for development.
    // If you enable this back it will slow down development time by a factor of 100x.
    if (process.env.NODE_ENV === 'development') {
      const p = room?.getPCastExpress();
      if (p) {
        for (const l of p._logger.getAppenders()) {
          l._minLevel = 6;
        }
      }
    }

    return room;
  }

  async phenixInit(): Promise<void> {
    phenixData.roomExpress = this.createPhenixRoom();

    await this.createRoomPhenix();

    // old: only producers can create the room.
    // if (this.canProduce) {
    //   await this.createRoomPhenix();
    // } else {
    //   await this.joinRoomPhenix();
    // }
  }

  async joinRoomPhenix(
    phenixRoomJoined: (error: any, response: any) => void,
    membersChanged: (members: PhenixMember[]) => void,
    roomExpress: PhenixRoomExpress | null = phenixData.roomExpress
  ): Promise<void> {
    const roomAlias = this.event.objectId;
    if (roomAlias) {
      const options = {
        role: 'Audience',
        alias: roomAlias,
        screenName: UserModule.user.objectId
      };
      roomExpress?.joinRoom(options, phenixRoomJoined, membersChanged);
    } else {
      console.error(
        '[FanFest] Unable to launch Phenix, no event id available: %s',
        JSON.stringify(this.event)
      );
    }
  }

  async createRoomPhenix(): Promise<void> {
    const roomAlias = this.event.objectId;
    const options = {
      room: {
        name: roomAlias,
        type: 'MultiPartyChat',
        alias: roomAlias
      }
    };

    return new Promise<void>((resolve, reject) => {
      phenixData.roomExpress?.createRoom(
        options,
        (error: any, response: any) => {
          if (error) {
            // Handle error
            // self.leaveRoomAndStopPublisher();
            EventBus.$emit(
              Events.AlertError,
              'Unable to create room: ' + error.message
            );
            reject(error);
            return;
          }

          if (response.status === 'already-exists') {
            // Room already exists
            resolve();
          } else if (response.status !== 'ok') {
            // Handle error
            EventBus.$emit(Events.AlertError, 'Error creating room');
          } else if (response.status === 'ok' && response.room) {
            resolve();
          }
        }
      );
    });
  }

  onChatSendMessage(message: string): void {
    try {
      phenixSendChatMessage(
        message,
        ChatMessageType.Message,
        this.event.trackData()
      );
    } catch (e) {
      EventBus.$emit(Events.AlertError, 'Could not send your message');
    }
  }

  phenixInitChatService(): void {
    const batchSize = 50;
    const chatService = phenixData.roomService?.getChatService();
    if (!chatService) {
      return;
    }

    chatService.start(batchSize);

    chatService.getObservableChatEnabled().subscribe(
      (enabled: boolean) => {
        if (enabled) {
          console.warn('Chat is enabled');
        } else {
          console.warn('Chat is DISABLED');
        }
      },
      { initial: 'notify' }
    );

    // load initial data
    let firstLoadedLast = false;
    chatService
      .getObservableLastChatMessage()
      .subscribe(async (message: PhenixChatMessage) => {
        try {
          const m = phenixDecodeMessage(message);
          this.processNewChatMessage(m);
          if (!firstLoadedLast) {
            firstLoadedLast = true;
            this.chatMessages.sort(
              (a, b) => a.datetime.toMillis() - b.datetime.toMillis()
            );
          }
        } catch (error) {
          EventBus.$emit(Events.AlertError, 'Error processing chat message');
          console.error(error);
        }
      });

    let firstLoadedList = false;
    chatService
      .getObservableChatMessages()
      .subscribe((messages: PhenixChatMessage[]) => {
        if (firstLoadedList) {
          return;
        }
        firstLoadedList = true;

        const parsed: ChatMessageData[] = messages
          .map((m) => {
            try {
              return phenixDecodeMessage(m);
            } catch (_error) {
              return null;
            }
          })
          .filter(notEmpty);

        parsed.map(this.processNewChatMessage.bind(this));

        this.chatMessages.sort(
          (a, b) => a.datetime.toMillis() - b.datetime.toMillis()
        );
      });
  }

  processNewChatMessage(m: ChatMessageData): void {
    switch (m.type) {
      case ChatMessageType.Message:
      case ChatMessageType.RaiseHand:
        if (this.chatMessages.find((message) => message.id === m.id)) {
          // already exists
          return;
        }
        this.chatMessages.push(m);
        break;
      default:
        break;
    }
  }

  // subscriberCallback
  _membersChanged(members: PhenixMember[]): void {
    console.warn('members updated', members);
    phenixData.members = members;

    const phenixStreamHashMap: Record<string, PhenixStreamMap> = {};

    for (const member of members) {
      const memberStreams = member.getObservableStreams().getValue();
      const memberStream = memberStreams[0];
      const streamInfo = memberStream.getInfo();

      if (memberStreams.length > 1) {
        console.warn(
          'Found more than one stream for user: ' +
            streamInfo.name +
            ' - ' +
            streamInfo.objectId
        );
      }

      phenixStreamHashMap[streamInfo.objectId] = {
        sessionId: member.getSessionId(),
        name: streamInfo.name,
        objectId: streamInfo.objectId
      };
    }
    const phenixStreamMap = Object.values(phenixStreamHashMap);

    this.$set(this, 'phenixStreamMap', phenixStreamMap);
  }

  get validChatMessages(): ChatMessageData[] {
    return this.chatMessages.filter(
      (i: ChatMessageData) =>
        !this.liveEventData.blockedUserIds.includes(i.user.objectId)
    );
  }

  cameraStart(devices: {
    audioInputDeviceId: string;
    videoInputDeviceId: string;
  }): Promise<any> {
    return new Promise((resolve, reject) => {
      const roomAlias = this.event.objectId;
      this.isCameraOpen = true;
      this.isCameraPropagated = false;

      this.cameraStop('beforeStart');

      const publishOptions = {
        capabilities: [
          // "prefer-h264",
          // 'multi-bitrate',
          'hd',
          // "low-latency",
          // "streaming",
          'on-demand'
        ], // Add other capabilities if you like. Prefer-h264 allows publishing/viewing on Safari/IOS 11
        room: {
          alias: roomAlias,
          name: roomAlias,
          type: 'MultiPartyChat'
        },
        mediaConstraints: {
          video: { deviceId: devices.videoInputDeviceId }, // TODO { facingMode: { ideal: cameraSelection } }, // Camera request setup
          audio: { deviceId: devices.audioInputDeviceId }
        }, // Use the same deviceIds for both
        streamType: 'User',
        memberRole: 'Participant',
        frameRate: 25,
        // videoElement: this.selfVideo,
        monitor: {
          options: {
            monitorBitRate: false,
            monitorFrameRate: false
          },
          callback: (error: any, res: any) => {
            if (res?.description === PhenixCustomEvent.userStoppedCamera) {
              socket.emit(
                ClientToServerMessageTypes.StopUserCamera,
                this.event.objectId,
                UserModule.user.objectId
              );
            }

            if (error) {
              socket.emit(
                ClientToServerMessageTypes.StopUserCamera,
                this.event.objectId,
                UserModule.user.objectId
              );
            }
          }
        },
        // these are undocumented, but exist
        screenName: UserModule.user.objectId,
        streamInfo: {
          name: UserModule.user.name,
          objectId: UserModule.user.objectId
        }
      };

      phenixData.publisher?.setPublisherEndedCallback(() => {
        console.error('publisher stream ended');

        socket.emit(
          ClientToServerMessageTypes.StopUserCamera,
          this.event.objectId,
          UserModule.user.objectId
        );
      });

      phenixData.roomExpress?.publishToRoom(
        publishOptions,
        (error: any, response: PhenixPublishToRoomResponse) => {
          if (error) {
            // Handle error
            EventBus.$emit(
              Events.AlertError,
              'Error publishing to room: ' + error
            );
            reject(new Error(error));
            this.isCameraOpen = false;
            return;
          }

          if (response.status !== 'ok') {
            // Handle error
            EventBus.$emit(
              Events.AlertError,
              'Error trying to publish to room'
            );
            reject(new Error(response.message));
            this.isCameraOpen = false;
            return;
          }

          // Successfully published to room
          if (response.status === 'ok' && response.publisher) {
            console.warn('published to room');
            phenixData.publisher = response.publisher;
            // save self media stream
            phenixData.selfSrcObject = response.publisher.getStream();

            resolve(response);
          }
        }
      );
    });
  }

  cameraStop(cause: 'user' | 'socket' | 'beforeStart') {
    // this.selfVideo.srcObject = null;
    this.isCameraOpen = false;
    this.isCameraPropagated = false;
    console.warn('stopping', phenixData.publisher);

    let stopCause: PhenixCustomEvent;

    if (cause === 'user') {
      stopCause = PhenixCustomEvent.userStoppedCamera;
    } else if (cause === 'socket') {
      stopCause = PhenixCustomEvent.socketStoppedCamera;
    } else {
      stopCause = PhenixCustomEvent.startCleanupStoppedCamera;
    }

    phenixData.publisher?.stop(stopCause);

    phenixData.publisher = null;
    phenixData.selfSrcObject = null;
  }

  async presentationStart() {
    if (!phenixData.presentationRoomExpress) {
      phenixData.presentationRoomExpress = this.createPhenixRoom();
      await new Promise((resolve) =>
        this.joinRoomPhenix(
          (error) => (error ? rejects(error) : resolve(undefined)),
          // eslint-disable-next-line @typescript-eslint/no-empty-function
          () => {},
          phenixData.presentationRoomExpress
        )
      );
    }

    try {
      this.presentationStream = await navigator.mediaDevices.getDisplayMedia({
        audio: true,
        video: true
      });
      const tracks = this.presentationStream?.getTracks();

      for (const track of tracks) {
        track.addEventListener('ended', () => {
          socket.emit(
            ClientToServerMessageTypes.StopPresentation,
            this.event.objectId
          );

          this.stopPresenting();
        });
      }

      this.presentationStream?.addEventListener('removetrack', () => {
        console.error('stream removed');

        socket.emit(
          ClientToServerMessageTypes.StopPresentation,
          this.event.objectId
        );
      });
    } catch (err) {
      console.error('Error: ' + err);
      throw err;
    }

    await new Promise((resolve, reject) => {
      const roomAlias = this.event.objectId;
      this.isPresenting = true;

      const publishOptions = {
        capabilities: ['sd', 'on-demand'],
        room: {
          alias: roomAlias,
          name: roomAlias,
          type: 'MultiPartyChat'
        },
        userMediaStream: this.presentationStream,
        mediaConstraints: {
          video: true,
          audio: true
        },
        streamType: 'Presentation',
        memberRole: 'Presenter',
        frameRate: 80,
        monitor: {
          options: {
            monitorBitRate: false,
            monitorFrameRate: false
          },
          callback: (error: any, res: any) => {
            console.log('monitor callback', error, res);

            if (error) {
              socket.emit(
                ClientToServerMessageTypes.StopPresentation,
                this.event.objectId
              );
            }
          }
        },
        screenName: 'Presentation',
        streamInfo: {
          name: 'Presentation',
          objectId: 'Presentation'
        }
      };

      phenixData.presentation?.setPublisherEndedCallback(() => {
        console.error('publisher stream ended');

        socket.emit(
          ClientToServerMessageTypes.StopPresentation,
          this.event.objectId
        );
      });

      phenixData.presentationRoomExpress?.publishToRoom(
        publishOptions,
        (error: any, response: PhenixPublishToRoomResponse) => {
          if (error) {
            // Handle error
            EventBus.$emit(
              Events.AlertError,
              'Error publishing to room: ' + error
            );
            reject(new Error(error));
            this.isPresenting = false;
            return;
          }

          if (response.status !== 'ok') {
            // Handle error
            EventBus.$emit(
              Events.AlertError,
              'Error trying to publish to room'
            );
            reject(new Error(response.message));
            this.isPresenting = false;
            return;
          }

          // Successfully published to room
          if (response.status === 'ok' && response.publisher) {
            console.warn('published to room');
            phenixData.presentation = response.publisher;
            // save self media stream
            phenixData.selfPresentationObject = response.publisher.getStream();

            resolve(response);
          }
        }
      );
    });
  }

  stopPresenting() {
    if (!this.presentationStream) return;

    const tracks = this.presentationStream.getTracks();

    for (const track of tracks) {
      track.stop();
    }

    console.warn('stopping presentation', phenixData.presentation);
    phenixData.presentation?.stop(PhenixCustomEvent.userStoppedCamera);
    phenixData.presentation = null;
    phenixData.selfPresentationObject = null;
  }
}
