/* eslint-disable no-use-before-define */

export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Any: any;
  Bytes: any;
  Date: any;
  File: any;
  Object: any;
  Upload: any;
};

export type Acl = {
  __typename?: 'ACL';
  public?: Maybe<PublicAcl>;
  roles?: Maybe<Array<RoleAcl>>;
  users?: Maybe<Array<UserAcl>>;
};

export type AclInput = {
  public?: InputMaybe<PublicAclInput>;
  roles?: InputMaybe<Array<RoleAclInput>>;
  users?: InputMaybe<Array<UserAclInput>>;
};

export type Address = Node &
  ParseObject & {
    __typename?: 'Address';
    ACL: Acl;
    address?: Maybe<Scalars['String']>;
    address2?: Maybe<Scalars['String']>;
    city?: Maybe<Scalars['String']>;
    country?: Maybe<Scalars['String']>;
    createdAt: Scalars['Date'];
    id: Scalars['ID'];
    objectId: Scalars['ID'];
    sendFromEmail?: Maybe<Scalars['String']>;
    state?: Maybe<Scalars['String']>;
    updatedAt: Scalars['Date'];
    zip?: Maybe<Scalars['String']>;
  };

export type AddressConnection = {
  __typename?: 'AddressConnection';
  count: Scalars['Int'];
  edges?: Maybe<Array<Maybe<AddressEdge>>>;
  pageInfo: PageInfo;
};

export type AddressEdge = {
  __typename?: 'AddressEdge';
  cursor: Scalars['String'];
  node?: Maybe<Address>;
};

export enum AddressOrder {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC'
}

export type AddressPointerInput = {
  createAndLink?: InputMaybe<CreateAddressFieldsInput>;
  link?: InputMaybe<Scalars['ID']>;
};

export type AddressRelationInput = {
  add?: InputMaybe<Array<Scalars['ID']>>;
  createAndAdd?: InputMaybe<Array<CreateAddressFieldsInput>>;
  remove?: InputMaybe<Array<Scalars['ID']>>;
};

export type AddressRelationWhereInput = {
  exists?: InputMaybe<Scalars['Boolean']>;
  have?: InputMaybe<AddressWhereInput>;
  haveNot?: InputMaybe<AddressWhereInput>;
};

export type AddressWhereInput = {
  AND?: InputMaybe<Array<AddressWhereInput>>;
  NOR?: InputMaybe<Array<AddressWhereInput>>;
  OR?: InputMaybe<Array<AddressWhereInput>>;
};

export type ArrayResult =
  | Address
  | Element
  | FanfestConfig
  | FanfestEvent
  | Offer
  | Registration
  | User
  | Video;

export type ArrayWhereInput = {
  containedBy?: InputMaybe<Array<InputMaybe<Scalars['Any']>>>;
  contains?: InputMaybe<Array<InputMaybe<Scalars['Any']>>>;
  equalTo?: InputMaybe<Scalars['Any']>;
  exists?: InputMaybe<Scalars['Boolean']>;
  greaterThan?: InputMaybe<Scalars['Any']>;
  greaterThanOrEqualTo?: InputMaybe<Scalars['Any']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Any']>>>;
  inQueryKey?: InputMaybe<SelectInput>;
  lessThan?: InputMaybe<Scalars['Any']>;
  lessThanOrEqualTo?: InputMaybe<Scalars['Any']>;
  notEqualTo?: InputMaybe<Scalars['Any']>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['Any']>>>;
  notInQueryKey?: InputMaybe<SelectInput>;
};

export type BooleanWhereInput = {
  equalTo?: InputMaybe<Scalars['Boolean']>;
  exists?: InputMaybe<Scalars['Boolean']>;
  inQueryKey?: InputMaybe<SelectInput>;
  notEqualTo?: InputMaybe<Scalars['Boolean']>;
  notInQueryKey?: InputMaybe<SelectInput>;
};

export type BoxInput = {
  bottomLeft: GeoPointInput;
  upperRight: GeoPointInput;
};

export type BytesWhereInput = {
  equalTo?: InputMaybe<Scalars['Bytes']>;
  exists?: InputMaybe<Scalars['Boolean']>;
  greaterThan?: InputMaybe<Scalars['Bytes']>;
  greaterThanOrEqualTo?: InputMaybe<Scalars['Bytes']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Bytes']>>>;
  inQueryKey?: InputMaybe<SelectInput>;
  lessThan?: InputMaybe<Scalars['Bytes']>;
  lessThanOrEqualTo?: InputMaybe<Scalars['Bytes']>;
  notEqualTo?: InputMaybe<Scalars['Bytes']>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['Bytes']>>>;
  notInQueryKey?: InputMaybe<SelectInput>;
};

export type CallCloudCodeInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  functionName: CloudCodeFunction;
  params?: InputMaybe<Scalars['Object']>;
};

export type CallCloudCodePayload = {
  __typename?: 'CallCloudCodePayload';
  clientMutationId?: Maybe<Scalars['String']>;
  result?: Maybe<Scalars['Any']>;
};

export type CenterSphereInput = {
  center: GeoPointInput;
  distance: Scalars['Float'];
};

export type Class = {
  __typename?: 'Class';
  name: Scalars['String'];
  schemaFields: Array<SchemaField>;
};

export enum CloudCodeFunction {
  CreateVideoFromYoutube = 'createVideoFromYoutube',
  DeleteUser2 = 'deleteUser2',
  RequestPasswordReset = 'requestPasswordReset',
  RestreamCallback = 'restreamCallback',
  RestreamToken = 'restreamToken',
  SwitchboardJwt = 'switchboardJWT',
  UpdateUser2 = 'updateUser2',
  UserAcceptInvitation = 'userAcceptInvitation',
  UserInvite = 'userInvite'
}

export type CreateAddressFieldsInput = {
  ACL?: InputMaybe<AclInput>;
  address?: InputMaybe<Scalars['String']>;
  address2?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  sendFromEmail?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
  zip?: InputMaybe<Scalars['String']>;
};

export type CreateAddressInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  fields?: InputMaybe<CreateAddressFieldsInput>;
};

export type CreateAddressPayload = {
  __typename?: 'CreateAddressPayload';
  address: Address;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateClassInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  schemaFields?: InputMaybe<SchemaFieldsInput>;
};

export type CreateClassPayload = {
  __typename?: 'CreateClassPayload';
  class: Class;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateFanfestConfigFieldsInput = {
  ACL?: InputMaybe<AclInput>;
  addressPointer?: InputMaybe<AddressPointerInput>;
  bannedRelation?: InputMaybe<UserRelationInput>;
  channelBackground?: InputMaybe<FileInput>;
  channelHeroColor: Scalars['String'];
  description?: InputMaybe<Scalars['String']>;
  enableGatedEvents?: InputMaybe<Scalars['Boolean']>;
  enableShowCreationNotifications?: InputMaybe<Scalars['Boolean']>;
  eventBackground?: InputMaybe<FileInput>;
  image?: InputMaybe<FileInput>;
  isActive: Scalars['Boolean'];
  name: Scalars['String'];
  sponsorImage?: InputMaybe<FileInput>;
  sponsorName?: InputMaybe<Scalars['String']>;
  title: Scalars['String'];
};

export type CreateFanfestConfigInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  fields?: InputMaybe<CreateFanfestConfigFieldsInput>;
};

export type CreateFanfestConfigPayload = {
  __typename?: 'CreateFanfestConfigPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  fanfestConfig: FanfestConfig;
};

export type CreateFanfestEventFieldsInput = {
  ACL?: InputMaybe<AclInput>;
  bannedRelation?: InputMaybe<UserRelationInput>;
  channelPointer: FanfestConfigPointerInput;
  cost?: InputMaybe<Scalars['Float']>;
  description?: InputMaybe<Scalars['String']>;
  eventBackground?: InputMaybe<FileInput>;
  hostUrl?: InputMaybe<Scalars['String']>;
  isActive: Scalars['Boolean'];
  language?: InputMaybe<Scalars['String']>;
  recording?: InputMaybe<Scalars['String']>;
  shouldSendNotification?: InputMaybe<Scalars['Boolean']>;
  shouldSendNotifications?: InputMaybe<Scalars['Boolean']>;
  startDatetime: Scalars['Date'];
  started?: InputMaybe<Scalars['Boolean']>;
  title: Scalars['String'];
  transmitted?: InputMaybe<Scalars['Boolean']>;
};

export type CreateFanfestEventInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  fields?: InputMaybe<CreateFanfestEventFieldsInput>;
};

export type CreateFanfestEventPayload = {
  __typename?: 'CreateFanfestEventPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  fanfestEvent: FanfestEvent;
};

export type CreateFileInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  upload: Scalars['Upload'];
};

export type CreateFilePayload = {
  __typename?: 'CreateFilePayload';
  clientMutationId?: Maybe<Scalars['String']>;
  fileInfo: FileInfo;
};

export type CreateOfferFieldsInput = {
  ACL?: InputMaybe<AclInput>;
  channelPointer?: InputMaybe<FanfestConfigPointerInput>;
  image?: InputMaybe<FileInput>;
  isActive: Scalars['Boolean'];
  subtitle?: InputMaybe<Scalars['String']>;
  title: Scalars['String'];
  url?: InputMaybe<Scalars['String']>;
};

export type CreateOfferInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  fields?: InputMaybe<CreateOfferFieldsInput>;
};

export type CreateOfferPayload = {
  __typename?: 'CreateOfferPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  offer: Offer;
};

export type CreateRegistrationFieldsInput = {
  ACL?: InputMaybe<AclInput>;
  eventPointer: FanfestEventPointerInput;
  location?: InputMaybe<Scalars['Object']>;
  userPointer?: InputMaybe<UserPointerInput>;
};

export type CreateRegistrationInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  fields?: InputMaybe<CreateRegistrationFieldsInput>;
};

export type CreateRegistrationPayload = {
  __typename?: 'CreateRegistrationPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  registration: Registration;
};

export type CreateUserFieldsInput = {
  ACL?: InputMaybe<AclInput>;
  authData?: InputMaybe<Scalars['Object']>;
  email?: InputMaybe<Scalars['String']>;
  emailNotifications?: InputMaybe<Scalars['Boolean']>;
  emailVerified?: InputMaybe<Scalars['Boolean']>;
  externalID?: InputMaybe<Scalars['String']>;
  language?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  password: Scalars['String'];
  phone?: InputMaybe<Scalars['String']>;
  restreamPointer?: InputMaybe<Scalars['Object']>;
  role: Scalars['String'];
  smsNotifications?: InputMaybe<Scalars['Boolean']>;
  timezone?: InputMaybe<Scalars['String']>;
  username: Scalars['String'];
  whatsappNotifications?: InputMaybe<Scalars['Boolean']>;
  worksChannelPointer?: InputMaybe<FanfestConfigPointerInput>;
};

export type CreateUserInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  fields?: InputMaybe<CreateUserFieldsInput>;
};

export type CreateUserPayload = {
  __typename?: 'CreateUserPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  user: User;
};

export type CreateVideoFieldsInput = {
  ACL?: InputMaybe<AclInput>;
  channelPointer: FanfestConfigPointerInput;
  duration?: InputMaybe<Scalars['Float']>;
  isActive: Scalars['Boolean'];
  isProcessed?: InputMaybe<Scalars['Boolean']>;
  manifest?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  userPointer?: InputMaybe<UserPointerInput>;
  video?: InputMaybe<FileInput>;
};

export type CreateVideoInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  fields?: InputMaybe<CreateVideoFieldsInput>;
};

export type CreateVideoPayload = {
  __typename?: 'CreateVideoPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  video: Video;
};

export type DateWhereInput = {
  equalTo?: InputMaybe<Scalars['Date']>;
  exists?: InputMaybe<Scalars['Boolean']>;
  greaterThan?: InputMaybe<Scalars['Date']>;
  greaterThanOrEqualTo?: InputMaybe<Scalars['Date']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Date']>>>;
  inQueryKey?: InputMaybe<SelectInput>;
  lessThan?: InputMaybe<Scalars['Date']>;
  lessThanOrEqualTo?: InputMaybe<Scalars['Date']>;
  notEqualTo?: InputMaybe<Scalars['Date']>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['Date']>>>;
  notInQueryKey?: InputMaybe<SelectInput>;
};

export type DeleteClassInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
};

export type DeleteClassPayload = {
  __typename?: 'DeleteClassPayload';
  class: Class;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type DeleteFanfestConfigInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};

export type DeleteFanfestConfigPayload = {
  __typename?: 'DeleteFanfestConfigPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  fanfestConfig: FanfestConfig;
};

export type DeleteFanfestEventInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};

export type DeleteFanfestEventPayload = {
  __typename?: 'DeleteFanfestEventPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  fanfestEvent: FanfestEvent;
};

export type DeleteOfferInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};

export type DeleteOfferPayload = {
  __typename?: 'DeleteOfferPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  offer: Offer;
};

export type DeleteUserInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};

export type DeleteVideoInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};

export type DeleteVideoPayload = {
  __typename?: 'DeleteVideoPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  video: Video;
};

export type Element = {
  __typename?: 'Element';
  value: Scalars['Any'];
};

export type FanfestConfig = Node &
  ParseObject & {
    __typename?: 'FanfestConfig';
    ACL: Acl;
    addressPointer?: Maybe<Address>;
    bannedRelation: UserConnection;
    channelBackground?: Maybe<FileInfo>;
    channelHeroColor: Scalars['String'];
    createdAt: Scalars['Date'];
    description?: Maybe<Scalars['String']>;
    enableGatedEvents?: Maybe<Scalars['Boolean']>;
    enableShowCreationNotifications?: Maybe<Scalars['Boolean']>;
    eventBackground?: Maybe<FileInfo>;
    id: Scalars['ID'];
    image?: Maybe<FileInfo>;
    isActive: Scalars['Boolean'];
    name: Scalars['String'];
    objectId: Scalars['ID'];
    sponsorImage?: Maybe<FileInfo>;
    sponsorName?: Maybe<Scalars['String']>;
    title: Scalars['String'];
    updatedAt: Scalars['Date'];
  };

export type FanfestConfigBannedRelationArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  options?: InputMaybe<ReadOptionsInput>;
  order?: InputMaybe<Array<UserOrder>>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<UserWhereInput>;
};

export type FanfestConfigConnection = {
  __typename?: 'FanfestConfigConnection';
  count: Scalars['Int'];
  edges?: Maybe<Array<Maybe<FanfestConfigEdge>>>;
  pageInfo: PageInfo;
};

export type FanfestConfigEdge = {
  __typename?: 'FanfestConfigEdge';
  cursor: Scalars['String'];
  node?: Maybe<FanfestConfig>;
};

export enum FanfestConfigOrder {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC'
}

export type FanfestConfigPointerInput = {
  createAndLink?: InputMaybe<CreateFanfestConfigFieldsInput>;
  link?: InputMaybe<Scalars['ID']>;
};

export type FanfestConfigRelationInput = {
  add?: InputMaybe<Array<Scalars['ID']>>;
  createAndAdd?: InputMaybe<Array<CreateFanfestConfigFieldsInput>>;
  remove?: InputMaybe<Array<Scalars['ID']>>;
};

export type FanfestConfigRelationWhereInput = {
  exists?: InputMaybe<Scalars['Boolean']>;
  have?: InputMaybe<FanfestConfigWhereInput>;
  haveNot?: InputMaybe<FanfestConfigWhereInput>;
};

export type FanfestConfigWhereInput = {
  AND?: InputMaybe<Array<FanfestConfigWhereInput>>;
  NOR?: InputMaybe<Array<FanfestConfigWhereInput>>;
  OR?: InputMaybe<Array<FanfestConfigWhereInput>>;
  name?: InputMaybe<StringWhereInput>;
};

export type FanfestEvent = Node &
  ParseObject & {
    __typename?: 'FanfestEvent';
    ACL: Acl;
    bannedRelation: UserConnection;
    channelPointer: FanfestConfig;
    cost?: Maybe<Scalars['Float']>;
    createdAt: Scalars['Date'];
    description?: Maybe<Scalars['String']>;
    eventBackground?: Maybe<FileInfo>;
    hostUrl?: Maybe<Scalars['String']>;
    id: Scalars['ID'];
    isActive: Scalars['Boolean'];
    language?: Maybe<Scalars['String']>;
    objectId: Scalars['ID'];
    recording?: Maybe<Scalars['String']>;
    shouldSendNotification?: Maybe<Scalars['Boolean']>;
    shouldSendNotifications?: Maybe<Scalars['Boolean']>;
    startDatetime: Scalars['Date'];
    started?: Maybe<Scalars['Boolean']>;
    title: Scalars['String'];
    transmitted?: Maybe<Scalars['Boolean']>;
    updatedAt: Scalars['Date'];
  };

export type FanfestEventBannedRelationArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  options?: InputMaybe<ReadOptionsInput>;
  order?: InputMaybe<Array<UserOrder>>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<UserWhereInput>;
};

export type FanfestEventConnection = {
  __typename?: 'FanfestEventConnection';
  count: Scalars['Int'];
  edges?: Maybe<Array<Maybe<FanfestEventEdge>>>;
  pageInfo: PageInfo;
};

export type FanfestEventEdge = {
  __typename?: 'FanfestEventEdge';
  cursor: Scalars['String'];
  node?: Maybe<FanfestEvent>;
};

export enum FanfestEventOrder {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  StartDatetimeAsc = 'startDatetime_ASC',
  StartDatetimeDesc = 'startDatetime_DESC'
}

export type FanfestEventPointerInput = {
  createAndLink?: InputMaybe<CreateFanfestEventFieldsInput>;
  link?: InputMaybe<Scalars['ID']>;
};

export type FanfestEventRelationInput = {
  add?: InputMaybe<Array<Scalars['ID']>>;
  createAndAdd?: InputMaybe<Array<CreateFanfestEventFieldsInput>>;
  remove?: InputMaybe<Array<Scalars['ID']>>;
};

export type FanfestEventRelationWhereInput = {
  exists?: InputMaybe<Scalars['Boolean']>;
  have?: InputMaybe<FanfestEventWhereInput>;
  haveNot?: InputMaybe<FanfestEventWhereInput>;
};

export type FanfestEventWhereInput = {
  AND?: InputMaybe<Array<FanfestEventWhereInput>>;
  NOR?: InputMaybe<Array<FanfestEventWhereInput>>;
  OR?: InputMaybe<Array<FanfestEventWhereInput>>;
  channelPointer?: InputMaybe<FanfestConfigRelationWhereInput>;
  startDatetime?: InputMaybe<DateWhereInput>;
  started?: InputMaybe<BooleanWhereInput>;
  title?: InputMaybe<StringWhereInput>;
  transmitted?: InputMaybe<BooleanWhereInput>;
};

export type FileInfo = {
  __typename?: 'FileInfo';
  name: Scalars['String'];
  url: Scalars['String'];
};

export type FileInput = {
  file?: InputMaybe<Scalars['File']>;
  unlink?: InputMaybe<Scalars['Boolean']>;
  upload?: InputMaybe<Scalars['Upload']>;
};

export type FileWhereInput = {
  equalTo?: InputMaybe<Scalars['File']>;
  exists?: InputMaybe<Scalars['Boolean']>;
  greaterThan?: InputMaybe<Scalars['File']>;
  greaterThanOrEqualTo?: InputMaybe<Scalars['File']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['File']>>>;
  inQueryKey?: InputMaybe<SelectInput>;
  lessThan?: InputMaybe<Scalars['File']>;
  lessThanOrEqualTo?: InputMaybe<Scalars['File']>;
  matchesRegex?: InputMaybe<Scalars['String']>;
  notEqualTo?: InputMaybe<Scalars['File']>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['File']>>>;
  notInQueryKey?: InputMaybe<SelectInput>;
  options?: InputMaybe<Scalars['String']>;
};

export type GeoIntersectsInput = {
  point?: InputMaybe<GeoPointInput>;
};

export type GeoPoint = {
  __typename?: 'GeoPoint';
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
};

export type GeoPointInput = {
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
};

export type GeoPointWhereInput = {
  exists?: InputMaybe<Scalars['Boolean']>;
  geoWithin?: InputMaybe<GeoWithinInput>;
  maxDistance?: InputMaybe<Scalars['Float']>;
  maxDistanceInKilometers?: InputMaybe<Scalars['Float']>;
  maxDistanceInMiles?: InputMaybe<Scalars['Float']>;
  maxDistanceInRadians?: InputMaybe<Scalars['Float']>;
  nearSphere?: InputMaybe<GeoPointInput>;
  within?: InputMaybe<WithinInput>;
};

export type GeoWithinInput = {
  centerSphere?: InputMaybe<CenterSphereInput>;
  polygon?: InputMaybe<Array<GeoPointInput>>;
};

export type IdWhereInput = {
  equalTo?: InputMaybe<Scalars['ID']>;
  exists?: InputMaybe<Scalars['Boolean']>;
  greaterThan?: InputMaybe<Scalars['ID']>;
  greaterThanOrEqualTo?: InputMaybe<Scalars['ID']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  inQueryKey?: InputMaybe<SelectInput>;
  lessThan?: InputMaybe<Scalars['ID']>;
  lessThanOrEqualTo?: InputMaybe<Scalars['ID']>;
  notEqualTo?: InputMaybe<Scalars['ID']>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  notInQueryKey?: InputMaybe<SelectInput>;
};

export type KeyValueInput = {
  key: Scalars['String'];
  value: Scalars['Any'];
};

export type LogInInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  password: Scalars['String'];
  username: Scalars['String'];
};

export type LogInPayload = {
  __typename?: 'LogInPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  viewer: Viewer;
};

export type LogInWithInput = {
  authData: Scalars['Object'];
  clientMutationId?: InputMaybe<Scalars['String']>;
  fields?: InputMaybe<UserLoginWithInput>;
};

export type LogInWithPayload = {
  __typename?: 'LogInWithPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  viewer: Viewer;
};

export type LogOutInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
};

export type LogOutPayload = {
  __typename?: 'LogOutPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
};

export type Mutation = {
  __typename?: 'Mutation';
  callCloudCode?: Maybe<CallCloudCodePayload>;
  createAddress?: Maybe<CreateAddressPayload>;
  createClass?: Maybe<CreateClassPayload>;
  createFanfestConfig?: Maybe<CreateFanfestConfigPayload>;
  createFanfestEvent?: Maybe<CreateFanfestEventPayload>;
  createFile?: Maybe<CreateFilePayload>;
  createOffer?: Maybe<CreateOfferPayload>;
  createRegistration?: Maybe<CreateRegistrationPayload>;
  createUser?: Maybe<CreateUserPayload>;
  createVideo?: Maybe<CreateVideoPayload>;
  createVideoFromYoutube: Scalars['String'];
  deleteClass?: Maybe<DeleteClassPayload>;
  deleteFanfestConfig?: Maybe<DeleteFanfestConfigPayload>;
  deleteFanfestEvent?: Maybe<DeleteFanfestEventPayload>;
  deleteOffer?: Maybe<DeleteOfferPayload>;
  deleteUser2?: Maybe<Scalars['Boolean']>;
  deleteVideo?: Maybe<DeleteVideoPayload>;
  logIn?: Maybe<LogInPayload>;
  logInWith?: Maybe<LogInWithPayload>;
  logOut?: Maybe<LogOutPayload>;
  requestPasswordReset: RequestPasswordResetPayload;
  resetPassword?: Maybe<ResetPasswordPayload>;
  restreamCallback: RestreamTokenData;
  sendVerificationEmail?: Maybe<SendVerificationEmailPayload>;
  signUp?: Maybe<SignUpPayload>;
  updateAddress?: Maybe<UpdateAddressPayload>;
  updateClass?: Maybe<UpdateClassPayload>;
  updateFanfestConfig?: Maybe<UpdateFanfestConfigPayload>;
  updateFanfestEvent?: Maybe<UpdateFanfestEventPayload>;
  updateOffer?: Maybe<UpdateOfferPayload>;
  updateUser?: Maybe<UpdateUserPayload>;
  updateUser2?: Maybe<UpdateUserPayload>;
  updateVideo?: Maybe<UpdateVideoPayload>;
  userAcceptInvitation?: Maybe<CreateUserPayload>;
  userInvite?: Maybe<Scalars['Boolean']>;
};

export type MutationCallCloudCodeArgs = {
  input: CallCloudCodeInput;
};

export type MutationCreateAddressArgs = {
  input: CreateAddressInput;
};

export type MutationCreateClassArgs = {
  input: CreateClassInput;
};

export type MutationCreateFanfestConfigArgs = {
  input: CreateFanfestConfigInput;
};

export type MutationCreateFanfestEventArgs = {
  input: CreateFanfestEventInput;
};

export type MutationCreateFileArgs = {
  input: CreateFileInput;
};

export type MutationCreateOfferArgs = {
  input: CreateOfferInput;
};

export type MutationCreateRegistrationArgs = {
  input: CreateRegistrationInput;
};

export type MutationCreateUserArgs = {
  input: CreateUserInput;
};

export type MutationCreateVideoArgs = {
  input: CreateVideoInput;
};

export type MutationCreateVideoFromYoutubeArgs = {
  channel: Scalars['String'];
  url: Scalars['String'];
};

export type MutationDeleteClassArgs = {
  input: DeleteClassInput;
};

export type MutationDeleteFanfestConfigArgs = {
  input: DeleteFanfestConfigInput;
};

export type MutationDeleteFanfestEventArgs = {
  input: DeleteFanfestEventInput;
};

export type MutationDeleteOfferArgs = {
  input: DeleteOfferInput;
};

export type MutationDeleteUser2Args = {
  input: DeleteUserInput;
};

export type MutationDeleteVideoArgs = {
  input: DeleteVideoInput;
};

export type MutationLogInArgs = {
  input: LogInInput;
};

export type MutationLogInWithArgs = {
  input: LogInWithInput;
};

export type MutationLogOutArgs = {
  input: LogOutInput;
};

export type MutationRequestPasswordResetArgs = {
  email: Scalars['String'];
  password: Scalars['String'];
  token: Scalars['String'];
};

export type MutationResetPasswordArgs = {
  input: ResetPasswordInput;
};

export type MutationRestreamCallbackArgs = {
  code: Scalars['String'];
  scope: Scalars['String'];
  state: Scalars['String'];
};

export type MutationSendVerificationEmailArgs = {
  input: SendVerificationEmailInput;
};

export type MutationSignUpArgs = {
  input: SignUpInput;
};

export type MutationUpdateAddressArgs = {
  input: UpdateAddressInput;
};

export type MutationUpdateClassArgs = {
  input: UpdateClassInput;
};

export type MutationUpdateFanfestConfigArgs = {
  input: UpdateFanfestConfigInput;
};

export type MutationUpdateFanfestEventArgs = {
  input: UpdateFanfestEventInput;
};

export type MutationUpdateOfferArgs = {
  input: UpdateOfferInput;
};

export type MutationUpdateUserArgs = {
  input: UpdateUserInput;
};

export type MutationUpdateUser2Args = {
  input: UpdateUserInput;
};

export type MutationUpdateVideoArgs = {
  input: UpdateVideoInput;
};

export type MutationUserAcceptInvitationArgs = {
  date: Scalars['String'];
  input: CreateUserInput;
  invitationCode: Scalars['String'];
};

export type MutationUserInviteArgs = {
  channelId?: InputMaybe<Scalars['String']>;
  email: Scalars['String'];
  role: Scalars['String'];
};

export type Node = {
  id: Scalars['ID'];
};

export type NumberWhereInput = {
  equalTo?: InputMaybe<Scalars['Float']>;
  exists?: InputMaybe<Scalars['Boolean']>;
  greaterThan?: InputMaybe<Scalars['Float']>;
  greaterThanOrEqualTo?: InputMaybe<Scalars['Float']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>;
  inQueryKey?: InputMaybe<SelectInput>;
  lessThan?: InputMaybe<Scalars['Float']>;
  lessThanOrEqualTo?: InputMaybe<Scalars['Float']>;
  notEqualTo?: InputMaybe<Scalars['Float']>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>;
  notInQueryKey?: InputMaybe<SelectInput>;
};

export type ObjectWhereInput = {
  equalTo?: InputMaybe<KeyValueInput>;
  exists?: InputMaybe<Scalars['Boolean']>;
  greaterThan?: InputMaybe<KeyValueInput>;
  greaterThanOrEqualTo?: InputMaybe<KeyValueInput>;
  in?: InputMaybe<Array<InputMaybe<KeyValueInput>>>;
  inQueryKey?: InputMaybe<SelectInput>;
  lessThan?: InputMaybe<KeyValueInput>;
  lessThanOrEqualTo?: InputMaybe<KeyValueInput>;
  notEqualTo?: InputMaybe<KeyValueInput>;
  notIn?: InputMaybe<Array<InputMaybe<KeyValueInput>>>;
  notInQueryKey?: InputMaybe<SelectInput>;
};

export type Offer = Node &
  ParseObject & {
    __typename?: 'Offer';
    ACL: Acl;
    channelPointer?: Maybe<FanfestConfig>;
    createdAt: Scalars['Date'];
    id: Scalars['ID'];
    image?: Maybe<FileInfo>;
    isActive: Scalars['Boolean'];
    objectId: Scalars['ID'];
    subtitle?: Maybe<Scalars['String']>;
    title: Scalars['String'];
    updatedAt: Scalars['Date'];
    url?: Maybe<Scalars['String']>;
  };

export type OfferConnection = {
  __typename?: 'OfferConnection';
  count: Scalars['Int'];
  edges?: Maybe<Array<Maybe<OfferEdge>>>;
  pageInfo: PageInfo;
};

export type OfferEdge = {
  __typename?: 'OfferEdge';
  cursor: Scalars['String'];
  node?: Maybe<Offer>;
};

export enum OfferOrder {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC'
}

export type OfferPointerInput = {
  createAndLink?: InputMaybe<CreateOfferFieldsInput>;
  link?: InputMaybe<Scalars['ID']>;
};

export type OfferRelationInput = {
  add?: InputMaybe<Array<Scalars['ID']>>;
  createAndAdd?: InputMaybe<Array<CreateOfferFieldsInput>>;
  remove?: InputMaybe<Array<Scalars['ID']>>;
};

export type OfferRelationWhereInput = {
  exists?: InputMaybe<Scalars['Boolean']>;
  have?: InputMaybe<OfferWhereInput>;
  haveNot?: InputMaybe<OfferWhereInput>;
};

export type OfferWhereInput = {
  AND?: InputMaybe<Array<OfferWhereInput>>;
  NOR?: InputMaybe<Array<OfferWhereInput>>;
  OR?: InputMaybe<Array<OfferWhereInput>>;
  channelPointer?: InputMaybe<FanfestConfigRelationWhereInput>;
  isActive?: InputMaybe<BooleanWhereInput>;
  title?: InputMaybe<StringWhereInput>;
};

export type PageInfo = {
  __typename?: 'PageInfo';
  endCursor?: Maybe<Scalars['String']>;
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  startCursor?: Maybe<Scalars['String']>;
};

export type ParseObject = {
  ACL: Acl;
  createdAt: Scalars['Date'];
  objectId: Scalars['ID'];
  updatedAt: Scalars['Date'];
};

export type PointerFieldInput = {
  name: Scalars['String'];
  targetClassName: Scalars['String'];
};

export type PolygonWhereInput = {
  exists?: InputMaybe<Scalars['Boolean']>;
  geoIntersects?: InputMaybe<GeoIntersectsInput>;
};

export type PublicAcl = {
  __typename?: 'PublicACL';
  read?: Maybe<Scalars['Boolean']>;
  write?: Maybe<Scalars['Boolean']>;
};

export type PublicAclInput = {
  read: Scalars['Boolean'];
  write: Scalars['Boolean'];
};

export type Query = {
  __typename?: 'Query';
  address: Address;
  addresses: AddressConnection;
  class: Class;
  classes: Array<Class>;
  fanfestConfig: FanfestConfig;
  fanfestConfigs: FanfestConfigConnection;
  fanfestEvent: FanfestEvent;
  fanfestEvents: FanfestEventConnection;
  health: Scalars['Boolean'];
  node?: Maybe<Node>;
  offer: Offer;
  offers: OfferConnection;
  registration: Registration;
  registrations: RegistrationConnection;
  restreamToken: RestreamTokenData;
  switchboardJWT: Scalars['String'];
  user: User;
  users: UserConnection;
  video: Video;
  videos: VideoConnection;
  viewer: Viewer;
};

export type QueryAddressArgs = {
  id: Scalars['ID'];
  options?: InputMaybe<ReadOptionsInput>;
};

export type QueryAddressesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  options?: InputMaybe<ReadOptionsInput>;
  order?: InputMaybe<Array<AddressOrder>>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<AddressWhereInput>;
};

export type QueryClassArgs = {
  name: Scalars['String'];
};

export type QueryFanfestConfigArgs = {
  id: Scalars['ID'];
  options?: InputMaybe<ReadOptionsInput>;
};

export type QueryFanfestConfigsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  options?: InputMaybe<ReadOptionsInput>;
  order?: InputMaybe<Array<FanfestConfigOrder>>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<FanfestConfigWhereInput>;
};

export type QueryFanfestEventArgs = {
  id: Scalars['ID'];
  options?: InputMaybe<ReadOptionsInput>;
};

export type QueryFanfestEventsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  options?: InputMaybe<ReadOptionsInput>;
  order?: InputMaybe<Array<FanfestEventOrder>>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<FanfestEventWhereInput>;
};

export type QueryNodeArgs = {
  id: Scalars['ID'];
};

export type QueryOfferArgs = {
  id: Scalars['ID'];
  options?: InputMaybe<ReadOptionsInput>;
};

export type QueryOffersArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  options?: InputMaybe<ReadOptionsInput>;
  order?: InputMaybe<Array<OfferOrder>>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<OfferWhereInput>;
};

export type QueryRegistrationArgs = {
  id: Scalars['ID'];
  options?: InputMaybe<ReadOptionsInput>;
};

export type QueryRegistrationsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  options?: InputMaybe<ReadOptionsInput>;
  order?: InputMaybe<Array<RegistrationOrder>>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<RegistrationWhereInput>;
};

export type QueryUserArgs = {
  id: Scalars['ID'];
  options?: InputMaybe<ReadOptionsInput>;
};

export type QueryUsersArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  options?: InputMaybe<ReadOptionsInput>;
  order?: InputMaybe<Array<UserOrder>>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<UserWhereInput>;
};

export type QueryVideoArgs = {
  id: Scalars['ID'];
  options?: InputMaybe<ReadOptionsInput>;
};

export type QueryVideosArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  options?: InputMaybe<ReadOptionsInput>;
  order?: InputMaybe<Array<VideoOrder>>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<VideoWhereInput>;
};

export type ReadOptionsInput = {
  includeReadPreference?: InputMaybe<ReadPreference>;
  readPreference?: InputMaybe<ReadPreference>;
  subqueryReadPreference?: InputMaybe<ReadPreference>;
};

export enum ReadPreference {
  Nearest = 'NEAREST',
  Primary = 'PRIMARY',
  PrimaryPreferred = 'PRIMARY_PREFERRED',
  Secondary = 'SECONDARY',
  SecondaryPreferred = 'SECONDARY_PREFERRED'
}

export type Registration = Node &
  ParseObject & {
    __typename?: 'Registration';
    ACL: Acl;
    createdAt: Scalars['Date'];
    eventPointer: FanfestEvent;
    id: Scalars['ID'];
    location?: Maybe<Scalars['Object']>;
    objectId: Scalars['ID'];
    updatedAt: Scalars['Date'];
    userPointer?: Maybe<User>;
  };

export type RegistrationConnection = {
  __typename?: 'RegistrationConnection';
  count: Scalars['Int'];
  edges?: Maybe<Array<Maybe<RegistrationEdge>>>;
  pageInfo: PageInfo;
};

export type RegistrationEdge = {
  __typename?: 'RegistrationEdge';
  cursor: Scalars['String'];
  node?: Maybe<Registration>;
};

export enum RegistrationOrder {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC'
}

export type RegistrationPointerInput = {
  createAndLink?: InputMaybe<CreateRegistrationFieldsInput>;
  link?: InputMaybe<Scalars['ID']>;
};

export type RegistrationRelationInput = {
  add?: InputMaybe<Array<Scalars['ID']>>;
  createAndAdd?: InputMaybe<Array<CreateRegistrationFieldsInput>>;
  remove?: InputMaybe<Array<Scalars['ID']>>;
};

export type RegistrationRelationWhereInput = {
  exists?: InputMaybe<Scalars['Boolean']>;
  have?: InputMaybe<RegistrationWhereInput>;
  haveNot?: InputMaybe<RegistrationWhereInput>;
};

export type RegistrationWhereInput = {
  AND?: InputMaybe<Array<RegistrationWhereInput>>;
  NOR?: InputMaybe<Array<RegistrationWhereInput>>;
  OR?: InputMaybe<Array<RegistrationWhereInput>>;
  eventPointer?: InputMaybe<FanfestEventRelationWhereInput>;
};

export type RelationFieldInput = {
  name: Scalars['String'];
  targetClassName: Scalars['String'];
};

export type RequestPasswordResetPayload = {
  __typename?: 'RequestPasswordResetPayload';
  ok: Scalars['Boolean'];
};

export type ResetPasswordInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  email: Scalars['String'];
};

export type ResetPasswordPayload = {
  __typename?: 'ResetPasswordPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
};

export type RestreamTokenData = {
  __typename?: 'RestreamTokenData';
  accessToken: Scalars['String'];
  accessTokenExpiresAt: Scalars['String'];
  message?: Maybe<Scalars['String']>;
  status: Scalars['Boolean'];
};

export type RoleAcl = {
  __typename?: 'RoleACL';
  read: Scalars['Boolean'];
  roleName: Scalars['ID'];
  write: Scalars['Boolean'];
};

export type RoleAclInput = {
  read: Scalars['Boolean'];
  roleName: Scalars['String'];
  write: Scalars['Boolean'];
};

export type SchemaAclField = SchemaField & {
  __typename?: 'SchemaACLField';
  name: Scalars['String'];
};

export type SchemaArrayField = SchemaField & {
  __typename?: 'SchemaArrayField';
  name: Scalars['String'];
};

export type SchemaArrayFieldInput = {
  name: Scalars['String'];
};

export type SchemaBooleanField = SchemaField & {
  __typename?: 'SchemaBooleanField';
  name: Scalars['String'];
};

export type SchemaBooleanFieldInput = {
  name: Scalars['String'];
};

export type SchemaBytesField = SchemaField & {
  __typename?: 'SchemaBytesField';
  name: Scalars['String'];
};

export type SchemaBytesFieldInput = {
  name: Scalars['String'];
};

export type SchemaDateField = SchemaField & {
  __typename?: 'SchemaDateField';
  name: Scalars['String'];
};

export type SchemaDateFieldInput = {
  name: Scalars['String'];
};

export type SchemaField = {
  name: Scalars['String'];
};

export type SchemaFieldInput = {
  name: Scalars['String'];
};

export type SchemaFieldsInput = {
  addArrays?: InputMaybe<Array<SchemaArrayFieldInput>>;
  addBooleans?: InputMaybe<Array<SchemaBooleanFieldInput>>;
  addBytes?: InputMaybe<Array<SchemaBytesFieldInput>>;
  addDates?: InputMaybe<Array<SchemaDateFieldInput>>;
  addFiles?: InputMaybe<Array<SchemaFileFieldInput>>;
  addGeoPoint?: InputMaybe<SchemaGeoPointFieldInput>;
  addNumbers?: InputMaybe<Array<SchemaNumberFieldInput>>;
  addObjects?: InputMaybe<Array<SchemaObjectFieldInput>>;
  addPointers?: InputMaybe<Array<PointerFieldInput>>;
  addPolygons?: InputMaybe<Array<SchemaPolygonFieldInput>>;
  addRelations?: InputMaybe<Array<RelationFieldInput>>;
  addStrings?: InputMaybe<Array<SchemaStringFieldInput>>;
  remove?: InputMaybe<Array<SchemaFieldInput>>;
};

export type SchemaFileField = SchemaField & {
  __typename?: 'SchemaFileField';
  name: Scalars['String'];
};

export type SchemaFileFieldInput = {
  name: Scalars['String'];
};

export type SchemaGeoPointField = SchemaField & {
  __typename?: 'SchemaGeoPointField';
  name: Scalars['String'];
};

export type SchemaGeoPointFieldInput = {
  name: Scalars['String'];
};

export type SchemaNumberField = SchemaField & {
  __typename?: 'SchemaNumberField';
  name: Scalars['String'];
};

export type SchemaNumberFieldInput = {
  name: Scalars['String'];
};

export type SchemaObjectField = SchemaField & {
  __typename?: 'SchemaObjectField';
  name: Scalars['String'];
};

export type SchemaObjectFieldInput = {
  name: Scalars['String'];
};

export type SchemaPointerField = SchemaField & {
  __typename?: 'SchemaPointerField';
  name: Scalars['String'];
  targetClassName: Scalars['String'];
};

export type SchemaPolygonField = SchemaField & {
  __typename?: 'SchemaPolygonField';
  name: Scalars['String'];
};

export type SchemaPolygonFieldInput = {
  name: Scalars['String'];
};

export type SchemaRelationField = SchemaField & {
  __typename?: 'SchemaRelationField';
  name: Scalars['String'];
  targetClassName: Scalars['String'];
};

export type SchemaStringField = SchemaField & {
  __typename?: 'SchemaStringField';
  name: Scalars['String'];
};

export type SchemaStringFieldInput = {
  name: Scalars['String'];
};

export type SearchInput = {
  caseSensitive?: InputMaybe<Scalars['Boolean']>;
  diacriticSensitive?: InputMaybe<Scalars['Boolean']>;
  language?: InputMaybe<Scalars['String']>;
  term: Scalars['String'];
};

export type SelectInput = {
  key: Scalars['String'];
  query: SubqueryInput;
};

export type SendVerificationEmailInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  email: Scalars['String'];
};

export type SendVerificationEmailPayload = {
  __typename?: 'SendVerificationEmailPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
};

export type SignUpInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  fields?: InputMaybe<CreateUserFieldsInput>;
};

export type SignUpPayload = {
  __typename?: 'SignUpPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  viewer: Viewer;
};

export type StringWhereInput = {
  equalTo?: InputMaybe<Scalars['String']>;
  exists?: InputMaybe<Scalars['Boolean']>;
  greaterThan?: InputMaybe<Scalars['String']>;
  greaterThanOrEqualTo?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  inQueryKey?: InputMaybe<SelectInput>;
  lessThan?: InputMaybe<Scalars['String']>;
  lessThanOrEqualTo?: InputMaybe<Scalars['String']>;
  matchesRegex?: InputMaybe<Scalars['String']>;
  notEqualTo?: InputMaybe<Scalars['String']>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  notInQueryKey?: InputMaybe<SelectInput>;
  options?: InputMaybe<Scalars['String']>;
  text?: InputMaybe<TextInput>;
};

export type SubqueryInput = {
  className: Scalars['String'];
  where: Scalars['Object'];
};

export type TextInput = {
  search: SearchInput;
};

export type UpdateAddressFieldsInput = {
  ACL?: InputMaybe<AclInput>;
  address?: InputMaybe<Scalars['String']>;
  address2?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  sendFromEmail?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
  zip?: InputMaybe<Scalars['String']>;
};

export type UpdateAddressInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  fields?: InputMaybe<UpdateAddressFieldsInput>;
  id: Scalars['ID'];
};

export type UpdateAddressPayload = {
  __typename?: 'UpdateAddressPayload';
  address: Address;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateClassInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  schemaFields?: InputMaybe<SchemaFieldsInput>;
};

export type UpdateClassPayload = {
  __typename?: 'UpdateClassPayload';
  class: Class;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateFanfestConfigFieldsInput = {
  ACL?: InputMaybe<AclInput>;
  addressPointer?: InputMaybe<AddressPointerInput>;
  bannedRelation?: InputMaybe<UserRelationInput>;
  channelBackground?: InputMaybe<FileInput>;
  channelHeroColor?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  enableGatedEvents?: InputMaybe<Scalars['Boolean']>;
  enableShowCreationNotifications?: InputMaybe<Scalars['Boolean']>;
  eventBackground?: InputMaybe<FileInput>;
  image?: InputMaybe<FileInput>;
  isActive?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  sponsorImage?: InputMaybe<FileInput>;
  sponsorName?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};

export type UpdateFanfestConfigInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  fields?: InputMaybe<UpdateFanfestConfigFieldsInput>;
  id: Scalars['ID'];
};

export type UpdateFanfestConfigPayload = {
  __typename?: 'UpdateFanfestConfigPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  fanfestConfig: FanfestConfig;
};

export type UpdateFanfestEventFieldsInput = {
  ACL?: InputMaybe<AclInput>;
  bannedRelation?: InputMaybe<UserRelationInput>;
  channelPointer?: InputMaybe<FanfestConfigPointerInput>;
  cost?: InputMaybe<Scalars['Float']>;
  description?: InputMaybe<Scalars['String']>;
  eventBackground?: InputMaybe<FileInput>;
  hostUrl?: InputMaybe<Scalars['String']>;
  isActive?: InputMaybe<Scalars['Boolean']>;
  language?: InputMaybe<Scalars['String']>;
  recording?: InputMaybe<Scalars['String']>;
  shouldSendNotification?: InputMaybe<Scalars['Boolean']>;
  shouldSendNotifications?: InputMaybe<Scalars['Boolean']>;
  startDatetime?: InputMaybe<Scalars['Date']>;
  started?: InputMaybe<Scalars['Boolean']>;
  title?: InputMaybe<Scalars['String']>;
  transmitted?: InputMaybe<Scalars['Boolean']>;
};

export type UpdateFanfestEventInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  fields?: InputMaybe<UpdateFanfestEventFieldsInput>;
  id: Scalars['ID'];
};

export type UpdateFanfestEventPayload = {
  __typename?: 'UpdateFanfestEventPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  fanfestEvent: FanfestEvent;
};

export type UpdateOfferFieldsInput = {
  ACL?: InputMaybe<AclInput>;
  channelPointer?: InputMaybe<FanfestConfigPointerInput>;
  image?: InputMaybe<FileInput>;
  isActive?: InputMaybe<Scalars['Boolean']>;
  subtitle?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  url?: InputMaybe<Scalars['String']>;
};

export type UpdateOfferInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  fields?: InputMaybe<UpdateOfferFieldsInput>;
  id: Scalars['ID'];
};

export type UpdateOfferPayload = {
  __typename?: 'UpdateOfferPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  offer: Offer;
};

export type UpdateRegistrationFieldsInput = {
  ACL?: InputMaybe<AclInput>;
  eventPointer?: InputMaybe<FanfestEventPointerInput>;
  location?: InputMaybe<Scalars['Object']>;
  userPointer?: InputMaybe<UserPointerInput>;
};

export type UpdateUserFieldsInput = {
  ACL?: InputMaybe<AclInput>;
  authData?: InputMaybe<Scalars['Object']>;
  email?: InputMaybe<Scalars['String']>;
  emailNotifications?: InputMaybe<Scalars['Boolean']>;
  emailVerified?: InputMaybe<Scalars['Boolean']>;
  externalID?: InputMaybe<Scalars['String']>;
  language?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  password?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  restreamPointer?: InputMaybe<Scalars['Object']>;
  role?: InputMaybe<Scalars['String']>;
  smsNotifications?: InputMaybe<Scalars['Boolean']>;
  timezone?: InputMaybe<Scalars['String']>;
  username?: InputMaybe<Scalars['String']>;
  whatsappNotifications?: InputMaybe<Scalars['Boolean']>;
  worksChannelPointer?: InputMaybe<FanfestConfigPointerInput>;
};

export type UpdateUserInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  fields?: InputMaybe<UpdateUserFieldsInput>;
  id: Scalars['ID'];
};

export type UpdateUserPayload = {
  __typename?: 'UpdateUserPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  user: User;
};

export type UpdateVideoFieldsInput = {
  ACL?: InputMaybe<AclInput>;
  channelPointer?: InputMaybe<FanfestConfigPointerInput>;
  duration?: InputMaybe<Scalars['Float']>;
  isActive?: InputMaybe<Scalars['Boolean']>;
  isProcessed?: InputMaybe<Scalars['Boolean']>;
  manifest?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  userPointer?: InputMaybe<UserPointerInput>;
  video?: InputMaybe<FileInput>;
};

export type UpdateVideoInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  fields?: InputMaybe<UpdateVideoFieldsInput>;
  id: Scalars['ID'];
};

export type UpdateVideoPayload = {
  __typename?: 'UpdateVideoPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  video: Video;
};

export type User = Node &
  ParseObject & {
    __typename?: 'User';
    ACL: Acl;
    authData?: Maybe<Scalars['Object']>;
    createdAt: Scalars['Date'];
    email?: Maybe<Scalars['String']>;
    emailNotifications?: Maybe<Scalars['Boolean']>;
    emailVerified?: Maybe<Scalars['Boolean']>;
    externalID?: Maybe<Scalars['String']>;
    id: Scalars['ID'];
    language?: Maybe<Scalars['String']>;
    name: Scalars['String'];
    objectId: Scalars['ID'];
    phone?: Maybe<Scalars['String']>;
    restreamPointer?: Maybe<Scalars['Object']>;
    role: Scalars['String'];
    smsNotifications?: Maybe<Scalars['Boolean']>;
    timezone?: Maybe<Scalars['String']>;
    updatedAt: Scalars['Date'];
    username?: Maybe<Scalars['String']>;
    whatsappNotifications?: Maybe<Scalars['Boolean']>;
    worksChannelPointer?: Maybe<FanfestConfig>;
  };

export type UserAcl = {
  __typename?: 'UserACL';
  read: Scalars['Boolean'];
  userId: Scalars['ID'];
  write: Scalars['Boolean'];
};

export type UserAclInput = {
  read: Scalars['Boolean'];
  userId: Scalars['ID'];
  write: Scalars['Boolean'];
};

export type UserConnection = {
  __typename?: 'UserConnection';
  count: Scalars['Int'];
  edges?: Maybe<Array<Maybe<UserEdge>>>;
  pageInfo: PageInfo;
};

export type UserEdge = {
  __typename?: 'UserEdge';
  cursor: Scalars['String'];
  node?: Maybe<User>;
};

export type UserLoginWithInput = {
  ACL?: InputMaybe<AclInput>;
  email?: InputMaybe<Scalars['String']>;
  emailNotifications?: InputMaybe<Scalars['Boolean']>;
  emailVerified?: InputMaybe<Scalars['Boolean']>;
  externalID?: InputMaybe<Scalars['String']>;
  language?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  phone?: InputMaybe<Scalars['String']>;
  restreamPointer?: InputMaybe<Scalars['Object']>;
  role: Scalars['String'];
  smsNotifications?: InputMaybe<Scalars['Boolean']>;
  timezone?: InputMaybe<Scalars['String']>;
  whatsappNotifications?: InputMaybe<Scalars['Boolean']>;
  worksChannelPointer?: InputMaybe<FanfestConfigPointerInput>;
};

export enum UserOrder {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC'
}

export type UserPointerInput = {
  createAndLink?: InputMaybe<CreateUserFieldsInput>;
  link?: InputMaybe<Scalars['ID']>;
};

export type UserRelationInput = {
  add?: InputMaybe<Array<Scalars['ID']>>;
  createAndAdd?: InputMaybe<Array<CreateUserFieldsInput>>;
  remove?: InputMaybe<Array<Scalars['ID']>>;
};

export type UserRelationWhereInput = {
  exists?: InputMaybe<Scalars['Boolean']>;
  have?: InputMaybe<UserWhereInput>;
  haveNot?: InputMaybe<UserWhereInput>;
};

export type UserWhereInput = {
  AND?: InputMaybe<Array<UserWhereInput>>;
  NOR?: InputMaybe<Array<UserWhereInput>>;
  OR?: InputMaybe<Array<UserWhereInput>>;
  name?: InputMaybe<StringWhereInput>;
};

export type Video = Node &
  ParseObject & {
    __typename?: 'Video';
    ACL: Acl;
    channelPointer: FanfestConfig;
    createdAt: Scalars['Date'];
    duration?: Maybe<Scalars['Float']>;
    id: Scalars['ID'];
    isActive: Scalars['Boolean'];
    isProcessed?: Maybe<Scalars['Boolean']>;
    manifest?: Maybe<Scalars['String']>;
    name: Scalars['String'];
    objectId: Scalars['ID'];
    updatedAt: Scalars['Date'];
    userPointer?: Maybe<User>;
    video?: Maybe<FileInfo>;
  };

export type VideoConnection = {
  __typename?: 'VideoConnection';
  count: Scalars['Int'];
  edges?: Maybe<Array<Maybe<VideoEdge>>>;
  pageInfo: PageInfo;
};

export type VideoEdge = {
  __typename?: 'VideoEdge';
  cursor: Scalars['String'];
  node?: Maybe<Video>;
};

export enum VideoOrder {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC'
}

export type VideoPointerInput = {
  createAndLink?: InputMaybe<CreateVideoFieldsInput>;
  link?: InputMaybe<Scalars['ID']>;
};

export type VideoRelationInput = {
  add?: InputMaybe<Array<Scalars['ID']>>;
  createAndAdd?: InputMaybe<Array<CreateVideoFieldsInput>>;
  remove?: InputMaybe<Array<Scalars['ID']>>;
};

export type VideoRelationWhereInput = {
  exists?: InputMaybe<Scalars['Boolean']>;
  have?: InputMaybe<VideoWhereInput>;
  haveNot?: InputMaybe<VideoWhereInput>;
};

export type VideoWhereInput = {
  AND?: InputMaybe<Array<VideoWhereInput>>;
  NOR?: InputMaybe<Array<VideoWhereInput>>;
  OR?: InputMaybe<Array<VideoWhereInput>>;
  channelPointer?: InputMaybe<FanfestConfigRelationWhereInput>;
  isActive?: InputMaybe<BooleanWhereInput>;
  isProcessed?: InputMaybe<BooleanWhereInput>;
  name?: InputMaybe<StringWhereInput>;
};

export type Viewer = {
  __typename?: 'Viewer';
  sessionToken: Scalars['String'];
  user: User;
};

export type WithinInput = {
  box: BoxInput;
};

export type LogInMutationVariables = Exact<{
  input: LogInInput;
}>;

export type LogInMutation = {
  __typename?: 'Mutation';
  logIn?:
    | {
        __typename?: 'LogInPayload';
        viewer: {
          __typename?: 'Viewer';
          sessionToken: string;
          user: {
            __typename?: 'User';
            id: string;
            objectId: string;
            name: string;
            email?: string | null | undefined;
            username?: string | null | undefined;
            role: string;
            language?: string | null | undefined;
            timezone?: string | null | undefined;
            phone?: string | null | undefined;
            smsNotifications?: boolean | null | undefined;
            whatsappNotifications?: boolean | null | undefined;
            emailNotifications?: boolean | null | undefined;
            createdAt: any;
            updatedAt: any;
            worksChannelPointer?:
              | {
                  __typename?: 'FanfestConfig';
                  id: string;
                  objectId: string;
                  name: string;
                  title: string;
                  image?:
                    | { __typename?: 'FileInfo'; url: string; name: string }
                    | null
                    | undefined;
                  eventBackground?:
                    | { __typename?: 'FileInfo'; url: string; name: string }
                    | null
                    | undefined;
                }
              | null
              | undefined;
          };
        };
      }
    | null
    | undefined;
};

export type LogOutMutationVariables = Exact<{ [key: string]: never }>;

export type LogOutMutation = {
  __typename?: 'Mutation';
  logOut?:
    | {
        __typename?: 'LogOutPayload';
        clientMutationId?: string | null | undefined;
      }
    | null
    | undefined;
};

export type RequestPasswordResetMutationVariables = Exact<{
  email: Scalars['String'];
  password: Scalars['String'];
  token: Scalars['String'];
}>;

export type RequestPasswordResetMutation = {
  __typename?: 'Mutation';
  requestPasswordReset: {
    __typename?: 'RequestPasswordResetPayload';
    ok: boolean;
  };
};

export type ResetPasswordMutationVariables = Exact<{
  input: ResetPasswordInput;
}>;

export type ResetPasswordMutation = {
  __typename?: 'Mutation';
  resetPassword?:
    | { __typename?: 'ResetPasswordPayload'; ok: boolean }
    | null
    | undefined;
};

export type SignUpMutationVariables = Exact<{
  input: SignUpInput;
}>;

export type SignUpMutation = {
  __typename?: 'Mutation';
  signUp?:
    | {
        __typename?: 'SignUpPayload';
        viewer: {
          __typename?: 'Viewer';
          sessionToken: string;
          user: {
            __typename?: 'User';
            id: string;
            objectId: string;
            name: string;
            email?: string | null | undefined;
            username?: string | null | undefined;
            role: string;
            language?: string | null | undefined;
            timezone?: string | null | undefined;
            phone?: string | null | undefined;
            smsNotifications?: boolean | null | undefined;
            whatsappNotifications?: boolean | null | undefined;
            emailNotifications?: boolean | null | undefined;
            createdAt: any;
            updatedAt: any;
            worksChannelPointer?:
              | {
                  __typename?: 'FanfestConfig';
                  id: string;
                  objectId: string;
                  name: string;
                  title: string;
                  image?:
                    | { __typename?: 'FileInfo'; url: string; name: string }
                    | null
                    | undefined;
                  eventBackground?:
                    | { __typename?: 'FileInfo'; url: string; name: string }
                    | null
                    | undefined;
                }
              | null
              | undefined;
          };
        };
      }
    | null
    | undefined;
};

export type UserAuthFragmentFragment = {
  __typename?: 'User';
  id: string;
  objectId: string;
  name: string;
  email?: string | null | undefined;
  username?: string | null | undefined;
  role: string;
  language?: string | null | undefined;
  timezone?: string | null | undefined;
  phone?: string | null | undefined;
  smsNotifications?: boolean | null | undefined;
  whatsappNotifications?: boolean | null | undefined;
  emailNotifications?: boolean | null | undefined;
  createdAt: any;
  updatedAt: any;
  worksChannelPointer?:
    | {
        __typename?: 'FanfestConfig';
        id: string;
        objectId: string;
        name: string;
        title: string;
        image?:
          | { __typename?: 'FileInfo'; url: string; name: string }
          | null
          | undefined;
        eventBackground?:
          | { __typename?: 'FileInfo'; url: string; name: string }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type ViewerQueryVariables = Exact<{ [key: string]: never }>;

export type ViewerQuery = {
  __typename?: 'Query';
  viewer: {
    __typename?: 'Viewer';
    sessionToken: string;
    user: {
      __typename?: 'User';
      id: string;
      objectId: string;
      name: string;
      email?: string | null | undefined;
      username?: string | null | undefined;
      role: string;
      language?: string | null | undefined;
      timezone?: string | null | undefined;
      phone?: string | null | undefined;
      smsNotifications?: boolean | null | undefined;
      whatsappNotifications?: boolean | null | undefined;
      emailNotifications?: boolean | null | undefined;
      createdAt: any;
      updatedAt: any;
      worksChannelPointer?:
        | {
            __typename?: 'FanfestConfig';
            id: string;
            objectId: string;
            name: string;
            title: string;
            image?:
              | { __typename?: 'FileInfo'; url: string; name: string }
              | null
              | undefined;
            eventBackground?:
              | { __typename?: 'FileInfo'; url: string; name: string }
              | null
              | undefined;
          }
        | null
        | undefined;
    };
  };
};

export type AddressFragmentFragment = {
  __typename?: 'Address';
  id: string;
  objectId: string;
  createdAt: any;
  updatedAt: any;
  address?: string | null | undefined;
  address2?: string | null | undefined;
  city?: string | null | undefined;
  state?: string | null | undefined;
  zip?: string | null | undefined;
  country?: string | null | undefined;
  sendFromEmail?: string | null | undefined;
};

export type CreateAddressMutationVariables = Exact<{
  address: CreateAddressFieldsInput;
}>;

export type CreateAddressMutation = {
  __typename?: 'Mutation';
  createAddress?:
    | {
        __typename?: 'CreateAddressPayload';
        address: {
          __typename?: 'Address';
          id: string;
          objectId: string;
          createdAt: any;
          updatedAt: any;
          address?: string | null | undefined;
          address2?: string | null | undefined;
          city?: string | null | undefined;
          state?: string | null | undefined;
          zip?: string | null | undefined;
          country?: string | null | undefined;
          sendFromEmail?: string | null | undefined;
        };
      }
    | null
    | undefined;
};

export type GetAddressByChannelQueryVariables = Exact<{ [key: string]: never }>;

export type GetAddressByChannelQuery = {
  __typename?: 'Query';
  addresses: {
    __typename?: 'AddressConnection';
    count: number;
    pageInfo: {
      __typename?: 'PageInfo';
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor?: string | null | undefined;
      endCursor?: string | null | undefined;
    };
    edges?:
      | Array<
          | {
              __typename?: 'AddressEdge';
              cursor: string;
              node?:
                | {
                    __typename?: 'Address';
                    id: string;
                    objectId: string;
                    createdAt: any;
                    updatedAt: any;
                    address?: string | null | undefined;
                    address2?: string | null | undefined;
                    city?: string | null | undefined;
                    state?: string | null | undefined;
                    zip?: string | null | undefined;
                    country?: string | null | undefined;
                    sendFromEmail?: string | null | undefined;
                  }
                | null
                | undefined;
            }
          | null
          | undefined
        >
      | null
      | undefined;
  };
};

export type UpdateAddressMutationVariables = Exact<{
  id: Scalars['ID'];
  address: UpdateAddressFieldsInput;
}>;

export type UpdateAddressMutation = {
  __typename?: 'Mutation';
  updateAddress?:
    | {
        __typename?: 'UpdateAddressPayload';
        address: {
          __typename?: 'Address';
          id: string;
          objectId: string;
          createdAt: any;
          updatedAt: any;
          address?: string | null | undefined;
          address2?: string | null | undefined;
          city?: string | null | undefined;
          state?: string | null | undefined;
          zip?: string | null | undefined;
          country?: string | null | undefined;
          sendFromEmail?: string | null | undefined;
        };
      }
    | null
    | undefined;
};

export type CreateFileMutationVariables = Exact<{
  file: Scalars['Upload'];
}>;

export type CreateFileMutation = {
  __typename?: 'Mutation';
  createFile?:
    | {
        __typename?: 'CreateFilePayload';
        fileInfo: { __typename?: 'FileInfo'; name: string; url: string };
      }
    | null
    | undefined;
};

export type AutocompleteFanfestConfigsQueryVariables = Exact<{
  search?: InputMaybe<Scalars['String']>;
}>;

export type AutocompleteFanfestConfigsQuery = {
  __typename?: 'Query';
  fanfestConfigs: {
    __typename?: 'FanfestConfigConnection';
    count: number;
    edges?:
      | Array<
          | {
              __typename?: 'FanfestConfigEdge';
              cursor: string;
              node?:
                | {
                    __typename?: 'FanfestConfig';
                    id: string;
                    objectId: string;
                    name: string;
                  }
                | null
                | undefined;
            }
          | null
          | undefined
        >
      | null
      | undefined;
  };
};

export type CreateFanfestConfigMutationVariables = Exact<{
  fanfestConfig: CreateFanfestConfigFieldsInput;
}>;

export type CreateFanfestConfigMutation = {
  __typename?: 'Mutation';
  createFanfestConfig?:
    | {
        __typename?: 'CreateFanfestConfigPayload';
        fanfestConfig: {
          __typename?: 'FanfestConfig';
          id: string;
          objectId: string;
          name: string;
          title: string;
          description?: string | null | undefined;
          channelHeroColor: string;
          isActive: boolean;
          sponsorName?: string | null | undefined;
          createdAt: any;
          updatedAt: any;
          sponsorImage?:
            | { __typename?: 'FileInfo'; name: string; url: string }
            | null
            | undefined;
        };
      }
    | null
    | undefined;
};

export type DeleteFanfestConfigMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type DeleteFanfestConfigMutation = {
  __typename?: 'Mutation';
  deleteFanfestConfig?:
    | {
        __typename?: 'DeleteFanfestConfigPayload';
        fanfestConfig: { __typename?: 'FanfestConfig'; id: string };
      }
    | null
    | undefined;
};

export type FanfestConfigFragmentFragment = {
  __typename?: 'FanfestConfig';
  id: string;
  objectId: string;
  name: string;
  title: string;
  description?: string | null | undefined;
  channelHeroColor: string;
  isActive: boolean;
  sponsorName?: string | null | undefined;
  createdAt: any;
  updatedAt: any;
  enableGatedEvents?: boolean | null | undefined;
  enableShowCreationNotifications?: boolean | null | undefined;
  image?:
    | { __typename?: 'FileInfo'; name: string; url: string }
    | null
    | undefined;
  eventBackground?:
    | { __typename?: 'FileInfo'; name: string; url: string }
    | null
    | undefined;
  channelBackground?:
    | { __typename?: 'FileInfo'; name: string; url: string }
    | null
    | undefined;
  sponsorImage?:
    | { __typename?: 'FileInfo'; name: string; url: string }
    | null
    | undefined;
};

export type GetFanfestConfigQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetFanfestConfigQuery = {
  __typename?: 'Query';
  fanfestConfig: {
    __typename?: 'FanfestConfig';
    id: string;
    objectId: string;
    name: string;
    title: string;
    description?: string | null | undefined;
    channelHeroColor: string;
    isActive: boolean;
    sponsorName?: string | null | undefined;
    createdAt: any;
    updatedAt: any;
    enableGatedEvents?: boolean | null | undefined;
    enableShowCreationNotifications?: boolean | null | undefined;
    image?:
      | { __typename?: 'FileInfo'; name: string; url: string }
      | null
      | undefined;
    eventBackground?:
      | { __typename?: 'FileInfo'; name: string; url: string }
      | null
      | undefined;
    channelBackground?:
      | { __typename?: 'FileInfo'; name: string; url: string }
      | null
      | undefined;
    sponsorImage?:
      | { __typename?: 'FileInfo'; name: string; url: string }
      | null
      | undefined;
  };
};

export type GetFanfestConfigByNameQueryVariables = Exact<{
  name: Scalars['String'];
}>;

export type GetFanfestConfigByNameQuery = {
  __typename?: 'Query';
  fanfestConfigs: {
    __typename?: 'FanfestConfigConnection';
    count: number;
    pageInfo: {
      __typename?: 'PageInfo';
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor?: string | null | undefined;
      endCursor?: string | null | undefined;
    };
    edges?:
      | Array<
          | {
              __typename?: 'FanfestConfigEdge';
              cursor: string;
              node?:
                | {
                    __typename?: 'FanfestConfig';
                    id: string;
                    objectId: string;
                    name: string;
                    title: string;
                    description?: string | null | undefined;
                    channelHeroColor: string;
                    isActive: boolean;
                    sponsorName?: string | null | undefined;
                    createdAt: any;
                    updatedAt: any;
                    enableGatedEvents?: boolean | null | undefined;
                    enableShowCreationNotifications?:
                      | boolean
                      | null
                      | undefined;
                    image?:
                      | { __typename?: 'FileInfo'; name: string; url: string }
                      | null
                      | undefined;
                    eventBackground?:
                      | { __typename?: 'FileInfo'; name: string; url: string }
                      | null
                      | undefined;
                    channelBackground?:
                      | { __typename?: 'FileInfo'; name: string; url: string }
                      | null
                      | undefined;
                    sponsorImage?:
                      | { __typename?: 'FileInfo'; name: string; url: string }
                      | null
                      | undefined;
                  }
                | null
                | undefined;
            }
          | null
          | undefined
        >
      | null
      | undefined;
  };
};

export type GetFanfestConfigWithAddressQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetFanfestConfigWithAddressQuery = {
  __typename?: 'Query';
  fanfestConfig: {
    __typename?: 'FanfestConfig';
    id: string;
    objectId: string;
    name: string;
    title: string;
    description?: string | null | undefined;
    channelHeroColor: string;
    isActive: boolean;
    sponsorName?: string | null | undefined;
    createdAt: any;
    updatedAt: any;
    enableGatedEvents?: boolean | null | undefined;
    enableShowCreationNotifications?: boolean | null | undefined;
    addressPointer?:
      | {
          __typename?: 'Address';
          id: string;
          objectId: string;
          createdAt: any;
          updatedAt: any;
          address?: string | null | undefined;
          address2?: string | null | undefined;
          city?: string | null | undefined;
          state?: string | null | undefined;
          zip?: string | null | undefined;
          country?: string | null | undefined;
          sendFromEmail?: string | null | undefined;
        }
      | null
      | undefined;
    image?:
      | { __typename?: 'FileInfo'; name: string; url: string }
      | null
      | undefined;
    eventBackground?:
      | { __typename?: 'FileInfo'; name: string; url: string }
      | null
      | undefined;
    channelBackground?:
      | { __typename?: 'FileInfo'; name: string; url: string }
      | null
      | undefined;
    sponsorImage?:
      | { __typename?: 'FileInfo'; name: string; url: string }
      | null
      | undefined;
  };
};

export type GetFanfestConfigsQueryVariables = Exact<{
  order?: InputMaybe<Array<FanfestConfigOrder> | FanfestConfigOrder>;
  first: Scalars['Int'];
  skip: Scalars['Int'];
  name?: InputMaybe<Scalars['String']>;
}>;

export type GetFanfestConfigsQuery = {
  __typename?: 'Query';
  fanfestConfigs: {
    __typename?: 'FanfestConfigConnection';
    count: number;
    pageInfo: {
      __typename?: 'PageInfo';
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor?: string | null | undefined;
      endCursor?: string | null | undefined;
    };
    edges?:
      | Array<
          | {
              __typename?: 'FanfestConfigEdge';
              cursor: string;
              node?:
                | {
                    __typename?: 'FanfestConfig';
                    id: string;
                    objectId: string;
                    name: string;
                    title: string;
                    description?: string | null | undefined;
                    channelHeroColor: string;
                    isActive: boolean;
                    sponsorName?: string | null | undefined;
                    createdAt: any;
                    updatedAt: any;
                    enableGatedEvents?: boolean | null | undefined;
                    enableShowCreationNotifications?:
                      | boolean
                      | null
                      | undefined;
                    image?:
                      | { __typename?: 'FileInfo'; name: string; url: string }
                      | null
                      | undefined;
                    eventBackground?:
                      | { __typename?: 'FileInfo'; name: string; url: string }
                      | null
                      | undefined;
                    channelBackground?:
                      | { __typename?: 'FileInfo'; name: string; url: string }
                      | null
                      | undefined;
                    sponsorImage?:
                      | { __typename?: 'FileInfo'; name: string; url: string }
                      | null
                      | undefined;
                  }
                | null
                | undefined;
            }
          | null
          | undefined
        >
      | null
      | undefined;
  };
};

export type UpdateFanfestConfigMutationVariables = Exact<{
  id: Scalars['ID'];
  fanfestConfig: UpdateFanfestConfigFieldsInput;
}>;

export type UpdateFanfestConfigMutation = {
  __typename?: 'Mutation';
  updateFanfestConfig?:
    | {
        __typename?: 'UpdateFanfestConfigPayload';
        fanfestConfig: {
          __typename?: 'FanfestConfig';
          id: string;
          name: string;
          isActive: boolean;
          createdAt: any;
          updatedAt: any;
        };
      }
    | null
    | undefined;
};

export type CreateFanfestEventMutationVariables = Exact<{
  fanfestEvent: CreateFanfestEventFieldsInput;
}>;

export type CreateFanfestEventMutation = {
  __typename?: 'Mutation';
  createFanfestEvent?:
    | {
        __typename?: 'CreateFanfestEventPayload';
        fanfestEvent: {
          __typename?: 'FanfestEvent';
          id: string;
          objectId: string;
          title: string;
          description?: string | null | undefined;
          isActive: boolean;
          createdAt: any;
          updatedAt: any;
          ACL: {
            __typename?: 'ACL';
            public?:
              | {
                  __typename?: 'PublicACL';
                  read?: boolean | null | undefined;
                  write?: boolean | null | undefined;
                }
              | null
              | undefined;
          };
        };
      }
    | null
    | undefined;
};

export type DeleteFanfestEventMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type DeleteFanfestEventMutation = {
  __typename?: 'Mutation';
  deleteFanfestEvent?:
    | {
        __typename?: 'DeleteFanfestEventPayload';
        fanfestEvent: { __typename?: 'FanfestEvent'; id: string };
      }
    | null
    | undefined;
};

export type FanfestEventFragmentFragment = {
  __typename?: 'FanfestEvent';
  id: string;
  objectId: string;
  title: string;
  description?: string | null | undefined;
  isActive: boolean;
  language?: string | null | undefined;
  startDatetime: any;
  transmitted?: boolean | null | undefined;
  started?: boolean | null | undefined;
  recording?: string | null | undefined;
  createdAt: any;
  updatedAt: any;
  hostUrl?: string | null | undefined;
  eventBackground?:
    | { __typename?: 'FileInfo'; name: string; url: string }
    | null
    | undefined;
  channelPointer: {
    __typename?: 'FanfestConfig';
    objectId: string;
    id: string;
    name: string;
    title: string;
    image?:
      | { __typename?: 'FileInfo'; name: string; url: string }
      | null
      | undefined;
    eventBackground?:
      | { __typename?: 'FileInfo'; name: string; url: string }
      | null
      | undefined;
  };
};

export type GetEventsForChannelPageQueryVariables = Exact<{
  channel: Scalars['String'];
}>;

export type GetEventsForChannelPageQuery = {
  __typename?: 'Query';
  fanfestEvents: {
    __typename?: 'FanfestEventConnection';
    count: number;
    pageInfo: {
      __typename?: 'PageInfo';
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor?: string | null | undefined;
      endCursor?: string | null | undefined;
    };
    edges?:
      | Array<
          | {
              __typename?: 'FanfestEventEdge';
              cursor: string;
              node?:
                | {
                    __typename?: 'FanfestEvent';
                    id: string;
                    objectId: string;
                    title: string;
                    description?: string | null | undefined;
                    isActive: boolean;
                    language?: string | null | undefined;
                    startDatetime: any;
                    transmitted?: boolean | null | undefined;
                    started?: boolean | null | undefined;
                    recording?: string | null | undefined;
                    createdAt: any;
                    updatedAt: any;
                    hostUrl?: string | null | undefined;
                    eventBackground?:
                      | { __typename?: 'FileInfo'; name: string; url: string }
                      | null
                      | undefined;
                    channelPointer: {
                      __typename?: 'FanfestConfig';
                      objectId: string;
                      id: string;
                      name: string;
                      title: string;
                      image?:
                        | { __typename?: 'FileInfo'; name: string; url: string }
                        | null
                        | undefined;
                      eventBackground?:
                        | { __typename?: 'FileInfo'; name: string; url: string }
                        | null
                        | undefined;
                    };
                  }
                | null
                | undefined;
            }
          | null
          | undefined
        >
      | null
      | undefined;
  };
};

export type GetFanfestEventQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetFanfestEventQuery = {
  __typename?: 'Query';
  fanfestEvent: {
    __typename?: 'FanfestEvent';
    id: string;
    objectId: string;
    title: string;
    description?: string | null | undefined;
    isActive: boolean;
    language?: string | null | undefined;
    startDatetime: any;
    transmitted?: boolean | null | undefined;
    started?: boolean | null | undefined;
    recording?: string | null | undefined;
    createdAt: any;
    updatedAt: any;
    hostUrl?: string | null | undefined;
    eventBackground?:
      | { __typename?: 'FileInfo'; name: string; url: string }
      | null
      | undefined;
    channelPointer: {
      __typename?: 'FanfestConfig';
      objectId: string;
      id: string;
      name: string;
      title: string;
      image?:
        | { __typename?: 'FileInfo'; name: string; url: string }
        | null
        | undefined;
      eventBackground?:
        | { __typename?: 'FileInfo'; name: string; url: string }
        | null
        | undefined;
    };
  };
};

export type GetFanfestEventsQueryVariables = Exact<{
  order?: InputMaybe<Array<FanfestEventOrder> | FanfestEventOrder>;
  first: Scalars['Int'];
  skip: Scalars['Int'];
  name?: InputMaybe<Scalars['String']>;
}>;

export type GetFanfestEventsQuery = {
  __typename?: 'Query';
  fanfestEvents: {
    __typename?: 'FanfestEventConnection';
    count: number;
    pageInfo: {
      __typename?: 'PageInfo';
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor?: string | null | undefined;
      endCursor?: string | null | undefined;
    };
    edges?:
      | Array<
          | {
              __typename?: 'FanfestEventEdge';
              cursor: string;
              node?:
                | {
                    __typename?: 'FanfestEvent';
                    id: string;
                    objectId: string;
                    title: string;
                    description?: string | null | undefined;
                    isActive: boolean;
                    language?: string | null | undefined;
                    startDatetime: any;
                    transmitted?: boolean | null | undefined;
                    started?: boolean | null | undefined;
                    recording?: string | null | undefined;
                    createdAt: any;
                    updatedAt: any;
                    hostUrl?: string | null | undefined;
                    eventBackground?:
                      | { __typename?: 'FileInfo'; name: string; url: string }
                      | null
                      | undefined;
                    channelPointer: {
                      __typename?: 'FanfestConfig';
                      objectId: string;
                      id: string;
                      name: string;
                      title: string;
                      image?:
                        | { __typename?: 'FileInfo'; name: string; url: string }
                        | null
                        | undefined;
                      eventBackground?:
                        | { __typename?: 'FileInfo'; name: string; url: string }
                        | null
                        | undefined;
                    };
                  }
                | null
                | undefined;
            }
          | null
          | undefined
        >
      | null
      | undefined;
  };
};

export type GetFanfestEventsByChannelQueryVariables = Exact<{
  order?: InputMaybe<Array<FanfestEventOrder> | FanfestEventOrder>;
  first: Scalars['Int'];
  skip: Scalars['Int'];
  name: Scalars['String'];
  channelName: Scalars['String'];
}>;

export type GetFanfestEventsByChannelQuery = {
  __typename?: 'Query';
  fanfestEvents: {
    __typename?: 'FanfestEventConnection';
    count: number;
    pageInfo: {
      __typename?: 'PageInfo';
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor?: string | null | undefined;
      endCursor?: string | null | undefined;
    };
    edges?:
      | Array<
          | {
              __typename?: 'FanfestEventEdge';
              cursor: string;
              node?:
                | {
                    __typename?: 'FanfestEvent';
                    id: string;
                    objectId: string;
                    title: string;
                    description?: string | null | undefined;
                    isActive: boolean;
                    language?: string | null | undefined;
                    startDatetime: any;
                    transmitted?: boolean | null | undefined;
                    started?: boolean | null | undefined;
                    recording?: string | null | undefined;
                    createdAt: any;
                    updatedAt: any;
                    hostUrl?: string | null | undefined;
                    eventBackground?:
                      | { __typename?: 'FileInfo'; name: string; url: string }
                      | null
                      | undefined;
                    channelPointer: {
                      __typename?: 'FanfestConfig';
                      objectId: string;
                      id: string;
                      name: string;
                      title: string;
                      image?:
                        | { __typename?: 'FileInfo'; name: string; url: string }
                        | null
                        | undefined;
                      eventBackground?:
                        | { __typename?: 'FileInfo'; name: string; url: string }
                        | null
                        | undefined;
                    };
                  }
                | null
                | undefined;
            }
          | null
          | undefined
        >
      | null
      | undefined;
  };
};

export type UpdateFanfestEventMutationVariables = Exact<{
  id: Scalars['ID'];
  fanfestEvent: UpdateFanfestEventFieldsInput;
}>;

export type UpdateFanfestEventMutation = {
  __typename?: 'Mutation';
  updateFanfestEvent?:
    | {
        __typename?: 'UpdateFanfestEventPayload';
        fanfestEvent: {
          __typename?: 'FanfestEvent';
          id: string;
          title: string;
          description?: string | null | undefined;
          isActive: boolean;
          createdAt: any;
          updatedAt: any;
          ACL: {
            __typename?: 'ACL';
            public?:
              | {
                  __typename?: 'PublicACL';
                  read?: boolean | null | undefined;
                  write?: boolean | null | undefined;
                }
              | null
              | undefined;
          };
        };
      }
    | null
    | undefined;
};

export type CreateOfferMutationVariables = Exact<{
  offer: CreateOfferFieldsInput;
}>;

export type CreateOfferMutation = {
  __typename?: 'Mutation';
  createOffer?:
    | {
        __typename?: 'CreateOfferPayload';
        offer: {
          __typename?: 'Offer';
          id: string;
          objectId: string;
          title: string;
          subtitle?: string | null | undefined;
          isActive: boolean;
          url?: string | null | undefined;
          createdAt: any;
          updatedAt: any;
          channelPointer?:
            | {
                __typename?: 'FanfestConfig';
                id: string;
                objectId: string;
                name: string;
                title: string;
              }
            | null
            | undefined;
          image?:
            | { __typename?: 'FileInfo'; name: string; url: string }
            | null
            | undefined;
        };
      }
    | null
    | undefined;
};

export type DeleteOfferMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type DeleteOfferMutation = {
  __typename?: 'Mutation';
  deleteOffer?:
    | {
        __typename?: 'DeleteOfferPayload';
        offer: { __typename?: 'Offer'; id: string };
      }
    | null
    | undefined;
};

export type GetActiveOffersByChannelQueryVariables = Exact<{
  order?: InputMaybe<Array<OfferOrder> | OfferOrder>;
  first: Scalars['Int'];
  skip: Scalars['Int'];
  channelName: Scalars['String'];
}>;

export type GetActiveOffersByChannelQuery = {
  __typename?: 'Query';
  offers: {
    __typename?: 'OfferConnection';
    count: number;
    pageInfo: {
      __typename?: 'PageInfo';
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor?: string | null | undefined;
      endCursor?: string | null | undefined;
    };
    edges?:
      | Array<
          | {
              __typename?: 'OfferEdge';
              cursor: string;
              node?:
                | {
                    __typename?: 'Offer';
                    id: string;
                    objectId: string;
                    title: string;
                    subtitle?: string | null | undefined;
                    isActive: boolean;
                    url?: string | null | undefined;
                    createdAt: any;
                    updatedAt: any;
                    channelPointer?:
                      | {
                          __typename?: 'FanfestConfig';
                          id: string;
                          objectId: string;
                          name: string;
                          title: string;
                        }
                      | null
                      | undefined;
                    image?:
                      | { __typename?: 'FileInfo'; name: string; url: string }
                      | null
                      | undefined;
                  }
                | null
                | undefined;
            }
          | null
          | undefined
        >
      | null
      | undefined;
  };
};

export type GetOfferQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetOfferQuery = {
  __typename?: 'Query';
  offer: {
    __typename?: 'Offer';
    id: string;
    objectId: string;
    title: string;
    subtitle?: string | null | undefined;
    isActive: boolean;
    url?: string | null | undefined;
    createdAt: any;
    updatedAt: any;
    channelPointer?:
      | {
          __typename?: 'FanfestConfig';
          id: string;
          objectId: string;
          name: string;
          title: string;
        }
      | null
      | undefined;
    image?:
      | { __typename?: 'FileInfo'; name: string; url: string }
      | null
      | undefined;
  };
};

export type GetOffersQueryVariables = Exact<{
  order?: InputMaybe<Array<OfferOrder> | OfferOrder>;
  first: Scalars['Int'];
  skip: Scalars['Int'];
  name?: InputMaybe<Scalars['String']>;
}>;

export type GetOffersQuery = {
  __typename?: 'Query';
  offers: {
    __typename?: 'OfferConnection';
    count: number;
    pageInfo: {
      __typename?: 'PageInfo';
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor?: string | null | undefined;
      endCursor?: string | null | undefined;
    };
    edges?:
      | Array<
          | {
              __typename?: 'OfferEdge';
              cursor: string;
              node?:
                | {
                    __typename?: 'Offer';
                    id: string;
                    objectId: string;
                    title: string;
                    subtitle?: string | null | undefined;
                    isActive: boolean;
                    url?: string | null | undefined;
                    createdAt: any;
                    updatedAt: any;
                    channelPointer?:
                      | {
                          __typename?: 'FanfestConfig';
                          id: string;
                          objectId: string;
                          name: string;
                          title: string;
                        }
                      | null
                      | undefined;
                    image?:
                      | { __typename?: 'FileInfo'; name: string; url: string }
                      | null
                      | undefined;
                  }
                | null
                | undefined;
            }
          | null
          | undefined
        >
      | null
      | undefined;
  };
};

export type GetOffersByChannelQueryVariables = Exact<{
  order?: InputMaybe<Array<OfferOrder> | OfferOrder>;
  first: Scalars['Int'];
  skip: Scalars['Int'];
  channelName: Scalars['String'];
}>;

export type GetOffersByChannelQuery = {
  __typename?: 'Query';
  offers: {
    __typename?: 'OfferConnection';
    count: number;
    pageInfo: {
      __typename?: 'PageInfo';
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor?: string | null | undefined;
      endCursor?: string | null | undefined;
    };
    edges?:
      | Array<
          | {
              __typename?: 'OfferEdge';
              cursor: string;
              node?:
                | {
                    __typename?: 'Offer';
                    id: string;
                    objectId: string;
                    title: string;
                    subtitle?: string | null | undefined;
                    isActive: boolean;
                    url?: string | null | undefined;
                    createdAt: any;
                    updatedAt: any;
                    channelPointer?:
                      | {
                          __typename?: 'FanfestConfig';
                          id: string;
                          objectId: string;
                          name: string;
                          title: string;
                        }
                      | null
                      | undefined;
                    image?:
                      | { __typename?: 'FileInfo'; name: string; url: string }
                      | null
                      | undefined;
                  }
                | null
                | undefined;
            }
          | null
          | undefined
        >
      | null
      | undefined;
  };
};

export type OfferFragmentFragment = {
  __typename?: 'Offer';
  id: string;
  objectId: string;
  title: string;
  subtitle?: string | null | undefined;
  isActive: boolean;
  url?: string | null | undefined;
  createdAt: any;
  updatedAt: any;
  channelPointer?:
    | {
        __typename?: 'FanfestConfig';
        id: string;
        objectId: string;
        name: string;
        title: string;
      }
    | null
    | undefined;
  image?:
    | { __typename?: 'FileInfo'; name: string; url: string }
    | null
    | undefined;
};

export type UpdateOfferMutationVariables = Exact<{
  id: Scalars['ID'];
  offer: UpdateOfferFieldsInput;
}>;

export type UpdateOfferMutation = {
  __typename?: 'Mutation';
  updateOffer?:
    | {
        __typename?: 'UpdateOfferPayload';
        offer: {
          __typename?: 'Offer';
          id: string;
          title: string;
          subtitle?: string | null | undefined;
          isActive: boolean;
          url?: string | null | undefined;
          channelPointer?:
            | {
                __typename?: 'FanfestConfig';
                id: string;
                objectId: string;
                name: string;
              }
            | null
            | undefined;
          image?:
            | { __typename?: 'FileInfo'; name: string; url: string }
            | null
            | undefined;
        };
      }
    | null
    | undefined;
};

export type CreateRegistrationMutationVariables = Exact<{
  registration: CreateRegistrationFieldsInput;
}>;

export type CreateRegistrationMutation = {
  __typename?: 'Mutation';
  createRegistration?:
    | {
        __typename?: 'CreateRegistrationPayload';
        registration: {
          __typename?: 'Registration';
          id: string;
          objectId: string;
          createdAt: any;
          updatedAt: any;
          userPointer?:
            | { __typename?: 'User'; id: string; objectId: string }
            | null
            | undefined;
          eventPointer: {
            __typename?: 'FanfestEvent';
            id: string;
            objectId: string;
          };
        };
      }
    | null
    | undefined;
};

export type RegistrationFragmentFragment = {
  __typename?: 'Registration';
  id: string;
  objectId: string;
  createdAt: any;
  updatedAt: any;
  userPointer?:
    | { __typename?: 'User'; id: string; objectId: string }
    | null
    | undefined;
  eventPointer: { __typename?: 'FanfestEvent'; id: string; objectId: string };
};

export type DeleteUser2MutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type DeleteUser2Mutation = {
  __typename?: 'Mutation';
  deleteUser2?: boolean | null | undefined;
};

export type GetUserQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetUserQuery = {
  __typename?: 'Query';
  user: {
    __typename?: 'User';
    id: string;
    objectId: string;
    name: string;
    email?: string | null | undefined;
    username?: string | null | undefined;
    role: string;
    language?: string | null | undefined;
    timezone?: string | null | undefined;
    phone?: string | null | undefined;
    smsNotifications?: boolean | null | undefined;
    whatsappNotifications?: boolean | null | undefined;
    emailNotifications?: boolean | null | undefined;
    createdAt: any;
    updatedAt: any;
    worksChannelPointer?:
      | {
          __typename?: 'FanfestConfig';
          id: string;
          objectId: string;
          name: string;
          title: string;
        }
      | null
      | undefined;
  };
};

export type GetUsersQueryVariables = Exact<{
  order?: InputMaybe<Array<UserOrder> | UserOrder>;
  first: Scalars['Int'];
  skip: Scalars['Int'];
  name?: InputMaybe<Scalars['String']>;
}>;

export type GetUsersQuery = {
  __typename?: 'Query';
  users: {
    __typename?: 'UserConnection';
    count: number;
    pageInfo: {
      __typename?: 'PageInfo';
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor?: string | null | undefined;
      endCursor?: string | null | undefined;
    };
    edges?:
      | Array<
          | {
              __typename?: 'UserEdge';
              cursor: string;
              node?:
                | {
                    __typename?: 'User';
                    id: string;
                    objectId: string;
                    name: string;
                    email?: string | null | undefined;
                    username?: string | null | undefined;
                    role: string;
                    language?: string | null | undefined;
                    timezone?: string | null | undefined;
                    phone?: string | null | undefined;
                    smsNotifications?: boolean | null | undefined;
                    whatsappNotifications?: boolean | null | undefined;
                    emailNotifications?: boolean | null | undefined;
                    createdAt: any;
                    updatedAt: any;
                    worksChannelPointer?:
                      | {
                          __typename?: 'FanfestConfig';
                          id: string;
                          objectId: string;
                          name: string;
                          title: string;
                        }
                      | null
                      | undefined;
                  }
                | null
                | undefined;
            }
          | null
          | undefined
        >
      | null
      | undefined;
  };
};

export type UpdateUser2MutationVariables = Exact<{
  id: Scalars['ID'];
  user: UpdateUserFieldsInput;
}>;

export type UpdateUser2Mutation = {
  __typename?: 'Mutation';
  updateUser2?:
    | {
        __typename?: 'UpdateUserPayload';
        user: {
          __typename?: 'User';
          id: string;
          objectId: string;
          name: string;
          email?: string | null | undefined;
          username?: string | null | undefined;
          role: string;
          language?: string | null | undefined;
          timezone?: string | null | undefined;
          phone?: string | null | undefined;
          smsNotifications?: boolean | null | undefined;
          whatsappNotifications?: boolean | null | undefined;
          emailNotifications?: boolean | null | undefined;
          createdAt: any;
          updatedAt: any;
          worksChannelPointer?:
            | {
                __typename?: 'FanfestConfig';
                id: string;
                objectId: string;
                name: string;
                title: string;
              }
            | null
            | undefined;
        };
      }
    | null
    | undefined;
};

export type UserAcceptInvitationMutationVariables = Exact<{
  input: CreateUserFieldsInput;
  date: Scalars['String'];
  invitationCode: Scalars['String'];
}>;

export type UserAcceptInvitationMutation = {
  __typename?: 'Mutation';
  userAcceptInvitation?:
    | {
        __typename?: 'CreateUserPayload';
        user: {
          __typename?: 'User';
          id: string;
          objectId: string;
          name: string;
          email?: string | null | undefined;
          username?: string | null | undefined;
          role: string;
          language?: string | null | undefined;
          timezone?: string | null | undefined;
          phone?: string | null | undefined;
          smsNotifications?: boolean | null | undefined;
          whatsappNotifications?: boolean | null | undefined;
          emailNotifications?: boolean | null | undefined;
          createdAt: any;
          updatedAt: any;
          worksChannelPointer?:
            | {
                __typename?: 'FanfestConfig';
                id: string;
                objectId: string;
                name: string;
                title: string;
              }
            | null
            | undefined;
        };
      }
    | null
    | undefined;
};

export type UserFragmentFragment = {
  __typename?: 'User';
  id: string;
  objectId: string;
  name: string;
  email?: string | null | undefined;
  username?: string | null | undefined;
  role: string;
  language?: string | null | undefined;
  timezone?: string | null | undefined;
  phone?: string | null | undefined;
  smsNotifications?: boolean | null | undefined;
  whatsappNotifications?: boolean | null | undefined;
  emailNotifications?: boolean | null | undefined;
  createdAt: any;
  updatedAt: any;
  worksChannelPointer?:
    | {
        __typename?: 'FanfestConfig';
        id: string;
        objectId: string;
        name: string;
        title: string;
      }
    | null
    | undefined;
};

export type UserInviteMutationVariables = Exact<{
  email: Scalars['String'];
  role: Scalars['String'];
  channelId?: InputMaybe<Scalars['String']>;
}>;

export type UserInviteMutation = {
  __typename?: 'Mutation';
  userInvite?: boolean | null | undefined;
};

export type CreateVideoMutationVariables = Exact<{
  video: CreateVideoFieldsInput;
}>;

export type CreateVideoMutation = {
  __typename?: 'Mutation';
  createVideo?:
    | {
        __typename?: 'CreateVideoPayload';
        video: {
          __typename?: 'Video';
          id: string;
          objectId: string;
          name: string;
          manifest?: string | null | undefined;
          isActive: boolean;
          isProcessed?: boolean | null | undefined;
          createdAt: any;
          updatedAt: any;
          video?:
            | { __typename?: 'FileInfo'; name: string; url: string }
            | null
            | undefined;
          channelPointer: {
            __typename?: 'FanfestConfig';
            id: string;
            objectId: string;
            name: string;
            title: string;
          };
        };
      }
    | null
    | undefined;
};

export type CreateVideoFromYoutubeMutationVariables = Exact<{
  url: Scalars['String'];
  channel: Scalars['String'];
}>;

export type CreateVideoFromYoutubeMutation = {
  __typename?: 'Mutation';
  createVideoFromYoutube: string;
};

export type DeleteVideoMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type DeleteVideoMutation = {
  __typename?: 'Mutation';
  deleteVideo?:
    | {
        __typename?: 'DeleteVideoPayload';
        video: { __typename?: 'Video'; id: string };
      }
    | null
    | undefined;
};

export type GetActiveVideosByChannelQueryVariables = Exact<{
  order?: InputMaybe<Array<VideoOrder> | VideoOrder>;
  first: Scalars['Int'];
  skip: Scalars['Int'];
  channelName: Scalars['String'];
}>;

export type GetActiveVideosByChannelQuery = {
  __typename?: 'Query';
  videos: {
    __typename?: 'VideoConnection';
    count: number;
    pageInfo: {
      __typename?: 'PageInfo';
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor?: string | null | undefined;
      endCursor?: string | null | undefined;
    };
    edges?:
      | Array<
          | {
              __typename?: 'VideoEdge';
              cursor: string;
              node?:
                | {
                    __typename?: 'Video';
                    id: string;
                    objectId: string;
                    name: string;
                    manifest?: string | null | undefined;
                    isActive: boolean;
                    isProcessed?: boolean | null | undefined;
                    createdAt: any;
                    updatedAt: any;
                    video?:
                      | { __typename?: 'FileInfo'; name: string; url: string }
                      | null
                      | undefined;
                    channelPointer: {
                      __typename?: 'FanfestConfig';
                      id: string;
                      objectId: string;
                      name: string;
                      title: string;
                    };
                  }
                | null
                | undefined;
            }
          | null
          | undefined
        >
      | null
      | undefined;
  };
};

export type GetVideoQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetVideoQuery = {
  __typename?: 'Query';
  video: {
    __typename?: 'Video';
    id: string;
    objectId: string;
    name: string;
    manifest?: string | null | undefined;
    isActive: boolean;
    isProcessed?: boolean | null | undefined;
    createdAt: any;
    updatedAt: any;
    video?:
      | { __typename?: 'FileInfo'; name: string; url: string }
      | null
      | undefined;
    channelPointer: {
      __typename?: 'FanfestConfig';
      id: string;
      objectId: string;
      name: string;
      title: string;
    };
  };
};

export type GetVideosQueryVariables = Exact<{
  order?: InputMaybe<Array<VideoOrder> | VideoOrder>;
  first: Scalars['Int'];
  skip: Scalars['Int'];
  name?: InputMaybe<Scalars['String']>;
}>;

export type GetVideosQuery = {
  __typename?: 'Query';
  videos: {
    __typename?: 'VideoConnection';
    count: number;
    pageInfo: {
      __typename?: 'PageInfo';
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor?: string | null | undefined;
      endCursor?: string | null | undefined;
    };
    edges?:
      | Array<
          | {
              __typename?: 'VideoEdge';
              cursor: string;
              node?:
                | {
                    __typename?: 'Video';
                    id: string;
                    objectId: string;
                    name: string;
                    manifest?: string | null | undefined;
                    isActive: boolean;
                    isProcessed?: boolean | null | undefined;
                    createdAt: any;
                    updatedAt: any;
                    video?:
                      | { __typename?: 'FileInfo'; name: string; url: string }
                      | null
                      | undefined;
                    channelPointer: {
                      __typename?: 'FanfestConfig';
                      id: string;
                      objectId: string;
                      name: string;
                      title: string;
                    };
                  }
                | null
                | undefined;
            }
          | null
          | undefined
        >
      | null
      | undefined;
  };
};

export type GetVideosByChannelQueryVariables = Exact<{
  order?: InputMaybe<Array<VideoOrder> | VideoOrder>;
  first: Scalars['Int'];
  skip: Scalars['Int'];
  channelName: Scalars['String'];
}>;

export type GetVideosByChannelQuery = {
  __typename?: 'Query';
  videos: {
    __typename?: 'VideoConnection';
    count: number;
    pageInfo: {
      __typename?: 'PageInfo';
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor?: string | null | undefined;
      endCursor?: string | null | undefined;
    };
    edges?:
      | Array<
          | {
              __typename?: 'VideoEdge';
              cursor: string;
              node?:
                | {
                    __typename?: 'Video';
                    id: string;
                    objectId: string;
                    name: string;
                    manifest?: string | null | undefined;
                    isActive: boolean;
                    isProcessed?: boolean | null | undefined;
                    createdAt: any;
                    updatedAt: any;
                    video?:
                      | { __typename?: 'FileInfo'; name: string; url: string }
                      | null
                      | undefined;
                    channelPointer: {
                      __typename?: 'FanfestConfig';
                      id: string;
                      objectId: string;
                      name: string;
                      title: string;
                    };
                  }
                | null
                | undefined;
            }
          | null
          | undefined
        >
      | null
      | undefined;
  };
};

export type UpdateVideoMutationVariables = Exact<{
  id: Scalars['ID'];
  video: UpdateVideoFieldsInput;
}>;

export type UpdateVideoMutation = {
  __typename?: 'Mutation';
  updateVideo?:
    | {
        __typename?: 'UpdateVideoPayload';
        video: {
          __typename?: 'Video';
          id: string;
          objectId: string;
          name: string;
          manifest?: string | null | undefined;
          isActive: boolean;
          isProcessed?: boolean | null | undefined;
          createdAt: any;
          updatedAt: any;
          video?:
            | { __typename?: 'FileInfo'; name: string; url: string }
            | null
            | undefined;
          channelPointer: {
            __typename?: 'FanfestConfig';
            id: string;
            objectId: string;
            name: string;
            title: string;
          };
        };
      }
    | null
    | undefined;
};

export type VideoFragmentFragment = {
  __typename?: 'Video';
  id: string;
  objectId: string;
  name: string;
  manifest?: string | null | undefined;
  isActive: boolean;
  isProcessed?: boolean | null | undefined;
  createdAt: any;
  updatedAt: any;
  video?:
    | { __typename?: 'FileInfo'; name: string; url: string }
    | null
    | undefined;
  channelPointer: {
    __typename?: 'FanfestConfig';
    id: string;
    objectId: string;
    name: string;
    title: string;
  };
};

export interface PossibleTypesResultData {
  possibleTypes: {
    [key: string]: string[];
  };
}
const result: PossibleTypesResultData = {
  possibleTypes: {
    ArrayResult: [
      'Address',
      'Element',
      'FanfestConfig',
      'FanfestEvent',
      'Offer',
      'Registration',
      'User',
      'Video'
    ],
    Node: [
      'Address',
      'FanfestConfig',
      'FanfestEvent',
      'Offer',
      'Registration',
      'User',
      'Video'
    ],
    ParseObject: [
      'Address',
      'FanfestConfig',
      'FanfestEvent',
      'Offer',
      'Registration',
      'User',
      'Video'
    ],
    SchemaField: [
      'SchemaACLField',
      'SchemaArrayField',
      'SchemaBooleanField',
      'SchemaBytesField',
      'SchemaDateField',
      'SchemaFileField',
      'SchemaGeoPointField',
      'SchemaNumberField',
      'SchemaObjectField',
      'SchemaPointerField',
      'SchemaPolygonField',
      'SchemaRelationField',
      'SchemaStringField'
    ]
  }
};
export default result;
