export function appendCurrentQueryString(url: string) {
  let result = url;

  const searchParams = new URLSearchParams(document.location.search).toString();

  if (searchParams) {
    if (!result.includes('?')) {
      result += '?' + searchParams;
    } else {
      result += '&' + searchParams;
    }
  }

  return result;
}

export async function shortenLink(url: string): Promise<string> {
  const body = {
    long_url: url,
    domain: 'bit.ly',
    group_uid: 'SHOWS0930'
  };

  const response = await fetch('https://api-ssl.bitly.com/v4/shorten', {
    method: 'POST',
    body: JSON.stringify(body),
    headers: {
      Authorization: 'Bearer ' + process.env.VUE_APP_BITLY_TOKEN,
      'Content-Type': 'application/json'
    }
  });

  if (response.ok) {
    const data = await response.json();

    if ('link' in data) {
      return data.link;
    }
  }

  return url;
}
