import { DateTime } from 'luxon';
import { extend } from 'vee-validate';
import { required, max, email, image } from 'vee-validate/dist/rules';

extend('required', required);

extend('max', max);

extend('email', email);

extend('image', image);

extend('url', {
  validate (value: string | null | undefined): boolean {
    if (value) {
      return /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/.test(
        value
      );
    }

    return false;
  },
  message: 'This value must be a valid URL'
});

extend('channelname', {
  validate (value: string | null | undefined): boolean {
    if (value) {
      return /^[0-9a-zA-Z]+$/.test(value);
    }

    return false;
  },
  message: 'Only characters and numbers'
});

extend('squareimage', {
  validate (value: File): Promise<boolean | string> {
    return new Promise<boolean | string>((resolve) => {
      const fr = new FileReader();
      fr.onload = function () {
        if (!fr.result) {
          resolve('Invalid image');
          return;
        }
        // file is loaded
        const img = new Image();

        img.onload = function () {
          if (img.width !== img.height) {
            resolve('Image must be square');
          } else {
            resolve(true);
          }
        };

        img.src = fr.result as string;
      };

      fr.readAsDataURL(value);
    });
  }
});

extend('afterDatetime', {
  params: ['target'],
  validate (
    value: DateTime | null | undefined,
    rules: Record<string, any>
  ): boolean {
    const target = rules.target as DateTime;
    if (!value) {
      return true;
    }
    if (!target) {
      return false;
    }
    const after = typeof value === 'string' ? DateTime.fromISO(value) : value;
    const before =
      typeof target === 'string' ? DateTime.fromISO(target) : target;
    return before <= after;
  },
  message: 'End date must be before date'
});

extend('eventBackgroundImage', {
  validate (value: File): Promise<boolean | string> {
    return new Promise<boolean | string>((resolve) => {
      const fr = new FileReader();
      fr.onload = function () {
        if (!fr.result) {
          resolve('Invalid image');
          return;
        }

        if (value.size > 3 * 1024 * 1024) {
          resolve('Image is too big. Maximum: 3MB');
          return;
        }

        // file is loaded
        const img = new Image();

        img.onload = function () {
          if (img.width < 720) {
            resolve('Image width is too small. Minimum: 720px');
          } else if (img.width === img.height) {
            resolve('Image must not be square.');
          } else {
            resolve(true);
          }
        };

        img.src = fr.result as string;
      };

      fr.readAsDataURL(value);
    });
  }
});
