import './componentRegistration';
import Vue from 'vue';
import App from './App.vue';
import store from './store';
import './scss/index.scss';

import * as Sentry from '@sentry/vue';
import { Integrations } from '@sentry/tracing';

// Vue-Meta
import Meta from 'vue-meta';

// Vue-Router
import router from './router';

// Vue-i18n
import i18n from './services/translation';

// Google Analytics
import VueGtag from 'vue-gtag';

import vuetify from './plugins/vuetify';
import './plugins/general';
import { createProvider } from './vue-apollo';

import socket from './services/socket';
import VueSocketIOExt from 'vue-socket.io-extended';

Vue.use(VueSocketIOExt, socket);

Vue.config.productionTip = false;
Vue.use(Meta, {
  ssrAppId: 1
});
Vue.use(
  VueGtag,
  {
    config: { id: `${process.env.VUE_APP_GTAG}` }
  },
  router
);

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    Vue,
    dsn: 'https://258ce030e9da44c29dd8c468601a5026@o1092906.ingest.sentry.io/6116994',
    release: 'fanfest@' + process.env.VUE_APP_VERSION,
    integrations: [
      new Integrations.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracingOrigins: ['localhost', 'fanfest.io', /^\//]
      })
    ],
    tracesSampleRate: 0.1
  });
}

new Vue({
  router,
  store,
  i18n,
  vuetify,
  apolloProvider: createProvider(),
  render: (h) => h(App)
}).$mount('#app');
