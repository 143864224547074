



























import { FanfestEventClass, FanfestEventGraphQLTranslator } from '@/interfaces';
import { Id } from '@/interfaces/base';
import { Component, Vue } from 'vue-property-decorator';
import FanfestEventCard from './FanfestEventCard.vue';
import { empty as emptyEventData } from '@/components/crud/FanfestEvent/data';

@Component({ components: { FanfestEventCard } })
export default class FanfestEventView extends Vue {
  event: FanfestEventClass = new FanfestEventClass({ ...emptyEventData });

  async created() {
    await this.fetchEvent(this.$route.params?.id);
  }

  get downloadURL(): string | null {
    if (!this.event.recording) {
      return null;
    }
    return this.event.recording;

    // const playURL = new URL(this.event.recording);
    // return (
    //   playURL.protocol +
    //   '//' +
    //   playURL.host +
    //   '/rest/download/video/' +
    //   this.event.objectId
    // );
  }

  async fetchEvent(id: Id): Promise<void> {
    try {
      const result = await this.$apollo.query({
        // Query
        query: require('./getFanfestEvent.graphql'),
        // Parameters
        variables: {
          id: id
        }
      });
      this.$set(
        this,
        'event',
        FanfestEventGraphQLTranslator(result.data.fanfestEvent)
      );
    } catch (e) {}
  }
}
