

































































import { FanfestEventClass } from '@/interfaces';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { LiveEventDataClass } from '@common/types/LiveEvent';
import socket from '@/services/socket';
import RestreamWidget from '../../restream/RestreamWidget.vue';
import { ClientToServerMessageTypes } from '@common/types/SocketProtocol';
import { ValidationObserver, ValidationProvider } from 'vee-validate';

@Component({
  components: { RestreamWidget, ValidationObserver, ValidationProvider }
})
export default class TransmissionTab extends Vue {
  @Prop({})
  readonly event!: FanfestEventClass;

  @Prop({})
  readonly liveEventData!: LiveEventDataClass;

  show = false;
  title = '';
  subtitle = '';

  mounted() {
    this.show = !!this.liveEventData.topic.show;
  }

  @Watch('liveEventData.topic', { deep: true })
  onTopicChange(): void {
    this.title = this.liveEventData.topic.title;
    this.subtitle = this.liveEventData.topic.subtitle;
    this.show = !!this.liveEventData.topic.show;
  }

  onShowChange(show: boolean) {
    if (show) return;

    socket.emit(
      ClientToServerMessageTypes.SetTopic,
      this.event.objectId,
      this.title,
      this.subtitle,
      show
    );
  }

  async onSetTopic(): Promise<void> {
    socket.emit(
      ClientToServerMessageTypes.SetTopic,
      this.event.objectId,
      this.title,
      this.subtitle,
      true
    );
  }
}
