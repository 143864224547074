import { Roles } from '@common/types/Roles';
import VideoEdit from './VideoEdit.vue';
import VideoPreview from './VideoPreview.vue';
import VideoList from './VideoList.vue';
import VideoCreateYoutube from './VideoCreateYoutube.vue';

export default [
  {
    path: 'video',
    name: 'VideoList',
    component: VideoList,
    meta: { roles: [Roles.Admin, Roles.ChannelAdmin, Roles.Producer] }
  },
  {
    path: 'video/youtube',
    name: 'VideoCreateYoutube',
    component: VideoCreateYoutube,
    meta: { roles: [Roles.Admin, Roles.ChannelAdmin, Roles.Producer] }
  },
  {
    path: 'video/:id?/edit',
    name: 'VideoEdit',
    component: VideoEdit,
    meta: { roles: [Roles.Admin, Roles.ChannelAdmin, Roles.Producer] }
  },
  {
    path: 'video/:id?/preview',
    name: 'VideoPreview',
    component: VideoPreview,
    meta: { roles: [Roles.Admin, Roles.ChannelAdmin, Roles.Producer] }
  }
];
