export enum LanguageEnum {
  'en' = 'English',
  'ar' = 'عرب',
  // 'de' = 'Deutsch',
  'el' = 'Ελληνικά',
  'es' = 'Español; castellano',
  'fr' = 'Français',
  // 'it' = 'Italiano',
  // 'nl' = 'Nederlands',
  'pt' = 'Português',
  'ja' = '日本語'
}

export type LanguageCode = keyof typeof LanguageEnum;

export const LanguageCodes: string[] = Object.keys(LanguageEnum);
