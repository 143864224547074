import {
  TranslationMessages,
  TranslationMessagesInner
} from '../translationMessages';
import ja from './ja.json';

// @ts-expect-error - we provide fallbacks to english
const messages: TranslationMessagesInner = ja;

export default messages as TranslationMessages;
