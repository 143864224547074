






























import { FanfestEventClass } from '@/interfaces';
import { Component, Vue, Prop } from 'vue-property-decorator';
import { LiveEventDataClass } from '@common/types/LiveEvent';
import ContentAspectRatio from './ContentAspectRatio.vue';
import MemberStream from './MemberStream.vue';
import ChatFloatingNotifications from '@/components/chat/ChatFloatingNotifications.vue';
import { PhenixStreamMap } from '@/util/phenix';
import { filterLiveStreamsLinkedWithPhenix } from '@/util/streams';

@Component({
  components: {
    ContentAspectRatio,
    MemberStream,
    ChatFloatingNotifications
  }
})
export default class StageAreaStreams extends Vue {
  @Prop({})
  readonly event!: FanfestEventClass;

  @Prop({})
  readonly liveEventData!: LiveEventDataClass;

  @Prop({})
  readonly phenixStreamMap!: PhenixStreamMap[];

  @Prop({})
  readonly isMobileBrowser!: boolean;

  @Prop({})
  readonly showStreamLabels!: boolean;

  @Prop({})
  readonly producerFeatures!: boolean;

  streamsIsPortrait = false;
  observer: ResizeObserver | null = null;

  mounted() {
    this.observer = new ResizeObserver(this.onResize);
    this.observer.observe(this.$el);
  }

  beforeDestroy() {
    this.observer?.disconnect();
  }

  onResize(): void {
    const innerWidth = this.$el.clientWidth;
    const innerHeight = this.$el.clientHeight;
    this.streamsIsPortrait = innerWidth < innerHeight;
  }

  get isEmbedding() {
    return !!this.liveEventData.embedURL || !!this.liveEventData.presentation;
  }

  get isEmbeddingOrVIP() {
    return (
      this.isEmbedding ||
      this.liveEventData.streams.some(
        (stream) => stream.stream.isVIP && stream.stream.isOnStage
      )
    );
  }

  get aspectRatio(): string {
    switch (this.stageStreams.length) {
      case 2:
        return '4/3';
      case 1:
      default:
        return '16/9';
    }
  }

  get stageStreams() {
    const streams = filterLiveStreamsLinkedWithPhenix(
      this.phenixStreamMap,
      this.liveEventData,
      (liveStream) =>
        liveStream.stream.isOnStage &&
        (this.isEmbedding
          ? true
          : !(liveStream.stream.isVIP && liveStream.stream.isOnStage)),
      'Stage'
    );

    this.$emit('visibleStreams', streams.length);
    return streams;
  }
}
