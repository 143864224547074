var render = function () {
var _obj, _obj$1;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"stageareaContainer",class:( _obj = {
    stagearea__container: true
  }, _obj[("stagearea__container-" + _vm.internalBreakpoint)] = true, _obj['container-portrait'] =  _vm.stageareaIsPortrait, _obj['container-landscape'] =  !_vm.stageareaIsPortrait, _obj['padding-bottom-offers'] =  _vm.showFullWidthBottomOffers, _obj )},[_c('div',{ref:"embedContainer",class:( _obj$1 = {
      'stagearea__embed-container': true
    }, _obj$1[("stagearea__content-" + (_vm.isEmbedding ? 'true' : 'false'))] = true, _obj$1[("stagearea__streams-" + _vm.visibleStreams)] = true, _obj$1['container-portrait'] =  _vm.embedIsPortrait, _obj$1['container-landscape'] =  !_vm.embedIsPortrait, _obj$1['padding-bottom-offers'] =  _vm.showHalfWidthOffers, _obj$1 )},[_c('div',{staticClass:"stagearea__embed-wrapper"},[_vm._t("default")],2),(_vm.showHalfWidthOffers)?_c('div',{staticClass:"offer__floater offer__floater--half"},[_c('offer-show',{attrs:{"offer":_vm.currentOffer,"default-image":_vm.event.channelPointer.image}})],1):_vm._e()]),_c('stage-area-streams',{ref:"stageAreaStreamsContainer",attrs:{"event":_vm.event,"live-event-data":_vm.liveEventData,"phenix-stream-map":_vm.phenixStreamMap,"is-mobile-browser":_vm.isMobileBrowser,"show-stream-labels":_vm.showStreamLabels,"producer-features":_vm.producerFeatures},on:{"visibleStreams":_vm.onVisibleStreams}}),(_vm.showFullWidthBottomOffers)?_c('div',{class:_vm.internalBreakpoint === 'xs'
        ? 'offer__floater--bottom'
        : 'offer__floater'},[_c('offer-show',{attrs:{"offer":_vm.currentOffer,"default-image":_vm.event.channelPointer.image}})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }