






import { Component, Vue } from 'vue-property-decorator';
import ResetPasswordForm from '@/components/auth/ResetPasswordForm.vue';
import { MetaInfo } from 'vue-meta';

@Component({
  components: { ResetPasswordForm }
})
export default class ResetPassword extends Vue {
  metaInfo(): MetaInfo {
    const title = 'Fanfest';
    return {
      title: title
    };
  }
}
