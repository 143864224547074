import { Events } from '@/components/eventbus/events';
import { GraphQLError } from 'graphql';
import EventBus from '@/components/eventbus/EventBus.vue';

export function graphQLErrorAlert (error: any): boolean {
  // eslint-disable-next-line no-debugger
  if (error.networkError) {
    // eslint-disable-next-line no-debugger
    if (error.networkError.result?.code === 209) {
      // invalid session token
      EventBus.$emit(Events.LoggedOut);
    } else if (error.networkError.result?.errors) {
      const g = error.networkError.result?.errors as GraphQLError[];
      EventBus.$emit(
        Events.AlertError,
        'Failed: ' + g.map((i) => i.message).join('\n')
      );
    } else {
      EventBus.$emit(Events.AlertError, 'Failed: ' + error.message);
    }
  } else if (error.graphQLErrors) {
    const g = error.graphQLErrors as GraphQLError[];
    EventBus.$emit(
      Events.AlertError,
      'Failed: ' + g.map((i) => i.message).join('\n')
    );
    return true;
  } else if (error.errors) {
    const g = error.errors as GraphQLError[];
    EventBus.$emit(
      Events.AlertError,
      'Failed: ' + g.map((i) => i.message).join('\n')
    );
    return true;
  } else {
    console.error(error);
  }
  return false;
}
