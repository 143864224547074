























































import { FanfestEventClass } from '@/interfaces';
import socket from '@/services/socket';
import { ClientToServerMessageTypes } from '@common/types/SocketProtocol';
import { Component, Prop, Vue } from 'vue-property-decorator';
import defaultContentOptions from './mockcontent';

@Component({})
export default class EventMock extends Vue {
  @Prop({})
  readonly event!: FanfestEventClass;

  totalUsers = 0;
  totalFanbar = 0;
  totalStage = 0;
  content = '';
  showMock = false;

  contentOptions = defaultContentOptions;

  async mounted () {
    const params = new URLSearchParams(document.location.search);
    this.totalUsers = parseInt(params.get('totalUsers') || '0', 10) || 0;
    this.totalFanbar = parseInt(params.get('totalFanbar') || '0', 10) || 0;
    this.totalStage = parseInt(params.get('totalStage') || '0', 10) || 0;
    this.content = params.get('content') || '';
    this.showMock = params.has('showMock') || false;

    if (
      this.totalUsers ||
      this.totalFanbar ||
      this.totalStage ||
      this.content
    ) {
      // TODO: crappy solution, handles a race condition
      // we need to wait for a joinroom, this should be enough
      setTimeout(() => this.mockSession(), 2000);
    }
  }

  mockSession (): void {
    console.warn('mocking sessions');
    socket.emit(
      ClientToServerMessageTypes.MockUsers,
      this.event.objectId,
      this.content,
      this.totalUsers,
      this.totalFanbar,
      this.totalStage
    );
    this.updateURL();
  }

  updateURL (): void {
    const url = new URL(this.event.urlWithDomain);
    url.searchParams.set('content', this.content);
    url.searchParams.set('totalUsers', this.totalUsers + '');
    url.searchParams.set('totalFanbar', this.totalFanbar + '');
    url.searchParams.set('totalStage', this.totalStage + '');
    if (this.showMock) {
      url.searchParams.set('showMock', 'true');
    } else {
      url.searchParams.delete('showMock');
    }
    window.history.replaceState({}, document.title, url.toString());
  }

  close (): void {
    this.showMock = false;
  }
}
