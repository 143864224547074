export function bottomBarHeight (
  breakpoint: string,
  isPortrait: boolean
): number {
  // keep this in sync with variables.scss
  const bottomBarHeightSmall = 44;
  const bottomBarHeight = 52;

  // sny
  if (!isPortrait) {
    // landscape is different for mobile
    switch (breakpoint) {
    case 'xs':
      return bottomBarHeight;
    case 'sm':
      return bottomBarHeight;
    }
  }
  switch (breakpoint) {
  case 'xs':
    return bottomBarHeightSmall;
  case 'sm':
    return bottomBarHeightSmall;
  case 'md':
    return bottomBarHeight;
  case 'lg':
    return bottomBarHeight;
  case 'xl':
  default:
    return bottomBarHeight;
  }
}
