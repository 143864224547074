import { FanfestConfigClass, FanfestEventInterface } from '@/interfaces';
import { DateTime } from 'luxon';
import {
  empty as FanfestConfigEmpty,
  test as FanfestConfigTest
} from '../FanfestConfig/data';

const empty: FanfestEventInterface = {
  id: '',
  objectId: '',

  title: '',
  description: '',
  isActive: true,
  cost: 0,
  startDatetime: DateTime.now(),
  channelPointer: new FanfestConfigClass(FanfestConfigEmpty),
  updatedAt: '',
  createdAt: '',
  shouldSendNotifications: false
};

const test: FanfestEventInterface = {
  id: '000000',
  objectId: '',

  title: 'Some awesome event',
  description: 'This will be the most awesome event ever',

  isActive: true,
  cost: 0,
  // channel: { ...FanfestConfigEmpty },
  startDatetime: DateTime.now(),
  channelPointer: new FanfestConfigClass(FanfestConfigTest),

  updatedAt: '2021-12-06T14:04:35.893Z',
  createdAt: '2021-12-06T14:04:35.893Z'
};

export { empty, test };
