
























































import { Component, Vue } from 'vue-property-decorator';
import { UserModule } from '@/store/user';
import { UserClass } from '@/interfaces';
import UserDropdown from '@/components/base/UserDropdown.vue';
import UserEdit from '@/components/crud/User/UserEdit.vue';
import { Events } from '@/components/eventbus/events';
import EventBus from '@/components/eventbus/EventBus.vue';
import { graphQLErrorAlert } from '@/util/GraphQL';
import Logo from '@/components/util/Logo.vue';
import { Roles } from '@common/types/Roles';
import NavigationListAdmin from '@/components/dashboard/NavigationList.admin.vue';
import NavigationListChannelAdmin from '@/components/dashboard/NavigationList.channelAdmin.vue';
import NavigationListProducer from '@/components/dashboard/NavigationList.producer.vue';
import DashboardHeaderSlotAdmin from '@/components/dashboard/DashboardHeaderSlot.admin.vue';
import DashboardHeaderSlotChannel from '@/components/dashboard/DashboardHeaderSlot.channel.vue';

@Component({
  components: {
    Logo,
    UserEdit,
    UserDropdown,
    NavigationListAdmin,
    NavigationListChannelAdmin,
    NavigationListProducer,
    DashboardHeaderSlotAdmin,
    DashboardHeaderSlotChannel
  }
})
export default class Profile extends Vue {
  drawer = true;

  created () {
    this.drawer = !this.showNavIcon;
  }

  get showNavIcon (): boolean {
    return this.$vuetify.breakpoint.mdAndDown;
  }

  get userName (): string {
    return UserModule.user.name;
  }

  get userEmail (): string {
    return UserModule.user.email;
  }

  get dashboardComponentHeaderSlot (): string | null {
    switch (UserModule.user.role) {
    case Roles.Admin:
      return 'DashboardHeaderSlotAdmin';
    case Roles.ChannelAdmin:
    case Roles.Producer:
    case Roles.Moderator:
      return 'DashboardHeaderSlotChannel';
    default:
      return null;
    }
  }

  get dashboardComponentNavigation (): string | null {
    switch (UserModule.user.role) {
    case Roles.Admin:
      return 'NavigationListAdmin';
    case Roles.ChannelAdmin:
      return 'NavigationListChannelAdmin';
    case Roles.Producer:
      return 'NavigationListProducer';
    default:
      return null;
    }
  }

  get hasDashboard () {
    return [Roles.Admin, Roles.ChannelAdmin, Roles.Producer].includes(UserModule.user.role);
  }

  get user (): UserClass {
    return UserModule.user;
  }

  async deleteAccount (): Promise<void> {
    if (!confirm(this.$t('form.reallyDelete') as string)) {
      return;
    }
    try {
      await this.$apollo.mutate({
        mutation: require('../components/crud/User/deleteUser.graphql'),
        variables: {
          id: UserModule.user.objectId
        }
      });
      EventBus.$emit(Events.AlertSuccess, this.$t('form.deleted'));
      EventBus.$emit(Events.LoggedOut, { path: '/login' });
    } catch (error: any) {
      graphQLErrorAlert(error);
    }
  }
}
