




















import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({})
export default class StreamInputSettingsDialog extends Vue {
  @Prop()
  readonly value!: boolean;

  get isOpen() {
    return this.value;
  }

  set isOpen(value: boolean) {
    this.$emit('input', value);
  }

  onClose(): void {
    this.$emit('input', false);
  }
}
