
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { FanfestEventClass, OfferClass } from '@/interfaces';
import { bottomBarHeight } from './layout';
import { Analytics, EventCategory, TrackEventEnum } from '@/services/tracking';
import { LiveEventDataClass } from '@common/types/LiveEvent';
import { empty as OfferEmpty } from '../crud/Offer/data';

const OFFER_DURATION = 15 * 1000;
const TOPIC_DURATION = 30 * 1000;

@Component
export default class OfferTopicAreaMixin extends Vue {
  @Prop({})
  readonly event!: FanfestEventClass;

  @Prop({})
  readonly liveEventData!: LiveEventDataClass;

  @Prop({})
  readonly offers!: OfferClass[];

  showTopic = false;
  currentOfferIndex = 0;

  running = true;
  sleepPromise: Promise<void> | undefined;
  sleepPromiseResolve: undefined | ((value: void | PromiseLike<void>) => void);

  mounted() {
    this.loop();
  }

  beforeDestroy() {
    this.running = false;
    if (this.sleepPromiseResolve) {
      this.sleepPromiseResolve();
    }
  }

  get showOffers(): boolean {
    return (
      this.currentOfferIndex >= 0 &&
      this.liveEventData.started &&
      !this.event.transmitted
    );
  }

  /**
   * Sleeps for a given time and stores the promise and its resolve function
   * locally. This way we have an external `break` for the sleep function.
   */
  async _sleep(ms: number | null) {
    this.sleepPromise = new Promise((resolve) => {
      this.sleepPromiseResolve = resolve;

      if (ms) {
        setTimeout(resolve, ms);
      }
    });

    await this.sleepPromise;
  }

  restartLoopIfRunning() {
    if (this.sleepPromiseResolve) this.sleepPromiseResolve();
  }

  /**
   * infinite loop updating the current offer/topic
   */
  async loop(): Promise<void> {
    while (this.running) {
      if (!this.running) {
        break;
      }

      if (this.liveEventData.topic.show && !this.showTopic) {
        this.showTopic = true;
      } else if (this.liveEventData.topic.show && this.showTopic) {
        this.showTopic = false;
      }

      if (!this.showTopic) {
        // We got no offers, wait until we either have a topic or offers.

        if (!this.offers.length) {
          await this._sleep(null);
          continue;
        }

        // If no more offers nor topics, start again on offers.
        if (this.currentOfferIndex >= this.offers.length) {
          this.currentOfferIndex = 0;
          continue;
        }
      }

      if (this.showTopic) {
        Analytics.track(
          TrackEventEnum.TopicWatched,
          EventCategory.Engagement,
          {},
          true
        );
      } else {
        Analytics.track(
          TrackEventEnum.OfferWatched,
          EventCategory.Engagement,
          {
            offerId: this.currentOffer?.objectId || '',
            label: this.currentOffer?.title || ''
          },
          true
        );
      }

      if (this.showTopic) {
        await this._sleep(TOPIC_DURATION);
      } else {
        await this._sleep(OFFER_DURATION);
        this.currentOfferIndex = this.currentOfferIndex + 1;
      }
    }
  }

  @Watch('offers')
  onOffersChanged() {
    this.currentOfferIndex = 0;
    this.restartLoopIfRunning();
  }

  @Watch('liveEventData.topic.show')
  onTopicChanged() {
    // Reset showTopic state.
    this.showTopic = false;
    this.restartLoopIfRunning();
  }

  get isPortrait() {
    return this.$vuetify.breakpoint.width < this.$vuetify.breakpoint.height;
  }

  get height(): number {
    return bottomBarHeight(this.$vuetify.breakpoint.name, this.isPortrait);
  }

  get currentOffer(): OfferClass | null {
    if (this.showTopic) {
      // fake it with the topic data.
      const x = new OfferClass({
        ...OfferEmpty,
        id: '000',
        channelPointer: this.event.channelPointer,
        image: this.event.channelPointer.image,
        isActive: true,
        subtitle: this.liveEventData.topic.subtitle,
        title: this.liveEventData.topic.title
      });
      return x;
    }
    if (this.offers.length === 0) {
      return null;
    }
    if (!this.showOffers) {
      return null;
    }

    return this.offers[
      this.currentOfferIndex >= 0 && this.currentOfferIndex < this.offers.length
        ? this.currentOfferIndex
        : 0
    ];
  }
}
