import { Roles } from '@common/types/Roles';

export function getLoginRedirectUrl (role: Roles, myChannelURI?: string): string {
  switch (role) {
  case Roles.Admin:
  case Roles.ChannelAdmin:
  case Roles.Producer:
    return '/dashboard/main';
  case Roles.Moderator:
    return myChannelURI || '/dashboard';
  default:
    return '/profile';
  }
}
