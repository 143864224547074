// const preferDark = window.matchMedia('(prefers-color-scheme: dark)').matches;

const opts = {
  icons: {
    iconfont: 'md' as const
  },
  theme: {
    options: { customProperties: true },
    dark: true,
    themes: {
      dark: {
        primary: '#5E38BD',
        secondary: '#6C757D',
        helper: '#4E4CAB',
        light_helper: '#B49FE1',
        accent: '#82B1FF',
        anchor: '#0073C2',
        error: '#DB493C',
        info: '#19B3E6',
        success: '#4CAF50',
        warning: '#FF9F24',
        background: '#13141C'
      }
    }
  }
};

export default opts;
