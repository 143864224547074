





















































import { Vue, Component } from 'vue-property-decorator';
import EventBus from '../eventbus/EventBus.vue';
import { Events } from '../eventbus/events';

@Component({})
export default class NavigationListAdmin extends Vue {
  onLogout(): void {
    EventBus.$emit(Events.LoggedOut, { path: '/' });
  }
}
