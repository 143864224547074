import { Roles } from '@common/types/Roles';
import OfferEdit from './OfferEdit.vue';
import OfferList from './OfferList.vue';

export default [
  {
    path: 'offer',
    name: 'offerList',
    component: OfferList,
    meta: { roles: [Roles.Admin, Roles.ChannelAdmin, Roles.Producer] }
  },
  {
    path: 'offer/:id?/edit',
    name: 'offerEdit',
    component: OfferEdit,
    meta: { roles: [Roles.Admin, Roles.ChannelAdmin, Roles.Producer] }
  }
];
