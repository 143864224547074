import { FanfestConfigInterface } from '@/interfaces';

const empty: FanfestConfigInterface = {
  objectId: '',
  id: '',
  name: '',
  title: '',
  channelHeroColor: '#000000FF',
  isActive: false,
  // the logo for this Offer
  image: {
    name: '',
    url: ''
  },
  sponsorName: '',
  sponsorImage: {
    name: '',
    url: ''
  },

  updatedAt: '',
  createdAt: '',
  enableGatedEvents: false,
  enableShowCreationNotifications: false
};

const test: FanfestConfigInterface = {
  objectId: '',
  id: '000000',
  name: 'testteam',
  title: 'Test Team',
  channelHeroColor: '#FF0000FF',
  isActive: true,
  // the logo for this Offer
  image: {
    name: 'Image',
    url: 'https://via.placeholder.com/100x100'
  },
  sponsorName: 'Sponsor Inc.',
  sponsorImage: {
    name: 'Image',
    url: 'https://via.placeholder.com/100x100'
  },

  updatedAt: '2021-12-06T14:04:35.893Z',
  createdAt: '2021-12-06T14:04:35.893Z'
};

export { empty, test };
