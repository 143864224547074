import { Address, Scalars } from '@/generated/graphql';

/**
 * Patch the frontend types that we don't have on GraphQL and remove
 * stuff we don't need
 */
export type AddressInterface = Omit<Address, 'ACL'>;

export class AddressClass implements AddressInterface {
  id = '';
  createdAt: any;
  objectId = '';
  updatedAt: any;
  __typename?: 'Address' | undefined;
  address: Scalars['String'] = '';
  address2: Scalars['String'] = '';
  city: Scalars['String'] = '';
  state: Scalars['String'] = '';
  zip: Scalars['String'] = '';
  country: Scalars['String'] = '';
  sendFromEmail: Scalars['String'] = '';

  constructor (data: AddressInterface | Address | AddressClass) {
    Object.assign(this, data);
  }
}

/**
 * Converts data that comes from GraphQL to the expected values/types.
 *
 * @param configData GraphQL data
 * @returns A properly built interface
 */
export function AddressGraphQLTranslator (eventData: Address): AddressClass {
  const event = new AddressClass({ ...eventData });
  return event;
}
