var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"fanfestConfig-list__container"},[_c('h1',{staticClass:"text-h1 font-weight-light mb-12"},[_vm._v(" "+_vm._s(_vm.$t('fanfestConfig.objectName'))+" ")]),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.fanfestConfigs,"loading":_vm.loading,"items-per-page":_vm.perPage,"server-items-length":_vm.totalItems,"footer-props":{
      'items-per-page-options': [20, 50]
    },"sort-by":"title"},on:{"update:itemsPerPage":function($event){_vm.perPage=$event},"update:items-per-page":[function($event){_vm.perPage=$event},_vm.updateItemsPerPage],"update:page":_vm.updatePage},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
    var item = ref.item;
return [_c('router-link',{attrs:{"to":'/' + item.name}},[_vm._v(_vm._s(item.title))])]}},{key:"item.isActive",fn:function(ref){
    var item = ref.item;
return [_c('boolean-chip',{attrs:{"value":item.isActive}})]}},{key:"item.createdAt",fn:function(ref){
    var item = ref.item;
return [_c('date-ISO',{attrs:{"date":item.createdAt}})]}},{key:"item.image",fn:function(ref){
    var item = ref.item;
return [_c('img',{staticClass:"list__image",attrs:{"src":item.image.url}})]}},{key:"item.sponsorName",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(item.sponsorName)+" ")]}},{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":_vm.$t('form.search'),"single-line":"","hide-details":"","clearable":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-spacer'),_c('v-btn',{staticClass:"mb-2",attrs:{"color":"primary","dark":"","to":"/dashboard/fanfestConfig/edit"}},[_vm._v(" "+_vm._s(_vm.$t('form.new'))+" "+_vm._s(_vm.$t('fanfestConfig.objectName'))+" "),_c('v-icon',{staticClass:"ml-1"},[_vm._v("add_box")])],1)],1)]},proxy:true},{key:"item.actions",fn:function(ref){
    var item = ref.item;
return [_c('router-link',{attrs:{"to":("/dashboard/fanfestConfig/" + (item.id) + "/edit")}},[_c('v-icon',{staticClass:"mr-2",attrs:{"small":""}},[_vm._v("edit")])],1),_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v("delete")])]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }