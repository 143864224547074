import { FanfestConfigClass, FanfestEventClass } from '@/interfaces';
import { getLocation } from './navigator';

export async function getShareableLink() {
  const { href } = await getLocation();

  return href;
}

async function getFanfestUrl({
  eventId,
  channelId
}: {
  eventId: string | null | undefined;
  channelId: string | null | undefined;
}) {
  const { origin, pathname, queryString: currentQs } = await getLocation();

  const url = new URL(pathname, origin);
  const qs = new URLSearchParams(currentQs);

  if (eventId !== undefined) qs.delete('fanfest_event');
  if (eventId) {
    qs.append('fanfest_event', eventId);
  }

  if (channelId !== undefined) qs.delete('fanfest_channel');
  if (channelId) {
    qs.append('fanfest_channel', channelId);
  }

  url.search = qs.toString();
  return url.toString();
}

export async function getShareableEventLink(
  event: FanfestEventClass
): Promise<string> {
  if (window.top && window.top !== window) {
    return getFanfestUrl({
      eventId: event.objectId,
      channelId: undefined
    });
  } else {
    return event.urlWithDomain;
  }
}

export async function getShareableChannelLink(channel: FanfestConfigClass) {
  if (window.top && window.top !== window) {
    return getFanfestUrl({ eventId: null, channelId: channel.name });
  } else {
    return channel.url;
  }
}
