










import { Component, Vue } from 'vue-property-decorator';
import NewPasswordForm from '@/components/auth/NewPasswordForm.vue';
import { MetaInfo } from 'vue-meta';

@Component({
  components: { NewPasswordForm }
})
export default class ResetPassword extends Vue {
  metaInfo(): MetaInfo {
    const title = 'Fanfest';
    return {
      title: title
    };
  }
}
