export enum Roles {
    Admin = 'Admin',
    ChannelAdmin = 'ChannelAdmin',
    Producer = 'Producer',
    Moderator = 'Moderator',
    Fan = 'Fan',
    Guest = 'Guest',
    GuestPlus = 'GuestPlus'
}

export const RolesChannel = [Roles.ChannelAdmin, Roles.Producer, Roles.Moderator];
