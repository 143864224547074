




import { Component, Vue, Prop } from 'vue-property-decorator';
import { ChatMessageData } from '../chat';

@Component({})
export default class ChatMessageText extends Vue {
  @Prop({
    required: true
  })
  readonly message!: ChatMessageData;
}
