






import Vue from 'vue';

export default Vue.extend({
  name: 'Error',
  metaInfo () {
    return {
      title: 'No FanFest Found ¯\\_(ツ)_/¯'
    };
  },
  props: {
    message: {
      type: String,
      default: ''
    }
  }
});
