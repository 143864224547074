






import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({
  components: {}
})
export default class BooleanChip extends Vue {
  @Prop({})
  readonly value!: boolean;
}
