

































































































import { FanfestEventClass } from '@/interfaces';
import socket from '@/services/socket';
import { UserModule } from '@/store/user';
import { LiveEventStreamInterface, MuteOrigin } from '@common/types/LiveEvent';
import { ClientToServerMessageTypes } from '@common/types/SocketProtocol';
import { Component, Prop, Vue } from 'vue-property-decorator';
import ContentAspectRatio from './ContentAspectRatio.vue';

@Component({ components: { ContentAspectRatio } })
export default class VideoButtonControls extends Vue {
  @Prop({})
  readonly event!: FanfestEventClass;

  @Prop({})
  readonly liveStream!: LiveEventStreamInterface;

  @Prop({ default: false })
  readonly isSelf!: boolean;

  MuteOrigin = Object.freeze(MuteOrigin);

  get canProduce (): boolean {
    return this.event.canProduce(UserModule.user);
  }

  get isOnStage (): boolean {
    return (
      this.liveStream.stream.isStreaming && this.liveStream.stream.isOnStage
    );
  }

  get isOnFanBar (): boolean {
    return (
      this.liveStream.stream.isStreaming && !this.liveStream.stream.isOnStage
    );
  }

  toggleMuteCamera (): void {
    socket.emit(
      ClientToServerMessageTypes.SetUserMuteCamera,
      this.event.objectId,
      this.liveStream.user.objectId,
      this.liveStream.stream.muteCamera === MuteOrigin.NONE
    );
  }

  toggleMuteMicrophone (): void {
    socket.emit(
      ClientToServerMessageTypes.SetUserMuteMicrophone,
      this.event.objectId,
      this.liveStream.user.objectId,
      this.liveStream.stream.muteMicrophone === MuteOrigin.NONE
    );
  }

  closeVideo (): void {
    socket.emit(
      ClientToServerMessageTypes.StopUserCamera,
      this.event.objectId,
      this.liveStream.user.objectId
    );
  }

  toggleStage (): void {
    socket.emit(
      ClientToServerMessageTypes.SetUserStage,
      this.event.objectId,
      this.liveStream.user.objectId,
      !this.liveStream.stream.isOnStage
    );
  }

  toggleVIP (): void {
    socket.emit(
      ClientToServerMessageTypes.SetUserVIP,
      this.event.objectId,
      this.liveStream.user.objectId,
      !this.liveStream.stream.isVIP
    );
  }

  sendToFanBar (): void {
    socket.emit(
      ClientToServerMessageTypes.SetUserStage,
      this.event.objectId,
      this.liveStream.user.objectId,
      false
    );
  }

  sendUserToStage (): void {
    socket.emit(
      ClientToServerMessageTypes.SetUserStage,
      this.event.objectId,
      this.liveStream.user.objectId,
      true
    );
  }

  mirrorStageVideo (): void {
    this.$emit('mirrorStageVideo');
  }
}
