











































































































import { AddressClass, AddressGraphQLTranslator } from '../../../interfaces';
import { empty } from './data';
import { Component, Vue, Ref, Prop, Watch } from 'vue-property-decorator';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import { Id } from '@/interfaces/base';
import { graphQLErrorAlert } from '@/util/GraphQL';

@Component({
  components: { ValidationObserver, ValidationProvider },

  apollo: {
    // Apollo specific options
  }
})
export default class AddressEdit extends Vue {
  @Ref() readonly observer!: InstanceType<typeof ValidationProvider>;

  @Prop({})
  readonly value!: AddressClass;

  address: AddressClass = new AddressClass({ ...empty });

  loading = false;

  @Watch('value', { deep: true })
  onValueChange(newval: AddressClass) {
    this.address = new AddressClass(newval);
  }

  async mounted(): Promise<void> {
    this.address = new AddressClass(this.value);
  }

  async load(id: Id): Promise<void> {
    try {
      this.loading = true;
      const result = await this.$apollo.query({
        // Query
        query: require('./getAddressByChannel.graphql'),
        // Parameters
        variables: {
          id: id
        }
      });
      this.$set(this, 'address', AddressGraphQLTranslator(result.data.address));
    } catch (error) {
      graphQLErrorAlert(error);
    } finally {
      this.loading = false;
    }
  }

  async save(): Promise<AddressClass> {
    const addressData: { [k: string]: any } = {
      address: this.address.address,
      address2: this.address.address2,
      city: this.address.city,
      state: this.address.state,
      zip: this.address.zip,
      country: this.address.country,
      sendFromEmail: this.address.sendFromEmail.toLocaleLowerCase()
    };

    try {
      this.loading = true;

      if (this.address.id) {
        const result = await this.$apollo.mutate({
          // Query
          mutation: require('./updateAddress.graphql'),
          // Parameters
          variables: {
            id: this.address.id,
            address: addressData
          }
        });
        const address = AddressGraphQLTranslator(
          result.data.updateAddress.address
        );
        this.$set(this, 'address', address);
        this.$emit('input', address);
        return address;
      } else {
        const result = await this.$apollo.mutate({
          mutation: require('./createAddress.graphql'),
          // Parameters
          variables: {
            address: addressData
          }
        });
        const address = AddressGraphQLTranslator(
          result.data.createAddress.address
        );
        this.$set(this, 'address', address);
        this.$emit('input', address);
        return address;
      }
    } catch (error) {
      graphQLErrorAlert(error);
      throw error;
    } finally {
      this.loading = false;
    }
  }
}
