





















































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
// import EventBus from '../eventbus/EventBus.vue';
// import { Events } from '../eventbus/events';
import { Restream, RestreamChannel, RestreamPlatform } from './restream';

@Component({ components: {} })
export default class RestreamChannelForm extends Vue {
  @Prop({})
  readonly restream!: Restream;

  @Prop({})
  readonly platforms!: RestreamPlatform[];

  @Prop({})
  readonly restreamChannel!: RestreamChannel;

  title = '';
  showMetaForm = false;
  showMetaFormLoading = false;
  active = false;

  @Watch('restreamChannel.enabled', { deep: true, immediate: true })
  onActive(newval: boolean) {
    this.active = newval;

    if (this.active) {
      this.onShowMetaForm();
    } else {
      this.showMetaForm = false;
    }
  }

  get channelId(): string {
    return this.restreamChannel.id.toString();
  }

  mounted() {
    if (this.active) this.onShowMetaForm();
  }

  async updateChannelStatus(active: boolean): Promise<void> {
    this.$emit('ChannelStatusUpdate', { id: this.channelId, active });
  }

  async updateChannelMeta(): Promise<void> {
    this.showMetaForm = false;
    this.$emit('ChannelMetaUpdate', {
      id: this.channelId,
      title: this.title
    });
  }

  get platformIcon(): string {
    const platform = this.platforms.find(
      (p: RestreamPlatform) => p.id === this.restreamChannel.streamingPlatformId
    );
    if (platform) {
      return platform.image.png;
    } else {
      return '';
    }
  }

  async onShowMetaForm(): Promise<void> {
    try {
      this.showMetaFormLoading = true;
      const meta = await this.restream.getChannelMeta(this.restreamChannel.id);
      this.title = meta.title;
      this.showMetaForm = true;
    } catch (e) {
      // EventBus.$emit(Events.AlertError, e);
    } finally {
      this.showMetaFormLoading = false;
    }
  }

  closeForm(): void {
    this.showMetaForm = false;
    this.title = '';
  }
}
