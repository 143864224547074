













import { UserModule } from '@/store/user';
import { MetaInfo } from 'vue-meta';
import { Component, Vue } from 'vue-property-decorator';

@Component({})
export default class HomePage extends Vue {
  metaInfo(): MetaInfo {
    const title = 'Fanfest';
    return {
      title: title
    };
  }

  get isLogged(): boolean {
    return UserModule.isLogged;
  }
}
