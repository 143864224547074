




import { Component, Vue, Prop } from 'vue-property-decorator';
import { DateTime } from 'luxon';

@Component({
  components: {}
})
export default class DateHuman extends Vue {
  @Prop({ default: null })
  readonly date!: DateTime;

  get formatted (): string {
    return this.date?.toLocaleString(DateTime.DATETIME_MED);
  }
}
