import { LiveEventStreamStateInterface, LiveEventStreamUserInterface } from './LiveEvent';

/**
 * This is associated with the socket.data field in socket.io.
 */
export interface EventUser {
  user: LiveEventStreamUserInterface,
  stream: LiveEventStreamStateInterface;
}

/**
 * socketio client to server messages.
 */
export enum ClientToServerMessageTypes {
  Login = 'Login',
  StartEvent = 'StartEvent',
  StopEvent = 'StopEvent',
  JoinRoom = 'JoinRoom',
  LeaveRoom = 'LeaveRoom',
  EmbedURL = 'EmbedURL',
  InviteUserToStage = 'InviteUserToStage',
  StartUserCamera = 'StartUserCamera',
  StopUserCamera = 'StopUserCamera',
  SetUserMuteCamera = 'SetUserMuteCamera',
  SetUserMuteMicrophone = 'SetUserMuteMicrophone',
  SetUserVIP = 'SetUserVIP',
  SetUserStage = 'SetUserStage',
  UserBlockChannel = 'UserBlockChannel',
  UserRemoveEvent = 'UserRemoveEvent',
  RaiseHand = 'RaiseHand',
  UserSessions = 'UserSessions',
  MockUsers = 'MockUsers',
  SimulcastStartRTMP = 'SimulcastStartRTMP',
  SimulcastStatus = 'SimulcastStatus',
  SetTopic = 'SetTopic',
  StartPresentation = 'StartPresentation',
  StopPresentation = 'StopPresentation',
}

/**
 * socketio server to client messages.
 */
export enum ServerToClientMessageTypes {
  LiveEventData = 'LiveEventData',
  LiveEventAdmin = 'LiveEventAdmin',
  InviteUserToStage = 'InviteUserToStage',
  StopUserCamera = 'StopUserCamera',
  UserJoin = 'UserJoin',
  UserLeft = 'UserLeft',
  UserBlockChannel = 'UserBlockChannel',
  UserRemoveEvent = 'UserRemoveEvent',
  SimulcastStartTransmitting = 'SimulcastStartTransmitting',
  SimulcastStop = 'SimulcastStop',
  SimulcastInstanceCreate = 'SimulcastInstanceCreate',
  DuplicatedConnection = 'DuplicatedConnection'
}
