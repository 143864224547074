










































































import { VideoClass } from '../../../interfaces';
import { Component, Vue, Ref } from 'vue-property-decorator';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import { Events } from '@/components/eventbus/events';
import EventBus from '@/components/eventbus/EventBus.vue';
import { graphQLErrorAlert } from '@/util/GraphQL';
import { Id } from '@/interfaces/base';
import { empty } from './data';
import { UserModule } from '@/store/user';
import { Roles } from '@common/types/Roles';
import AutocompletePointer from '@/components/crud/Base/AutocompletePointer.vue';

import { S3Client, PutObjectCommand } from '@aws-sdk/client-s3';

if (
  !process.env.VUE_APP_S3_REGION ||
  !process.env.VUE_APP_S3_ACCESS_KEY_ID ||
  !process.env.VUE_APP_S3_SECRET_ACCESS_KEY ||
  !process.env.VUE_APP_S3_BUCKET
) {
  throw new Error('S3 envs are not setup correctly');
}

const s3Client = new S3Client({
  region: process.env.VUE_APP_S3_REGION,
  credentials: {
    accessKeyId: process.env.VUE_APP_S3_ACCESS_KEY_ID,
    secretAccessKey: process.env.VUE_APP_S3_SECRET_ACCESS_KEY
  }
});

@Component({
  components: { AutocompletePointer, ValidationObserver, ValidationProvider }
})
export default class VideoEdit extends Vue {
  @Ref() readonly observer!: InstanceType<typeof ValidationProvider>;

  readonly fanfestConfigQuery = require('../FanfestConfig/autocompleteFanfestConfigs.graphql');

  video: VideoClass = new VideoClass({ ...empty, isActive: true });

  videoFile: File | null = null;

  loading = false;

  async mounted(): Promise<void> {
    if (this.$route?.params.id) {
      this.load(this.$route.params.id);
    }
    if (UserModule.user.role !== Roles.Admin) {
      this.video.channelPointer.objectId =
        UserModule.user.worksChannelPointer?.objectId || '';
    }
  }

  get channelEditable(): boolean {
    return UserModule.user.role === Roles.Admin && !this.video.objectId;
  }

  async load(id: Id): Promise<void> {
    try {
      this.loading = true;
      const result = await this.$apollo.query({
        // Query
        query: require('./getVideo.graphql'),
        // Parameters
        variables: {
          id: id
        }
      });
      this.$set(this, 'video', result.data.video);
    } catch (e) {
      graphQLErrorAlert(e);
    } finally {
      this.loading = false;
    }
  }

  async save(): Promise<void> {
    this.observer.validate();
    const videoData: { [k: string]: any } = {
      name: this.video.name,
      channelPointer: {
        link: this.video.channelPointer?.objectId
      },
      isActive: this.video.isActive
    };

    // let cleanFilenameArray = this.videoFile.name
    //   .split('')
    //   .filter((char) => /[a-zA-Z0-9_.]/.test(char))
    //   .join('');
    // if (cleanFilenameArray.length > 50) {
    //   cleanFilenameArray = cleanFilenameArray.slice(
    //     cleanFilenameArray.length - 50
    //   );
    // }
    // const newFile = new File([this.videoFile], cleanFilenameArray);
    // videoData.video = {
    //   upload: newFile
    // };

    try {
      this.loading = true;
      if (this.video.id) {
        await this.$apollo.mutate({
          // Query
          mutation: require('./updateVideo.graphql'),
          // Parameters
          variables: {
            id: this.video.id,
            video: videoData
          }
        });
      } else {
        if (!this.videoFile) {
          return;
        }
        const response = await this.$apollo.mutate({
          mutation: require('./createVideo.graphql'),
          // Parameters
          variables: {
            video: videoData
          }
        });

        const videoObjectId = response.data.createVideo.video
          .objectId as string;

        const bucket = process.env.VUE_APP_S3_BUCKET || '';
        const key =
          videoObjectId +
          this.videoFile.name.substring(this.videoFile.name.lastIndexOf('.'));

        try {
          const command = new PutObjectCommand({
            Bucket: bucket,
            Key: key,
            Body: this.videoFile
            /** input parameters */
          });

          const data = await s3Client.send(command);
          console.debug('Upload success', data);

          // process data.
        } catch (error) {
          console.error('Error uploading recording to s3');
          console.error(error);
          throw error;
        }
      }

      EventBus.$emit(Events.AlertSuccess, this.$t('video.savedAndProcessing'));
      this.$router.push({ path: '/dashboard/video' });
    } catch (error) {
      graphQLErrorAlert(error);
    } finally {
      this.loading = false;
    }
  }
}
