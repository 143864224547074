import { empty } from '@/components/crud/User/data';
import { UserClass, UserInterface } from '@/interfaces';
import { URLString } from '@/interfaces/base';
import { exists } from '@/util/objects';
import { Roles } from '@common/types/Roles';
import Vue from 'vue';
import {
  VuexModule,
  Module,
  Mutation,
  getModule
} from 'vuex-module-decorators';
import store from './index';

function shouldPreserveState() {
  const hasStoredState =
    localStorage.getItem('vuex') !== null &&
    'user' in JSON.parse(localStorage.getItem('vuex') || '');

  if (hasStoredState) {
    return true;
  }

  const url = new URLSearchParams(window.location.href);
  const guestPlusUsername = url.get('guest_username');

  return exists(guestPlusUsername);
}

@Module({
  store,
  namespaced: true,
  name: 'user',
  dynamic: true,
  preserveState: shouldPreserveState()
})
class UserVuex extends VuexModule {
  isFetching = false;
  user: UserClass = new UserClass({ ...empty });
  sessionToken = '';

  get isLogged(): boolean {
    return !!this.user.id;
  }

  get isGuestPlus(): boolean {
    return this.user.role === Roles.GuestPlus;
  }

  get myChannelURI(): URLString | undefined {
    return this.user.worksChannelPointer?.url;
  }

  @Mutation
  setUser(user: UserClass | UserInterface): void {
    Vue.set(this, 'user', user);
  }

  @Mutation
  setSessionToken(token: string): void {
    Vue.set(this, 'sessionToken', token);
  }

  @Mutation
  logOut() {
    Vue.set(this, 'user', new UserClass({ ...empty }));
    Vue.set(this, 'sessionToken', null);
  }
}

export const UserModule = getModule(UserVuex);
