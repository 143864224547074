import Vue from 'vue';
import { VSelect } from 'vuetify/lib';

Vue.component('FSelect', {
  extends: VSelect,
  props: {
    outlined: {
      type: Boolean,
      default: true
    }
  }
});
