






import { MetaInfo } from 'vue-meta';
import { Component, Vue } from 'vue-property-decorator';
import { gql } from '@apollo/client/core';
import { graphQLErrorAlert } from '@/util/GraphQL';
import EventBus from '@/components/eventbus/EventBus.vue';
import { Events } from '@/components/eventbus/events';

@Component({})
export default class RestreamCallback extends Vue {
  metaInfo(): MetaInfo {
    const title = 'Restream';
    return {
      title: title
    };
  }

  mounted() {
    // https://localhost:8080/restream/callback?code=cb5243d4b28d5f8421168914042dc3632a5274ea&scope=profile.default.read,channels.default.read,chat.default.read,chat.write,channels.write,stream.default.read&state=asdfasdf
    this.handleAuth();
  }

  async handleAuth(): Promise<void> {
    try {
      const params = new URLSearchParams(document.location.search);
      const mutationParams = {
        code: params.get('code'),
        state: params.get('state'),
        scope: params.get('scope')
      };

      const result = await this.$apollo.mutate({
        mutation: gql`
          mutation restreamCallback(
            $code: String!
            $scope: String!
            $state: String!
          ) {
            restreamCallback(code: $code, scope: $scope, state: $state) {
              status
              message
              accessToken
              accessTokenExpiresAt
            }
          }
        `,
        variables: mutationParams
      });
      if (!result.data.restreamCallback.status) {
        EventBus.$emit(
          Events.AlertError,
          'Failed: ' + result.data.restreamCallback.message
        );
      } else {
        window.opener.postMessage(
          {
            accessToken: result.data.restreamCallback.accessToken,
            accessTokenExpiresAt:
              result.data.restreamCallback.accessTokenExpiresAt
          },
          window.location.origin
        );
        window.close();
      }
    } catch (e) {
      graphQLErrorAlert(e);
      console.error(e);
    }
  }
}
