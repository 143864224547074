var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('validation-provider',{attrs:{"name":_vm.$t('address.address'),"rules":"required|max:200"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('f-text-field',{staticClass:"required",attrs:{"counter":200,"error-messages":errors,"label":_vm.$t('address.address'),"required":""},model:{value:(_vm.address.address),callback:function ($$v) {_vm.$set(_vm.address, "address", $$v)},expression:"address.address"}})]}}])}),_c('validation-provider',{attrs:{"name":_vm.$t('address.address2'),"rules":"max:200"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('f-text-field',{attrs:{"counter":200,"error-messages":errors,"label":_vm.$t('address.address2')},model:{value:(_vm.address.address2),callback:function ($$v) {_vm.$set(_vm.address, "address2", $$v)},expression:"address.address2"}})]}}])}),_c('validation-provider',{attrs:{"name":_vm.$t('address.city'),"rules":"required|max:200"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('f-text-field',{staticClass:"required",attrs:{"counter":200,"error-messages":errors,"label":_vm.$t('address.city'),"required":""},model:{value:(_vm.address.city),callback:function ($$v) {_vm.$set(_vm.address, "city", $$v)},expression:"address.city"}})]}}])}),_c('validation-provider',{attrs:{"name":_vm.$t('address.state'),"rules":"required|max:200"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('f-text-field',{staticClass:"required",attrs:{"counter":200,"error-messages":errors,"label":_vm.$t('address.state'),"required":""},model:{value:(_vm.address.state),callback:function ($$v) {_vm.$set(_vm.address, "state", $$v)},expression:"address.state"}})]}}])}),_c('validation-provider',{attrs:{"name":_vm.$t('address.zip'),"rules":"required|max:200"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('f-text-field',{staticClass:"required",attrs:{"counter":200,"error-messages":errors,"label":_vm.$t('address.zip'),"required":""},model:{value:(_vm.address.zip),callback:function ($$v) {_vm.$set(_vm.address, "zip", $$v)},expression:"address.zip"}})]}}])}),_c('validation-provider',{attrs:{"name":_vm.$t('address.country'),"rules":"required|max:200"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('f-text-field',{staticClass:"required",attrs:{"counter":200,"error-messages":errors,"label":_vm.$t('address.country'),"required":""},model:{value:(_vm.address.country),callback:function ($$v) {_vm.$set(_vm.address, "country", $$v)},expression:"address.country"}})]}}])}),_c('validation-provider',{attrs:{"name":_vm.$t('address.sendFromEmail'),"rules":"required|email|max:200"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('f-text-field',{staticClass:"required",attrs:{"counter":200,"error-messages":errors,"label":_vm.$t('address.sendFromEmail'),"required":""},model:{value:(_vm.address.sendFromEmail),callback:function ($$v) {_vm.$set(_vm.address, "sendFromEmail", $$v)},expression:"address.sendFromEmail"}})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }