import { DateTime } from 'luxon';
import { FanfestEvent, FileInfo, Maybe, Scalars } from '@/generated/graphql';
import { BaseRoute } from '@/router/routeNames';
import { RawLocation } from 'vue-router';
import { LanguageCode } from '@common/types/LanguageEnum';
import slugify from 'slugify';
import { FanfestConfigClass, UserClass } from '.';
import { Roles } from '@common/types/Roles';

/**
 * Patch the frontend types that we don't have on GraphQL and remove
 * stuff we don't need
 */
export type FanfestEventInterface = Omit<
  FanfestEvent,
  'language' | 'ACL' | 'channelPointer' | 'startDatetime' | 'bannedRelation'
> & {
  language?: LanguageCode;
  channelPointer: FanfestConfigClass;
  startDatetime: DateTime;
};

export enum FanfestEventMode {
  Live = 'Live',
  Future = 'Future',
  Replay = 'Replay',
  ReplayUnavailable = 'ReplayUnavailable'
}

export class FanfestEventClass implements FanfestEventInterface {
  __typename?: 'FanfestEvent' | undefined;
  objectId = '';
  bannedRelation: any;
  channelPointer: FanfestConfigClass;
  cost?: Maybe<Scalars['Float']>;
  createdAt: Scalars['Date'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'] = '';
  isActive: Scalars['Boolean'] = true;
  language?: LanguageCode;
  startDatetime: DateTime;
  transmitted: Scalars['Boolean'] = false;
  started: Scalars['Boolean'] = false;
  title: Scalars['String'] = '';
  updatedAt: Scalars['Date'];
  eventBackground?: Maybe<FileInfo> | undefined;
  recording?: Scalars['String'];
  hostUrl?: Scalars['String'];
  shouldSendNotifications: Scalars['Boolean'] = false;

  constructor(data: FanfestEvent | FanfestEventInterface | FanfestEventClass) {
    Object.assign(this, data);
    this.channelPointer = new FanfestConfigClass(data.channelPointer);
    if (typeof data.startDatetime === 'string') {
      this.startDatetime = DateTime.fromISO(data.startDatetime as any);
    } else {
      this.startDatetime = data.startDatetime;
    }
    this.shouldSendNotifications ??= false;
  }

  get urlWithDomain(): string {
    return (
      window.location.protocol +
      '//' +
      window.location.host +
      `/${this.channelPointer.name}/${this.objectId}-` +
      slugify(this.title)
    );
  }

  get url(): string {
    return (
      `/${this.channelPointer.name}/${this.objectId}-` + slugify(this.title)
    );
  }

  get simulfestUrl(): string {
    return (
      `/${this.channelPointer.name}/simulfest/${this.objectId}-` +
      slugify(this.title)
    );
  }

  get rawLocation(): RawLocation {
    return {
      name: BaseRoute.Event,
      params: {
        id: this.id,
        title: this.title
      }
    };
  }

  get mode(): FanfestEventMode {
    if (this.started) {
      if (this.transmitted) {
        if (this.recording) {
          return FanfestEventMode.Replay;
        }

        return FanfestEventMode.ReplayUnavailable;
      }
      return FanfestEventMode.Live;
    }
    return FanfestEventMode.Future;
  }

  canProduce(user: UserClass | undefined): boolean {
    if (!user) {
      return false;
    } else if (user.role === Roles.Admin) {
      return true;
    } else if (
      user.role === Roles.ChannelAdmin &&
      user.worksChannelPointer?.objectId === this.channelPointer.objectId
    ) {
      return true;
    } else if (
      user.role === Roles.Producer &&
      user.worksChannelPointer?.objectId === this.channelPointer.objectId
    ) {
      return true;
    }
    return false;
  }

  trackData(): Record<string, string> {
    return {
      Show: this.title,
      Channel: this.channelPointer.name
    };
  }
}

/**
 * Converts data that comes from GraphQL to the expected values/types.
 *
 * @param configData GraphQL data
 * @returns A properly built interface
 */
export function FanfestEventGraphQLTranslator(
  eventData: FanfestEvent
): FanfestEventClass {
  const event = new FanfestEventClass({ ...eventData });

  return event;
}
