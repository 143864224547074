































import { Component, Vue } from 'vue-property-decorator';
import { UserModule } from '@/store/user';
import EventBus from '../eventbus/EventBus.vue';
import { Events } from '../eventbus/events';

@Component({
  components: {}
})
export default class UserDropdown extends Vue {
  get userName (): string {
    return UserModule.user.name;
  }

  get userEmail (): string {
    return UserModule.user.email;
  }

  onLogout (): void {
    EventBus.$emit(Events.LoggedOut, { path: '/' });
  }
}
