import { Roles } from '@common/types/Roles';
import { UserInterface } from '@/interfaces';

const empty: UserInterface = {
  id: '',
  objectId: '',

  name: '',
  username: '',
  email: '',
  role: Roles.Guest,
  language: '',
  timezone: '',
  phone: '',
  smsNotifications: false,
  whatsappNotifications: false,
  emailNotifications: false,

  updatedAt: '',
  createdAt: ''
};

const test: UserInterface = {
  id: '000000',
  objectId: '000000',
  name: '',
  username: 'test@test.com',
  email: 'test@test.com',
  role: Roles.Fan,
  language: 'en',
  timezone: '',
  phone: '',
  smsNotifications: false,
  whatsappNotifications: false,
  emailNotifications: false,

  updatedAt: '2021-12-06T14:04:35.893Z',
  createdAt: '2021-12-06T14:04:35.893Z'
};

export { empty, test };
