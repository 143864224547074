













import { FanfestEventClass } from '@/interfaces';
import { Component, Vue, Prop, Ref, Watch } from 'vue-property-decorator';
import { LiveEventPresentation, MuteOrigin } from '@common/types/LiveEvent';
import ContentAspectRatio from '../ContentAspectRatio.vue';
import {
  phenixData,
  PhenixRealTimeStream,
  PhenixStreamMap,
  PhenixSubscribeToMemberStreamResponse
} from '@/util/phenix';
import VideoButtonControls from '../VideoButtonControls.vue';
import { getTestStreamSrc } from '@/components/event/data';
import { UserModule } from '@/store/user';

@Component({ components: { ContentAspectRatio, VideoButtonControls } })
export default class PresentationStream extends Vue {
  @Ref('videoElement') readonly videoElement!: InstanceType<
    typeof HTMLVideoElement
  >;

  @Prop({})
  readonly event!: FanfestEventClass;

  @Prop({})
  readonly liveStream!: LiveEventPresentation;

  @Prop({})
  readonly phenixEntry!: PhenixStreamMap | null;

  @Prop({ default: '16/9' })
  readonly aspectRatio!: string;

  streamLoaded = false;
  phenixRealTimeStream: PhenixRealTimeStream | null = null;
  forceMuted = true;
  wantsAudio = true;
  videoAspectRatio = 16.0 / 9.0;

  MuteOrigin = Object.freeze(MuteOrigin);

  mounted() {
    console.warn(
      'mounting member stream with stream data',
      this.liveStream,
      this.phenixEntry
    );
    this.playStream();
  }

  get streamSessionCombined(): string {
    return this.phenixEntry?.sessionId + '';
  }

  @Watch('streamSessionCombined')
  onChangeStream() {
    this.playStream();
  }

  beforeDestroy() {
    this.videoElement.srcObject = null;
  }

  playStream(): void {
    this.forceMuted = true;
    if (this.streamLoaded) {
      console.warn('stream already loaded');
      this.videoElement.src = '';
      this.videoElement.srcObject = null;
      this.streamLoaded = false;
      this.wantsAudio = true;
    }

    if (this.liveStream.user.objectId.startsWith('xyz')) {
      // we're mocking for tests.
      this.streamLoaded = true;
      this.videoElement.src = getTestStreamSrc();
      this.videoElement.addEventListener(
        'loadedmetadata',
        () => {
          this.videoAspectRatio =
            this.videoElement.videoWidth / this.videoElement.videoHeight;
        },
        { once: true }
      );

      this.manualUnmute();
      return;
    }

    // make sure this member exists
    const member = phenixData.members.find(
      (member) => member.getSessionId() === this.phenixEntry?.sessionId
    );
    if (!member) {
      console.error('could not find member', phenixData);
      return;
    }

    // if we have a local stream, use it
    if (
      this.liveStream.user.name === UserModule.user.name &&
      phenixData.selfPresentationObject
    ) {
      // Make sure we don't use the audio stream to prevent audio loop.
      const mediaStreamClone = phenixData.selfPresentationObject.clone();
      for (const track of mediaStreamClone.getAudioTracks()) {
        mediaStreamClone.removeTrack(track);
      }

      this.videoElement.srcObject = mediaStreamClone;
      this.streamLoaded = true;
      // we never want local streams to have audio.
      this.wantsAudio = false;
      return;
    }

    // otherwise load the remote stream
    const memberStream = member.getObservableStreams().getValue()[0];
    const options = {
      monitor: {
        monitorFrameRate: false,
        monitorBitRate: false,
        frameRateThreshold: 1,
        conditionCountForNotificationThreshold: 30,
        callback: () => {
          // TODO
        }
      }
    };
    phenixData.roomExpress?.subscribeToMemberStream(
      memberStream,
      options,
      (error: any, response: PhenixSubscribeToMemberStreamResponse) => {
        if (error) {
          // TODO: hide self
          console.error('Could not open stream: ' + error);
          return;
        }

        if (response.status !== 'ok') {
          // TODO: hide self
          // Handle error
          console.error('Could not open stream.');
          return;
        }

        if (response.status === 'ok' && response.mediaStream) {
          response.mediaStream.setStreamEndedCallback(
            (mediaStream, reason, reasonDescription) => {
              // Called when the stream has ended
              console.error(
                'mediaStream ended',
                mediaStream,
                reason,
                reasonDescription
              );
            }
          );
          response.mediaStream.setStreamErrorCallback((...s) => {
            // Called when the stream has ended
            console.error('mediaStream error', arguments, JSON.stringify(s));
          });
          (response.mediaStream as any).monitor({}, () => {
            console.error('monitor', arguments);
          });

          // start as muted
          this.videoElement.pause();
          const stream = response.mediaStream.getStream();
          this.videoElement.srcObject = stream;
          this.videoAspectRatio =
            stream.getVideoTracks()[0].getSettings().aspectRatio || 16.0 / 9.0;

          // try playing the video and handle if there was no previous interaction
          setTimeout(async (): Promise<void> => {
            this.manualUnmute();
          }, 1000);
        }
      }
    );
  }

  async manualUnmute(): Promise<void> {
    this.forceMuted = false;
    this.$nextTick(async () => {
      try {
        await this.videoElement.play();
      } catch (e) {
        this.forceMuted = true;
        this.$nextTick(async () => {
          try {
            await this.videoElement.play();
          } catch {}
        });
      }
      this.streamLoaded = true;

      this.videoElement?.addEventListener('pause', async () => {
        await this.videoElement.play().catch(() => {
          console.warn('streamer video likely frozen');
        });
      });
    });
  }
}
