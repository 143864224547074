

































































import { FanfestEventClass, OfferClass } from '@/interfaces';
import { Component, Prop, Ref, Mixins, Vue } from 'vue-property-decorator';
import { LiveEventDataClass } from '@common/types/LiveEvent';
import ContentAspectRatio from './ContentAspectRatio.vue';
import MemberStream from './MemberStream.vue';
import OfferTopicAreaMixin from './OfferTopicAreaMixin.vue';
import StageAreaStreams from './StageAreaStreams.vue';
import ChatFloatingNotifications from '@/components/chat/ChatFloatingNotifications.vue';
import { PhenixStreamMap } from '@/util/phenix';
import { ChatMessageData } from '../chat/chat';
import OfferShow from '@/components/crud/Offer/OfferShow.vue';
import { BreakpointName } from 'vuetify/types/services/breakpoint';

@Component({
  components: {
    ContentAspectRatio,
    MemberStream,
    ChatFloatingNotifications,
    OfferShow,
    StageAreaStreams
  }
})
export default class StageArea extends Mixins(OfferTopicAreaMixin) {
  @Ref('stageareaContainer') readonly stageContainerElement!: InstanceType<
    typeof HTMLDivElement
  >;

  @Ref('embedContainer') readonly embedContainerElement!: InstanceType<
    typeof HTMLDivElement
  >;

  @Ref('stageAreaStreamsContainer')
  readonly stageAreaStreamsContainerElement!: InstanceType<typeof Vue>;

  @Prop({})
  readonly event!: FanfestEventClass;

  @Prop({})
  readonly liveEventData!: LiveEventDataClass;

  @Prop({})
  readonly phenixStreamMap!: PhenixStreamMap[];

  @Prop({})
  readonly offers!: OfferClass[];

  @Prop({ default: () => [] })
  readonly messages!: ChatMessageData[];

  @Prop({})
  readonly isMobileBrowser!: boolean;

  @Prop({})
  readonly showStreamLabels!: boolean;

  @Prop({})
  readonly producerFeatures!: boolean;

  internalBreakpoint: BreakpointName = 'sm';
  stageareaIsPortrait = false;
  streamsIsPortrait = false;
  embedIsPortrait = false;
  observer: ResizeObserver | null = null;
  showFullWidthBottomOffers = false;
  showHalfWidthOffers = false;
  visibleStreams = 0;

  mounted() {
    this.observer = new ResizeObserver(this.onResize);
    this.observer.observe(this.stageContainerElement);
    // this.observer.observe(this.embedContainerElement);
    // this.observer.observe(this.stageAreaStreamsContainerElement.$el);
  }

  beforeDestroy() {
    this.observer?.disconnect();
  }

  get isEmbedding() {
    return (
      !!this.liveEventData.embedURL ||
      !!this.liveEventData.presentation ||
      this.liveEventData.streams.some(
        (stream) => stream.stream.isVIP && stream.stream.isOnStage
      )
    );
  }

  // TODO: Remove this since we always want offers full width now
  computeShowFullWidthBottomOffers(): boolean {
    if (this.stageContainerElement) {
      if (
        !this.stageareaIsPortrait &&
        this.isEmbedding &&
        this.stageContainerElement.clientWidth < 768
      ) {
        return false;
      }
    }
    return this.showOffers && !!this.currentOffer;
  }

  // TODO: Remove this since we always want offers full width now
  computeShowHalfWidthOffers(): boolean {
    if (
      this.stageContainerElement &&
      !this.stageareaIsPortrait &&
      this.isEmbedding &&
      this.stageContainerElement.clientWidth < 768
    ) {
      return this.showOffers && !!this.currentOffer;
    }
    return false;
  }

  onResize(): void {
    const innerWidth = this.stageContainerElement.clientWidth;
    const innerHeight = this.stageContainerElement.clientHeight;
    if (innerWidth < 600) {
      this.internalBreakpoint = 'xs';
    } else if (innerWidth < 960) {
      this.internalBreakpoint = 'sm';
    } else if (innerWidth < 1264) {
      this.internalBreakpoint = 'md';
    } else if (innerWidth < 1904) {
      this.internalBreakpoint = 'lg';
    } else {
      this.internalBreakpoint = 'xl';
    }
    this.stageareaIsPortrait = innerWidth < innerHeight;
    this.embedIsPortrait =
      this.embedContainerElement.clientWidth <
      this.embedContainerElement.clientHeight;
    this.streamsIsPortrait =
      this.stageAreaStreamsContainerElement.$el.clientWidth <
      this.stageAreaStreamsContainerElement.$el.clientHeight;
    this.showHalfWidthOffers = false;
    this.showFullWidthBottomOffers = !this.showHalfWidthOffers;
  }

  onVisibleStreams(total: number): void {
    this.visibleStreams = total;
  }
}
