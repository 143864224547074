

















































































































import { MetaInfo } from 'vue-meta';
import { Component, Vue } from 'vue-property-decorator';

@Component({})
export default class CookiesPolicy extends Vue {
  metaInfo(): MetaInfo {
    const title = 'Cookies Policy';
    return {
      title: title
    };
  }
}
