import { UserModule } from '@/store/user';
import { Roles } from '@common/types/Roles';
import { ClientToServerMessageTypes } from '@common/types/SocketProtocol';
import socket from './socket';

export function socketLogin (forceClear: boolean): Promise<void> {
  return new Promise((resolve, reject) => {
    socket.emit(
      ClientToServerMessageTypes.Login,
      UserModule.user.role === Roles.GuestPlus ? UserModule.user.objectId : UserModule.sessionToken,
      forceClear,
      (result: boolean): void => {
        if (result) {
          resolve();
        } else {
          reject(new Error('Error refreshing socket'));
        }
      }
    );
  });
}
