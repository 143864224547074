var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"fanfestEvent-list__container"},[_c('h1',{staticClass:"text-h1 font-weight-light mb-12"},[_vm._v(" "+_vm._s(_vm.$t('fanfestEvent.objectNamePlural'))+" ")]),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.fanfestEvents,"loading":_vm.loading,"items-per-page":_vm.perPage,"server-items-length":_vm.totalItems,"footer-props":{
      'items-per-page-options': [20, 50]
    }},on:{"update:itemsPerPage":function($event){_vm.perPage=$event},"update:items-per-page":[function($event){_vm.perPage=$event},_vm.updateItemsPerPage],"update:page":_vm.updatePage,"update:sort-by":_vm.updateSortByAsc,"update:sort-desc":_vm.updateSortByDesc},scopedSlots:_vm._u([{key:"item.title",fn:function(ref){
    var item = ref.item;
return [_c('span',{staticClass:"title__container",staticStyle:{"display":"flex","flex-direction":"row"}},[_vm._v(_vm._s(item.title)+" "),(!item.transmitted)?_c('v-btn',{staticClass:"title__button",attrs:{"to":item.url,"small":"","block":"","color":"primary"}},[_vm._v(_vm._s(_vm.$t('fanfestEvent.join'))+" ")]):_vm._e()],1)]}},{key:"item.status",fn:function(ref){
    var item = ref.item;
return [_c('v-chip',{attrs:{"small":"","outlined":"","to":item.recording ? ("/dashboard/fanfestEvent/" + (item.id) + "/view") : null,"color":item.started && !item.transmitted
            ? 'red'
            : item.transmitted
            ? item.recording
              ? 'primary'
              : 'grey'
            : 'success'}},[_vm._v(_vm._s(item.started && !item.transmitted ? _vm.$t('fanfestEvent.live') : item.transmitted ? item.recording ? _vm.$t('fanfestEvent.replayAvailable') : _vm.$t('fanfestEvent.replayUnavailable') : _vm.$t('fanfestEvent.upcoming')))])]}},{key:"item.language",fn:function(ref){
            var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.languageName(item.language)))])]}},{key:"item.startDatetime",fn:function(ref){
            var item = ref.item;
return [_c('date-human',{attrs:{"date":item.startDatetime}})]}},{key:"item.channelPointer",fn:function(ref){
            var item = ref.item;
return [_c('router-link',{attrs:{"to":item.channelPointer.url}},[_vm._v(" "+_vm._s(item.channelPointer.name)+" ")])]}},{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":_vm.$t('form.search'),"single-line":"","hide-details":"","clearable":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-spacer'),_c('v-btn',{staticClass:"mb-2",attrs:{"color":"primary","dark":"","to":"/dashboard/fanfestEvent/edit"}},[_vm._v(" "+_vm._s(_vm.$t('form.new'))+" "+_vm._s(_vm.$t('fanfestEvent.objectName'))+" "),_c('v-icon',{staticClass:"ml-1"},[_vm._v("add_box")])],1)],1)]},proxy:true},{key:"item.actions",fn:function(ref){
            var item = ref.item;
return [_c('router-link',{attrs:{"to":("/dashboard/fanfestEvent/" + (item.id) + "/edit"),"title":_vm.$tc('form.edit')}},[_c('v-icon',{staticClass:"mr-2",attrs:{"small":""}},[_vm._v(" edit ")])],1),_c('router-link',{attrs:{"to":("/dashboard/fanfestEvent/edit?clone=" + (item.objectId)),"title":_vm.$tc('fanfestEvent.clone')}},[_c('v-icon',{staticClass:"mr-2",attrs:{"small":""}},[_vm._v(" content_copy")])],1),_c('v-icon',{staticClass:"mr-2",attrs:{"small":"","title":_vm.$tc('share.copy')},on:{"click":function($event){return _vm.shareItem(item)}}},[_vm._v("link")]),_c('a',{attrs:{"href":'https://www.facebook.com/sharer/sharer.php?u=' + _vm.getItemUrl(item),"target":"_blank","title":_vm.$tc('share.copy')}},[_c('v-icon',{staticClass:"mr-2",attrs:{"small":""}},[_vm._v(" facebook ")])],1),_c('twitter-share-anchor',{attrs:{"event":item}}),_c('v-icon',{attrs:{"small":"","title":_vm.$tc('form.delete')},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v(" delete ")])]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }