





















import { Component, Vue, Prop } from 'vue-property-decorator';
import { FanfestEventClass } from '@/interfaces';
import Logo from '@/components/util/Logo.vue';
import OfferShow from '@/components/crud/Offer/OfferShow.vue';
import ButtonsBottomBar from '@/components/event/ButtonsBottomBar.vue';
import { Events } from '../eventbus/events';
import { bottomBarHeight } from './layout';
import {
  LiveEventDataClass,
  LiveEventStreamInterface
} from '@common/types/LiveEvent';

@Component({ components: { Logo, OfferShow, ButtonsBottomBar } })
export default class BottomBar extends Vue {
  @Prop({})
  readonly event!: FanfestEventClass;

  @Prop({})
  readonly liveEventData!: LiveEventDataClass;

  @Prop({})
  readonly selfLiveStream!: LiveEventStreamInterface | undefined;

  @Prop({ default: true })
  readonly showButtonBar!: boolean;

  @Prop({})
  readonly chatDrawer!: boolean;

  @Prop({})
  readonly canProduce!: boolean;

  @Prop({})
  readonly isChatEnabled!: boolean;

  get isPortrait() {
    return this.$vuetify.breakpoint.width < this.$vuetify.breakpoint.height;
  }

  get height(): number {
    return bottomBarHeight(this.$vuetify.breakpoint.name, this.isPortrait);
  }

  onCloseCamera(): void {
    this.$emit(Events.CloseCamera);
  }

  OpenChat(): void {
    this.$emit(Events.OpenChat);
  }

  OpenLogin(): void {
    this.$emit(Events.OpenLogin);
  }

  onChatRaiseHand(isRaisedHand: boolean): void {
    this.$emit(Events.ChatRaiseHand, isRaisedHand);
  }

  onTogglePresentation(isPresenting: boolean): void {
    this.$emit(Events.TogglePresentation, isPresenting);
  }
}
