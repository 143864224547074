







import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({
  components: {}
})
export default class LargeIconButton extends Vue {
  @Prop({})
  readonly text!: string;

  @Prop({})
  readonly icon!: string;
}
