































































import { OfferClass, OfferGraphQLTranslator } from '../../../interfaces';
import { Component, Vue, Watch } from 'vue-property-decorator';
import { OfferOrder } from '@/generated/graphql';
import DateISO from '@/components/base/DateISO.vue';
import BooleanChip from '@/components/base/BooleanChip.vue';
import EventBus from '@/components/eventbus/EventBus.vue';
import { Events } from '@/components/eventbus/events';
import { graphQLErrorAlert } from '@/util/GraphQL';
import { UserModule } from '@/store/user';
import { Roles } from '@common/types/Roles';
import { debounce } from 'typescript-debounce-decorator';

@Component({
  components: { DateISO, BooleanChip }
})
export default class OfferList extends Vue {
  private loading = false;

  private initialItem = 0;

  private perPage = 20;

  private totalItems = -1;

  headers = [
    {
      text: this.$t('offer.title'),
      align: 'start',
      sortable: false,
      value: 'title'
    },
    { text: this.$t('offer.isActive'), value: 'isActive' },
    { text: this.$t('offer.url'), value: 'url' },
    ...(UserModule.user.role === Roles.Admin
      ? [{ text: this.$t('offer.channel'), value: 'channelPointer' }]
      : []),
    { text: this.$t('form.createdAt'), value: 'createdAt' },
    { text: this.$t('form.actions'), value: 'actions', sortable: false }
  ];

  offers = [];

  order: OfferOrder = OfferOrder.CreatedAtAsc;

  search = '';

  @Watch('$route')
  onRouteChanged() {
    this.fetch();
  }

  @Watch('search')
  @debounce(250)
  onSearchChanged() {
    this.fetch();
  }

  async mounted(): Promise<void> {
    await this.fetch();
  }

  async updateItemsPerPage(perPage: number): Promise<void> {
    this.perPage = perPage;
    await this.fetch();
  }

  async updatePage(page: number): Promise<void> {
    this.initialItem = (page - 1) * this.perPage;
    await this.fetch();
  }

  async fetch(): Promise<void> {
    this.loading = true;
    try {
      let queryData;
      if (UserModule.user.role === Roles.Admin) {
        queryData = {
          query: require('./getOffers.graphql'),
          variables: {
            order: [this.order],
            skip: this.initialItem,
            first: this.perPage,
            name: this.search || ''
          }
        };
      } else {
        queryData = {
          query: require('./getOffersByChannel.graphql'),
          variables: {
            order: [this.order],
            skip: this.initialItem,
            first: this.perPage,
            channelName: UserModule.user.worksChannelPointer?.name
          }
        };
      }
      const result = await this.$apollo.query(queryData);
      this.$set(
        this,
        'offers',
        result.data.offers.edges.map((i: any) => OfferGraphQLTranslator(i.node))
      );
      this.totalItems = result.data.offers.count;
    } catch (error) {
      graphQLErrorAlert(error);
    } finally {
      this.loading = false;
    }
  }

  async deleteItem(item: OfferClass): Promise<void> {
    if (!confirm(this.$t('form.reallyDelete') as string)) {
      return;
    }
    try {
      await this.$apollo.mutate({
        mutation: require('./deleteOffer.graphql'),
        variables: {
          id: item.id
        }
      });
      EventBus.$emit(Events.AlertSuccess, this.$t('form.deleted'));
      await this.fetch();
    } catch (error: any) {
      graphQLErrorAlert(error);
    }
  }
}
