






















































































































import { FanfestEventClass, FanfestEventGraphQLTranslator } from '@/interfaces';
import { Component, Vue, Prop } from 'vue-property-decorator';
import Logo from '@/components/util/Logo.vue';
import { UserModule } from '@/store/user';
import NotificationSettings from '@/components/notification/NotificationSettings.vue';
import { DateTime } from 'luxon';
import { Id } from '@/interfaces/base';
import LoginForm from '@/components/auth/LoginForm.vue';
import { Analytics, EventCategory, TrackEventEnum } from '@/services/tracking';

@Component({ components: { Logo, NotificationSettings, LoginForm } })
export default class RecentEventList extends Vue {
  @Prop({ required: true })
  readonly event!: FanfestEventClass;

  fetchTimeout: ReturnType<typeof setTimeout> | null = null;
  recording: string | null = null;
  hasTimedOut = false;

  notificationsMenu = false;
  loginDialog = false;

  // post login action callback
  postLoginAction: (() => void) | null = null;

  get isLogged() {
    return UserModule.isLogged;
  }

  get showNavIcon() {
    return true;
  }

  get humanizedEventDate() {
    return this.event.startDatetime.toLocaleString(DateTime.DATE_MED);
  }

  mounted() {
    const app = document.querySelector('#app');
    if (app) {
      app.classList.add('post-show-body');
      document.documentElement.classList.remove('full-page-height');
    }
    this.recording = this.event.recording ?? null;
    this.fetchEventRecording(this.$route.params?.id);
  }

  beforeDestroy() {
    const app = document.querySelector('#app');
    if (app) {
      app.classList.remove('post-show-body');
    }
    if (this.fetchTimeout) {
      clearTimeout(this.fetchTimeout);
    }
  }

  onLoggedIn(): void {
    this.loginDialog = false;
    if (this.postLoginAction) {
      this.postLoginAction();
      this.postLoginAction = null;
    }
  }

  onOpenLogin(postLoginAction: (() => void) | null = null): void {
    if (UserModule.isLogged && postLoginAction) {
      postLoginAction();
    } else {
      this.loginDialog = true;
      this.postLoginAction = postLoginAction;
    }
  }

  showNotificationsMenu(): void {
    this.onOpenLogin(() => {
      this.notificationsMenu = true;
    });
  }

  async fetchEventRecording(id: Id, retries = 0): Promise<void> {
    if (this.recording) {
      return;
    }

    try {
      const result = await this.$apollo.query({
        // Query
        query: require('../components/crud/FanfestEvent/getFanfestEvent.graphql'),
        // Parameters
        variables: {
          id: id
        }
      });
      const event = FanfestEventGraphQLTranslator(result.data.fanfestEvent);

      this.recording = event.recording ?? null;

      const maxRetries = 1000;

      if (!this.recording && retries < maxRetries) {
        this.fetchTimeout = setTimeout(() => {
          this.fetchTimeout = null;
          this.fetchEventRecording(id, retries + 1);
        }, 1000);
      } else if (retries >= maxRetries) {
        this.hasTimedOut = true;
      }

      Analytics.track(
        TrackEventEnum.ReplayViewed,
        EventCategory.Engagement,
        this.event.trackData()
      );
    } catch (e) {}
  }
}
