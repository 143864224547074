







































































































import { FanfestEventClass, FanfestEventGraphQLTranslator } from '@/interfaces';
import { Component, Vue, Watch } from 'vue-property-decorator';
import { FanfestEventOrder } from '@/generated/graphql';
import DateHuman from '@/components/base/DateHuman.vue';
import BooleanChip from '@/components/base/BooleanChip.vue';
import EventBus from '@/components/eventbus/EventBus.vue';
import { Events } from '@/components/eventbus/events';
import { graphQLErrorAlert } from '@/util/GraphQL';
import { UserModule } from '@/store/user';
import { Roles } from '@common/types/Roles';
import { debounce } from 'typescript-debounce-decorator';
import { LanguageEnum } from '@common/types/LanguageEnum';
import { getShareableEventLink } from '@/util/share';
import { writeToClipboard } from '@/util/navigator';

@Component({
  components: { DateHuman, BooleanChip }
})
export default class RecentEventList extends Vue {
  loading = false;

  initialItem = 0;

  perPage = 4;

  headers = [
    {
      text: this.$t('fanfestEvent.title'),
      align: 'start',
      sortable: false,
      value: 'title'
    },
    // { text: this.$t('fanfestEvent.status'), value: 'mode' },
    ...(UserModule.user.role === Roles.Admin
      ? [{ text: this.$t('offer.channel'), value: 'channelPointer' }]
      : []),
    { text: this.$t('fanfestEvent.status'), value: 'status' },
    { text: this.$t('fanfestEvent.startTime'), value: 'startDatetime' },
    { text: this.$t('form.actions'), value: 'actions', sortable: false }
  ];

  fanfestEvents: ReturnType<typeof FanfestEventGraphQLTranslator>[] = [];

  order: FanfestEventOrder = FanfestEventOrder.CreatedAtDesc;

  languageNames: any = null;

  search = '';

  @Watch('$route')
  onRouteChanged() {
    this.fetch();
  }

  @Watch('search')
  @debounce(250)
  onSearchChanged() {
    this.fetch();
  }

  async created() {
    this.languageNames = new (Intl as any).DisplayNames(
      [this.$i18n.locale, 'en'],
      {
        type: 'language'
      }
    );
  }

  async mounted(): Promise<void> {
    await this.fetch();
  }

  get channelLanguage() {
    return LanguageEnum.en;
  }

  languageName(language: string | null | undefined): string {
    if (!language) {
      return '';
    }
    return this.languageNames.of(language);
  }

  async updateItemsPerPage(perPage: number): Promise<void> {
    this.perPage = perPage;
    await this.fetch();
  }

  async updatePage(page: number): Promise<void> {
    this.initialItem = (page - 1) * this.perPage;
    await this.fetch();
  }

  async fetch(): Promise<void> {
    this.loading = true;
    try {
      let queryData;
      if (UserModule.user.role === Roles.Admin) {
        queryData = {
          query: require('./getFanfestEvents.graphql'),
          variables: {
            order: [this.order],
            skip: this.initialItem,
            first: this.perPage,
            name: this.search || ''
          }
        };
      } else {
        queryData = {
          query: require('./getFanfestEventsByChannel.graphql'),
          variables: {
            order: [this.order],
            skip: this.initialItem,
            first: this.perPage,
            name: '',
            channelName: UserModule.user.worksChannelPointer?.name
          }
        };
      }
      const result = await this.$apollo.query(queryData);
      const events: ReturnType<typeof FanfestEventGraphQLTranslator>[] =
        result.data.fanfestEvents.edges
          .slice(0, 4)
          .map((i: any) => FanfestEventGraphQLTranslator(i.node));

      this.$set(
        this,
        'fanfestEvents',
        events.filter((event) => (event.transmitted ? event.transmitted : true))
      );
    } catch (error) {
      graphQLErrorAlert(error);
    } finally {
      this.loading = false;
    }
  }

  async deleteItem(item: FanfestEventClass): Promise<void> {
    if (!confirm(this.$t('form.reallyDelete') as string)) {
      return;
    }
    try {
      await this.$apollo.mutate({
        mutation: require('./deleteFanfestEvent.graphql'),
        variables: {
          id: item.id
        }
      });
      EventBus.$emit(Events.AlertSuccess, this.$t('form.deleted'));
      await this.fetch();
    } catch (error: any) {
      graphQLErrorAlert(error);
    }
  }

  async shareItem(item: FanfestEventClass): Promise<void> {
    try {
      await writeToClipboard(await getShareableEventLink(item));

      EventBus.$emit(Events.AlertSuccess, this.$tc('share.clipboard'));
    } catch (error) {
      EventBus.$emit(Events.AlertError, this.$tc('share.shareError'));
    }
  }
}
