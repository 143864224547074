












































































































































































































































import { Component, Vue } from 'vue-property-decorator';
import {
  FanfestConfigClass,
  FanfestConfigGraphQLTranslator,
  FanfestEventClass,
  FanfestEventGraphQLTranslator,
  FanfestEventMode
} from '@/interfaces';
import { empty } from '@/components/crud/FanfestConfig/data';
import { MetaInfo } from 'vue-meta';
import FanfestEventCard from '@/components/crud/FanfestEvent/FanfestEventCard.vue';
import LoginForm from '@/components/auth/LoginForm.vue';
import { graphQLErrorAlert } from '@/util/GraphQL';
import EventBus from '@/components/eventbus/EventBus.vue';
import { Events } from '@/components/eventbus/events';
import Logo from '@/components/util/Logo.vue';
import { UserModule } from '@/store/user';
import { Roles } from '@common/types/Roles';
import NotificationSettings from '@/components/notification/NotificationSettings.vue';
import { Analytics } from '@/services/tracking';
import { ChannelError } from '@/util/channel';
import { isWindowEmbedded } from '@/util/embed';

@Component({
  components: { Logo, LoginForm, FanfestEventCard, NotificationSettings }
})
export default class Channel extends Vue {
  channel: FanfestConfigClass = new FanfestConfigClass({ ...empty });
  events: FanfestEventClass[] = [];
  loading = true;
  error: string | null = null;

  notificationsMenu = false;
  loginDialog = false;

  // post login action callback
  postLoginAction: (() => void) | null = null;

  metaInfo(): MetaInfo {
    const channel = this.channel;
    const title = channel.title + ' FanFest';
    const description =
      channel.description || 'Come watch a FanFest of your favorite team!';
    return {
      title: title,
      meta: [
        {
          vmid: 'description',
          name: 'description',
          content: description
        },
        {
          vmid: 'twitter:title',
          property: 'twitter:title',
          content: title
        },
        {
          vmid: 'twitter:description',
          property: 'twitter:description',
          content: description
        },
        {
          vmid: 'og:title',
          property: 'og:title',
          content: title
        },
        {
          vmid: 'og:description',
          property: 'og:description',
          content: description
        },
        {
          vmid: 'og:image',
          property: 'og:image',
          content:
            channel.image?.url ||
            'https://live.fanfest.io/logo/logo_square_fanfest_1024.png'
        }
      ]
    };
  }

  get isLogged(): boolean {
    return UserModule.isLogged;
  }

  get hasDashboard(): boolean {
    return (
      UserModule.user.role === Roles.Admin ||
      UserModule.user.role === Roles.ChannelAdmin ||
      UserModule.user.role === Roles.Producer
    );
  }

  get notFound(): boolean {
    return this.error === ChannelError.NOT_FOUND;
  }

  onLoggedIn(): void {
    this.loginDialog = false;
    if (this.postLoginAction) {
      this.postLoginAction();
      this.postLoginAction = null;
    }
  }

  onOpenLogin(postLoginAction: (() => void) | null = null): void {
    if (UserModule.isLogged && postLoginAction) {
      postLoginAction();
    } else {
      this.loginDialog = true;
      this.postLoginAction = postLoginAction;
    }
  }

  showNotificationsMenu(): void {
    this.onOpenLogin(() => {
      this.notificationsMenu = true;
    });
  }

  async created() {
    this.loading = true;

    try {
      await this.fetchChannel(this.$route.params?.channel);

      await this.fetchEvents(this.$route.params?.channel);
    } catch (error) {
      if (error instanceof Error && error.message === ChannelError.NOT_FOUND) {
        this.error = error.message;
        return;
      }

      graphQLErrorAlert(error);
    } finally {
      this.loading = false;
    }
  }

  get isEmbedded(): boolean {
    return isWindowEmbedded();
  }

  get htmlClassName(): string {
    return this.isEmbedded ? 'channel-page-embedded' : 'channel-page';
  }

  get contentContainer(): string {
    return this.isEmbedded ? 'div' : 'v-container';
  }

  mounted(): void {
    document.documentElement.classList.add(this.htmlClassName);
  }

  beforeDestroy(): void {
    document.documentElement.classList.remove(this.htmlClassName);
  }

  async fetchChannel(channel: string): Promise<void> {
    const result = await this.$apollo.query({
      query: require('../components/crud/FanfestConfig/getFanfestConfigByName.graphql'),
      variables: {
        name: channel
      }
    });
    if (result.data.fanfestConfigs.count !== 1) {
      EventBus.$emit(Events.AlertError, 'Could not fetch channel data');
      throw new Error(ChannelError.NOT_FOUND);
    } else {
      this.$set(
        this,
        'channel',
        FanfestConfigGraphQLTranslator(result.data.fanfestConfigs.edges[0].node)
      );
      if (!this.channel.title) {
        this.channel.title = this.channel.name;
      }

      // TODO this is wrong. this will set user properties for what is event data.
      // but it was requested to be done this way.
      // https://www.notion.so/playvici/Amplitude-user-tracking-implementation-incomplete-352fe64b046e4dd491f2bb0bd4aa3be8
      Analytics.setUserProperties(this.channel.trackData());
    }
  }

  async fetchEvents(channel: string): Promise<void> {
    const result = await this.$apollo.query({
      query: require('../components/crud/FanfestEvent/getEventsForChannelPage.graphql'),
      variables: {
        channel: channel
      }
    });
    this.$set(
      this,
      'events',
      result.data.fanfestEvents.edges
        .map(
          (i: any): FanfestEventClass => FanfestEventGraphQLTranslator(i.node)
        )
        .filter(
          (event: FanfestEventClass) =>
            event.isActive && event.mode !== FanfestEventMode.ReplayUnavailable
        )
    );
  }

  onLogout(): void {
    EventBus.$emit(Events.LoggedOut);
  }

  get backgroundGradient(): string {
    return `linear-gradient(
      180deg,
      #121212 0%,
      rgba(23, 25, 28, 0) 70.15%,
      #121212 100%
    ),
    ${this.channel.channelHeroColor}`;
  }
}
