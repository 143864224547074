import { Roles } from '@common/types/Roles';
import FanfestEventEdit from './FanfestEventEdit.vue';
import FanfestEventList from './FanfestEventList.vue';
import FanfestEventView from './FanfestEventView.vue';

export default [
  {
    path: 'fanfestEvent',
    name: 'fanfestEventList',
    component: FanfestEventList,
    meta: { roles: [Roles.Admin, Roles.ChannelAdmin, Roles.Producer] }
  },
  {
    path: 'fanfestEvent/:id?/edit',
    name: 'fanfestEventEdit',
    component: FanfestEventEdit,
    meta: { roles: [Roles.Admin, Roles.ChannelAdmin, Roles.Producer] }
  },
  {
    path: 'fanfestEvent/:id?/view',
    name: 'fanfestEventView',
    component: FanfestEventView,
    meta: {
      roles: [Roles.Admin, Roles.ChannelAdmin, Roles.Producer, Roles.Moderator]
    }
  }
];
