import { Maybe, Scalars, User } from '@/generated/graphql';
import { Roles } from '@common/types/Roles';
import { FanfestConfigClass } from '.';
import { DateISOString, Email, Id } from './base';
import { empty as FanfestConfigEmpty } from '../components/crud/FanfestConfig/data';

export interface UserPrivate {
  email: Email;
  canEmail: boolean;
  password?: string;
}

/**
 * Patch the frontend types that we don't have on GraphQL and remove
 * stuff we don't need
 */
export type UserInterface = Omit<User, 'ACL' | 'worksChannelPointer'> & {
  worksChannelPointer?: Maybe<FanfestConfigClass>;
};

export class UserClass implements UserInterface {
  __typename?: 'User' | undefined;
  objectId = '';
  id: Id = '';
  createdAt: DateISOString = '';
  updatedAt: DateISOString = '';

  email: Email = '';
  password?: string;
  username: Maybe<Scalars['String']> = '';
  emailVerified?: Maybe<Scalars['Boolean']>;
  externalID?: Maybe<Scalars['String']>;
  name: Scalars['String'] = '';
  role: Roles = Roles.Guest;
  language: Maybe<Scalars['String']> = 'en';
  timezone: Maybe<Scalars['String']> = '';
  worksChannelPointer?: Maybe<FanfestConfigClass> = null;
  phone = '';
  smsNotifications = false;
  whatsappNotifications = false;
  emailNotifications = false;

  constructor (data: User | UserInterface | UserClass) {
    Object.assign(this, data);
    this.worksChannelPointer = new FanfestConfigClass(
      data.worksChannelPointer ? data.worksChannelPointer : FanfestConfigEmpty
    );
  }
}

/**
 * Converts data that comes from GraphQL to the expected values/types.
 *
 * @param configData GraphQL data
 * @returns A properly built interface
 */
export function UserGraphQLTranslator (configData: User): UserClass {
  const config = new UserClass({ ...configData });
  return config;
}
