import { Roles } from '@common/types/Roles';
import UserEdit from './UserEdit.vue';
import UserList from './UserList.vue';
import UserInvite from './UserInvite.vue';

export default [
  {
    path: 'user',
    name: 'userList',
    component: UserList,
    meta: { roles: [Roles.Admin, Roles.ChannelAdmin] }
  },
  {
    path: 'user/:id?/edit',
    name: 'userEdit',
    component: UserEdit,
    meta: { roles: [Roles.Admin, Roles.ChannelAdmin] }
  },
  {
    path: 'userInvite',
    name: 'userInvite',
    component: UserInvite,
    meta: { roles: [Roles.Admin, Roles.ChannelAdmin] }
  }
];
