










































































































import { OfferClass, OfferGraphQLTranslator } from '../../../interfaces';
import { empty } from './data';
import { Component, Vue, Ref, Watch } from 'vue-property-decorator';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import { Id } from '@/interfaces/base';
import EventBus from '@/components/eventbus/EventBus.vue';
import { Events } from '@/components/eventbus/events';
import { graphQLErrorAlert } from '@/util/GraphQL';
import AutocompletePointer from '@/components/crud/Base/AutocompletePointer.vue';
import { UserModule } from '@/store/user';
import { Roles } from '@common/types/Roles';
import OfferShow from './OfferShow.vue';
import { squareCropImage } from '@/util/images';

@Component({
  components: {
    AutocompletePointer,
    ValidationObserver,
    ValidationProvider,
    OfferShow
  },

  apollo: {
    // Apollo specific options
  }
})
export default class OfferEdit extends Vue {
  @Ref() readonly observer!: InstanceType<typeof ValidationProvider>;

  readonly fanfestConfigQuery = require('../FanfestConfig/autocompleteFanfestConfigs.graphql');

  offer: OfferClass = new OfferClass({ ...empty });

  image: File | null = null;
  imagePreview: string | null = null;

  loading = false;

  @Watch('image')
  onImageChanged(newValue: File | null) {
    if (!newValue) {
      this.imagePreview = this.offer?.image?.url || '';
    } else {
      this.imagePreview = URL.createObjectURL(newValue);
    }
  }

  onUrlChange(value: string | null) {
    if (!value) return;

    if (
      value.length < (this.offer?.url?.length ?? 0) ||
      value?.startsWith('https://')
    ) {
      this.offer.url = value;
    } else {
      this.offer.url = 'https://' + value;
    }
  }

  get channelEditable(): boolean {
    return UserModule.user.role === Roles.Admin && !this.offer.objectId;
  }

  async mounted(): Promise<void> {
    if (this.$route?.params.id) {
      await this.load(this.$route.params.id);
    }
    if (UserModule.user.role !== Roles.Admin) {
      this.offer.channelPointer.objectId =
        UserModule.user.worksChannelPointer?.objectId || '';
    }
  }

  async load(id: Id): Promise<void> {
    try {
      this.loading = true;
      const result = await this.$apollo.query({
        // Query
        query: require('./getOffer.graphql'),
        // Parameters
        variables: {
          id: id
        }
      });
      this.$set(this, 'offer', OfferGraphQLTranslator(result.data.offer));
      if (this.offer.image && this.offer.image.url) {
        this.imagePreview = this.offer.image.url;
      }
    } catch (error) {
      graphQLErrorAlert(error);
    } finally {
      this.loading = false;
    }
  }

  async save(): Promise<void> {
    this.observer.validate();
    const offerData: { [k: string]: any } = {
      title: this.offer.title,
      subtitle: this.offer.subtitle ?? '',
      url: this.offer.url,
      channelPointer: {
        link: this.offer.channelPointer?.objectId
      },
      isActive: this.offer.isActive
    };
    if (this.image) {
      offerData.image = {
        upload: await squareCropImage(this.image)
      };
    }

    try {
      this.loading = true;

      if (this.offer.id) {
        await this.$apollo.mutate({
          // Query
          mutation: require('./updateOffer.graphql'),
          // Parameters
          variables: {
            id: this.offer.id,
            offer: offerData
          }
        });
      } else {
        await this.$apollo.mutate({
          mutation: require('./createOffer.graphql'),
          // Parameters
          variables: {
            offer: offerData
          }
        });
      }
      EventBus.$emit(Events.AlertSuccess, this.$t('form.saved'));
      this.$router.push({ path: '/dashboard/offer' });
    } catch (error) {
      graphQLErrorAlert(error);
    } finally {
      this.loading = false;
    }
  }
}
