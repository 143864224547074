





















































import { UserModule } from '@/store/user';
import { Vue, Component } from 'vue-property-decorator';
import EventBus from '../eventbus/EventBus.vue';
import { Events } from '../eventbus/events';

@Component({})
export default class NavigationListChannelAdmin extends Vue {
  get channelId(): string {
    return UserModule.user.worksChannelPointer?.id || '';
  }

  get channelURL(): string {
    return UserModule.myChannelURI || '';
  }

  get channelURLEdit(): string {
    return (
      '/dashboard/fanfestConfig/' +
      UserModule.user.worksChannelPointer?.objectId +
      '/edit'
    );
  }

  onLogout(): void {
    EventBus.$emit(Events.LoggedOut, { path: '/' });
  }
}
