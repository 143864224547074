




















































































































































































































































import {
  FanfestConfigClass,
  FanfestConfigGraphQLTranslator
} from '@/interfaces';
import { Component, Vue, Ref, Watch } from 'vue-property-decorator';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import { empty } from './data';
import { Id } from '@/interfaces/base';
import { graphQLErrorAlert } from '@/util/GraphQL';
import { Events } from '@/components/eventbus/events';
import EventBus from '@/components/eventbus/EventBus.vue';
import { UserModule } from '@/store/user';
import { Roles } from '@common/types/Roles';
import AutocompletePointer from '@/components/crud/Base/AutocompletePointer.vue';
import AddressEdit from '@/components/crud/Address/AddressEdit.vue';
import { squareCropImage } from '@/util/images';

@Component({
  components: {
    ValidationObserver,
    ValidationProvider,
    AutocompletePointer,
    AddressEdit
  }
})
export default class FanfestConfigEdit extends Vue {
  @Ref() readonly observer!: InstanceType<typeof ValidationProvider>;
  @Ref() readonly addressEdit!: InstanceType<typeof AddressEdit>;

  fanfestConfig: FanfestConfigClass = new FanfestConfigClass({
    ...empty,
    isActive: true
  });

  loading = false;
  image: File | null = null;
  imagePreview: string | null = null;
  sponsorImage: File | null = null;
  sponsorImagePreview: string | null = null;
  eventBackground: File | null = null;
  eventBackgroundPreview: string | null = null;
  channelBackgroundPreview: string | null = null;
  channelBackground: File | null = null;

  @Watch('image')
  onImageChanged(newValue: File | null) {
    if (!newValue) {
      this.imagePreview = this.fanfestConfig?.image?.url || '';
    } else {
      this.imagePreview = URL.createObjectURL(newValue);
    }
  }

  @Watch('eventBackground')
  onEventBackgroundPreviewChanged(newValue: File | null) {
    if (!newValue) {
      this.eventBackgroundPreview =
        this.fanfestConfig?.eventBackground?.url || '';
    } else {
      this.eventBackgroundPreview = URL.createObjectURL(newValue);
    }
  }

  @Watch('channelBackground')
  onChannelBackgroundPreviewChanged(newValue: File | null) {
    if (!newValue) {
      this.channelBackgroundPreview =
        this.fanfestConfig?.channelBackground?.url || '';
    } else {
      this.channelBackgroundPreview = URL.createObjectURL(newValue);
    }
  }

  @Watch('sponsorImage')
  onSponsorImagePreviewChanged(newValue: File | null) {
    if (!newValue) {
      this.sponsorImagePreview = this.fanfestConfig?.sponsorImage?.url || '';
    } else {
      this.sponsorImagePreview = URL.createObjectURL(newValue);
    }
  }

  sponsorImageDelete() {
    this.sponsorImage = null;
    this.sponsorImagePreview = null;
  }

  get requiredImage(): string {
    if (this.fanfestConfig.id) {
      return '';
    }
    return 'required|';
  }

  get nameEditable(): boolean {
    return this.isAdmin && !this.fanfestConfig.id;
  }

  get isAdmin(): boolean {
    return UserModule.user.role === Roles.Admin;
  }

  async mounted(): Promise<void> {
    if (this.$route.params.id) {
      await this.load(this.$route.params.id);
    }
  }

  async load(id: Id): Promise<void> {
    try {
      this.loading = true;
      const result = await this.$apollo.query({
        // Query
        query: require('./getFanfestConfigWithAddress.graphql'),
        // Parameters
        variables: {
          id: id
        }
      });

      if (result.data === null) {
        EventBus.$emit(
          Events.AlertError,
          this.$t('channel.notFoundOrInactive')
        );
        this.$router.replace('/dashboard');
        return;
      }

      this.$set(
        this,
        'fanfestConfig',
        FanfestConfigGraphQLTranslator(result.data.fanfestConfig)
      );
      if (this.fanfestConfig.image && this.fanfestConfig.image.url) {
        this.imagePreview = this.fanfestConfig.image.url;
      }
      if (
        this.fanfestConfig.eventBackground &&
        this.fanfestConfig.eventBackground.url
      ) {
        this.eventBackgroundPreview = this.fanfestConfig.eventBackground.url;
      }
      if (
        this.fanfestConfig.sponsorImage &&
        this.fanfestConfig.sponsorImage.url
      ) {
        this.sponsorImagePreview = this.fanfestConfig.sponsorImage.url;
      }
      if (
        this.fanfestConfig.channelBackground &&
        this.fanfestConfig.channelBackground.url
      ) {
        this.channelBackgroundPreview =
          this.fanfestConfig.channelBackground.url;
      }
    } catch (error) {
      graphQLErrorAlert(error);
    } finally {
      this.loading = false;
    }
  }

  async save(): Promise<void> {
    this.observer.validate();

    const fanfestConfigData: { [k: string]: any } = {
      title: this.fanfestConfig.title,
      description: this.fanfestConfig.description,
      isActive: this.fanfestConfig.isActive,
      channelHeroColor: this.fanfestConfig.channelHeroColor,
      sponsorName: this.fanfestConfig.sponsorName,
      enableGatedEvents: this.fanfestConfig.enableGatedEvents,
      enableShowCreationNotifications:
        this.fanfestConfig.enableShowCreationNotifications
    };

    if (this.nameEditable) {
      fanfestConfigData.name = this.fanfestConfig.name;
    }
    if (this.image) {
      fanfestConfigData.image = {
        upload: await squareCropImage(this.image)
      };
    }
    if (this.eventBackground) {
      fanfestConfigData.eventBackground = {
        upload: this.eventBackground
      };
    }
    if (this.channelBackground) {
      fanfestConfigData.channelBackground = {
        upload: this.channelBackground
      };
    }
    if (this.sponsorImage) {
      fanfestConfigData.sponsorImage = {
        upload: await squareCropImage(this.sponsorImage)
      };
    } else if (this.fanfestConfig.image?.url) {
      // TODO: delete file first
      fanfestConfigData.sponsorImage = {
        file: null
      };
    }

    try {
      this.loading = true;
      const addressData = await this.addressEdit.save();
      fanfestConfigData.addressPointer = {
        link: addressData.objectId
      };

      if (this.fanfestConfig.id) {
        await this.$apollo.mutate({
          // Query
          mutation: require('./updateFanfestConfig.graphql'),
          // Parameters
          variables: {
            id: this.fanfestConfig.id,
            fanfestConfig: fanfestConfigData
          }
        });
      } else {
        await this.$apollo.mutate({
          mutation: require('./createFanfestConfig.graphql'),
          // Parameters
          variables: {
            fanfestConfig: fanfestConfigData
          }
        });
      }
      EventBus.$emit(Events.AlertSuccess, this.$t('form.saved'));
      if (UserModule.user.role === Roles.Admin) {
        this.$router.push({ path: '/dashboard/fanfestConfig' });
      } else {
        this.$router.push({ path: '/dashboard' });
      }
    } catch (error) {
      graphQLErrorAlert(error);
    } finally {
      this.loading = false;
    }
  }
}
