














































import { Vue, Component } from 'vue-property-decorator';
import LargeIconButton from '@/components/base/LargeIconButton.vue';
import { UserModule } from '@/store/user';
import RecentEventList from '@/components/crud/FanfestEvent/RecentEventList.vue';

@Component({ components: { LargeIconButton, RecentEventList } })
export default class DashboardProducer extends Vue {
  get channelEditURL(): string {
    return UserModule.user.worksChannelPointer?.url + '/edit';
  }
}
