











import { VideoClass } from '../../../interfaces';
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({
  components: {}
})
export default class VideoShow extends Vue {
  @Prop({})
  readonly video!: VideoClass;

  onPlay (): void {
    this.$emit('play', this.video);
  }
}
