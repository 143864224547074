











import { FanfestEventClass } from '@/interfaces';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { getShareableEventLink } from '@/util/share';
import { navigateTo } from '@/util/navigator';

@Component({})
export default class TwitterShareAnchor extends Vue {
  @Prop()
  readonly event!: FanfestEventClass;

  fanfestLink = '';

  async mounted() {
    const link = await getShareableEventLink(this.event);
    this.fanfestLink = link;
  }

  get twitterShareLink() {
    return (
      'https://twitter.com/intent/tweet?text=' +
      encodeURIComponent(this.fanfestLink)
    );
  }

  async onTwitterShare(event: Event): Promise<void> {
    // Trick for embedded to work - Twitter disables cross-origin features.
    event.preventDefault();
    event.stopPropagation();
    event.stopImmediatePropagation();

    await navigateTo(this.twitterShareLink);
  }
}
