import { Module, Mutation, VuexModule, getModule } from 'vuex-module-decorators';

import store from './index';

@Module({
    store,
    namespaced: true,
    name: 'videoControls',
    dynamic: true
})
class VideoControlsVuex extends VuexModule {
    isMirrored = false;

    @Mutation
    setIsMirrored(isMirrored: boolean) {
        this.isMirrored = isMirrored;
    }
}

export const VideoControlsModule = getModule(VideoControlsVuex);
